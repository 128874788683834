import styled from '@emotion/styled';
import { Body, Button, CardFooter } from '@sumup/circuit-ui';

export const StyledBody = styled(Body)`
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 30px;
`;
export const StyledButton = styled(Button)`
  min-width: 100px;
  margin-left: 16px;
`;
export const MainContainer = styled.div`
  width: 770px;
  margin: 0 auto;
`;
export const StyledHeaderRow = styled.header`
  height: 64px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  top: -24px;
  z-index: 23;
`;
export const StyledRow = styled.div`
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.shadow};
  padding: 6px 0 0 0;
  height: 64px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const StyledHeaderCell = styled.div`
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  line-height: initial;
`;
export const StyledRowItem = styled.div`
  font-weight: 400;
  font-size: 13px;
  width: 30%;
  text-align: center;
  h2 {
    font-size: 13px;
  }
  & > div {
    width: 100%;
  }
`;
export const StyledCardFooter = styled(CardFooter)`
  display: flex;
  flex-direction: column;
  height: auto;
  bottom: 0px;
  left: 20px;
  width: 100%;
  background-color: white;
`;
export const StyledRowsContainer = styled.div`
  max-height: 220px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 30px ${(p) => p.theme.colors.p700};
    -webkit-box-shadow: inset 0 0 30px ${(p) => p.theme.colors.p700};
    border-radius: 6px;
  }
`;
