import styled from '@emotion/styled';

export const InvalidFormatImage = styled.div`
  background-color: #fbeded;
  font-size: 12px;
  padding: 12px;
  border-radius: 8px;
  color: #db4d4d;
  position: absolute;
  width: 100%;
  margin: 0 6px;
  max-width: 1152px;
  animation: slide 10s;
  animation-fill-mode: forwards;
  display: flex;
  align-items: center;

  p:first-child {
    font-size: 16px;
    margin: 0 14px;
  }

  @keyframes slide {
    0% {
      opacity: 0;
      bottom: 30px;
    }
    5% {
      opacity: 1;
      bottom: 60px;
    }
    95% {
      opacity: 1;
      bottom: 60px;
    }
    100% {
      bottom: 90px;
      opacity: 0;
    }
  }

  b {
    font-weight: bold;
  }
`;
export const Container = styled.div`
  position: relative;
`;

export const StyleEditor = styled.div<{ readonly: boolean }>`
  padding: 24px 18px;
  background-color: white;
  max-width: 1164px;
  height: 575px;
  margin: 20px auto;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid #323e4d;
  border-radius: 8px;

  .ql-container .ql-editor {
    width: 100%;
    height: 506px;
    border-bottom: 1px solid rgba(33, 33, 33, 0.08);
    margin: auto;
    overflow-y: auto;
  }

  .ql-editor.ql-blank::before {
    font-size: 34px;
    font-style: normal !important;
    color: rgb(133, 133, 133);
  }

  .ql-editor::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
    margin-right: 100px;
    margin-left: 100px;
  }

  .ql-editor::-webkit-scrollbar-thumb {
    background-color: #3063e9; /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
  }

  .ql-snow {
    border: none !important;
    display: flex;
    justify-content: end;
  }

  .ql-active {
    span {
      color: #3063e9;
    }
  }

  .ql-toolbar {
    ${({ readonly }) =>
      readonly
        ? 'outline: none; pointer-events: none; opacity: 30%; cursor: not-allowed'
        : ''}
  }

  img[src='invalid format'] {
    display: none;
  }

  .material-icons {
    cursor: ${({ readonly }) => (readonly ? 'not-allowed' : 'default')};
    color: rgba(0, 0, 0, 0.54);
  }

  .header_icon {
    cursor: ${({ readonly }) => (readonly ? 'not-allowed' : 'default')};
    color: rgba(0, 0, 0, 0.54);
    font-weight: bold;
    font-size: 16px;
  }

  b,
  strong {
    font-weight: bold !important;
  }

  sub {
    vertical-align: sub;
  }
  sup {
    vertical-align: super;
  }

  em {
    font-style: italic !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value) !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before {
    content: 'Normal' !important;
  }

  .ql-container {
    font-size: 34px !important;
  }
`;
