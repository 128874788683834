import styled from '@emotion/styled';

export const StyledButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1164px;
  position: relative;
`;
export const StyledBackIcon = styled.div`
  position: absolute;
  left: 0;
  top: 15px;
  svg {
    cursor: pointer;
    position: absolute;
    left: 0;
    color: ${(p) => p.theme.colors.p500};
  }
`;
