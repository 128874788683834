import styled from '@emotion/styled';
import { Cabecalho } from '../../components/SelectUserDialog/styles';

export const StyledBoxPage = styled.div`
  width: auto;
  margin: 0 auto;
  width: 100%;
`;
export const StyledButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 24px 0;
`;
export const CustomCabecalho = styled(Cabecalho)`
  grid-template-columns: 10% repeat(5, 18%);
  text-align: center;
`;
