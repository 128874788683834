import styled from '@emotion/styled';
import { Body, Popover } from '@sumup/circuit-ui';

export const StyledChildrenBox = styled(Body)`
  color: ${(props) => props.color};
  padding-left: 10px;
`;
export const StyledIcon = styled.div`
  cursor: pointer;
  .options-disabled {
    cursor: not-allowed;
  }
`;
export const StyledPopover = styled(Popover)`
  button:focus {
    box-shadow: none;
  }
`;
