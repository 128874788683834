import styled from '@emotion/styled';

export const HeaderStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);

  h1 {
    color: #3063e9;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
  }

  span {
    display: flex;
    align-items: center;
    font-size: 14px;

    p {
      padding: 20px 0;
      margin: 0 12px;
    }

    p:first-of-type {
      font-weight: bold;
      font-size: 14px;
    }
  }
`;

export const ContentBody = styled.div`
  width: 100%;
  min-height: 60vh;
  height: auto;
  display: flex;
  justify-content: center;
`;

export const TableStats = styled.div`
  font-size: 13px;
  width: 100%;
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1.3fr 1fr 1fr 1fr 0.9fr;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  padding: 16px 48px;

  div {
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
  }

  p {
    margin: auto;
    text-align: center;
    overflow: hidden;
    max-width: 20ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 8px;

    span {
      color: #9da5b1;
    }
  }
`;

export const HeaderTable = styled(TableRow)`
  font-weight: bold;
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;

  p {
    font-size: 13px;
    color: #9da5b1;
  }
`;
