import styled from '@emotion/styled';

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
`;
export const SubTitle = styled.p`
  font-size: 13px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
