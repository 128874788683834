import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  width: 90%;
  margin: auto;

  h1 {
    color: #0d062d;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 32px;
  }
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 60%;
  width: 90vw;
  max-width: 1400px;

  .p-multiselect {
    width: 261px;

    &:hover {
      border: 1px solid #3388ff;
      box-shadow: none;
    }

    @media (max-width: 1500px) {
      width: 215px;
    }
  }

  .p-treeselect {
    width: 261px;

    &:hover {
      border: 1px solid #3388ff;
      box-shadow: none;
    }

    @media (max-width: 1500px) {
      width: 215px;
    }

    margin-right: 15px;
    .p-highlight {
      background: red;
    }
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: black;
    color: red;
  }

  .p-highlight {
    background: none;
    color: #4338ca;
  }
`;

export const FilterButton = styled.button`
  color: white;
  background-color: #3063e9;
  height: 48px;
  width: 110px;
  text-align: center;
  padding: 3px 0;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 25px;
  margin-left: 6px;
  border: none;
  cursor: pointer;
`;

export const Title = styled.div`
  font-weight: bold;
  margin: 8px 0;
  font-size: 28px;
`;

export const SubTitle = styled.div`
  font-size: 13px;
  margin-bottom: 48px;
`;

export const BoxAlignFilters = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90vw;
`;
