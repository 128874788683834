import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Input } from '@sumup/circuit-ui';

export const StyledIcon = styled.div(
  ({ theme }) => css`
    position: absolute;
    right: 9px;
    bottom: 7px;
    color: ${theme.colors.n800};
    cursor: pointer;
  `
);

export const StyledInput = styled(Input)`
  font-size: 13px;
  text-align: center;
  padding-right: 16px;
  box-shadow: none;
  &:hover,
  &:focus {
    box-shadow: none;
  }
  &:read-only {
    background-color: #fff;
  }
  &.textarea {
    margin-top: 10px;
    line-height: initial;
    height: 50px;
    resize: none;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 30px ${(p) => p.theme.colors.p700};
      -webkit-box-shadow: inset 0 0 30px ${(p) => p.theme.colors.p700};
      border-radius: 6px;
    }
  }
`;
