import { SelectOption } from '@sumup/circuit-ui';

import { ReactComponent as Notify } from '../assets/icons/notify.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Loading } from '../assets/icons/loading.svg';
import { ReactComponent as Done } from '../assets/icons/done.svg';

export const ALCADA_FILTER_OPTIONS: SelectOption[] = [
  { value: '-1', label: 'Todos' },
  { value: '6', label: 'Aguardando Assinatura' },
  { value: '3', label: 'Aprovada' },
  { value: '8', label: 'Assinada' },
  { value: '7', label: 'Cancelado' },
  { value: '5', label: 'Concluída' },
  { value: '1', label: 'Em andamento' },
  { value: '2', label: 'Processando' },
  { value: '4', label: 'Reprovada' }
];

export const ALCADA_FILTER_DEFAULT_VALUE = '-1';

export const getAssetsPropostaByEnum = {
  1: {
    name: 'Em andamento',
    color: '#3063E9',
    icon: <Loading />
  },
  2: {
    name: 'Processando',
    color: '#3063E9',
    icon: <Loading />
  },
  3: {
    name: 'Aprovada',
    color: '#47995A',
    icon: <Notify />
  },
  4: {
    name: 'Reprovada',
    color: '#DB4D4D',
    icon: <Close />
  },
  5: {
    name: 'Concluída',
    color: '#47995A',
    icon: <Done />
  },
  6: {
    name: 'Aguardando Assinatura',
    color: '#3063E9',
    icon: <Loading />
  },
  7: {
    name: 'Cancelado',
    color: '#DB4D4D',
    icon: <Close />
  },
  8: {
    name: 'Assinada',
    color: '#47995A',
    icon: <Notify />
  }
};

export const tipoOferta = [
  {
    label: 'Todos',
    value: '0'
  },
  {
    label: 'Taxa',
    value: '1'
  },
  {
    label: 'Maquinas',
    value: '2'
  }
];

export const statusNotaFiscal = [
  {
    label: 'Não se aplica',
    value: 0
  },
  {
    label: 'Emitida',
    value: 1
  },
  {
    label: 'Falha',
    value: 2
  }
];

export const tipoAquisicao = [
  {
    label: 'Todos',
    value: '0'
  },
  {
    label: 'Venda',
    value: '1'
  },
  {
    label: 'Comodato',
    value: '2'
  }
];
