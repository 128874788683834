export enum ENUM_STATUS_PROPOSTA {
  EM_ANDAMENTO = 1,
  EM_PROCESSAMENTO = 2,
  APROVADA = 3,
  REPROVADA = 4,
  CONCLUIDA = 5,
  AGUARDANDO_ASSINATURA = 6,
  CANCELADO = 7,
  ASSINADA = 8
}
export enum TipoAquisicao {
  Venda = 1,
  Comodato = 2,
  Ambos = 3
}
