import { Add, Edit } from '@sumup/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from '../../../constants/api.constants';
import { STORAGE } from '../../../constants/storage.constants';
import {
  IDataResponseModel,
  IResponseListToEdit
} from '../../../interfaces/requests.interfaces';
import { patch } from '../../../services/axios.service';
import { StatusOptions } from '../../../services/filters.service';
import {
  CustomIconSelect,
  CustomPopover,
  CustomTooltip,
  Spinner
} from '../../_Atoms';
import { IStatusName } from '../../_Atoms/CustomIconSelect';
import { NoResultsState } from '../../_Molecules';
import NetworkError from '../NetWorkError';
import {
  StyledCustomCellTitle,
  StyledCustomContent,
  StyledCustomRowContent,
  StyledCustomTableTitles
} from './styles';

export enum ENUM_ACTION_ICON_TYPE {
  EDIT = 1,
  ADD = 2
}
export interface ListItem extends IResponseListToEdit {
  actions: {
    url?: string;
    color: string;
    text: string;
    onClick?: () => void;
    IconType: ENUM_ACTION_ICON_TYPE;
  }[];
}
interface ICustomEditListTable {
  mainColumnName: string;
  statusName: IStatusName;
  listData: ListItem[];
  resourceUrl: string;
  clearSearchInput?: () => void;
  filters?: {
    status: StatusOptions;
    search: string;
  };
  currentPage?: number;
  networkError?: boolean;
  loading?: boolean;
  retry?: () => void;
  backRoute?: () => void;
}

const CustomEditListTable: React.FC<ICustomEditListTable> = ({
  mainColumnName,
  statusName,
  listData,
  resourceUrl,
  filters,
  currentPage,
  networkError = false,
  loading = false,
  retry,
  backRoute,
  clearSearchInput
}) => {
  const { push } = useHistory();
  const [listDataTable, setListDataTable] = useState<ListItem[]>([]);
  const { active, disabled } = statusName;

  const onClickAction = (id: number, url?: string, onClick?: () => void) => {
    if (filters && currentPage && resourceUrl === API.OFERTAS.EDIT()) {
      localStorage.setItem(
        STORAGE.HISTORY_PAGINATION_OFFERS,
        JSON.stringify({ filters, currentPage })
      );
    } else if (filters && currentPage) {
      localStorage.setItem(
        STORAGE.HISTORY_PAGINATION_PLANS,
        JSON.stringify({ filters, currentPage })
      );
    }

    if (url) {
      push(`${url}/${id}`);
    } else if (onClick) {
      onClick();
    }
  };

  const handleOptionChange = async (value: boolean, idItem: number) => {
    const fetchUrl = `${resourceUrl}/${idItem}`;
    try {
      const res = await patch<IDataResponseModel<null>>(fetchUrl, {
        ativo: value
      });

      if (res.success) {
        const userListUpdated = listDataTable.map((item) =>
          item.id === idItem ? { ...item, ativo: value } : item
        );
        setListDataTable(userListUpdated);
      }
    } catch (error) {
      // TODO integrate an error message
      JSON.stringify(error);
    }
  };

  const handlePopoverActionIcon = (
    IconType: ENUM_ACTION_ICON_TYPE,
    color: string
  ) => {
    if (IconType === ENUM_ACTION_ICON_TYPE.EDIT) {
      return <Edit size="16" color={color} />;
    }
    if (IconType === ENUM_ACTION_ICON_TYPE.ADD) {
      return <Add style={{ height: 16, width: 16 }} color={color} />;
    }
    return <Edit size="16" color={color} />;
  };

  useEffect(() => {
    setListDataTable(listData);
  }, [listData]);

  return (
    <>
      <StyledCustomTableTitles>
        <StyledCustomCellTitle>Status</StyledCustomCellTitle>
        <StyledCustomCellTitle>{mainColumnName}</StyledCustomCellTitle>
        <StyledCustomCellTitle>Ações</StyledCustomCellTitle>
      </StyledCustomTableTitles>
      {!!listDataTable.length &&
        !loading &&
        listDataTable.map((item) => (
          <StyledCustomRowContent key={item.id}>
            <StyledCustomContent>
              <CustomIconSelect
                active={item.ativo}
                idItem={item.id}
                onOptionChange={handleOptionChange}
                statusName={{ active, disabled }}
              />
            </StyledCustomContent>
            <CustomTooltip title={item.nome}>
              <StyledCustomContent>
                <p style={{ opacity: item.ativo ? '1' : '0.5' }}>{item.nome}</p>
              </StyledCustomContent>
            </CustomTooltip>
            <StyledCustomContent>
              <CustomPopover
                actions={item.actions.map((action) => ({
                  children: {
                    color: action.color,
                    text: action.text
                  },
                  icon: () =>
                    handlePopoverActionIcon(action.IconType, action.color),
                  onClick: () =>
                    onClickAction(item.id, action.url, action.onClick)
                }))}
                disabled={!item.ativo}
              />
            </StyledCustomContent>
          </StyledCustomRowContent>
        ))}
      {networkError && !loading && <NetworkError retry={retry} />}

      {loading && (
        <div style={{ height: 400, display: 'flex', alignItems: 'center' }}>
          <Spinner size="46px" color="#3388ff" width="5px" />
        </div>
      )}

      {listData.length === 0 &&
        !loading &&
        !networkError &&
        filters?.search && (
          <NoResultsState
            action={clearSearchInput}
            buttonLabel="Resetar busca"
          />
        )}
    </>
  );
};

export default CustomEditListTable;
