import styled from '@emotion/styled';
import { StyledCustomHeaderRow } from '../AddDinamicOffersTableHeaders/styles';

export const FiltersContent = styled(StyledCustomHeaderRow)`
  z-index: 22;
  position: relative;
  height: auto;
  padding: 0 0 12px 0;

  select {
    border-radius: 8px;
    width: 90%;
    margin: auto;
    height: 40px;
    border: 1px solid #9da7b1;
    padding: 8px 12px;
    background-color: white;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    background-image: none;
  }

  #nivel {
    position: relative;
    display: flex;
    justify-content: end;
  }

  #nivel::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    border-bottom: 2px solid #9da7b1;
    border-left: 2px solid #9da7b1;
    rotate: -45deg;
    right: 18px;
    top: 15px;
    z-index: -1;
  }

  span {
    font-size: 12px;
    margin-left: -8px;
    margin-top: -7px;
  }

  input {
    border-radius: 8px;
    width: 90%;
    margin: auto;
    height: 40px;
    border: 1px solid #9da7b1;
    padding: 8px 12px;

    &:nth-child(-n + 3) {
      margin-top: -4px;
      padding: 8px 12px 8px 24px;
    }
  }

  input:focus {
    border: 2px solid rgb(48, 99, 233);
    outline: none; /* Remove o contorno padrão no foco (efeito do navegador) */
  }
  select:focus {
    border: 2px solid rgb(48, 99, 233);
    width: 100%;
    outline: none; /* Remove o contorno padrão no foco (efeito do navegador) */
  }
`;
