import { FC } from 'react';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Done } from '../../../assets/icons/done.svg';
import { ReactComponent as Loading } from '../../../assets/icons/loading.svg';
import {
  ENUM_STATUS_PROCESSAMENTO,
  ENUM_TIPO_SOLICITACAO
} from '../../../enums/history-files.enums';
import { formatCreationDate } from '../../../utils/format.utils';
import { CustomPopover, CustomTooltip, Spinner } from '../../_Atoms';
import { SVGDownload } from '../../_Atoms/Icon';
import { NoResultsState } from '../../_Molecules';
import NetworkError from '../NetWorkError';
import { ContentLine, Status, TableHeader } from './style';

export interface IHistoryList {
  id: number;
  statusArquivoSolicitado: number;
  dataCriacao: string;
  emailSolicitante: string;
  tipoArquivoSolicitado: number;
}
interface IProps {
  data: IHistoryList[];
  loading: boolean;
  error: boolean;
  exportFile: (id: number) => void;
}

const HistoryFilesTable: FC<IProps> = ({
  data,
  loading,
  error,
  exportFile
}) => {
  return (
    <div>
      <TableHeader>
        <p>Status de processamento</p>
        <p>Data do Registro</p>
        <p>Email do solicitante</p>
        <p>Tipo de solicitação</p>
        <p>Ações</p>
      </TableHeader>

      {data.length === 0 && !loading && !error && (
        <NoResultsState subtitle2="" />
      )}

      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: (data.length || 6) * 62
          }}
        >
          <Spinner size="48px" width="5px" />
        </div>
      )}

      {error && !loading && (
        <NetworkError
          title="Ops parece que algo deu errado."
          subtitle="Houve um erro no nosso sistema e não pudemos exibir as informações."
          secondSubtitle="Tente novamente mais tarde."
        />
      )}

      {!loading &&
        !error &&
        data.map((item) => (
          <ContentLine key={item.id}>
            <Status status={item.statusArquivoSolicitado}>
              {(item.statusArquivoSolicitado ===
                ENUM_STATUS_PROCESSAMENTO['Em processamento'] ||
                item.statusArquivoSolicitado ===
                  ENUM_STATUS_PROCESSAMENTO['Não iniciado']) && <Loading />}
              {item.statusArquivoSolicitado ===
                ENUM_STATUS_PROCESSAMENTO.Finalizado && <Done />}
              {item.statusArquivoSolicitado ===
                ENUM_STATUS_PROCESSAMENTO.Falha && <Close />}
              {
                ENUM_STATUS_PROCESSAMENTO[
                  item.statusArquivoSolicitado ===
                  ENUM_STATUS_PROCESSAMENTO['Não iniciado']
                    ? ENUM_STATUS_PROCESSAMENTO['Em processamento']
                    : item.statusArquivoSolicitado
                ]
              }
            </Status>

            <p>{formatCreationDate(item.dataCriacao)}</p>
            <CustomTooltip title={item.emailSolicitante}>
              <p>{item.emailSolicitante}</p>
            </CustomTooltip>
            <p>{ENUM_TIPO_SOLICITACAO[item.tipoArquivoSolicitado]}</p>
            <CustomPopover
              disabled={
                item.statusArquivoSolicitado !==
                ENUM_STATUS_PROCESSAMENTO.Finalizado
              }
              actions={[
                {
                  children: {
                    color: '#3063E9',
                    text: 'Baixar arquivo'
                  },
                  icon: () => <SVGDownload />,
                  onClick: () => exportFile(item.id)
                }
              ]}
            />
          </ContentLine>
        ))}
    </div>
  );
};

export default HistoryFilesTable;
