export enum STATUS_CARD {
  EM_PROCESSAMENTO = 2,
  APROVADA = 3,
  REPROVADA = 4
}

export enum SUBSTATUS_CARD {
  QUASE_FECHANDO = 1,
  NEGOCIACAO_DIFICIL = 2,
  TOMADOR_NAO_ENCONTRADO = 3,
  OUTRA = 4,
  NENHUMA_CLASSIFICACAO = 5,
  NAO_ACEITA = 6,
  INVALIDA = 7
}
