import { Checkbox } from '@sumup/circuit-ui';
import { useFormikContext } from 'formik';
import React, { memo, useState } from 'react';
import { IAddMccGroupForm } from '../../interfaces/mccs.interfaces';
import { IResponseRamoAtividades } from '../../interfaces/requests.interfaces';
import { CustomCheckbox } from '../_Atoms';
import {
  StyledCustomCell,
  StyledCustomHeaderCell,
  StyledCustomRow,
  StyledCustomRowContent,
  StyledSelectionBox
} from './styles';

interface IMccsSelection {
  mccsList: IResponseRamoAtividades[];
}
const MccsSelection: React.FC<IMccsSelection> = ({ mccsList }) => {
  const { handleChange, setFieldValue, values } =
    useFormikContext<IAddMccGroupForm>();
  const [allChecked, setAllChecked] = useState(false);

  const handleCheckAll = (isChecked: boolean) => {
    setAllChecked(isChecked);
    setFieldValue(
      'mccIds',
      isChecked ? mccsList.map((item) => String(item.id)) : [],
      true
    );
  };
  return (
    <>
      <StyledCustomCell>MCCs:</StyledCustomCell>

      {!!mccsList.length && (
        <>
          <StyledCustomRow>
            <StyledCustomHeaderCell>
              <Checkbox
                onChange={(e) => handleCheckAll(e.target.checked)}
                checked={allChecked && values.mccIds.length === mccsList.length}
                noMargin
                name="check-all"
                value="checked-all"
              >
                &nbsp;
              </Checkbox>
            </StyledCustomHeaderCell>
            <StyledCustomHeaderCell>Código</StyledCustomHeaderCell>
            <StyledCustomHeaderCell>Título</StyledCustomHeaderCell>
          </StyledCustomRow>
          <StyledSelectionBox>
            {mccsList.map((mcc) => (
              <StyledCustomRowContent key={mcc.id}>
                <StyledCustomCell>
                  <CustomCheckbox
                    onChange={handleChange}
                    value={mcc.id}
                    name="mccIds"
                    checked={values.mccIds.includes(String(mcc.id))}
                  >
                    &nbsp;
                  </CustomCheckbox>
                </StyledCustomCell>
                <StyledCustomCell>
                  {mcc.descricao.split(' ')[0]}
                </StyledCustomCell>
                <StyledCustomCell>
                  {mcc.descricao.split(/[0-9]{1,5}\s/g)[1]}
                </StyledCustomCell>
              </StyledCustomRowContent>
            ))}
          </StyledSelectionBox>
        </>
      )}
    </>
  );
};

export default memo(MccsSelection);
