import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as Done } from '../../../assets/icons/done.svg';
import { ReactComponent as LinkOut } from '../../../assets/icons/link.svg';
import { ReactComponent as Loading } from '../../../assets/icons/loading.svg';
import { API } from '../../../constants/api.constants';
import { ENUM_DATE_OPTIONS } from '../../../constants/kanban.constants';
import { useAuth } from '../../../contexts/auth.context';
import { usePagination } from '../../../hooks/usePagination';
import {
  IDataResponseModel,
  IPagination
} from '../../../interfaces/requests.interfaces';
import { get, post } from '../../../services/axios.service';
import { handleDownloadCSV } from '../../../services/download.service';
import { formatFullDate } from '../../../utils/format.utils';
import {
  Button,
  CountItensPerPage,
  CustomPagination,
  CustomTooltip,
  SelectDateRangeUniqueField,
  Spinner
} from '../../_Atoms';
import { Modal, NoResultsState, Toast } from '../../_Molecules';
import { IToast } from '../../_Molecules/Toast';
import { ClearFilters } from '../FilterUserEdit/styles';
import {
  ConfirmationModalLine,
  ConfirmationModalLineHeader,
  ContainerModalConfirmation,
  ContainerModalLines,
  HistoryAllocationTableLine,
  MultiSelectStyle,
  SpinnerContainer,
  StatusModal,
  StyledHeaderCell,
  StyledHeaderRow
} from './style';

interface IFilter {
  user: number[];
  startDate: string | null;
  endDate: string | null;
  initialLabelDateIndex: number;
}
interface IUsersModal {
  atribuida: boolean;
  promotorId: number;
  nomePromotor: string;
  nomeMaquina: string;
  serialNumber: string;
  sku: string;
}

interface IUserTable {
  atribuidoPor: string;
  dataAtribuicao: string;
  id: number;
  quantidadeAtribuicao: number;
  subGrupo: string;
}

const HistoryAllocationTable: FC = () => {
  const [filters, setFilters] = useState<IFilter>({
    user: [],
    startDate: null,
    endDate: null,
    initialLabelDateIndex: -1
  });
  const [dataTable, setDataTable] = useState<IUserTable[]>([]);

  const [userOptions, setUserOptions] = useState([{ value: -1, label: '' }]);
  const [userSelected, setUserSelected] = useState<IUserTable>();
  const { totalItems, setTotalItems } = usePagination();
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [resetPagination, setResetPagination] = useState(true);
  const [loading, setLoading] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const [modalUserList, setModalUserList] = useState<IUsersModal[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingExportFile, setLoadingExportFile] = useState(false);
  const [toastList, setToast] = useState<IToast[]>([]);
  const { userType } = useAuth();
  const getOptions = async () => {
    try {
      const data = await get<
        IDataResponseModel<{ id: number; descricao: string }[]>
      >(API.USUARIOS.LISTAR_USUARIOS_ATRIBUICAO);

      if (data.success) {
        setUserOptions(
          data.data.map((item) => ({
            value: item.id,
            label: item.descricao
          }))
        );
        setFilters({
          ...filters,
          user: data.data.map((item) => item.id)
        });
      }
    } catch (error) {
      setUserOptions([]);
    }

    loadDataInitial();
  };

  const getData = async (page: number) => {
    setLoading(true);
    setNetworkError(false);

    try {
      const { data } = await post<IDataResponseModel<IPagination<any>>>(
        API.CONTROLE_ATIVOS.HISTORICO_ATRIBUICAO,
        {
          pag: {
            pageNumber: page,
            pageSize: itemsPerPage
          },
          ...(filters.startDate && {
            dataReatribuicaoInicio: filters.startDate
          }),
          ...(filters.endDate && { dataReatribuicaoFim: filters.endDate }),
          ...(userType() === 'Admin' && { idsResponsaveis: filters.user })
        }
      );
      setTotalItems(data.totalItems);
      setDataTable(data.results);
    } catch {
      setNetworkError(true);
      setDataTable([]);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    setLoadingExportFile(true);

    const fetchUrl = `${
      process.env.REACT_APP_BASE_URL
    }${API.CONTROLE_ATIVOS.DOWNLOAD_ARQUIVO_BY_ID(`${userSelected?.id}`)}`;
    axios
      .get(fetchUrl, { responseType: 'blob' })
      .then((response) =>
        handleDownloadCSV(response.data, 'historico-atribuicao')
      )
      .catch(() => {
        setToast([
          {
            id: 0,
            title: 'Tempo excedido!',
            description: 'A exportação demorou demais para ser processada.',
            type: 'error'
          }
        ]);
      })
      .finally(() => setLoadingExportFile(false));
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page + 1);
    await getData(page + 1);
  };

  const loadDataInitial = async () => {
    setCurrentPage(1);
    setResetPagination(false);
    await getData(1);
    setResetPagination(true);
  };

  const clearFilters = () => {
    setFilters({
      user: userOptions.map((i) => i.value),
      startDate: null,
      endDate: null,
      initialLabelDateIndex: -1
    });
  };

  const getModalList = async (id: number) => {
    setLoadingModal(true);
    try {
      const data = await post<IDataResponseModel<IUsersModal[]>>(
        API.CONTROLE_ATIVOS.HISTORICO_ATRIBUICAO_BY_ID(`${id}`),
        {}
      );

      if (data.success) {
        setModalUserList(data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModal(false);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 32 }}>
        <div style={{ width: '100%', maxWidth: 300 }}>
          <SelectDateRangeUniqueField
            date={{ start: filters.startDate, end: filters.endDate }}
            setDate={(e) =>
              setFilters({ ...filters, endDate: e.end, startDate: e.start })
            }
            value={filters.initialLabelDateIndex}
            setValue={(e) =>
              setFilters({ ...filters, initialLabelDateIndex: e })
            }
            label="Período"
            options={[
              ENUM_DATE_OPTIONS['Exibir todo o período'],
              ENUM_DATE_OPTIONS.Hoje,
              ENUM_DATE_OPTIONS['Essa semana'],
              ENUM_DATE_OPTIONS['Ultimos 7 dias'],
              ENUM_DATE_OPTIONS['Últimos 15 Dias'],
              ENUM_DATE_OPTIONS['Últimos 30 dias'],
              ENUM_DATE_OPTIONS['Últimos 3 meses'],
              ENUM_DATE_OPTIONS['Últimos 6 meses']
            ]}
          />
        </div>

        {userType() === 'Admin' && (
          <MultiSelectStyle
            options={userOptions}
            filter
            placeholder="Selecione uma opção"
            selected={filters.user}
            setSelected={(e) => {
              setFilters({
                ...filters,
                user: e
              });
            }}
            label="Responsável pela reatribuição"
          />
        )}

        <ClearFilters
          active
          onClick={clearFilters}
          style={{
            margin: '0 20px 0 24px',
            bottom: 15,
            right: 0,
            position: 'relative'
          }}
        >
          Limpar filtros
        </ClearFilters>

        <Button
          style={{ marginTop: 5 }}
          value="Filtrar"
          onClick={() => {
            loadDataInitial();
          }}
        />
      </div>
      <div style={{ marginTop: 40 }}>
        <StyledHeaderRow>
          <StyledHeaderCell>Data de atribuição</StyledHeaderCell>
          <StyledHeaderCell>Atribuição por</StyledHeaderCell>
          <StyledHeaderCell>Subgrupo</StyledHeaderCell>
          <StyledHeaderCell>Quantidade de atribuições</StyledHeaderCell>
          <StyledHeaderCell>Detalhes</StyledHeaderCell>
        </StyledHeaderRow>
      </div>

      <div>
        {!loading &&
          dataTable?.map((item: any) => (
            <HistoryAllocationTableLine key={item.id}>
              <p>{formatFullDate(new Date(item.dataAtribuicao))}</p>
              <p>{item.atribuidoPor}</p>
              <p>{item.subGrupo}</p>
              <p>{item.quantidadeAtribuicao}</p>
              <p style={{ cursor: 'pointer' }}>
                <CustomTooltip title="Ver detalhes">
                  <LinkOut
                    onClick={() => {
                      getModalList(item.id);
                      setOpenModal(true);
                      setUserSelected(item);
                    }}
                  />
                </CustomTooltip>
              </p>
            </HistoryAllocationTableLine>
          ))}
      </div>

      {loading && (
        <SpinnerContainer>
          <Spinner size="46px" color="#3388ff" width="5px" />
        </SpinnerContainer>
      )}
      {!dataTable.length && !loading && <NoResultsState />}

      {totalItems > itemsPerPage && resetPagination && !networkError && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 24
          }}
        >
          <CountItensPerPage
            currentPage={currentPage}
            itensPerPage={itemsPerPage}
            totalItens={totalItems}
          />
          <CustomPagination
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            totalItems={totalItems}
            initialPage={currentPage}
            shouldResetPagination={resetPagination}
          />
        </div>
      )}

      <Modal
        title="Detalhes da atribuição"
        open={openModal}
        setOpen={setOpenModal}
        action={() => handleExport()}
        loading={loadingExportFile}
        labelAction="Baixar arquivo"
        widthButtonAction="220px"
        disableAction={loading || modalUserList.length === 0}
      >
        <ContainerModalConfirmation>
          <p style={{ marginLeft: -12, fontSize: 12 }}>
            Confira as atribuições de máquinas, confirme e gere o arquivo para o
            upload no sistema.
          </p>

          <p
            style={{
              fontSize: 16,
              marginTop: 20,
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <span>
              Responsável pela atribuição: {userSelected?.atribuidoPor}
            </span>
            <span>
              Data de atribuição:{' '}
              {formatFullDate(new Date(userSelected?.dataAtribuicao || ''))}
            </span>
            <span>
              Quantidade de atribuições: {userSelected?.quantidadeAtribuicao}
            </span>
          </p>
          {!loadingModal ? (
            <>
              <ConfirmationModalLineHeader>
                <p>Atribuída</p>
                <p>Tipo de máquina</p>
                <p>Serial number</p>
                <p>SKU</p>
                <p>Executivo</p>
              </ConfirmationModalLineHeader>

              <ContainerModalLines>
                {modalUserList.map((item) => (
                  <ConfirmationModalLine>
                    <StatusModal
                      color={!item?.atribuida ? '#3063e9' : '#47995a'}
                    >
                      {item?.atribuida ? (
                        <>
                          <Done />
                          <p>Máquina atribuida</p>
                        </>
                      ) : (
                        <>
                          <Loading />
                          <p>Máquina não atribuida</p>
                        </>
                      )}
                    </StatusModal>
                    <CustomTooltip title={item.nomeMaquina || ''}>
                      <p>{item.nomeMaquina}</p>
                    </CustomTooltip>
                    <CustomTooltip title={item.serialNumber}>
                      <p>{item.serialNumber}</p>
                    </CustomTooltip>
                    <CustomTooltip title={item.sku}>
                      <p>{item.sku}</p>
                    </CustomTooltip>
                    <CustomTooltip title={item.nomePromotor}>
                      <p style={{ fontWeight: 'bold' }}>{item.nomePromotor}</p>
                    </CustomTooltip>
                  </ConfirmationModalLine>
                ))}

                {modalUserList.length === 0 && (
                  <NoResultsState
                    image="secondary"
                    title="Ops... parece que algo deu errado"
                    subtitle="Houve um erro no nosso sistema e não pudemos exibir as informações."
                    subtitle2="Tente novamente mais tarde."
                  />
                )}
              </ContainerModalLines>
            </>
          ) : (
            <div
              style={{
                height: 74 * (modalUserList.length + 1),
                minHeight: 400,
                maxHeight: 600,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Spinner size="46px" color="#3388ff" width="5px" />
            </div>
          )}
        </ContainerModalConfirmation>
      </Modal>
      <Toast toastlist={toastList} position="top-right" setList={setToast} />
    </div>
  );
};

export default HistoryAllocationTable;
