import { FC } from 'react';
import { ColumnStyle, Content } from './styles';

const Column: FC = ({ children }) => {
  return (
    <ColumnStyle>
      <Content>{children}</Content>
    </ColumnStyle>
  );
};

export default Column;
