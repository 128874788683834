import { Container } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  InputField,
  SelectField,
  Spinner,
  SubTitle,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  Card,
  Modal,
  Toast
} from '../../components/_Molecules';
import { IToast } from '../../components/_Molecules/Toast';
import { API } from '../../constants/api.constants';
import {
  ENUM_MACHINE,
  OPTIONS_EXCHANGE_AND_RETURN_MACHINE,
  OPTIONS_TYPE_MACHINE_ALLOCATE
} from '../../constants/machineAllocate.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES
} from '../../constants/routes.constants';
import { IFormExchangeAndReturn } from '../../interfaces/exchangeAndReturn';
import { IDataResponseModel } from '../../interfaces/requests.interfaces';
import { get, post } from '../../services/axios.service';
import { EspecialCharacters } from '../../utils/format.utils';
import { ReturnMachineForm } from '../../utils/yup-utils';
import {
  ConfirmationLabel,
  ConfirmationModalField,
  ConfirmationModalForm,
  ConfirmationModalMachineContainer,
  ContainerForm,
  TipoMaquina
} from './style';

const ExchangeAndReturnRegister: FC = () => {
  const [troca, setTroca] = useState<{ value: number; label: string }[]>([]);
  const [devolucao, setDevolucao] = useState<
    { value: number; label: string }[]
  >([]);
  const { push } = useHistory();
  const [toast, setToastList] = useState<IToast[]>([]);
  const [validationMerchantCode, setValidationMerchantCode] = useState({
    error: true,
    message: '',
    touched: false
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModalFeedback, setOpenModalFeedback] = useState({
    open: false,
    title: '',
    error: false,
    description: ''
  });
  const [loading, setLoading] = useState(false);

  const getOptions = async () => {
    try {
      const [resTroca, resDevolucao] = await Promise.allSettled([
        get<IDataResponseModel<{ id: number; descricao: string }[]>>(
          API.MOTIVOS.TROCA
        ),
        get<IDataResponseModel<{ id: number; descricao: string }[]>>(
          API.MOTIVOS.DEVOLUCAO
        )
      ]);

      if (resTroca.status === 'fulfilled') {
        setTroca([
          ...resTroca.value.data.map((item) => ({
            value: item.id,
            label: item.descricao
          }))
        ]);
      }
      if (resDevolucao.status === 'fulfilled') {
        setDevolucao([
          ...resDevolucao.value.data.map((item) => ({
            value: item.id,
            label: item.descricao
          }))
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateMerchantCode = async (merchantCode: string) => {
    try {
      await get<IDataResponseModel<null>>(
        API.AQUISICAO_MAQUINA.VALIDAR_MERCHANT_CODE(merchantCode)
      );

      setValidationMerchantCode({
        error: false,
        message: '',
        touched: true
      });
    } catch (error) {
      setValidationMerchantCode({
        error: true,
        message: 'Merchant code inválido.',
        touched: true
      });
    }
  };

  const submitForm = async (form: IFormExchangeAndReturn) => {
    setLoading(true);
    setOpenModal(false);
    setOpenModalFeedback({ ...openModalFeedback, open: true });

    try {
      const res = await post<IDataResponseModel<null>>(
        API.AQUISICAO_MAQUINA.TROCA_DEVOLUCAO,
        {
          tipo: Number(form.tipoSolicitacao),
          merchantCode: form.merchantCode,
          motivoId: Number(form.motivoId),
          tipoMaquinaRetirada: Number(form.retiradaTipoMaquina),
          numeroSerieMaquinaRetirada: form.retiradaSerialNumber,
          numeroSeriePrinterRetirada: form.retiradaSerialNumberPrinter,
          tipoMaquinaEntregue: Number(form.entregaTipoMaquina),
          numeroSerieMaquinaEntregue: form.entregaSerialNumber,
          numeroSeriePrinterEntregue: form.entregaSerialNumberPrinter
        }
      );

      if (res.success) {
        setOpenModalFeedback({
          error: false,
          title: `${
            Number(form.tipoSolicitacao) === 1 ? 'Troca' : 'Devolução'
          } feita com sucesso`,
          open: true,
          description: `As informações de ${
            Number(form.tipoSolicitacao) === 1 ? 'troca' : 'devolução'
          } de máquinas foram salvas com sucesso.`
        });
      } else {
        setOpenModalFeedback({
          error: true,
          description: `Não foi possível salvar as informações de ${
            Number(form.tipoSolicitacao) === 1 ? 'troca' : 'devolução'
          } de máquina, deseja tentar novamente ou voltar a página inicial?`,
          open: true,
          title: `${
            Number(form.tipoSolicitacao) === 1 ? 'Troca' : 'Devolução'
          } não foi cadastrada.`
        });
      }
    } catch (error) {
      setOpenModalFeedback({
        error: true,
        description: `Não foi possível salvar as informações de ${
          Number(form.tipoSolicitacao) === 1 ? 'troca' : 'devolução'
        } de máquina, deseja tentar novamente ou voltar a página inicial?`,
        open: true,
        title: `${
          Number(form.tipoSolicitacao) === 1 ? 'Troca' : 'Devolução'
        } não foi cadastrada`
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getOptions();
  }, []);
  return (
    <Container style={{ margin: 'auto', maxWidth: 1336 }}>
      <Breadcrumb
        currentRouteName="Trocar / Devolver"
        previousRoutes={[
          { routeName: 'Menu principal', routePath: ROUTES.MAIN_MENU },
          {
            routeName: 'Gestão de ativos',
            routePath: MENU_ROUTES.ASSET_MANAGEMENT
          },
          {
            routeName: 'Troca e Devolução',
            routePath: SUB_MENU_ROUTES.EXCHANGE_AND_RETURN
          }
        ]}
        style={{ maxWidth: 1046, marginBottom: 36 }}
        noMargin
      />
      <Title fontSize="big">Solicitar Troca/Devolução</Title>
      <SubTitle>Realize o registro de troca ou devolução de máquinas</SubTitle>

      <ContainerForm>
        <Formik
          initialValues={{
            tipoSolicitacao: 0,
            merchantCode: '',
            motivoId: 0,
            retiradaTipoMaquina: 0,
            retiradaSerialNumber: '',
            retiradaTipoMaquinaPrinter: 1,
            retiradaSerialNumberPrinter: '',
            entregaTipoMaquina: 0,
            entregaSerialNumber: '',
            entregaTipoMaquinaPrinter: 1,
            entregaSerialNumberPrinter: ''
          }}
          validationSchema={ReturnMachineForm}
          validateOnChange
          validateOnBlur
          onSubmit={(e) => submitForm(e)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            values,
            isValid,
            setFieldValue
          }) => (
            <Form>
              <Card.Root
                style={{
                  border: '1px solid #D8DDE1',
                  maxWidth: 640,
                  margin: '24px auto'
                }}
              >
                <Card.Title
                  style={{ paddingBottom: 4 }}
                  title="Dados da troca ou devolução"
                />
                <div style={{ marginBottom: 24 }}>
                  <SubTitle>
                    Esses dados são necessários para realizar uma troca ou
                    devolução de uma máquina
                  </SubTitle>
                </div>
                <InputField
                  style={{ width: '100%' }}
                  invalid={
                    !!errors.merchantCode ||
                    (!!validationMerchantCode.error &&
                      !!validationMerchantCode.touched)
                  }
                  errorMessage={
                    errors.merchantCode || validationMerchantCode.message
                  }
                  name="merchantCode"
                  value={values.merchantCode}
                  onBlur={(e) => {
                    handleBlur(e);

                    if (!errors.merchantCode) {
                      validateMerchantCode(e.target.value);
                    }
                  }}
                  onChange={(e) => {
                    setValidationMerchantCode({
                      error: true,
                      message: '',
                      touched: false
                    });
                    if (/^[a-zA-Z0-9-]*$/.test(e.target.value)) {
                      setFieldValue(
                        'merchantCode',
                        e.target.value.toLocaleUpperCase().trim()
                      );
                    }
                  }}
                  label="Merchant Code:"
                  placeholder="Merchant Code"
                />
                <SelectField
                  style={{ width: '100%' }}
                  options={[
                    { label: 'Troca', value: 1 },
                    { label: 'Devolução', value: 2 }
                  ]}
                  value={values.tipoSolicitacao}
                  placeholder="Selecione uma opção"
                  label="Tipo de solicitação:"
                  name="tipoSolicitacao"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue('motivoId', 0);
                  }}
                />
                <SelectField
                  style={{ width: '100%' }}
                  options={
                    Number(values.tipoSolicitacao) === 1
                      ? troca
                      : Number(values.tipoSolicitacao) === 2
                      ? devolucao
                      : []
                  }
                  disabled={values.tipoSolicitacao === 0}
                  value={values.motivoId}
                  placeholder="Selecione uma opção"
                  label="Motivo:"
                  name="motivoId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Card.Root>

              {values.tipoSolicitacao !== 0 && (
                <>
                  <Card.Root
                    style={{
                      border: '1px solid #EA7A7A',
                      maxWidth: 640,
                      margin: '24px auto'
                    }}
                  >
                    <Card.Title title="Informações máquina retirada do merchant" />
                    <Card.Row>
                      <TipoMaquina
                        options={OPTIONS_EXCHANGE_AND_RETURN_MACHINE}
                        name="retiradaTipoMaquina"
                        onBlur={handleBlur}
                        value={values.retiradaTipoMaquina}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue('retiradaSerialNumberPrinter', '');
                          setFieldValue('retiradaSerialNumber', '');
                        }}
                        placeholder="Selecione um opção"
                        label="Tipo de máquina"
                        invalid={!!errors.retiradaTipoMaquina}
                        errorMessage={errors.retiradaTipoMaquina}
                      />
                      <InputField
                        name="retiradaSerialNumber"
                        onBlur={handleBlur}
                        disabled={values.retiradaTipoMaquina === 0}
                        onChange={(e) => {
                          if (!EspecialCharacters.test(e.target.value)) {
                            setFieldValue(
                              'retiradaSerialNumber',
                              e.target.value.toLocaleUpperCase().trim()
                            );
                          }
                        }}
                        value={values.retiradaSerialNumber}
                        label="Serial Number"
                        maxLength={
                          Number(values.retiradaTipoMaquina) ===
                          ENUM_MACHINE.Total
                            ? 8
                            : 17
                        }
                        invalid={
                          !!errors.retiradaSerialNumber &&
                          values.retiradaTipoMaquina !== 0
                        }
                        errorMessage={errors.retiradaSerialNumber}
                      />
                    </Card.Row>
                    {Number(values.retiradaTipoMaquina) ===
                      ENUM_MACHINE.Solo && (
                      <Card.Row>
                        <TipoMaquina
                          options={OPTIONS_TYPE_MACHINE_ALLOCATE}
                          placeholder="Selecione uma opção"
                          label="Tipo de máquina"
                          value={1}
                          disabled
                        />
                        <InputField
                          name="retiradaSerialNumberPrinter"
                          maxLength={17}
                          value={values.retiradaSerialNumberPrinter}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (!EspecialCharacters.test(e.target.value)) {
                              setFieldValue(
                                'retiradaSerialNumberPrinter',
                                e.target.value.toLocaleUpperCase().trim()
                              );
                            }
                          }}
                          invalid={!!errors.retiradaSerialNumberPrinter}
                          errorMessage={errors.retiradaSerialNumberPrinter}
                          label="Serial Number"
                        />
                      </Card.Row>
                    )}
                  </Card.Root>

                  {Number(values.tipoSolicitacao) === 1 && (
                    <Card.Root
                      style={{
                        border: '1px solid #8CC13F',
                        maxWidth: 640,
                        margin: '24px auto'
                      }}
                    >
                      <Card.Title title="Informações máquina entregue ao merchant" />
                      <Card.Row>
                        <TipoMaquina
                          options={OPTIONS_EXCHANGE_AND_RETURN_MACHINE}
                          placeholder="Selecione uma opção"
                          label="Tipo de máquina"
                          name="entregaTipoMaquina"
                          value={values.entregaTipoMaquina}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('entregaSerialNumberPrinter', '');
                            setFieldValue('entregaSerialNumber', '');
                          }}
                          invalid={!!errors.entregaTipoMaquina}
                          errorMessage={errors.entregaTipoMaquina}
                        />
                        <InputField
                          maxLength={
                            Number(values.entregaTipoMaquina) ===
                            ENUM_MACHINE.Total
                              ? 8
                              : 17
                          }
                          name="entregaSerialNumber"
                          value={values.entregaSerialNumber}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (!EspecialCharacters.test(e.target.value)) {
                              setFieldValue(
                                'entregaSerialNumber',
                                e.target.value.toLocaleUpperCase().trim()
                              );
                            }
                          }}
                          disabled={values.entregaTipoMaquina === 0}
                          invalid={
                            !!errors.entregaSerialNumber &&
                            values.entregaTipoMaquina !== 0
                          }
                          errorMessage={errors.entregaSerialNumber}
                          label="Serial Number"
                        />
                      </Card.Row>

                      {Number(values.entregaTipoMaquina) ===
                        ENUM_MACHINE.Solo && (
                        <Card.Row>
                          <TipoMaquina
                            options={OPTIONS_TYPE_MACHINE_ALLOCATE}
                            placeholder="Selecione um opção"
                            label="Tipo de máquina"
                            value={1}
                            disabled
                          />
                          <InputField
                            maxLength={17}
                            name="entregaSerialNumberPrinter"
                            onChange={(e) => {
                              if (!EspecialCharacters.test(e.target.value)) {
                                setFieldValue(
                                  'entregaSerialNumberPrinter',
                                  e.target.value.toLocaleUpperCase().trim()
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            label="Serial Number"
                            value={values.entregaSerialNumberPrinter}
                            invalid={!!errors.entregaSerialNumberPrinter}
                            errorMessage={errors.entregaSerialNumberPrinter}
                          />
                        </Card.Row>
                      )}
                    </Card.Root>
                  )}
                </>
              )}

              <Modal
                open={openModal}
                setOpen={setOpenModal}
                title={`Concluir ${
                  Number(values.tipoSolicitacao) === 1 ? 'troca' : 'devolução'
                }`}
                action={() => submitForm(values)}
                maxWidth={786}
              >
                <ConfirmationModalForm>
                  <ConfirmationModalField>
                    <ConfirmationLabel>Motivo troca</ConfirmationLabel>
                    <p>
                      {Number(values.tipoSolicitacao) === 1 &&
                        troca.filter(
                          (item) => item.value === Number(values.motivoId)
                        )[0]?.label}
                      {Number(values.tipoSolicitacao) === 2 &&
                        devolucao.filter(
                          (item) => item.value === Number(values.motivoId)
                        )[0]?.label}
                    </p>
                  </ConfirmationModalField>
                  <ConfirmationModalField>
                    <ConfirmationLabel>Merchant code</ConfirmationLabel>
                    <p>{values.merchantCode}</p>
                  </ConfirmationModalField>

                  <p
                    style={{
                      padding: '16px 24px',
                      textAlign: 'center',
                      color: '#ea7a7a',
                      fontWeight: 'bold'
                    }}
                  >
                    Máquina retirada do merchant
                  </p>
                  <ConfirmationModalMachineContainer>
                    <ConfirmationLabel>Tipo de máquina</ConfirmationLabel>
                    <p>{ENUM_MACHINE[values.retiradaTipoMaquina]}</p>
                    <ConfirmationLabel>Serial Number</ConfirmationLabel>
                    <p>{values.retiradaSerialNumber}</p>
                  </ConfirmationModalMachineContainer>
                  {Number(values.retiradaTipoMaquina) === ENUM_MACHINE.Solo && (
                    <>
                      <ConfirmationModalMachineContainer>
                        <ConfirmationLabel>Tipo de máquina</ConfirmationLabel>
                        <p>Printer</p>
                        <ConfirmationLabel>Serial Number</ConfirmationLabel>
                        <p>{values.retiradaSerialNumberPrinter}</p>
                      </ConfirmationModalMachineContainer>
                    </>
                  )}
                  {Number(values.tipoSolicitacao) === 1 && (
                    <>
                      <p
                        style={{
                          padding: '16px 24px',
                          textAlign: 'center',
                          color: '#8cc13f',
                          fontWeight: 'bold'
                        }}
                      >
                        Máquina entregue ao merchant
                      </p>
                      <ConfirmationModalMachineContainer>
                        <ConfirmationLabel>Tipo de máquina</ConfirmationLabel>
                        <p>{ENUM_MACHINE[values.entregaTipoMaquina]}</p>
                        <ConfirmationLabel>Serial Number</ConfirmationLabel>
                        <p>{values.entregaSerialNumber}</p>
                      </ConfirmationModalMachineContainer>

                      {Number(values.entregaTipoMaquina) ===
                        ENUM_MACHINE.Solo && (
                        <>
                          <ConfirmationModalMachineContainer>
                            <ConfirmationLabel>
                              Tipo de máquina
                            </ConfirmationLabel>
                            <p>Printer</p>
                            <ConfirmationLabel>Serial Number</ConfirmationLabel>
                            <p>{values.entregaSerialNumberPrinter}</p>
                          </ConfirmationModalMachineContainer>
                        </>
                      )}
                    </>
                  )}
                </ConfirmationModalForm>
              </Modal>

              <Modal
                title={openModalFeedback.title}
                open={openModalFeedback.open}
                setOpen={() => {
                  if (!openModalFeedback.title.includes('sucesso')) {
                    setOpenModalFeedback({
                      ...openModalFeedback,
                      open: !openModalFeedback.open
                    });
                  }
                }}
                labelAction={
                  openModalFeedback.error ? 'Tentar novamente' : 'Ok'
                }
                widthButtonAction={!openModalFeedback.error ? '100px' : '250px'}
                labelBackButton="Voltar para a página anterior"
                hideBackButton={!openModalFeedback.error}
                maxWidth={720}
                action={() => {
                  openModalFeedback.error
                    ? submitForm(values)
                    : push(SUB_MENU_ROUTES.EXCHANGE_AND_RETURN);
                }}
              >
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '32px'
                    }}
                  >
                    <Spinner size="32px" width="4px" />
                  </div>
                ) : (
                  <p style={{ padding: '24px' }}>
                    {openModalFeedback.description}
                  </p>
                )}
              </Modal>

              <BottomFixedActions style={{ zIndex: 2 }}>
                <Button
                  variant="secondary"
                  value="Voltar"
                  width="medium"
                  onClick={() => push(SUB_MENU_ROUTES.EXCHANGE_AND_RETURN)}
                >
                  Voltar
                </Button>
                <Button
                  disabled={!isValid || validationMerchantCode.error}
                  onClick={() => setOpenModal(true)}
                  variant="primary"
                  width="medium"
                  value="Concluir"
                />
              </BottomFixedActions>
            </Form>
          )}
        </Formik>

        <Toast toastlist={toast} setList={setToastList} position="top-right" />
      </ContainerForm>
    </Container>
  );
};

export default ExchangeAndReturnRegister;
