import axios from 'axios';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CountItensPerPage,
  CustomPagination,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  Toast
} from '../../components/_Molecules';
import { StyledBody } from '../../components/_Molecules/CardContainer/styles';
import { IToast } from '../../components/_Molecules/Toast';
import {
  ExchangeAndReturnSolicitationsFilter,
  ExchangeAndReturnSolicitationsTable,
  NetworkError
} from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES
} from '../../constants/routes.constants';
import { STORAGE } from '../../constants/storage.constants';
import { usePagination } from '../../hooks/usePagination';
import {
  IFiltersSolicitations,
  ISolicitationList
} from '../../interfaces/exchangeAndReturn';
import {
  IDataResponseModel,
  IPagination
} from '../../interfaces/requests.interfaces';
import { post } from '../../services/axios.service';
import { handleDownloadDynamicType } from '../../services/download.service';
import { rangeDateOptions } from '../../utils/kanban';
import { StyledContentBox, StyledListBox, StyledPaginationBox } from './styles';

const ExchangeAndReturnSolicitations: FC = () => {
  const [loadingToResetPaginator, setLoadingToResetPaginator] = useState(false);
  const { totalItems, setTotalItems } = usePagination();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const { push } = useHistory();
  const [data, setData] = useState<ISolicitationList[]>([]);
  const [filters, setFilters] = useState<IFiltersSolicitations>(() => {
    const history: IFiltersSolicitations = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_MACHINE_EXCHANGE) || '{}'
    );

    return {
      tipoSolicitacao: history.tipoSolicitacao || '0',
      subgrupo: history.subgrupo || [],
      email: history.email || [],
      dataInicial: history.dataInicial || rangeDateOptions('11').start,
      datafinal: history.datafinal || rangeDateOptions('11').end,
      initialLabelDateIndex: history.initialLabelDateIndex || 11,
      tipoMaquina: history.tipoMaquina || [1, 2],
      search: history.search || ''
    };
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [toast, setToastList] = useState<IToast[]>([]);

  const getData = async (page: number) => {
    setLoading(true);
    setError(false);
    try {
      const { data } = await post<
        IDataResponseModel<IPagination<ISolicitationList>>
      >(API.AQUISICAO_MAQUINA.LISTAR_TROCA_DEVOLUCAO, {
        pageNumber: page,
        pageSize: itemsPerPage,
        ...(filters.tipoSolicitacao !== '0' && {
          tipoSolicitacao: Number(filters.tipoSolicitacao)
        }),
        ...(filters.subgrupo.length > 0 && { subGrupos: filters.subgrupo }),
        dataInicioSolicitacao: filters.dataInicial,
        dataFimSolicitacao: filters.datafinal,
        ...(filters.tipoMaquina && {
          tipoMaquina: filters.tipoMaquina
        }),
        ...(filters.email && { solicitanteId: filters.email }),
        ...(filters.search && { campoBusca: filters.search })
      });
      setTotalItems(data.totalItems);
      setData(data.results);
    } catch (e) {
      setError(true);
      setData([]);
    }

    setLoading(false);
  };

  const DownloadFile = async () => {
    const fetchUrl = `${process.env.REACT_APP_BASE_URL}${API.AQUISICAO_MAQUINA.EXPORTAR_TROCA_DEVOLUCAO}`;
    axios
      .post(
        fetchUrl,
        {
          ...(filters.tipoSolicitacao !== '0' && {
            tipoSolicitacao: Number(filters.tipoSolicitacao)
          }),
          dataInicioSolicitacao: filters.dataInicial,
          dataFimSolicitacao: filters.datafinal,
          ...(filters.subgrupo.length > 0 && { subGrupos: filters.subgrupo }),
          ...(filters.tipoMaquina && {
            tipoMaquina: filters.tipoMaquina
          }),
          ...(filters.email && { solicitanteId: filters.email }),
          ...(filters.search && { campoBusca: filters.search })
        },
        { responseType: 'blob' }
      )
      .then((response) => {
        handleDownloadDynamicType(
          response.data,
          'arquivo-solicitações',
          response.data.type
        );
        setToastList([
          {
            id: 1,
            title: 'Download completo!',
            description: 'O arquivo foi baixado com sucesso.',
            type: 'sucess'
          }
        ]);
      })
      .catch((error) => {
        setToastList([
          {
            id: 1,
            title: 'Não foi possível gerar o arquivo!',
            description: 'Tente novamente.',
            type: 'error'
          }
        ]);
        if (error) console.error(error);
      });
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page + 1);
    await getData(page + 1);
  };

  const loadData = async () => {
    const history = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_MACHINE_EXCHANGE) || '{}'
    );
    setCurrentPage(history?.currentPage ?? 1);
    setLoadingToResetPaginator(false);
    await getData(history?.currentPage ?? 1);
    setLoadingToResetPaginator(true);
    localStorage.removeItem(STORAGE.HISTORY_PAGINATION_MACHINE_EXCHANGE);
  };

  return (
    <div style={{ padding: '0 25px 150px 25px' }}>
      <StyledContentBox>
        <Breadcrumb
          currentRouteName="Solicitações"
          previousRoutes={[
            {
              routeName: 'Menu principal',
              routePath: ROUTES.MAIN_MENU
            },
            {
              routeName: 'Gestão de ativos',
              routePath: MENU_ROUTES.ASSET_MANAGEMENT
            },
            {
              routeName: 'Troca e Devolução',
              routePath: SUB_MENU_ROUTES.EXCHANGE_AND_RETURN
            }
          ]}
          style={{ marginBottom: 36 }}
          noMargin
        />
        <Title fontSize="big">Solicitações</Title>
        <StyledBody as="p">
          Acompanhe e gerencie as trocas e devoluções de máquinas de seu time
        </StyledBody>
      </StyledContentBox>

      <ExchangeAndReturnSolicitationsFilter
        getData={loadData}
        setFilters={setFilters}
        filters={filters}
      />

      {error && !loading && (
        <NetworkError
          image="pencil"
          retry={() => getData(currentPage)}
          subtitle="Aguarde alguns instantes e tente novamente."
        />
      )}

      {!error && (
        <StyledListBox>
          <ExchangeAndReturnSolicitationsTable
            saveFilters={() =>
              localStorage.setItem(
                STORAGE.HISTORY_PAGINATION_MACHINE_EXCHANGE,
                JSON.stringify({ ...filters, currentPage })
              )
            }
            data={data}
            loading={loading}
          />

          {loadingToResetPaginator && totalItems > itemsPerPage && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <CountItensPerPage
                currentPage={currentPage}
                itensPerPage={itemsPerPage}
                totalItens={totalItems}
              />
              <StyledPaginationBox>
                <CustomPagination
                  itemsPerPage={itemsPerPage}
                  onPageChange={handlePageChange}
                  totalItems={totalItems}
                  initialPage={currentPage}
                />
              </StyledPaginationBox>
            </div>
          )}
        </StyledListBox>
      )}

      <Toast toastlist={toast} setList={setToastList} position="top-right" />

      <BottomFixedActions style={{ zIndex: 1 }}>
        <Button
          variant="secondary"
          type="button"
          value="Voltar"
          onClick={() => push(SUB_MENU_ROUTES.EXCHANGE_AND_RETURN)}
          width="medium"
        />

        <Button
          variant="primary"
          type="button"
          value="Exportar arquivo"
          onClick={DownloadFile}
          width="large"
          disabled={loading || data.length === 0}
        />
      </BottomFixedActions>
    </div>
  );
};

export default ExchangeAndReturnSolicitations;
