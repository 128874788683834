import { FC } from 'react';
import { Breadcrumb, MenuCardList } from '../../components/_Molecules';
import { MESSAGE_MENU_ITEMS } from '../../constants/menu.constants';
import { ROUTES } from '../../constants/routes.constants';

const MessageCenter: FC = () => {
  return (
    <>
      <Breadcrumb
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          }
        ]}
        currentRouteName="Central de mensagens"
      />
      <MenuCardList columns="TWO" menuCardList={MESSAGE_MENU_ITEMS} />
    </>
  );
};

export default MessageCenter;
