import React, { HTMLAttributes } from 'react';
import { useHistory } from 'react-router-dom';
import { StyledLink } from './styles';

interface ISimpleLink extends HTMLAttributes<HTMLAnchorElement> {
  route?: string;
  text: string;
}
const SimpleLink: React.FC<ISimpleLink> = ({ route, text, ...props }) => {
  const router = useHistory();

  return (
    <StyledLink
      {...props}
      onClick={() => {
        if (route) router.push(route);
      }}
    >
      {text}
    </StyledLink>
  );
};

export default SimpleLink;
