import styled from '@emotion/styled';

export const StyledCustomCellTitle = styled.p`
  color: #3063e9;
`;
export const StyledCustomTableTitles = styled.header`
  min-height: 64px;
  height: auto;
  background-color: #f9fbff;
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: 700;
  font-size: 16px;
`;
export const StyledCustomFlexRowContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #d8dde1;
`;
export const StyledCustomContent = styled.div`
  font-size: 13px;
  min-height: 64px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    height: auto;
    margin: auto;
    padding: 0px;
  }
`;
export const StyledCustomCenterContent = styled.div`
  grid-column: 2 / span 3;
  font-size: 13px;
  justify-content: center;
  text-align: center;
`;

export const StyledCopyValues = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d8dde1;
  height: 64px;
  color: #3063e9;
  cursor: pointer;

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    margin-right: 7px;
    font-size: 16px;
  }
`;

export const StyledFixTitle = styled.div`
  margin-bottom: 0px;
`;
