import { Backdrop } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../components/_Atoms';
import { Banner, MenuCardList } from '../../components/_Molecules';

import { API } from '../../constants/api.constants';
import { MENU_ITEMS, MENU_ITEMS_LEADER } from '../../constants/menu.constants';
import { STORAGE } from '../../constants/storage.constants';
import { useAuth } from '../../contexts/auth.context';
import { get } from '../../services/axios.service';

const MainMenuPage: React.FC = () => {
  const { userType } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const getImage = async (id: string) => {
    setLoading(true);
    try {
      const response: { data: string } = await get(
        API.COMODATO.DOWNLOAD_IMAGEM(id.replaceAll('"', ''))
      );

      localStorage.removeItem('ImagemDownload');
      window.location.href = response.data;
    } catch (error) {
      alert('Não foi possível carregar a imagem, tente novamente');
    }
    setLoading(false);
  };
  useEffect(() => {
    localStorage.removeItem(STORAGE.HISTORY_PAGINATION_ALCADA);
    localStorage.removeItem(STORAGE.HISTORY_PAGINATION_MESSAGE);
    localStorage.removeItem(STORAGE.HISTORY_PAGINATION_USER_EDIT);
    localStorage.removeItem(STORAGE.HISTORY_PAGINATION_PLANS);
    localStorage.removeItem(STORAGE.HISTORY_PAGINATION_MACHINE_EXCHANGE);

    loadBanner();

    const imagemId = localStorage.getItem('ImagemDownload');

    if (imagemId && userType() === 'Admin') {
      getImage(imagemId);
    }
  }, []);

  const loadBanner = () => {
    const banner = JSON.parse(localStorage.getItem('bannerMainMenu') || '{}');

    setShowBanner(
      !banner || new Date().getDay() !== new Date(banner.date).getDay()
    );
  };

  const closeBanner = () => {
    localStorage.setItem(
      'bannerMainMenu',
      JSON.stringify({ date: new Date() })
    );
    setShowBanner(false);
  };

  return (
    <>
      {loading ? (
        <Backdrop style={{ zIndex: 9999 }} open={loading}>
          <Spinner size="34px" />
        </Backdrop>
      ) : (
        <>
          {showBanner && userType() === 'Lider' && (
            <Banner
              style={{ maxWidth: 570 }}
              title="Confira as propostas de taxas pendentes"
              action={closeBanner}
              handleClose={closeBanner}
              description="Acesse o menu de Gestão de Propostas e revise as propostas de taxas que precisam da sua aprovação."
              labelAction="Entendi"
            />
          )}
          <MenuCardList
            columns="THREE"
            menuCardList={
              userType() === 'Lider' ? MENU_ITEMS_LEADER : MENU_ITEMS
            }
          />
        </>
      )}
    </>
  );
};

export default MainMenuPage;
