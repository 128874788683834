import { ButtonProps } from '@sumup/circuit-ui';
import React from 'react';
import { StyledButton, StyledButtonBox } from './styles';

type ICustomButton = ButtonProps;

const CustomButton: React.FC<ICustomButton> = ({ children, ...rest }) => {
  return (
    <StyledButtonBox>
      <StyledButton {...rest}>{children}</StyledButton>
    </StyledButtonBox>
  );
};

export default CustomButton;
