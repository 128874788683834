import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from '@emotion/styled';
import { Form, Formik } from 'formik';

import { Body, Button, Card } from '@sumup/circuit-ui';
import { CustomInlineMessage, InputField } from '../../components/_Atoms';
import { CustomDialog } from '../../components/_Molecules';

import { useAuth } from '../../contexts/auth.context';

import { IUserData } from '../../components/_Organisms/Header';
import { IChangePasswordForm } from '../../interfaces/login.interfaces';
import {
  IDataResponseModel,
  IRequestTrocarSenha,
  IResponseGetUsuarioByID
} from '../../interfaces/requests.interfaces';
import { IAuthUserData } from '../Login';

import { buildBearer } from '../../utils/format.utils';
import { changePasswordValidation } from '../../utils/yup-utils';

import { ROUTES } from '../../constants/routes.constants';
import { STORAGE } from '../../constants/storage.constants';

import { useQuery } from '../../hooks/useQuery';

import { API } from '../../constants/api.constants';
import { get, post } from '../../services/axios.service';
import { decodeToken, isValidToken } from '../../services/jwt.service';

const StyledCard = styled(Card)`
  max-width: 298px;
  margin: 0 auto;

  #submit-button {
    margin-top: 80px;
  }
`;
interface IHandleChangePasswordModal {
  message: string;
  title: string;
  handleClose: () => void;
}
const ChangePasswordPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [openDialog, setOpenDialog] = useState(true);
  const [dialogProps, setDialogProps] = useState<IHandleChangePasswordModal>({
    title: 'Modifique a sua senha',
    message:
      'Para maior segurança, redirecionamos você para a criação de uma nova senha.',
    handleClose: () => setOpenDialog(false)
  });
  const { signIn } = useAuth();
  const { push } = useHistory();
  const query = useQuery();
  const token = query.get('token');

  const signUser = (user: IAuthUserData) => {
    const formatedIUser: IUserData = {
      name: user.name
    };
    localStorage.setItem(STORAGE.USER_DATA, JSON.stringify(user));
    signIn(formatedIUser);
  };

  const submitForm = async (formData: IChangePasswordForm) => {
    setHasError(false);
    setLoading(true);
    const decodedToken = decodeToken(token as string);
    const formatedData: IRequestTrocarSenha = {
      usuarioId: Number(decodedToken?.nameid),
      senha: formData.password,
      confirmacaoSenha: formData.passwordConfirmation
    };

    try {
      const resReset = await post<IDataResponseModel<null>>(
        API.USUARIOS.TROCAR_SENHA,
        formatedData,
        {
          Authorization: buildBearer(token as string)
        }
      );

      const resUser = await get<IDataResponseModel<IResponseGetUsuarioByID>>(
        `/usuarios/${decodedToken?.nameid}`,
        {
          Authorization: buildBearer(token as string)
        }
      );

      if (resReset.success && resUser.success) {
        setDialogProps({
          title: 'Senha cadastrada com sucesso',
          message: 'Para continuar, redirecionaremos você para a tela de menu.',
          handleClose: () =>
            signUser({
              name: resUser.data.nome,
              refreshToken: resUser.data.refreshToken,
              token: token as string
            })
        });

        setOpenDialog(true);
      }

      if (resReset.error || resUser.error) setHasError(true);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!token || !isValidToken(token)) {
      push(ROUTES.LOGIN);
    }
  }, [query]);

  return (
    <div>
      <StyledCard>
        <Body size="one" variant="highlight">
          Sistema Administrativo
        </Body>

        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: ''
          }}
          validateOnMount
          validationSchema={changePasswordValidation}
          onSubmit={submitForm}
        >
          {({ handleChange, handleBlur, isValid, errors, touched }) => (
            <Form>
              <InputField
                id="signup-password"
                invalid={!!(errors.password && touched.password)}
                errorMessage={errors.password}
                label="Nova senha:"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Nova senha"
                type="password"
              />
              <InputField
                id="signup-passwordConfirmation"
                invalid={
                  !!(
                    errors.passwordConfirmation && touched.passwordConfirmation
                  )
                }
                errorMessage={errors.passwordConfirmation}
                label="Confirme a nova senha:"
                name="passwordConfirmation"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Confirmação de senha"
                type="password"
              />
              {hasError && (
                <CustomInlineMessage
                  fontSize="12px"
                  marginTop="0px"
                  size="giga"
                  variant="danger"
                >
                  Houve um problema ao trocar sua senha, tente novamente.
                </CustomInlineMessage>
              )}
              <Button
                id="submit-button"
                disabled={!isValid}
                isLoading={loading}
                loadingLabel="Carregando..."
                stretch
                variant="primary"
                type="submit"
              >
                Salvar
              </Button>
            </Form>
          )}
        </Formik>
      </StyledCard>

      {openDialog && (
        <CustomDialog
          handleClose={() => setOpenDialog(false)}
          message={dialogProps.message}
          title={dialogProps.title}
          primaryButton={{
            buttonTitle: 'Ok',
            onClick: dialogProps.handleClose
          }}
        />
      )}
    </div>
  );
};

export default ChangePasswordPage;
