import { FC, memo } from 'react';
import { ReactComponent as Canceled } from '../../../assets/kaban/canceled.svg';
import { ReactComponent as Concluded } from '../../../assets/kaban/concluded.svg';
import { ReactComponent as New } from '../../../assets/kaban/new.svg';
import { ReactComponent as Process } from '../../../assets/kaban/process.svg';
import { ReactComponent as Progress } from '../../../assets/kaban/progress.svg';
import { IColuna } from '../../../interfaces/kanban.interface';
import { TextIconPlus } from '../../_Atoms';
import { Column, ColumnHeader, KanbanCard } from '../../_Molecules';
import { BoardContent, BoardHeader, Container, FooterStyle } from './styles';

interface IProps {
  data: IColuna[];
  loading: boolean;
  loadingGetMoreCards: { coluna: number; loading: boolean };
  error: boolean;
  getMoreCards: (coluna: number, quantity: number) => void;
}

const Board: FC<IProps> = ({
  data,
  loading,
  error,
  getMoreCards,
  loadingGetMoreCards
}) => {
  const getTotalByColumn = (columnNumber: number) => {
    const columnData = data.find((col) => col.coluna === columnNumber);
    return columnData ? columnData.total : 0;
  };
  const getQuantityByColumn = (columnNumber: number) => {
    const columnData = data.find((col) => col.coluna === columnNumber);
    return columnData ? columnData.cards.length : 0;
  };

  const renderColumn = (dados: IColuna[]) => {
    return dados.map((coluna) => (
      <Column>
        {coluna.cards.map((card) => (
          <KanbanCard
            company={card.nomeCliente}
            promoter={card.nomePromotor}
            status={card.status}
            subStatus={card.subStatus}
            updatedDate={card.modificadoEm}
            returnDate={card.dataRetorno}
          />
        ))}
        {getQuantityByColumn(coluna.coluna) !==
          getTotalByColumn(coluna.coluna) &&
          getTotalByColumn(coluna.coluna) > 20 && (
            <div
              onClick={() => getMoreCards(coluna.coluna, coluna.cards.length)}
              onKeyDown={() => null}
              tabIndex={0}
              role="button"
            >
              <TextIconPlus
                loading={
                  loadingGetMoreCards.loading &&
                  loadingGetMoreCards.coluna === coluna.coluna
                }
                text="Ver mais propostas"
              />
            </div>
          )}
      </Column>
    ));
  };

  return (
    <div>
      <Container>
        <BoardHeader>
          <ColumnHeader
            title="Novas Visitas"
            subTitle="Visitas frias"
            icon={New}
            quantity={getQuantityByColumn(1)}
            total={getTotalByColumn(1)}
            color="#9da7b1"
          />
          <ColumnHeader
            title="Em andamento"
            subTitle="Agendadas ou realizadas"
            icon={Progress}
            quantity={getQuantityByColumn(2)}
            total={getTotalByColumn(2)}
            color="#7fb5ff"
          />
          <ColumnHeader
            title="Em processamento"
            subTitle="Aprovadas ou reprovadas"
            icon={Process}
            quantity={getQuantityByColumn(3)}
            total={getTotalByColumn(3)}
            color="#3063e9"
          />
          <ColumnHeader
            title="Concluídas"
            subTitle="Finalizadas com sucesso"
            icon={Concluded}
            quantity={getQuantityByColumn(4)}
            total={getTotalByColumn(4)}
            color="#47995a"
          />
          <ColumnHeader
            title="Canceladas"
            subTitle="Não aceitas ou inválidas"
            icon={Canceled}
            quantity={getQuantityByColumn(5)}
            total={getTotalByColumn(5)}
            color="#db4d4d"
          />
        </BoardHeader>
        <BoardContent>
          {!loading && data.length === 5 ? (
            renderColumn(data)
          ) : (
            <>
              <Column />
              <Column />
              <Column />
              <Column />
              <Column />
            </>
          )}
        </BoardContent>
        <FooterStyle>
          <div />
          <div />
          <div />
          <div />
          <div />
        </FooterStyle>
      </Container>
    </div>
  );
};

export default memo(Board);
