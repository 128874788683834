import { CardFooter, Headline } from '@sumup/circuit-ui';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { API } from '../../constants/api.constants';
import { ISubmitError } from '../../interfaces/requests.interfaces';
import { post } from '../../services/axios.service';
import { doubleMachineGroupValidation } from '../../utils/yup-utils';
import { CustomButton, InputField } from '../_Atoms';
import { StyledActionsBar, StyledContentBox } from './styles';

interface IDoubleOffer {
  id: number;
  removeModal: () => void;
  getMachinesGroup: () => void;
}

const DoubleMachineGroup: React.FC<IDoubleOffer> = ({
  id,
  removeModal,
  getMachinesGroup
}) => {
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<ISubmitError>({
    showError: false,
    message: ''
  });

  const handleSubmit = async (formValues: { name: string }) => {
    setLoading(true);
    setSubmitError({ showError: false, message: '' });
    const formatedData = {
      id,
      nomeGrupoMaquina: formValues.name
    };
    try {
      const data: { error: string; message: string } = await post(
        API.MAQUINAS.DUPLICAR,
        formatedData
      );

      if (data.error === 'grupo_maquina_existente') {
        setSubmitError({
          showError: true,
          message: data.message
        });
      } else {
        getMachinesGroup();
        removeModal();
      }
    } catch {
      setSubmitError({
        showError: true,
        message: 'Erro ao duplicar preço de máquina'
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Headline size="three" as="h3">
        Duplicar preço de máquina
      </Headline>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={(formValues) => handleSubmit(formValues)}
        validationSchema={doubleMachineGroupValidation}
        validateOnMount
      >
        {({ handleChange, handleBlur, errors, touched, isValid }) => (
          <Form>
            <StyledActionsBar>
              <StyledContentBox>
                <InputField
                  errorMessage={
                    errors.name != null ? errors.name : submitError.message
                  }
                  label="Para duplicar este preço de máquina, atribua um novo nome e salve abaixo:"
                  placeholder="Insira um nome"
                  id="double-machines-group"
                  invalid={
                    !!(errors.name && touched.name) || submitError.showError
                  }
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                />
              </StyledContentBox>
            </StyledActionsBar>
            <CardFooter align="right">
              <CustomButton
                variant="secondary"
                type="button"
                stretch
                onClick={removeModal}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                isLoading={loading}
                disabled={!isValid}
                loadingLabel="Salvando..."
                variant="primary"
                type="submit"
                stretch
              >
                Salvar
              </CustomButton>
            </CardFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DoubleMachineGroup;
