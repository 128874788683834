import styled from '@emotion/styled';
import { ENUM_STATUS_PROCESSAMENTO } from '../../../enums/history-files.enums';

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
  font-size: 13px;
  padding: 15px 0;
  font-weight: bold;
  text-align: center;
  background-color: #f9f9f9;
  margin-top: 24px;
  align-items: center;
  p {
    padding: 0 6px;
  }
`;

export const ContentLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
  font-size: 13px;
  text-align: center;
  align-items: center;
  height: 62px;
  border-bottom: 1px solid #eef0f2;

  p {
    padding: 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Status = styled.div<{ status: number }>`
  border-left: 2px solid
    ${({ status }) => {
      if (status === ENUM_STATUS_PROCESSAMENTO.Falha) return '#DB4D4D';
      if (
        status === ENUM_STATUS_PROCESSAMENTO['Em processamento'] ||
        status === ENUM_STATUS_PROCESSAMENTO['Não iniciado']
      )
        return '#3063E9';
      if (status === ENUM_STATUS_PROCESSAMENTO.Finalizado) return '#47995A';
      return '';
    }};
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 100%;
  text-align: left;

  svg {
    margin: 0 10px;
  }

  color: ${({ status }) => {
    if (status === ENUM_STATUS_PROCESSAMENTO.Falha) return '#DB4D4D';
    if (
      status === ENUM_STATUS_PROCESSAMENTO['Em processamento'] ||
      status === ENUM_STATUS_PROCESSAMENTO['Não iniciado']
    )
      return '#3063E9';
    if (status === ENUM_STATUS_PROCESSAMENTO.Finalizado) return '#47995A';
    return '';
  }};
`;
