/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CustomSpinner } from '../../components/_Atoms';
import AddRatePlansPage from '../AddRatePlans';

import {
  IDataResponseModel,
  IResponsePlanoById
} from '../../interfaces/requests.interfaces';

import { API } from '../../constants/api.constants';
import { SUB_MENU_ROUTES } from '../../constants/routes.constants';
import {
  ENUM_TIPO_TAXA,
  ENUM_TIPO_TRANSACAO
} from '../../enums/rate-plans.enum';
import { IFormAddRatePlans } from '../../interfaces/rate-plans.interfaces';
import { get } from '../../services/axios.service';

const EditRatePlanByIdPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const [initialValues, setInitialValues] = useState<IFormAddRatePlans>();

  const getInitialValues = (ratePlan: IResponsePlanoById) => {
    const initialRatePlansFormInitialValues: IFormAddRatePlans = {
      nome: '',
      url: '',
      closeAccordion: [false, true, true],
      taxaAntecipada: [
        {
          taxaId: 0,
          tipoTaxa: ENUM_TIPO_TAXA.PLANO_ANTECIPADO,
          tipoTransacao: ENUM_TIPO_TRANSACAO.DEBITO,
          taxaVisaMaster: '',
          taxaOutrasBandeiras: '',
          parceladoFlexivel: false
        },
        {
          taxaId: 0,
          tipoTaxa: ENUM_TIPO_TAXA.PLANO_ANTECIPADO,
          tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA,
          taxaVisaMaster: '',
          taxaOutrasBandeiras: '',
          parceladoFlexivel: false
        },
        {
          taxaId: 0,
          tipoTaxa: ENUM_TIPO_TAXA.PLANO_ANTECIPADO,
          tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO,
          taxaVisaMaster: '',
          taxaOutrasBandeiras: '',
          parceladoFlexivel: false,
          detalhamentoParcelasVisaMaster: {
            taxa2x: '',
            taxa3x: '',
            taxa4x: '',
            taxa5x: '',
            taxa6x: '',
            taxa7x: '',
            taxa8x: '',
            taxa9x: '',
            taxa10x: '',
            taxa11x: '',
            taxa12x: ''
          },
          detalhamentoParcelasOutrasBandeiras: {
            taxa2x: '',
            taxa3x: '',
            taxa4x: '',
            taxa5x: '',
            taxa6x: '',
            taxa7x: '',
            taxa8x: '',
            taxa9x: '',
            taxa10x: '',
            taxa11x: '',
            taxa12x: ''
          }
        }
      ],
      taxaEconomica: [
        {
          tipoTaxa: ENUM_TIPO_TAXA.PLANO_ECONOMICO,
          tipoTransacao: ENUM_TIPO_TRANSACAO.DEBITO,
          taxaVisaMaster: '',
          taxaOutrasBandeiras: '',
          parceladoFlexivel: false
        },
        {
          tipoTaxa: ENUM_TIPO_TAXA.PLANO_ECONOMICO,
          tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA,
          taxaVisaMaster: '',
          taxaOutrasBandeiras: '',
          parceladoFlexivel: false
        },
        {
          tipoTaxa: ENUM_TIPO_TAXA.PLANO_ECONOMICO,
          tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO,
          taxaVisaMaster: '',
          taxaOutrasBandeiras: '',
          parceladoFlexivel: false,
          detalhamentoParcelasVisaMaster: {
            taxa2x: '',
            taxa3x: '',
            taxa4x: '',
            taxa5x: '',
            taxa6x: '',
            taxa7x: '',
            taxa8x: '',
            taxa9x: '',
            taxa10x: '',
            taxa11x: '',
            taxa12x: ''
          },
          detalhamentoParcelasOutrasBandeiras: {
            taxa2x: '',
            taxa3x: '',
            taxa4x: '',
            taxa5x: '',
            taxa6x: '',
            taxa7x: '',
            taxa8x: '',
            taxa9x: '',
            taxa10x: '',
            taxa11x: '',
            taxa12x: ''
          }
        }
      ],
      taxaInstantanea: [
        {
          tipoTaxa: ENUM_TIPO_TAXA.PLANO_INSTANTANEO,
          tipoTransacao: ENUM_TIPO_TRANSACAO.DEBITO,
          taxaVisaMaster: '',
          taxaOutrasBandeiras: '',
          parceladoFlexivel: false
        },
        {
          tipoTaxa: ENUM_TIPO_TAXA.PLANO_INSTANTANEO,
          tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA,
          taxaVisaMaster: '',
          taxaOutrasBandeiras: '',
          parceladoFlexivel: false
        },
        {
          tipoTaxa: ENUM_TIPO_TAXA.PLANO_INSTANTANEO,
          tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO,
          taxaVisaMaster: '',
          taxaOutrasBandeiras: '',
          parceladoFlexivel: false,
          detalhamentoParcelasVisaMaster: {
            taxa2x: '',
            taxa3x: '',
            taxa4x: '',
            taxa5x: '',
            taxa6x: '',
            taxa7x: '',
            taxa8x: '',
            taxa9x: '',
            taxa10x: '',
            taxa11x: '',
            taxa12x: ''
          },
          detalhamentoParcelasOutrasBandeiras: {
            taxa2x: '',
            taxa3x: '',
            taxa4x: '',
            taxa5x: '',
            taxa6x: '',
            taxa7x: '',
            taxa8x: '',
            taxa9x: '',
            taxa10x: '',
            taxa11x: '',
            taxa12x: ''
          }
        }
      ]
    };

    const closeAccordion = [false, true, true];

    const oldTaxas = ratePlan.taxas.filter((taxa) => taxa.tipoTaxa === 1);

    if (oldTaxas[0]) {
      const {
        tipoTransacao,
        taxaVisaMaster,
        taxaOutrasBandeiras,
        parceladoFlexivel
      } = oldTaxas[0];

      initialRatePlansFormInitialValues.taxaAntecipada[0] = {
        taxaId: 0,
        tipoTaxa: 2,
        tipoTransacao,
        taxaVisaMaster,
        taxaOutrasBandeiras,
        parceladoFlexivel
      };

      initialRatePlansFormInitialValues.taxaEconomica[0] = {
        taxaId: 0,
        tipoTaxa: 3,
        tipoTransacao,
        taxaVisaMaster,
        taxaOutrasBandeiras,
        parceladoFlexivel
      };

      const taxaAntecipadaAux = ratePlan.taxas
        .filter((item) => item.tipoTaxa === 2)
        .map((item) => {
          const taxa = item;

          taxa.taxaId = item.taxaId;
          taxa.tipoTaxa = item.tipoTaxa;
          taxa.tipoTransacao = item.tipoTransacao;
          taxa.parceladoFlexivel = item.parceladoFlexivel;
          taxa.taxaOutrasBandeiras =
            item.taxaOutrasBandeiras === 'Flexível'
              ? ''
              : item.taxaOutrasBandeiras;
          taxa.taxaVisaMaster =
            item.taxaVisaMaster === 'Flexível' ? '' : item.taxaVisaMaster;
          if (
            taxa.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO &&
            item.parceladoFlexivel
          ) {
            taxa.detalhamentoParcelasVisaMaster = {
              taxa2x: item.parcelas[0].porcentagemVisaMaster,
              taxa3x: item.parcelas[1].porcentagemVisaMaster,
              taxa4x: item.parcelas[2].porcentagemVisaMaster,
              taxa5x: item.parcelas[3].porcentagemVisaMaster,
              taxa6x: item.parcelas[4].porcentagemVisaMaster,
              taxa7x: item.parcelas[5].porcentagemVisaMaster,
              taxa8x: item.parcelas[6].porcentagemVisaMaster,
              taxa9x: item.parcelas[7].porcentagemVisaMaster,
              taxa10x: item.parcelas[8].porcentagemVisaMaster,
              taxa11x: item.parcelas[9].porcentagemVisaMaster,
              taxa12x: item.parcelas[10].porcentagemVisaMaster
            };
            taxa.detalhamentoParcelasOutrasBandeiras = {
              taxa2x: item.parcelas[0].porcentagemOutrasBandeiras,
              taxa3x: item.parcelas[1].porcentagemOutrasBandeiras,
              taxa4x: item.parcelas[2].porcentagemOutrasBandeiras,
              taxa5x: item.parcelas[3].porcentagemOutrasBandeiras,
              taxa6x: item.parcelas[4].porcentagemOutrasBandeiras,
              taxa7x: item.parcelas[5].porcentagemOutrasBandeiras,
              taxa8x: item.parcelas[6].porcentagemOutrasBandeiras,
              taxa9x: item.parcelas[7].porcentagemOutrasBandeiras,
              taxa10x: item.parcelas[8].porcentagemOutrasBandeiras,
              taxa11x: item.parcelas[9].porcentagemOutrasBandeiras,
              taxa12x: item.parcelas[10].porcentagemOutrasBandeiras
            };
          }
          return taxa;
        });

      initialRatePlansFormInitialValues.taxaAntecipada[1] =
        taxaAntecipadaAux.filter(
          ({ tipoTransacao, tipoTaxa }) =>
            tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA &&
            tipoTaxa === 2
        )[0];

      initialRatePlansFormInitialValues.taxaAntecipada[2] =
        taxaAntecipadaAux.filter(
          ({ tipoTransacao, tipoTaxa }) =>
            tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO &&
            tipoTaxa === 2
        )[0];

      const taxaEconomica = ratePlan.taxas
        .filter((item) => item.tipoTaxa === 3)
        .map((item) => {
          const taxa = item;

          taxa.taxaId = item.taxaId;
          taxa.tipoTaxa = item.tipoTaxa;
          taxa.tipoTransacao = item.tipoTransacao;
          taxa.parceladoFlexivel = item.parceladoFlexivel;
          taxa.taxaVisaMaster = item.taxaVisaMaster;
          if (
            taxa.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO &&
            item.parceladoFlexivel
          ) {
            taxa.detalhamentoParcelasVisaMaster = {
              taxa2x: item.parcelas[0].porcentagemVisaMaster,
              taxa3x: item.parcelas[1].porcentagemVisaMaster,
              taxa4x: item.parcelas[2].porcentagemVisaMaster,
              taxa5x: item.parcelas[3].porcentagemVisaMaster,
              taxa6x: item.parcelas[4].porcentagemVisaMaster,
              taxa7x: item.parcelas[5].porcentagemVisaMaster,
              taxa8x: item.parcelas[6].porcentagemVisaMaster,
              taxa9x: item.parcelas[7].porcentagemVisaMaster,
              taxa10x: item.parcelas[8].porcentagemVisaMaster,
              taxa11x: item.parcelas[9].porcentagemVisaMaster,
              taxa12x: item.parcelas[10].porcentagemVisaMaster
            };
            taxa.detalhamentoParcelasOutrasBandeiras = {
              taxa2x: item.parcelas[0].porcentagemOutrasBandeiras,
              taxa3x: item.parcelas[1].porcentagemOutrasBandeiras,
              taxa4x: item.parcelas[2].porcentagemOutrasBandeiras,
              taxa5x: item.parcelas[3].porcentagemOutrasBandeiras,
              taxa6x: item.parcelas[4].porcentagemOutrasBandeiras,
              taxa7x: item.parcelas[5].porcentagemOutrasBandeiras,
              taxa8x: item.parcelas[6].porcentagemOutrasBandeiras,
              taxa9x: item.parcelas[7].porcentagemOutrasBandeiras,
              taxa10x: item.parcelas[8].porcentagemOutrasBandeiras,
              taxa11x: item.parcelas[9].porcentagemOutrasBandeiras,
              taxa12x: item.parcelas[10].porcentagemOutrasBandeiras
            };
          }

          return taxa;
        });

      initialRatePlansFormInitialValues.taxaEconomica[1] = taxaEconomica.filter(
        ({ tipoTransacao, tipoTaxa }) =>
          tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA &&
          tipoTaxa === 3
      )[0];

      initialRatePlansFormInitialValues.taxaEconomica[2] = taxaEconomica.filter(
        ({ tipoTransacao, tipoTaxa }) =>
          tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO &&
          tipoTaxa === 3
      )[0];

      closeAccordion[1] = false;
    } else {
      const taxaAntecipadaAux = [];
      const taxaEconomicaAux = [];
      const taxaInstantaneaAux = [];

      taxaAntecipadaAux[0] = ratePlan.taxas.filter(
        (item) =>
          item.tipoTaxa === 2 &&
          item.tipoTransacao === ENUM_TIPO_TRANSACAO.DEBITO
      )[0];

      taxaAntecipadaAux[1] = ratePlan.taxas.filter(
        (item) =>
          item.tipoTaxa === 2 &&
          item.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA
      )[0];

      taxaAntecipadaAux[2] = ratePlan.taxas
        .filter(
          (item) =>
            item.tipoTaxa === 2 &&
            item.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO
        )
        .map((item) => {
          const taxa = item;
          if (
            taxa.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO &&
            item.parceladoFlexivel
          ) {
            taxa.detalhamentoParcelasVisaMaster = {
              taxa2x: item.parcelas[0].porcentagemVisaMaster,
              taxa3x: item.parcelas[1].porcentagemVisaMaster,
              taxa4x: item.parcelas[2].porcentagemVisaMaster,
              taxa5x: item.parcelas[3].porcentagemVisaMaster,
              taxa6x: item.parcelas[4].porcentagemVisaMaster,
              taxa7x: item.parcelas[5].porcentagemVisaMaster,
              taxa8x: item.parcelas[6].porcentagemVisaMaster,
              taxa9x: item.parcelas[7].porcentagemVisaMaster,
              taxa10x: item.parcelas[8].porcentagemVisaMaster,
              taxa11x: item.parcelas[9].porcentagemVisaMaster,
              taxa12x: item.parcelas[10].porcentagemVisaMaster
            };
            taxa.detalhamentoParcelasOutrasBandeiras = {
              taxa2x: item.parcelas[0].porcentagemOutrasBandeiras,
              taxa3x: item.parcelas[1].porcentagemOutrasBandeiras,
              taxa4x: item.parcelas[2].porcentagemOutrasBandeiras,
              taxa5x: item.parcelas[3].porcentagemOutrasBandeiras,
              taxa6x: item.parcelas[4].porcentagemOutrasBandeiras,
              taxa7x: item.parcelas[5].porcentagemOutrasBandeiras,
              taxa8x: item.parcelas[6].porcentagemOutrasBandeiras,
              taxa9x: item.parcelas[7].porcentagemOutrasBandeiras,
              taxa10x: item.parcelas[8].porcentagemOutrasBandeiras,
              taxa11x: item.parcelas[9].porcentagemOutrasBandeiras,
              taxa12x: item.parcelas[10].porcentagemOutrasBandeiras
            };
          }
          return {
            tipoTaxa: taxa.tipoTaxa,
            tipoTransacao: taxa.tipoTransacao,
            parceladoFlexivel: taxa.parceladoFlexivel,
            taxaVisaMaster:
              taxa.taxaVisaMaster === 'Flexível' ? '' : taxa.taxaVisaMaster,
            taxaOutrasBandeiras:
              taxa.taxaOutrasBandeiras === 'Flexível'
                ? ''
                : taxa.taxaOutrasBandeiras,
            detalhamentoParcelasVisaMaster: taxa.detalhamentoParcelasVisaMaster,
            detalhamentoParcelasOutrasBandeiras:
              taxa.detalhamentoParcelasOutrasBandeiras
          };
        })[0];

      if (ratePlan.taxas.some((item) => item.tipoTaxa === 3)) {
        taxaEconomicaAux[0] = ratePlan.taxas.filter(
          (item) =>
            item.tipoTaxa === 3 &&
            item.tipoTransacao === ENUM_TIPO_TRANSACAO.DEBITO
        )[0];

        taxaEconomicaAux[1] = ratePlan.taxas.filter(
          (item) =>
            item.tipoTaxa === 3 &&
            item.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA
        )[0];

        taxaEconomicaAux[2] = ratePlan.taxas
          .filter(
            (item) =>
              item.tipoTaxa === 3 &&
              item.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO
          )
          .map((item) => {
            const taxa = item;
            if (
              taxa.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO &&
              item.parceladoFlexivel
            ) {
              taxa.detalhamentoParcelasVisaMaster = {
                taxa2x: item.parcelas[0].porcentagemVisaMaster,
                taxa3x: item.parcelas[1].porcentagemVisaMaster,
                taxa4x: item.parcelas[2].porcentagemVisaMaster,
                taxa5x: item.parcelas[3].porcentagemVisaMaster,
                taxa6x: item.parcelas[4].porcentagemVisaMaster,
                taxa7x: item.parcelas[5].porcentagemVisaMaster,
                taxa8x: item.parcelas[6].porcentagemVisaMaster,
                taxa9x: item.parcelas[7].porcentagemVisaMaster,
                taxa10x: item.parcelas[8].porcentagemVisaMaster,
                taxa11x: item.parcelas[9].porcentagemVisaMaster,
                taxa12x: item.parcelas[10].porcentagemVisaMaster
              };
              taxa.detalhamentoParcelasOutrasBandeiras = {
                taxa2x: item.parcelas[0].porcentagemOutrasBandeiras,
                taxa3x: item.parcelas[1].porcentagemOutrasBandeiras,
                taxa4x: item.parcelas[2].porcentagemOutrasBandeiras,
                taxa5x: item.parcelas[3].porcentagemOutrasBandeiras,
                taxa6x: item.parcelas[4].porcentagemOutrasBandeiras,
                taxa7x: item.parcelas[5].porcentagemOutrasBandeiras,
                taxa8x: item.parcelas[6].porcentagemOutrasBandeiras,
                taxa9x: item.parcelas[7].porcentagemOutrasBandeiras,
                taxa10x: item.parcelas[8].porcentagemOutrasBandeiras,
                taxa11x: item.parcelas[9].porcentagemOutrasBandeiras,
                taxa12x: item.parcelas[10].porcentagemOutrasBandeiras
              };
            }
            return {
              tipoTaxa: taxa.tipoTaxa,
              tipoTransacao: taxa.tipoTransacao,
              parceladoFlexivel: taxa.parceladoFlexivel,
              taxaVisaMaster:
                taxa.taxaVisaMaster === 'Flexível' ? '' : taxa.taxaVisaMaster,
              taxaOutrasBandeiras:
                taxa.taxaOutrasBandeiras === 'Flexível'
                  ? ''
                  : taxa.taxaOutrasBandeiras,
              detalhamentoParcelasVisaMaster:
                taxa.detalhamentoParcelasVisaMaster,
              detalhamentoParcelasOutrasBandeiras:
                taxa.detalhamentoParcelasOutrasBandeiras
            };
          })[0];
        closeAccordion[1] = false;
        initialRatePlansFormInitialValues.taxaEconomica = taxaEconomicaAux;
      }

      if (ratePlan.taxas.some((item) => item.tipoTaxa === 4)) {
        taxaInstantaneaAux[0] = ratePlan.taxas.filter(
          (item) =>
            item.tipoTaxa === 4 &&
            item.tipoTransacao === ENUM_TIPO_TRANSACAO.DEBITO
        )[0];
        taxaInstantaneaAux[1] = ratePlan.taxas.filter(
          (item) =>
            item.tipoTaxa === 4 &&
            item.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA
        )[0];
        taxaInstantaneaAux[2] = ratePlan.taxas
          .filter(
            (item) =>
              item.tipoTaxa === 4 &&
              item.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO
          )
          .map((item) => {
            const taxa = item;
            if (
              taxa.tipoTransacao === ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO &&
              item.parceladoFlexivel
            ) {
              taxa.detalhamentoParcelasVisaMaster = {
                taxa2x: item.parcelas[0].porcentagemVisaMaster,
                taxa3x: item.parcelas[1].porcentagemVisaMaster,
                taxa4x: item.parcelas[2].porcentagemVisaMaster,
                taxa5x: item.parcelas[3].porcentagemVisaMaster,
                taxa6x: item.parcelas[4].porcentagemVisaMaster,
                taxa7x: item.parcelas[5].porcentagemVisaMaster,
                taxa8x: item.parcelas[6].porcentagemVisaMaster,
                taxa9x: item.parcelas[7].porcentagemVisaMaster,
                taxa10x: item.parcelas[8].porcentagemVisaMaster,
                taxa11x: item.parcelas[9].porcentagemVisaMaster,
                taxa12x: item.parcelas[10].porcentagemVisaMaster
              };
              taxa.detalhamentoParcelasOutrasBandeiras = {
                taxa2x: item.parcelas[0].porcentagemOutrasBandeiras,
                taxa3x: item.parcelas[1].porcentagemOutrasBandeiras,
                taxa4x: item.parcelas[2].porcentagemOutrasBandeiras,
                taxa5x: item.parcelas[3].porcentagemOutrasBandeiras,
                taxa6x: item.parcelas[4].porcentagemOutrasBandeiras,
                taxa7x: item.parcelas[5].porcentagemOutrasBandeiras,
                taxa8x: item.parcelas[6].porcentagemOutrasBandeiras,
                taxa9x: item.parcelas[7].porcentagemOutrasBandeiras,
                taxa10x: item.parcelas[8].porcentagemOutrasBandeiras,
                taxa11x: item.parcelas[9].porcentagemOutrasBandeiras,
                taxa12x: item.parcelas[10].porcentagemOutrasBandeiras
              };
            }
            return {
              tipoTaxa: taxa.tipoTaxa,
              tipoTransacao: taxa.tipoTransacao,
              parceladoFlexivel: taxa.parceladoFlexivel,
              taxaVisaMaster: taxa.taxaVisaMaster,
              taxaOutrasBandeiras: taxa.taxaOutrasBandeiras,
              detalhamentoParcelasVisaMaster:
                taxa.detalhamentoParcelasVisaMaster,
              detalhamentoParcelasOutrasBandeiras:
                taxa.detalhamentoParcelasOutrasBandeiras
            };
          })[0];
        closeAccordion[2] = false;
        initialRatePlansFormInitialValues.taxaInstantanea = taxaInstantaneaAux;
      }
      initialRatePlansFormInitialValues.taxaAntecipada = taxaAntecipadaAux;
    }

    initialRatePlansFormInitialValues.id = ratePlan.id;
    initialRatePlansFormInitialValues.nome = ratePlan.nome;
    initialRatePlansFormInitialValues.url = ratePlan.url;

    initialRatePlansFormInitialValues.closeAccordion = closeAccordion;

    setInitialValues(initialRatePlansFormInitialValues);
  };

  useEffect(() => {
    if (!id) return;
    const getRatePlan = async () => {
      try {
        const { data } = await get<IDataResponseModel<IResponsePlanoById>>(
          API.PLANOS.GET_BY_ID(`${id}`)
        );
        if (data) getInitialValues(data);
      } catch (error) {
        push(SUB_MENU_ROUTES.RATE_PLANS);
      }
    };
    getRatePlan();
  }, [id]);

  return (
    <>
      {initialValues ? (
        <div>
          <AddRatePlansPage isEditable initialValues={initialValues} />
        </div>
      ) : (
        <CustomSpinner />
      )}
    </>
  );
};

export default EditRatePlanByIdPage;
