import { FC } from 'react';

const SVGPropostaNaoAceita: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.64 8.57434C13.64 8.75721 13.5964 8.9288 13.5233 9.08476C13.0358 10.6575 10.7534 10.5426 7.50698 10.6193C6.96421 10.632 7.27467 11.2729 7.46517 12.6799C7.58914 13.5949 6.99911 15 6.00736 15C4.37213 15 5.94519 13.7101 4.49937 10.5204C3.72684 8.8161 2 9.77077 2 8.05884L2 4.162C2 3.49562 2.06544 2.85506 3.00266 2.74963C3.91115 2.64747 3.70684 2 5.01742 2L11.5772 2C12.2454 2 12.7889 2.54386 12.7889 3.21206C12.7889 3.48908 12.6919 3.74138 12.5352 3.94569C12.906 4.15327 13.1605 4.54517 13.1605 4.99961C13.1605 5.2759 13.0638 5.5282 12.9074 5.73215C13.2793 5.93937 13.5346 6.33163 13.5346 6.78679C13.5346 7.11726 13.4008 7.41682 13.1852 7.63603C13.4604 7.85816 13.64 8.19408 13.64 8.57434Z"
        fill="#FFDB5E"
      />
      <path
        d="M9.30098 7.3625L12.4289 7.3625C12.8543 7.3625 13.2534 7.59008 13.4708 7.95617C13.5603 8.10704 13.5105 8.30226 13.3592 8.39206C13.2084 8.48185 13.0131 8.43132 12.9233 8.28045C12.8205 8.10631 12.6303 7.9987 12.4286 7.9987L9.22573 7.9987C8.90836 7.9987 8.65024 8.25682 8.65024 8.57419C8.65024 8.89157 8.90836 9.14969 9.22573 9.14969L11.3659 9.14968C11.5415 9.14968 11.684 9.29219 11.684 9.46779C11.684 9.64338 11.5415 9.78589 11.3659 9.78589L9.22537 9.78589C8.55717 9.78589 8.01367 9.24239 8.01367 8.57419C8.01367 8.20156 8.18636 7.87183 8.45174 7.64934C8.22816 7.42939 8.08893 7.12401 8.08893 6.78664C8.08893 6.41292 8.2627 6.08209 8.52954 5.85997C8.30742 5.64039 8.16963 5.3361 8.16963 4.99946C8.16963 4.59229 8.37285 4.2331 8.68187 4.01316C8.49137 3.79903 8.37213 3.52019 8.37213 3.21191C8.37213 2.54371 8.91563 2.00021 9.58382 2.00021L11.5775 2.00021C12.0028 2.00021 12.4024 2.22743 12.6198 2.59352C12.7096 2.74439 12.6598 2.93961 12.5089 3.02941C12.3577 3.11848 12.1624 3.0694 12.073 2.91853C11.9694 2.74439 11.7793 2.63642 11.5775 2.63642L9.58382 2.63642C9.26645 2.63642 9.00833 2.89453 9.00833 3.21191C9.00833 3.52928 9.26645 3.7874 9.58382 3.7874L11.949 3.7874C12.3744 3.7874 12.7743 4.01498 12.9913 4.38107C13.0811 4.5323 13.0313 4.72753 12.8804 4.81696C12.7281 4.90821 12.5336 4.85695 12.4446 4.70608C12.3395 4.52903 12.1541 4.4236 11.949 4.4236L9.38133 4.4236C9.06395 4.4236 8.80584 4.68208 8.80584 4.99946C8.80584 5.31683 9.06395 5.57495 9.38133 5.57495L12.3228 5.57495C12.7481 5.57495 13.1477 5.80216 13.3651 6.16825C13.4548 6.31912 13.405 6.51435 13.2542 6.60414C13.1026 6.69358 12.9077 6.64413 12.8183 6.49326C12.7132 6.31658 12.5278 6.21115 12.3228 6.21115L9.30098 6.21115C8.98361 6.21115 8.72549 6.46927 8.72549 6.78664C8.72549 7.10402 8.98325 7.3625 9.30098 7.3625Z"
        fill="#EE9547"
      />
    </svg>
  );
};

export default SVGPropostaNaoAceita;
