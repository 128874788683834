import { FC } from 'react';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Done } from '../../../assets/icons/done.svg';
import { ReactComponent as Notify } from '../../../assets/icons/notify.svg';
import { ITask } from '../../../interfaces/taskList.interfaces';
import { formatCreationDate } from '../../../utils/format.utils';
import {
  CountItensPerPage,
  CustomPagination,
  CustomPopover,
  CustomTooltip,
  Spinner
} from '../../_Atoms';
import { SVGDownload } from '../../_Atoms/Icon';
import { NoResultsState } from '../../_Molecules';
import { TaskListStatus } from '../FilterTaskList/styles';
import {
  ResultValue,
  SpinnerContainer,
  TableHeader,
  TableValue
} from './styles';

interface IProps {
  loading: boolean;
  search: string;
  setSearch: (p: string) => void;
  taskList: ITask[];
  setCurrentPage: (p: number) => void;
  getDataTable: (page: number, noSearch?: boolean) => void;
  totalItems: number;
  itemsPerPage: number;
  resetPagination: boolean;
  networkError: boolean;
  currentPage: number;
  handlePageChange: (page: number) => void;
  getExportationFile: (url: string, nameFile: string) => void;
}
const TableTaskList: FC<IProps> = ({
  loading,
  setSearch,
  search,
  taskList,
  setCurrentPage,
  getDataTable,
  totalItems,
  itemsPerPage,
  resetPagination,
  networkError,
  currentPage,
  handlePageChange,
  getExportationFile
}) => {
  const createActions = (item: ITask) => {
    const actions = [];

    if (item.arquivoOriginal) {
      actions.push({
        children: {
          color: 'blue',
          text: 'Baixar arquivo original'
        },
        icon: () => <SVGDownload />,
        onClick: () =>
          getExportationFile(
            item.arquivoOriginal,
            `${item.nomeArquivo ? item.nomeArquivo : 'arquivo_original'}`
          )
      });
    }

    if (item.arquivoRetificado) {
      actions.push({
        children: {
          color: 'blue',
          text: 'Baixar arquivo correção'
        },
        icon: () => <SVGDownload />,
        onClick: () =>
          getExportationFile(
            item.arquivoRetificado,
            `${
              item.nomeArquivo
                ? `${item.nomeArquivo}_retificado`
                : 'arquivo_retificado'
            }`
          )
      });
    }

    return actions;
  };

  return (
    <>
      <TableHeader>
        <p>Status do Upload</p>
        <p>Data de Upload</p>
        <p>E-mail</p>
        <p>Nome da Carga</p>
        <p>Ações</p>
      </TableHeader>

      {loading && (
        <SpinnerContainer>
          <Spinner size="46px" color="#3388ff" width="5px" />
        </SpinnerContainer>
      )}
      {!taskList.length && !loading && search && (
        <NoResultsState
          action={() => {
            setSearch('');
            setCurrentPage(1);
            getDataTable(1, true);
          }}
        />
      )}
      {!taskList.length && !loading && search === '' && <NoResultsState />}
      {taskList.length > 0 && !loading && (
        <div>
          {taskList.map((item) => (
            <TableValue key={item.id}>
              <TaskListStatus status={item.statusUpload.toLocaleLowerCase()}>
                {item.statusUpload.toLocaleLowerCase() === 'completo' && (
                  <Done />
                )}
                {item.statusUpload.toLowerCase() === 'parcial' && <Notify />}
                {item.statusUpload.toLowerCase() === 'reprovado' && <Close />}
                {item.statusUpload}
              </TaskListStatus>
              <p>{formatCreationDate(item.dataCriacao)}</p>
              <CustomTooltip title={item.email}>
                <ResultValue>{item.email}</ResultValue>
              </CustomTooltip>
              <CustomTooltip title={item.nomeCarga}>
                <ResultValue>{item.nomeCarga}</ResultValue>
              </CustomTooltip>
              <p>
                <CustomPopover
                  actions={createActions(item)}
                  disabled={!item.arquivoOriginal && !item.arquivoRetificado}
                />
              </p>
            </TableValue>
          ))}
        </div>
      )}
      {totalItems > itemsPerPage && resetPagination && !networkError && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 24
          }}
        >
          <CountItensPerPage
            currentPage={currentPage}
            itensPerPage={itemsPerPage}
            totalItens={totalItems}
          />
          <CustomPagination
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            totalItems={totalItems}
            initialPage={currentPage}
            shouldResetPagination={resetPagination}
          />
        </div>
      )}
    </>
  );
};

export default TableTaskList;
