export const formatCurrencyToNumber = (currency: string): number => {
  return Number(currency.replace(/\./g, '').replace(/,/, '.'));
};

export const formatNumberToCurrency = (value: number): string => {
  return String(value).replace(/\./g, ',');
};

export const formatNumberToBrlCurrency = (value: number): string => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
};

export const formatStringWithMaxLength = (
  maxLength: number,
  text: string
): string => {
  return text.length > maxLength
    ? text.slice(0, maxLength).concat('...')
    : text;
};

export const formatFullDate = (isoDate: Date): string => {
  return isoDate
    .toLocaleDateString('pt-BR')
    .concat(' - ')
    .concat(
      isoDate.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit'
      })
    );
};

export const formatCreationDate = (stringDate: string): string => {
  const data = new Date(stringDate);
  const dia = data.getDate().toString().padStart(2, '0');
  const mes = (data.getMonth() + 1).toString().padStart(2, '0');
  const ano = data.getFullYear();
  const hora = data.getHours().toString().padStart(2, '0');
  const minutos = data.getMinutes().toString().padStart(2, '0');

  return `${dia}/${mes}/${ano} - ${hora}:${minutos}`;
};

export const formatPhone = (phone: string): string => {
  return phone.length === 10
    ? phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
    : phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
};

export const formatCpfCnpj = (cpfCnpj: string): string => {
  return cpfCnpj.length === 11
    ? cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    : cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const buildBearer = (token: string): string => {
  return `Bearer ${token}`;
};

export const formatFileNameDownload = (filename: string): string => {
  return `${filename}_${new Date().toLocaleDateString('pt-BR')}`;
};

export const formatExpirationDate = (
  expirationTime: number
): { date: string; hour: string } => {
  const formatedExpiration = new Date(expirationTime * 1000);

  return {
    date: formatedExpiration.toLocaleDateString('pt-BR'),
    hour: formatedExpiration.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit'
    })
  };
};

export const calculateTextWidth = (fontSize: number, str: string): number => {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const context: CanvasRenderingContext2D | null = canvas.getContext('2d');

  if (context) {
    context.font = `${fontSize}px Arial`;

    const stringWidth = context.measureText(str).width;
    return stringWidth;
  }

  return 0;
};

export const splitStringWithDelimiter = (str: string): string => {
  return str.length > 100
    ? `${str.slice(0, Math.floor(str.length / 2))} ${' \\A '} ${str.slice(
        Math.floor(str.length / 2)
      )}`
    : str;
};

export const alphanumericWithSpacesAndHyphens = /^(?=.*\S)[A-Za-z0-9\s\\-]+$/;
export const EspecialCharacters = /\W|_/;

export const equalsObject = (obj1: any, obj2: any): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (JSON.stringify(obj1) === JSON.stringify(obj2)) return true;

  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const fomartDateDDMMAAAA = (dateString: string): string => {
  if (!dateString) return '';
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};
