import React, { useState } from 'react';

import { Body, Headline } from '@sumup/circuit-ui';
import { useHistory } from 'react-router-dom';

import { getAssetsPropostaByEnum } from '../../../constants/alcada.constants';
import { USER_TYPE_BY_ENUM } from '../../../constants/user.constants';

import { formatCreationDate } from '../../../utils/format.utils';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Done } from '../../../assets/icons/done.svg';
import { API } from '../../../constants/api.constants';
import { SUB_MENU_ROUTES } from '../../../constants/routes.constants';
import {
  ENUM_STATUS_PROPOSTA,
  TipoAquisicao
} from '../../../enums/alcada.enums';
import { IDetalhamentoAquisicao } from '../../../interfaces/requests.interfaces';
import { get, post } from '../../../services/axios.service';
import { CustomStatus, CustomTooltip, SimpleLink, Spinner } from '../../_Atoms';
import { CustomDialog, Modal } from '../../_Molecules';
import { StatusNotaFiscal } from '../AlcadaTable/styles';
import {
  Button,
  NotFoundItem,
  StyledBox,
  StyledBoxTitleRow,
  StyledRow,
  StyledRowItem,
  StyledScrollBar,
  StyledTitleItem
} from './styles';

interface IApproveAlcadaTable {
  aquisition: IDetalhamentoAquisicao;
}
const ApproveAlcadaAquisitionTable: React.FC<IApproveAlcadaTable> = ({
  aquisition
}) => {
  const {
    cliente,
    dataCriacao,
    promotor,
    tipoAquisicao,
    transacaoId,
    aquisicaoId,
    status,
    totalValorOriginal,
    totalValorVenda,
    quantidadeMaquinas,
    maquinas,
    contratoComodato,
    nomeArquivoAssinatura,
    nomeArquivoDocumento,
    statusNotaFiscal
  } = aquisition;

  const valorUnitarioOriginalDuplicado = maquinas.map(
    (item) => item.valorOriginal
  );
  const valorUnitarioOriginal = Array.from(
    new Set(valorUnitarioOriginalDuplicado)
  );
  const valorUnitarioVendaDuplicado = maquinas.map((item) => item.valorVenda);
  const valorUnitarioVenda = Array.from(new Set(valorUnitarioVendaDuplicado));

  const assetsPropostas = getAssetsPropostaByEnum[status];
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState({
    title: '',
    message: ''
  });
  const [openModal, setOpenModal] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [urlImage, setUrlImage] = useState('');

  const getImage = async (id: string, type: number) => {
    setLoadingImage(true);
    setOpenModal(true);
    try {
      const response: { data: string } = await get(
        API.COMODATO.DOWNLOAD_IMAGEM(id, type)
      );

      setUrlImage(response.data);
    } catch (error) {
      setUrlImage('');
    }
    setLoadingImage(false);
  };

  const contractResend = async () => {
    setLoading(true);
    try {
      await post('/comodato/reenviar-contrato', { id: aquisicaoId, email: '' });

      setDialogText({
        title: 'Contrato reenviado com sucesso',
        message: 'Tudo certo! Reenviamos o contrato para o e-mail cadastrado.'
      });
    } catch (error) {
      setDialogText({
        title: 'Erro ao reenviar o contrato',
        message:
          'Não foi possível reenviar o contrato. Por favor, aguarde uns instantes e tente novamente.'
      });
    } finally {
      setLoading(false);
      setOpenDialog(true);
    }
  };

  return (
    <>
      <StyledBox>
        <Headline as="h2">Proposta</Headline>
        <StyledScrollBar>
          <StyledBoxTitleRow gridColumns="repeat(2, 1fr) 1.5fr repeat(2, 1fr) 0.5fr">
            <StyledTitleItem>Status</StyledTitleItem>
            <StyledTitleItem>Status nota fiscal</StyledTitleItem>
            <StyledTitleItem>Data da criação</StyledTitleItem>
            <StyledTitleItem>
              ID da imagem <br /> da assinatura
            </StyledTitleItem>
            <StyledTitleItem>
              ID da imagem <br /> do documento
            </StyledTitleItem>
            <StyledTitleItem>ID</StyledTitleItem>
          </StyledBoxTitleRow>
          <StyledRow gridColumns="repeat(2, 1fr) 1.5fr repeat(2, 1fr) 0.5fr">
            <StyledRowItem
              style={{ display: 'flex', alignItems: 'center' }}
              textAlign="left"
            >
              <CustomStatus status={status} />
              <Body
                noMargin
                as="span"
                style={{
                  color: assetsPropostas.color,
                  paddingLeft: '30px',
                  whiteSpace: 'nowrap'
                }}
              >
                {assetsPropostas.name}
              </Body>
            </StyledRowItem>
            <StyledRowItem>
              {statusNotaFiscal === 0 && (
                <StatusNotaFiscal color="#9da7b1">
                  Não se aplica
                </StatusNotaFiscal>
              )}
              {statusNotaFiscal === 1 && (
                <StatusNotaFiscal color="#47995a">
                  <Done /> Emitida{' '}
                </StatusNotaFiscal>
              )}
              {statusNotaFiscal === 2 && (
                <StatusNotaFiscal color="#DB4D4D">
                  <Close /> Falha
                </StatusNotaFiscal>
              )}
            </StyledRowItem>
            <StyledRowItem>
              {formatCreationDate(dataCriacao)} <br />
              <span>UTC - 03:00 (Horário de Brasília)</span>
            </StyledRowItem>
            <StyledRowItem>
              {nomeArquivoAssinatura ? (
                <CustomTooltip title="ver imagem">
                  <StyledRowItem
                    style={{ padding: '0 20px' }}
                    onClick={() => getImage(nomeArquivoAssinatura, 1)}
                  >
                    <SimpleLink
                      style={{ whiteSpace: 'nowrap' }}
                      text={nomeArquivoAssinatura}
                    />
                  </StyledRowItem>
                </CustomTooltip>
              ) : (
                <p style={{ color: '#9DA7B1' }}>(Não encontrado)</p>
              )}
            </StyledRowItem>
            <StyledRowItem>
              {nomeArquivoDocumento ? (
                <CustomTooltip title="ver imagem">
                  <StyledRowItem
                    style={{ padding: '0 20px' }}
                    onClick={() => getImage(nomeArquivoDocumento, 1)}
                  >
                    <SimpleLink
                      style={{ whiteSpace: 'nowrap' }}
                      text={nomeArquivoDocumento}
                    />
                  </StyledRowItem>
                </CustomTooltip>
              ) : (
                <p style={{ color: '#9DA7B1' }}>(Não encontrado)</p>
              )}
            </StyledRowItem>
            <StyledRowItem>{aquisicaoId}</StyledRowItem>
          </StyledRow>
        </StyledScrollBar>
      </StyledBox>

      <StyledBox>
        <Headline as="h2">Promotor de Vendas</Headline>
        <StyledScrollBar>
          <StyledBoxTitleRow gridColumns="repeat(5, 20%)">
            <StyledTitleItem>Nome</StyledTitleItem>
            <StyledTitleItem>Empresa</StyledTitleItem>
            <StyledTitleItem>E-mail</StyledTitleItem>
            <StyledTitleItem>Tipo de usuário</StyledTitleItem>
            <StyledTitleItem>Subgrupo</StyledTitleItem>
          </StyledBoxTitleRow>
          <StyledRow gridColumns="repeat(5, 20%)">
            <CustomTooltip title={promotor.nome}>
              <StyledRowItem>
                {promotor.nome ? (
                  <p>{promotor.nome}</p>
                ) : (
                  <NotFoundItem>(Não encontrado)</NotFoundItem>
                )}
              </StyledRowItem>
            </CustomTooltip>

            <CustomTooltip title={promotor.empresa}>
              <StyledRowItem>
                {promotor.empresa ? (
                  <p>{promotor.empresa}</p>
                ) : (
                  <NotFoundItem>(Não encontrado)</NotFoundItem>
                )}
              </StyledRowItem>
            </CustomTooltip>

            <CustomTooltip title={promotor.email}>
              <StyledRowItem>
                {promotor.email ? (
                  <p>{promotor.email}</p>
                ) : (
                  <NotFoundItem>(Não encontrado)</NotFoundItem>
                )}
              </StyledRowItem>
            </CustomTooltip>

            <StyledRowItem>
              {promotor.tipoDeUsuario ? (
                USER_TYPE_BY_ENUM[promotor.tipoDeUsuario]
              ) : (
                <NotFoundItem>(Não encontrado)</NotFoundItem>
              )}
            </StyledRowItem>

            <CustomTooltip title={promotor.subGrupo}>
              <StyledRowItem>
                {promotor.subGrupo ? (
                  <p>{promotor.subGrupo}</p>
                ) : (
                  <NotFoundItem>(Não encontrado)</NotFoundItem>
                )}
              </StyledRowItem>
            </CustomTooltip>
          </StyledRow>
        </StyledScrollBar>
      </StyledBox>

      <StyledBox>
        <Headline as="h2">Oferta de máquinas</Headline>
        <StyledScrollBar style={{ marginBottom: 64 }}>
          <StyledBoxTitleRow gridColumns="25% 25% 25% 25%">
            <StyledTitleItem>Merchant code</StyledTitleItem>
            <StyledTitleItem>Razão social ou nome completo</StyledTitleItem>
            <StyledTitleItem>CNPJ/CPF</StyledTitleItem>
            <StyledTitleItem>Email</StyledTitleItem>
          </StyledBoxTitleRow>

          <StyledRow gridColumns="25% 25% 25% 25%">
            <StyledRowItem>{cliente.merchantCode}</StyledRowItem>
            <StyledRowItem>
              {cliente.nome ? (
                cliente.nome
              ) : (
                <NotFoundItem>(Não encontrado)</NotFoundItem>
              )}
            </StyledRowItem>
            <StyledRowItem>
              {cliente.cnpjCpf ? (
                cliente.cnpjCpf
              ) : (
                <NotFoundItem>(Não encontrado)</NotFoundItem>
              )}
            </StyledRowItem>
            <StyledRowItem>
              {cliente.email ? (
                cliente.email
              ) : (
                <NotFoundItem>(Não encontrado)</NotFoundItem>
              )}
            </StyledRowItem>
          </StyledRow>
        </StyledScrollBar>

        {TipoAquisicao[tipoAquisicao] === 'Venda' ? (
          <StyledScrollBar style={{ marginBottom: 64 }}>
            <StyledBoxTitleRow gridColumns="25% 25% 25% 25%">
              <StyledTitleItem>Quantidade</StyledTitleItem>
              <StyledTitleItem>Tipo de aquisição</StyledTitleItem>
              <StyledTitleItem>ID da transação</StyledTitleItem>
              <StyledTitleItem>Serial</StyledTitleItem>
            </StyledBoxTitleRow>

            <StyledRow gridColumns="25% 25% 25% 25%">
              <StyledRowItem>{quantidadeMaquinas}</StyledRowItem>
              <StyledRowItem>{TipoAquisicao[tipoAquisicao]}</StyledRowItem>
              <StyledRowItem>{transacaoId}</StyledRowItem>
              <StyledRowItem>
                {!maquinas.every((item) => item.numeroSerie === null) ? (
                  maquinas.map((item) => <p>{item.numeroSerie}</p>)
                ) : (
                  <NotFoundItem>(Não encontrado)</NotFoundItem>
                )}
              </StyledRowItem>
            </StyledRow>
          </StyledScrollBar>
        ) : (
          <StyledScrollBar style={{ marginBottom: 64 }}>
            <StyledBoxTitleRow gridColumns="25% 12.5% 25% 12.5% 25%">
              <StyledTitleItem>Tipo de aquisição</StyledTitleItem>
              <StyledTitleItem />
              <StyledTitleItem>Quantidade</StyledTitleItem>
              <StyledTitleItem />
              <StyledTitleItem>Serial</StyledTitleItem>
            </StyledBoxTitleRow>

            <StyledRow gridColumns="25% 12.5% 25% 12.5% 25%">
              <StyledRowItem>{TipoAquisicao[tipoAquisicao]}</StyledRowItem>
              <StyledRowItem />
              <StyledRowItem>{quantidadeMaquinas}</StyledRowItem>
              <StyledRowItem />
              <StyledRowItem>
                {!maquinas.every((item) => item.numeroSerie === null) ? (
                  maquinas.map((item) => <p>{item.numeroSerie}</p>)
                ) : (
                  <NotFoundItem>(Não encontrado)</NotFoundItem>
                )}
              </StyledRowItem>
            </StyledRow>
          </StyledScrollBar>
        )}

        {TipoAquisicao[tipoAquisicao] === 'Venda' && (
          <StyledScrollBar style={{ marginBottom: 64 }}>
            <StyledBoxTitleRow gridColumns="25% 25% 25% 25%">
              <StyledTitleItem>Valor original do produto</StyledTitleItem>
              <StyledTitleItem>Valor de venda do produto</StyledTitleItem>
              <StyledTitleItem>Valor original total</StyledTitleItem>
              <StyledTitleItem>Valor de venda total</StyledTitleItem>
            </StyledBoxTitleRow>

            <StyledRow gridColumns="25% 25% 25% 25%">
              <StyledRowItem>
                {valorUnitarioOriginal.map((item) => (
                  <p>
                    {valorUnitarioOriginalDuplicado.filter((i) => i === item)
                      .length > 1 &&
                      item != null && (
                        <span
                          style={{
                            fontSize: 12,
                            color: 'rgb(157, 167, 177)',
                            marginLeft: '-20px',
                            position: 'absolute'
                          }}
                        >
                          {
                            valorUnitarioOriginalDuplicado.filter(
                              (i) => i === item
                            ).length
                          }
                          x
                        </span>
                      )}
                    {item != null ? (
                      `R$ ${item}`
                    ) : (
                      <NotFoundItem>(Não encontrado)</NotFoundItem>
                    )}
                  </p>
                ))}
              </StyledRowItem>
              <StyledRowItem>
                {valorUnitarioVenda.map((item) => (
                  <p>
                    {valorUnitarioVendaDuplicado.filter((i) => i === item)
                      .length > 1 &&
                      item != null && (
                        <span
                          style={{
                            fontSize: 12,
                            color: 'rgb(157, 167, 177)',
                            marginLeft: '-20px',
                            position: 'absolute'
                          }}
                        >
                          {
                            valorUnitarioVendaDuplicado.filter(
                              (i) => i === item
                            ).length
                          }
                          x
                        </span>
                      )}
                    {item != null ? (
                      `R$ ${item}`
                    ) : (
                      <NotFoundItem>(Não encontrado)</NotFoundItem>
                    )}
                  </p>
                ))}
              </StyledRowItem>
              <StyledRowItem>
                {totalValorOriginal != null ? (
                  `R$ ${totalValorOriginal}`
                ) : (
                  <NotFoundItem>(Não encontrado)</NotFoundItem>
                )}
              </StyledRowItem>
              <StyledRowItem>
                {totalValorVenda != null ? (
                  `R$ ${totalValorVenda}`
                ) : (
                  <NotFoundItem>(Não encontrado)</NotFoundItem>
                )}
              </StyledRowItem>
            </StyledRow>
          </StyledScrollBar>
        )}

        {TipoAquisicao[tipoAquisicao] === 'Comodato' && (
          <StyledBox>
            <Headline as="h2">INFORMAÇÕES DO CONTRATO DE COMODATO</Headline>
            <StyledScrollBar>
              <StyledBoxTitleRow gridColumns="repeat(3, 33%)">
                <StyledTitleItem>Razão social ou nome completo</StyledTitleItem>
                <StyledTitleItem>CNPJ / CPF</StyledTitleItem>
                <StyledTitleItem>
                  E-mail do responsável pela assinatura
                </StyledTitleItem>
              </StyledBoxTitleRow>

              <StyledRow gridColumns="repeat(3, 33%)">
                <CustomTooltip title={contratoComodato.nomeContrato}>
                  <StyledRowItem>
                    {contratoComodato.nomeContrato ? (
                      <p>{contratoComodato.nomeContrato}</p>
                    ) : (
                      <NotFoundItem>(Não encontrado)</NotFoundItem>
                    )}
                  </StyledRowItem>
                </CustomTooltip>

                <CustomTooltip title={contratoComodato.documentoContrato}>
                  <StyledRowItem>
                    {contratoComodato.documentoContrato ? (
                      <p>{contratoComodato.documentoContrato}</p>
                    ) : (
                      <NotFoundItem>(Não encontrado)</NotFoundItem>
                    )}
                  </StyledRowItem>
                </CustomTooltip>

                <CustomTooltip title={contratoComodato.emailContrato}>
                  <StyledRowItem>
                    {contratoComodato.emailContrato ? (
                      <p>{contratoComodato.emailContrato}</p>
                    ) : (
                      <NotFoundItem>(Não encontrado)</NotFoundItem>
                    )}
                  </StyledRowItem>
                </CustomTooltip>
              </StyledRow>
            </StyledScrollBar>
          </StyledBox>
        )}
      </StyledBox>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => push(SUB_MENU_ROUTES.PROPOSALS)}
          variant="secondary"
          type="button"
          stretch
        >
          Voltar
        </Button>
        {TipoAquisicao[tipoAquisicao] === 'Comodato' &&
          ENUM_STATUS_PROPOSTA[status] !== 'CONCLUIDA' &&
          ENUM_STATUS_PROPOSTA[status] !== 'ASSINADA' && (
            <Button
              onClick={contractResend}
              isLoading={loading}
              loadingLabel="loading"
              variant="primary"
              type="button"
            >
              Reenviar contrato
            </Button>
          )}
      </div>

      {openDialog && (
        <CustomDialog
          title={dialogText.title}
          message={dialogText.message}
          handleClose={() => setOpenDialog(false)}
          primaryButton={{
            buttonTitle: 'Ok',
            onClick: () => setOpenDialog(false)
          }}
        />
      )}

      <Modal
        showFooter={false}
        open={openModal}
        setOpen={setOpenModal}
        title="Visualizar imagem"
        widthAuto
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 650,
            height: 'auto',
            minHeight: 650,
            padding: '16px'
          }}
        >
          {!loadingImage ? (
            <img
              style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
              src={urlImage}
              alt=""
            />
          ) : (
            <Spinner size="46px" color="#3388ff" width="5px" />
          )}
        </div>
      </Modal>
    </>
  );
};

export default ApproveAlcadaAquisitionTable;
