import styled from '@emotion/styled';

export const Line = styled.div<{ selected: boolean }>`
  display: flex;
  height: 72px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 0 16px;
  border: 2px solid ${({ selected }) => (selected ? '#3063e9' : '#eef0f2')};

  @media screen and (min-width: 1384px) {
    width: 1274px;
  }

  div {
    height: 42px;
  }
`;

export const ActionButton = styled.div`
  padding: 8px 24px;
  color: #3063e9;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
`;

export const Status = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: ${({ color }) => color};
  border-right: 1px solid #9da7b1;

  p {
    margin-left: 10px;
    font-size: 13px;
  }
`;

export const MachineName = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding: 8px 16px;
`;

export const SerialNumber = styled.div`
  font-size: 16px;
  padding: 8px 24px 0 0;
`;

export const PromoterName = styled.div`
  font-size: 16px;
  border-left: 1px solid #9da7b1;
  padding: 8px 16px;
  margin-left: 16px;

  overflow: hidden !important;
  text-overflow: ellipsis;
  display: block;

  b {
    font-weight: bold;
    padding: 0 4px;
  }
`;

export const InfoContainer = styled.div`
  max-width: 90%;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
`;

export const SKU = styled.div`
  padding: 8px 0;
`;

export const AllocationListContainer = styled.div<{ scrollActive?: boolean }>`
  margin: 20px 0 150px 0;
  width: 100%;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
`;

export const ModalHeaders = styled.div<{ bold?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  height: 64px;
  align-items: center;
  font-size: 13px;
  padding: 0 12px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  background-color: ${({ bold }) => (bold ? '#f9f9f9' : 'white')};
`;

export const StatusModal = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: ${({ color }) => color};
  p {
    margin-left: 10px;
  }
`;

export const ModalMain = styled.div`
  padding: 46px 26px 12px 26px;
  display: grid;
  border-top: 1px solid #ededed;
  grid-template-columns: 1fr 0.3fr 1fr;
`;

export const PermissionOptions = styled.span<{ selected?: boolean }>`
  color: #3063e9;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  font-size: 16px;
  text-decoration: underline;
  margin: 0 12px;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

export const PermissionContainer = styled.div`
  b {
    font-weight: bold;
  }
`;

export const ViewAllSelectionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 42px;
  justify-content: space-between;
  background-color: #eaeffd;
  border-radius: 8px;
  padding: 0 24px;
  margin-bottom: 14px;
`;

export const ViewAllSelectionsOptions = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewAllSelectionsText = styled.div`
  display: flex;
  align-items: center;
  color: #3063e9;
  font-weight: bold;
  font-size: 14px;

  svg {
    margin-right: 12px;
  }
`;

export const ViewAllSelectionsCount = styled.div`
  background-color: white;
  cursor: pointer;
  color: #3063e9;
  font-weight: bold;
  padding: 6px 16px;
  border-radius: 4px;
`;

export const DeleteAllSelections = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: #db4d4d;
  font-weight: bold;
  cursor: pointer;
`;
