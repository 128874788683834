import styled from '@emotion/styled';

export const Actions = styled.div<{ align: string }>`
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: ${({ align }) =>
    align === 'center'
      ? 'center'
      : align === 'left'
      ? 'flex-start'
      : 'flex-end'};
  align-items: center;
`;

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${(e) => e.theme.colors.n100};
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 0 24px;
  min-height: 96px;
  height: auto;
  box-shadow: 0px -2px 8px rgba(12, 15, 20, 0.1);
  z-index: 999999;
`;
