import styled from '@emotion/styled';
import { MultiSelect } from '../../_Atoms';

export const MultiSelectStyle = styled(MultiSelect)`
  .p-multiselect {
    min-width: 264px !important;
    margin-right: 20px;
  }
  margin-top: 25px;
`;

export const Container = styled.div`
  .p-multiselect-close p-link {
    display: none;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
