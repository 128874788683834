import styled from '@emotion/styled';
import { Accordion } from '@material-ui/core';
import { InputField } from '../../components/_Atoms';

export const CustomAccordion = styled(Accordion)`
  border: 1px solid #d8dde1;
  border-bottom: none;
  border-radius: 4px !important;
  padding: 0px !important;

  .MuiAccordionSummary-root {
    padding: 0px;
  }

  .MuiAccordionSummary-content {
    height: 72px;
    display: flex;
    border-bottom: 1px solid #d8dde1;
    padding: 25px 22px;
    margin: 0px !important;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: bold;
      font-size: 19px;
    }
  }
`;

export const ContentAccordion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  font-size: 13px;
  height: 56px;
  width: 100%;
  border-bottom: 1px solid #d8dde1;
  background-color: #fafbfc;
`;

export const ContentCellProductName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 350px;

  input {
    width: 100%;
    font-size: 13px;
    margin: 12px auto auto auto;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    box-shadow: none;
    text-align: center;

    label > div > span {
      color: blue;
    }

    &:hover,
    &:focus {
      box-shadow: none;
      border: none;
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;

export const ContentCellCurrency = styled(ContentCellProductName)<{
  error?: boolean;
}>`
  input {
    text-align: start;
    max-width: 120px;
    margin: auto;
    padding: 12px 12px 12px 36px;

    ${({ error }) => (error ? '::placeholder {color: #d84d4d;}' : '')}
  }

  span {
    font-size: 13px;
    color: ${({ error }) => (error ? 'red' : 'blue')};
  }
`;

export const MachineNameStyle = styled(InputField)<{ error?: boolean }>`
  ${({ error }) =>
    error ? 'color: #d84d4d; ::placeholder { color: #d84d4d !important; }' : ''}
`;
