import React from 'react';
import { Breadcrumb, MenuCardList } from '../../components/_Molecules';
import { IPreviousRoute } from '../../components/_Molecules/Breadcrumb';
import { MCCS_GROUPS_MENU_ITEMS } from '../../constants/menu.constants';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';

const MccsGroupsPage: React.FC = () => {
  const previousRoutesList: IPreviousRoute[] = [
    {
      routeName: 'Menu principal',
      routePath: ROUTES.MAIN_MENU
    },
    {
      routeName: 'Gestão de campanhas',
      routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
    }
  ];
  return (
    <>
      <Breadcrumb
        previousRoutes={previousRoutesList}
        currentRouteName="Grupos de MCCs"
      />
      <MenuCardList columns="TWO" menuCardList={MCCS_GROUPS_MENU_ITEMS} />
    </>
  );
};

export default MccsGroupsPage;
