import React from 'react';
import { useHistory } from 'react-router-dom';

import { Body, Card } from '@sumup/circuit-ui';
import { ReactComponent as LinkOut } from '../../../assets/icons/link.svg';

import { getAssetsPropostaByEnum } from '../../../constants/alcada.constants';
import { MENU_ROUTES } from '../../../constants/routes.constants';

import { formatCreationDate } from '../../../utils/format.utils';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Done } from '../../../assets/icons/done.svg';
import { STORAGE } from '../../../constants/storage.constants';
import { TipoAquisicao } from '../../../enums/alcada.enums';
import { IResponseGetPropostas } from '../../../interfaces/requests.interfaces';
import { CustomStatus, CustomTooltip } from '../../_Atoms';
import { NotFoundItem } from '../../_Molecules';
import { IFilterAlcada } from '../FilterAlcada';
import {
  StatusNotaFiscal,
  StyledBoxTable,
  StyledHeaderCell,
  StyledHeaderRow,
  StyledIconBox,
  StyledRow,
  StyledRowItem
} from './styles';

interface IAlcadaTable {
  proposals: IResponseGetPropostas[];
  filters: IFilterAlcada;
  page: number;
}
const AlcadaTable: React.FC<IAlcadaTable> = ({ proposals, filters, page }) => {
  const { push } = useHistory();

  return (
    <StyledBoxTable>
      <StyledHeaderRow>
        <StyledHeaderCell>
          Status da <br /> proposta
        </StyledHeaderCell>
        <StyledHeaderCell>
          Status da <br /> Nota Fiscal
        </StyledHeaderCell>
        <StyledHeaderCell>Nome do promotor</StyledHeaderCell>
        <StyledHeaderCell>
          Subgrupo <br />
          do promotor
        </StyledHeaderCell>
        <StyledHeaderCell>
          Razão social ou <br />
          nome completo
        </StyledHeaderCell>
        <StyledHeaderCell>
          Tipo de <br /> oferta
        </StyledHeaderCell>
        <StyledHeaderCell>
          Merchant <br /> code
        </StyledHeaderCell>
        <StyledHeaderCell>
          Tipo de <br /> Aquisição
        </StyledHeaderCell>
        <StyledHeaderCell>
          Data da <br /> proposta
        </StyledHeaderCell>
        <StyledHeaderCell>Detalhes</StyledHeaderCell>
      </StyledHeaderRow>

      {proposals.length === 0 && (
        <NotFoundItem message="Nenhum item foi encontrado" />
      )}
      {!!proposals.length &&
        proposals.map((proposta) => (
          <StyledRow as={Card} key={proposta.id}>
            <StyledRowItem>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <CustomStatus status={proposta.statusProposta} />
                <Body
                  noMargin
                  as="span"
                  style={{
                    color:
                      getAssetsPropostaByEnum[proposta.statusProposta].color,
                    marginLeft: '16px',
                    whiteSpace: 'break-spaces',
                    textAlign: 'start'
                  }}
                >
                  {getAssetsPropostaByEnum[proposta.statusProposta].name}
                </Body>
              </div>
            </StyledRowItem>

            <StyledRowItem>
              {proposta.statusNotaFiscal === 0 && (
                <StatusNotaFiscal color="#9da7b1">
                  Não se aplica
                </StatusNotaFiscal>
              )}
              {proposta.statusNotaFiscal === 1 && (
                <StatusNotaFiscal color="#47995a">
                  <Done /> Emitida{' '}
                </StatusNotaFiscal>
              )}
              {proposta.statusNotaFiscal === 2 && (
                <StatusNotaFiscal color="#DB4D4D">
                  <Close /> Falha
                </StatusNotaFiscal>
              )}
            </StyledRowItem>

            <CustomTooltip title={proposta.promotor}>
              <StyledRowItem>{proposta.promotor}</StyledRowItem>
            </CustomTooltip>
            <CustomTooltip title={proposta.subGrupo}>
              <StyledRowItem>{proposta.subGrupo}</StyledRowItem>
            </CustomTooltip>
            <CustomTooltip title={proposta.cliente}>
              <StyledRowItem>{proposta.cliente}</StyledRowItem>
            </CustomTooltip>
            <CustomTooltip title={proposta.oferta}>
              <StyledRowItem>{proposta.oferta}</StyledRowItem>
            </CustomTooltip>
            <CustomTooltip title={proposta.merchantCode}>
              <StyledRowItem>
                {proposta.merchantCode ? (
                  proposta.merchantCode
                ) : (
                  <p style={{ color: '#9DA7B1' }}>(Não encontrado)</p>
                )}
              </StyledRowItem>
            </CustomTooltip>
            <StyledRowItem>
              {proposta.tipoAquisicao === 0 ? (
                <p style={{ color: '#9DA7B1' }}>Não se aplica</p>
              ) : (
                <>{TipoAquisicao[proposta.tipoAquisicao]}</>
              )}
            </StyledRowItem>
            <StyledRowItem style={{ whiteSpace: 'normal' }}>
              {formatCreationDate(proposta.dataCriacao)}
            </StyledRowItem>
            <StyledRowItem>
              <StyledIconBox
                style={{ textAlign: 'center' }}
                onClick={() => {
                  push(
                    `${MENU_ROUTES.PROPOSALS_MANAGEMENT}/${proposta.id}/${proposta.tipoOferta}`
                  );
                  localStorage.setItem(
                    STORAGE.HISTORY_PAGINATION_ALCADA,
                    JSON.stringify({ ...filters, page })
                  );
                }}
              >
                <CustomTooltip title="Ver detalhes">
                  <LinkOut />
                </CustomTooltip>
              </StyledIconBox>
            </StyledRowItem>
          </StyledRow>
        ))}
    </StyledBoxTable>
  );
};

export default AlcadaTable;
