import * as Yup from 'yup';
import { ENUM_TROCA_DEVOLUCAO } from '../constants/machineAllocate.constants';
import { ENUM_TIPO_TAXA } from '../enums/rate-plans.enum';
import { IMaquinas } from '../interfaces/addMachines.interfaces';

const COMMON_MESSAGES = {
  GENERIC_REQUIRED: 'Campo obrigatório.',
  GENERIC_MAX_LENGTH: 'Número máximo de caracteres ultrapassado.',
  EMAIL: {
    REQUIRED: 'É necessário um endereço de e-mail.',
    INVALID:
      'E-mail inválido. Por favor use o seguinte formato: exemplo@provedor.com.'
  },
  PASSWORD: {
    REQUIRED: 'É necessário inserir uma senha.',
    MIN: 'Sua senha deve ter pelo menos 8 caracteres.'
  },
  RATE_PLANS: {
    TAXAS: {
      REGEX_VALIDATION: 'Deve seguir o padrão de cadastro.'
    }
  }
};

const regexValorBaseComAdicionalPorParcela =
  /(?:[0-9]{1,3}[,.]{0,1}[0-9]{0,2}%\s\+\s[0-9]{1,3}[,.]{0,1}[0-9]{0,2}%)/;
const regexValorBase = /|^(?:[0-9]{1,3}[,.]{0,1}[0-9]{0,2}%)$/;

const regexParaValidacaoDeTaxas = new RegExp(
  regexValorBaseComAdicionalPorParcela.source + regexValorBase.source
);

export const loginValidation = Yup.object().shape({
  email: Yup.string()
    .email(COMMON_MESSAGES.EMAIL.INVALID)
    .required(COMMON_MESSAGES.EMAIL.REQUIRED),
  password: Yup.string()
    .required(COMMON_MESSAGES.PASSWORD.REQUIRED)
    .min(8, COMMON_MESSAGES.PASSWORD.MIN)
});

export const addUserValidation = Yup.object().shape({
  name: Yup.string()
    .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
  email: Yup.string()
    .email(COMMON_MESSAGES.EMAIL.INVALID)
    .required(COMMON_MESSAGES.EMAIL.REQUIRED)
    .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
  company: Yup.string()
    .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
  user_type: Yup.number().required(COMMON_MESSAGES.GENERIC_REQUIRED),
  partner_code: Yup.string()
    .matches(/^[a-z0-9]+$/i, 'Este Id deve ser alfanumérico')
    .when('user_type', {
      is: 3,
      then: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
    })
    .when('user_type', {
      is: 2,
      then: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
    }),
  subgroup: Yup.string()
    .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH)
    .when('user_type', {
      is: 3,
      then: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
    })
    .when('user_type', {
      is: 2,
      then: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
    }),
  liderId: Yup.number()
    .when('user_type', {
      is: 2,
      then: Yup.number()
        .not([-2], 'Selecione uma liderança')
        .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    })
    .when('user_type', {
      is: 3,
      then: Yup.number()
        .not([-2], 'Selecione uma liderança')
        .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    })
});

export const editCampaignsValidation = Yup.object({
  campanhas: Yup.array().of(
    Yup.object().shape({
      subGrupo: Yup.string(),
      ofertaId: Yup.string()
    })
  )
});

export const addOfferValidation = Yup.object({
  name: Yup.string()
    .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
  ofertas: Yup.array()
    .of(
      Yup.object().shape({
        faturamentoInicial: Yup.string()
          .required(COMMON_MESSAGES.GENERIC_REQUIRED)
          .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
        faturamentoFinal: Yup.string()
          .required(COMMON_MESSAGES.GENERIC_REQUIRED)
          .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
        grupoMccs: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED),
        tipoTransacao: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED),
        nivel: Yup.number().required(COMMON_MESSAGES.GENERIC_REQUIRED),
        alcada: Yup.boolean(),
        planoTaxas: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED),
        valorMaquina: Yup.string()
          .required(COMMON_MESSAGES.GENERIC_REQUIRED)
          .max(255, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
        remuneracao: Yup.string()
          .required(COMMON_MESSAGES.GENERIC_REQUIRED)
          .max(255, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
        condicoes: Yup.string()
          .required(COMMON_MESSAGES.GENERIC_REQUIRED)
          .max(255, COMMON_MESSAGES.GENERIC_MAX_LENGTH)
      })
    )
    .min(1, 'Preencha pelo menos uma oferta.')
});

export const addMachinePricesValidation = Yup.object().shape({
  nomeGrupoMaquina: Yup.string()
    .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    .test(
      'Verifica se é apenas espaços em branco',
      COMMON_MESSAGES.GENERIC_REQUIRED,
      (value) => {
        if (!value) return false;
        return value.trim() !== '';
      }
    ),
  maquinas: Yup.array().of(
    Yup.object().shape({
      nomeMaquina: Yup.string()
        .required(COMMON_MESSAGES.GENERIC_REQUIRED)
        .test(
          'Verifica se é apenas espaços em branco',
          COMMON_MESSAGES.GENERIC_REQUIRED,
          (value) => {
            if (!value) return false;
            return value.trim() !== '';
          }
        )
        .test(
          'único nome de máquina',
          'Produto já inserido. Insira outro produto.',
          (value, context: any) => {
            const nomesMaquinas = context.from[1].value.maquinas.map(
              (item: IMaquinas) => item.nomeMaquina
            );

            const nomeDuplicado =
              nomesMaquinas.filter((nome: string) => nome === value).length > 1;

            return !nomeDuplicado;
          }
        ),

      valorOriginal: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED),
      valorVenda: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
    })
  )
});

export const doubleOfferValidation = Yup.object({
  name: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
});

export const doubleMachineGroupValidation = Yup.object({
  name: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
});

export const ReturnMachineForm = Yup.object().shape({
  tipoSolicitacao: Yup.number().required(COMMON_MESSAGES.GENERIC_REQUIRED),
  merchantCode: Yup.string()
    .test('merchantCode', 'Deve iniciar com "M".', (value: any) => {
      return /^[Mm]/.test(value);
    })
    .min(6, 'Deve conter no mínimo 6 caracteres.')
    .required(COMMON_MESSAGES.GENERIC_REQUIRED),
  motivoId: Yup.number().moreThan(0).required(COMMON_MESSAGES.GENERIC_REQUIRED),
  retiradaTipoMaquina: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED),
  retiradaSerialNumber: Yup.string()
    .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    .when('retiradaTipoMaquina', {
      is: '1',
      then: Yup.string()
        .test(
          'retiradaSerialNumber',
          'Deve iniciar com 51, 5C ou 5F',
          (value: any) => {
            return (
              value?.toUpperCase().trim().startsWith('51') ||
              value?.toUpperCase().trim().startsWith('5C') ||
              value?.toUpperCase().trim().startsWith('5F')
            );
          }
        )
        .length(8, 'Deve conter 8 caracteres')
    })
    .when('retiradaTipoMaquina', {
      is: '2' || '3',
      then: Yup.string()
        .test(
          'retiradaSerialNumber',
          'Deve conter apenas números',
          (value: any) => /^[0-9]+$/.test(value)
        )
        .length(17, 'Deve conter 17 caracteres')
    })
    .notOneOf(
      [Yup.ref('entregaSerialNumber'), null],
      'Seriais não podem ser os mesmos'
    )
    .notOneOf(
      [Yup.ref('entregaSerialNumberPrinter'), null],
      'Seriais não podem ser os mesmos'
    )
    .notOneOf(
      [Yup.ref('retiradaSerialNumberPrinter'), null],
      'Seriais não podem ser os mesmos'
    ),
  retiradaTipoMaquinaPrinter: Yup.string().when('retiradaTipoMaquina', {
    is: '2',
    then: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
  }),
  retiradaSerialNumberPrinter: Yup.string().when('retiradaTipoMaquina', {
    is: '2',
    then: Yup.string()
      .required(COMMON_MESSAGES.GENERIC_REQUIRED)
      .test(
        'retiradaSerialNumberPrinter',
        'Deve conter apenas números',
        (value: any) => /^[0-9]+$/.test(value)
      )
      .length(17, 'Deve conter 17 caracteres')
      .notOneOf(
        [Yup.ref('retiradaSerialNumber'), null],
        'Seriais não podem ser os mesmos'
      )
      .notOneOf(
        [Yup.ref('entregaSerialNumber'), null],
        'Seriais não podem ser os mesmos'
      )
      .notOneOf(
        [Yup.ref('entregaSerialNumberPrinter'), null],
        'Seriais não podem ser os mesmos'
      )
  }),
  entregaTipoMaquina: Yup.string().when('tipoSolicitacao', {
    is: ENUM_TROCA_DEVOLUCAO.Troca,
    then: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
  }),
  entregaSerialNumber: Yup.string().when('tipoSolicitacao', {
    is: ENUM_TROCA_DEVOLUCAO.Troca,
    then: Yup.string()
      .required(COMMON_MESSAGES.GENERIC_REQUIRED)
      .when('entregaTipoMaquina', {
        is: '1',
        then: Yup.string()
          .test(
            'retiradaSerialNumber',
            'Deve iniciar com 51, 5C ou 5F',
            (value: any) => {
              return (
                value?.toUpperCase().trim().startsWith('51') ||
                value?.toUpperCase().trim().startsWith('5C') ||
                value?.toUpperCase().trim().startsWith('5F')
              );
            }
          )
          .length(8, 'Deve conter 8 caracteres')
      })
      .when('entregaTipoMaquina', {
        is: '2' || '3',
        then: Yup.string()
          .test(
            'entregaSerialNumber',
            'Deve conter apenas números',
            (value: any) => /^[0-9]+$/.test(value)
          )
          .length(17, 'Deve conter 17 caracteres')
      })
      .notOneOf(
        [Yup.ref('retiradaSerialNumber'), null],
        'Seriais não podem ser os mesmos'
      )
      .notOneOf(
        [Yup.ref('retiradaSerialNumberPrinter'), null],
        'Seriais não podem ser os mesmos'
      )
      .notOneOf(
        [Yup.ref('entregaSerialNumberPrinter'), null],
        'Seriais não podem ser os mesmos'
      )
  }),
  entregaTipoMaquinaPrinter: Yup.string().when('tipoSolicitacao', {
    is: ENUM_TROCA_DEVOLUCAO.Troca,
    then: Yup.string().when('entregaTipoMaquina', {
      is: '2',
      then: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED)
    })
  }),
  entregaSerialNumberPrinter: Yup.string().when('tipoSolicitacao', {
    is: ENUM_TROCA_DEVOLUCAO.Troca,
    then: Yup.string().when('entregaTipoMaquina', {
      is: '2',
      then: Yup.string()
        .required(COMMON_MESSAGES.GENERIC_REQUIRED)
        .test(
          'entregaSerialNumberPrinter',
          'Deve conter apenas números',
          (value: any) => /^[0-9]+$/.test(value)
        )
        .notOneOf(
          [Yup.ref('retiradaSerialNumber'), null],
          'Seriais não podem ser os mesmos'
        )
        .notOneOf(
          [Yup.ref('retiradaSerialNumberPrinter'), null],
          'Seriais não podem ser os mesmos'
        )
        .notOneOf(
          [Yup.ref('entregaSerialNumber'), null],
          'Seriais não podem ser os mesmos'
        )
        .length(17, 'Deve conter 17 caracteres')
    })
  })
});

export const addRatePlansValidation = Yup.object({
  nome: Yup.string()
    .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
  url: Yup.string()
    .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    .url('Insira uma URL válida.')
    .max(500, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
  taxaAntecipada: Yup.array()
    .of(
      Yup.object().shape({
        tipoTaxa: Yup.mixed().oneOf(Object.values(ENUM_TIPO_TAXA)),
        tipoTransacao: Yup.number(),
        parceladoFlexivel: Yup.boolean(),
        taxaVisaMaster: Yup.string().when('parceladoFlexivel', {
          is: undefined || false,
          then: Yup.string()
            .required(COMMON_MESSAGES.GENERIC_REQUIRED)
            .matches(
              regexParaValidacaoDeTaxas,
              COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
            )
        }),
        taxaOutrasBandeiras: Yup.string().when('parceladoFlexivel', {
          is: undefined || false,
          then: Yup.string()
            .required(COMMON_MESSAGES.GENERIC_REQUIRED)
            .matches(
              regexParaValidacaoDeTaxas,
              COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
            )
        }),
        detalhamentoParcelasVisaMaster: Yup.object().when('parceladoFlexivel', {
          is: true,
          then: Yup.object().shape({
            taxa2x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa3x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa4x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa5x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa6x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa7x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa8x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa9x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa10x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa11x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              ),
            taxa12x: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              )
          })
        }),
        detalhamentoParcelasOutrasBandeiras: Yup.object().when(
          'parceladoFlexivel',
          {
            is: true,
            then: Yup.object({
              taxa2x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa3x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa4x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa5x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa6x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa7x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa8x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa9x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa10x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa11x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                ),
              taxa12x: Yup.string()
                .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                .matches(
                  regexParaValidacaoDeTaxas,
                  COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                )
            })
          }
        )
      })
    )
    .min(1, 'É necessário selecionar ao menos uma bandeira'),
  taxaEconomica: Yup.array().when('closeAccordion[1]', {
    is: false,
    then: Yup.array()
      .of(
        Yup.object().shape({
          tipoTaxa: Yup.mixed().oneOf(Object.values(ENUM_TIPO_TAXA)),
          tipoTransacao: Yup.number(),
          parceladoFlexivel: Yup.boolean(),
          taxaVisaMaster: Yup.string().when('parceladoFlexivel', {
            is: undefined || false,
            then: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              )
          }),
          taxaOutrasBandeiras: Yup.string().when('parceladoFlexivel', {
            is: undefined || false,
            then: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              )
          }),
          detalhamentoParcelasVisaMaster: Yup.object().when(
            'parceladoFlexivel',
            {
              is: true,
              then: Yup.object().shape({
                taxa2x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa3x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa4x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa5x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa6x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa7x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa8x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa9x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa10x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa11x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa12x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  )
              })
            }
          ),
          detalhamentoParcelasOutrasBandeiras: Yup.object().when(
            'parceladoFlexivel',
            {
              is: true,
              then: Yup.object({
                taxa2x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa3x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa4x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa5x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa6x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa7x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa8x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa9x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa10x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa11x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa12x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  )
              })
            }
          )
        })
      )
      .min(1, 'É necessário selecionar ao menos uma bandeira')
  }),
  taxaInstantanea: Yup.array().when('closeAccordion[2]', {
    is: false,
    then: Yup.array()
      .of(
        Yup.object().shape({
          tipoTaxa: Yup.mixed().oneOf(Object.values(ENUM_TIPO_TAXA)),
          tipoTransacao: Yup.number(),
          parceladoFlexivel: Yup.boolean(),
          taxaVisaMaster: Yup.string().when('parceladoFlexivel', {
            is: undefined || false,
            then: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              )
          }),
          taxaOutrasBandeiras: Yup.string().when('parceladoFlexivel', {
            is: undefined || false,
            then: Yup.string()
              .required(COMMON_MESSAGES.GENERIC_REQUIRED)
              .matches(
                regexParaValidacaoDeTaxas,
                COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
              )
          }),
          detalhamentoParcelasVisaMaster: Yup.object().when(
            'parceladoFlexivel',
            {
              is: true,
              then: Yup.object().shape({
                taxa2x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa3x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa4x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa5x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa6x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa7x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa8x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa9x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa10x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa11x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa12x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  )
              })
            }
          ),
          detalhamentoParcelasOutrasBandeiras: Yup.object().when(
            'parceladoFlexivel',
            {
              is: true,
              then: Yup.object({
                taxa2x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa3x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa4x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa5x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa6x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa7x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa8x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa9x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa10x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa11x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  ),
                taxa12x: Yup.string()
                  .required(COMMON_MESSAGES.GENERIC_REQUIRED)
                  .matches(
                    regexParaValidacaoDeTaxas,
                    COMMON_MESSAGES.RATE_PLANS.TAXAS.REGEX_VALIDATION
                  )
              })
            }
          )
        })
      )
      .min(1, 'É necessário selecionar ao menos uma bandeira')
  })
});

export const addMccGroupValidation = Yup.object({
  nome: Yup.string()
    .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    .min(3, 'Mínimo de 3 caracteres no campo.')
    .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH),
  mccIds: Yup.array().min(1, 'Você deve selecionar uma opção')
});

export const changePasswordValidation = Yup.object({
  password: Yup.string()
    .required(COMMON_MESSAGES.PASSWORD.REQUIRED)
    .min(8, COMMON_MESSAGES.PASSWORD.MIN),
  passwordConfirmation: Yup.string()
    .required(COMMON_MESSAGES.PASSWORD.REQUIRED)
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais.')
});

export const forgotPasswordValidation = Yup.object({
  email: Yup.string()
    .email(COMMON_MESSAGES.EMAIL.INVALID)
    .required(COMMON_MESSAGES.EMAIL.REQUIRED)
    .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH)
});

export const filterUsersValidation = Yup.object({
  status: Yup.string().required(COMMON_MESSAGES.GENERIC_REQUIRED),
  tipoDeUsuario: Yup.string(),
  subgrupo: Yup.string(),
  empresa: Yup.string()
});

export const doubleRatePlan = Yup.object({
  name: Yup.string()
    .required(COMMON_MESSAGES.GENERIC_REQUIRED)
    .max(100, COMMON_MESSAGES.GENERIC_MAX_LENGTH)
});
