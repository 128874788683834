import { FC } from 'react';

const SVGOutraClassificacao: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.85714 11.7143C7.147 11.7143 6.57143 11.1387 6.57143 10.4286V8.71429C6.57143 8.00414 7.147 7.42857 7.85714 7.42857C8.11557 7.426 10.4286 7 10.4286 5.28571C10.4286 4.42857 9.57143 3.57143 8.28571 3.57143C7.23957 3.57143 6.57143 4.42857 6.57143 4.85714C6.57143 5.56729 5.99586 6.14286 5.28571 6.14286C4.57557 6.14286 4 5.56729 4 4.85714C4 2.76657 5.96286 1 8.28571 1C11.7143 1 13 3.56371 13 5.71429C13 7.49072 12.0241 8.84843 10.2516 9.53714C9.86586 9.68757 9.48443 9.78872 9.14286 9.85729V10.4286C9.14286 11.1387 8.56729 11.7143 7.85714 11.7143Z"
        fill="#9DA7B1"
      />
      <path
        d="M7.857 15.143C8.56708 15.143 9.14272 14.5673 9.14272 13.8572C9.14272 13.1472 8.56708 12.5715 7.857 12.5715C7.14692 12.5715 6.57129 13.1472 6.57129 13.8572C6.57129 14.5673 7.14692 15.143 7.857 15.143Z"
        fill="#9DA7B1"
      />
    </svg>
  );
};

export default SVGOutraClassificacao;
