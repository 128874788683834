/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Container } from '@material-ui/core';
import { SearchInput } from '@sumup/circuit-ui';
import { Search } from '@sumup/icons';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  InputChip,
  Spinner,
  TextIconPlus
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Modal,
  NoResultsState
} from '../../components/_Molecules';
import {
  AssetManagementHeader,
  NetworkError,
  TableAssetManagement
} from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import { MENU_ROUTES } from '../../constants/routes.constants';
import { get } from '../../services/axios.service';
import {
  ModalContent,
  ModalLoading,
  ModalSearchBar,
  SearchIcon,
  SerialResultsContainer
} from './styles';

interface IMaquina {
  numeroSerie: string;
  produto: string;
  utilizado: boolean;
}

const AssetControl: FC = () => {
  const [userSelected, setUserSelected] = useState<{
    name: string;
    partnerCode: string;
  }>({ name: '', partnerCode: '' });
  const [search, setSearch] = useState('');
  const router = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [numeroMaquinas, setNumeroMaquinas] = useState<IMaquina[]>([]);
  const [numeroMaquinasFilter, setNumeroMaquinasFilter] = useState<IMaquina[]>(
    []
  );
  const [searchNumeros, setSearchNumeros] = useState('');
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [error, setError] = useState({ isError: false, code: '' });

  const getModalData = async () => {
    setLoadingModal(true);
    setError({ ...error, isError: false });
    setOpenModal(true);
    try {
      const data = await get<{
        data: IMaquina[];
        error: string;
      }>(API.CONTROLE_ATIVOS.BUSCAR_MAQUINAS(userSelected.partnerCode));
      setNumeroMaquinas(!data.error ? data.data : []);
      setNumeroMaquinasFilter(!data.error ? data.data : []);

      if (data.error === 'erro_conectorsumup') {
        setError({ isError: true, code: data.error });
      }
    } catch (error) {
      setNumeroMaquinas([]);
      setNumeroMaquinasFilter([]);
      setError({ isError: true, code: '' });
    }
    setLoadingModal(false);
  };

  const filter = () => {
    setNumeroMaquinasFilter(
      numeroMaquinas.filter((maquina) =>
        maquina.numeroSerie
          .toUpperCase()
          .includes(searchNumeros.toLocaleUpperCase())
      )
    );
  };

  useEffect(() => {
    setSearchNumeros('');
  }, [openModal]);

  return (
    <>
      <Container style={{ maxWidth: 1332 }}>
        <AssetManagementHeader
          submitSearch={setSearch}
          initialSearch={search}
        />
        <TableAssetManagement
          search={search}
          setSearch={setSearch}
          setPartnerCode={setUserSelected}
        />
        <BottomFixedActions>
          <Button
            style={{ margin: '0 6px' }}
            width="medium"
            value="Voltar"
            variant="secondary"
            onClick={() => router.push(MENU_ROUTES.ASSET_MANAGEMENT)}
          />
          <Button
            style={{ margin: '0 6px' }}
            width="medium"
            disabled={userSelected.partnerCode === ''}
            value="Consultar SN"
            onClick={getModalData}
          />
        </BottomFixedActions>
        <Modal title="Consulta de SM's" open={openModal} setOpen={setOpenModal}>
          <div>
            {loadingModal ? (
              <ModalLoading>
                <Spinner size="40px" width="4px" color="#3388ff" />
              </ModalLoading>
            ) : !error.isError ? (
              <>
                {!!numeroMaquinas.length && (
                  <ModalContent>
                    <p>
                      Exibindo{' '}
                      <span>
                        {numeroMaquinasFilter.length
                          .toString()
                          .padStart(2, '0')}
                      </span>{' '}
                      de{' '}
                      <span>
                        {numeroMaquinas.length.toString().padStart(2, '0')}
                      </span>{' '}
                      serial numbers associados a{' '}
                      <span>{userSelected.name}</span>
                    </p>

                    <ModalSearchBar>
                      <SearchInput
                        placeholder="Buscar serial number"
                        label=" "
                        value={searchNumeros}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' && searchNumeros !== '') {
                            filter();
                          }
                        }}
                        onChange={(e) => setSearchNumeros(e.target.value)}
                        style={{
                          margin: 0,
                          marginBottom: 0,
                          boxShadow: '0 0 0 1px #D8DDE1'
                        }}
                      />
                      <SearchIcon
                        active={searchNumeros !== ''}
                        onClick={() => {
                          if (searchNumeros !== '') filter();
                        }}
                        style={{ marginLeft: 16 }}
                      >
                        <Search size="24" />
                      </SearchIcon>
                    </ModalSearchBar>
                  </ModalContent>
                )}
                <SerialResultsContainer>
                  {numeroMaquinasFilter.map((item) => (
                    <>
                      <span>
                        MÁQUINA{' '}
                        {(numeroMaquinas.indexOf(item) + 1)
                          .toString()
                          .padStart(2, '0')}
                      </span>{' '}
                      <p>{item.numeroSerie}</p>
                      <p>
                        {item.utilizado && <InputChip>Utilizado</InputChip>}
                      </p>
                    </>
                  ))}
                  {numeroMaquinasFilter.length !== 0 &&
                    numeroMaquinas.length !== numeroMaquinasFilter.length && (
                      <div
                        onClick={() => {
                          setNumeroMaquinasFilter(numeroMaquinas);
                          setSearchNumeros('');
                        }}
                      >
                        <TextIconPlus text="Exibir todos serial numbers" />
                      </div>
                    )}
                </SerialResultsContainer>
                {numeroMaquinasFilter.length === 0 &&
                  numeroMaquinas.length > 0 && (
                    <div style={{ height: '424px', width: '100%' }}>
                      <NoResultsState
                        action={() => {
                          setSearchNumeros('');
                          setNumeroMaquinasFilter(numeroMaquinas);
                        }}
                      />
                    </div>
                  )}
                {numeroMaquinas.length === 0 && (
                  <div style={{ height: '424px', width: '100%' }}>
                    <NoResultsState
                      image="secondary"
                      title="Nenhum serial number atribuído"
                      subtitle="Esse usuário não possui nenhum serial number atribuído,"
                      subtitle2="e por isso, não pudemos exibir resultados."
                    />
                  </div>
                )}
              </>
            ) : (
              <NetworkError
                title="Ops... Parece que algo deu errado"
                subtitle={
                  error.code !== 'erro_conectorsumup'
                    ? 'Houve um erro no nosso sistema e não pudemos exibir as informações.'
                    : 'Houve um erro ao tentar obter os dados da Sumup.'
                }
                secondSubtitle="Tente novamente mais tarde."
              />
            )}
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default AssetControl;
