/* eslint-disable react/no-unknown-property */
import { FC, useEffect, useState, HTMLAttributes } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import { addLocale } from 'primereact/api';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow_right.svg';

import {
  ContainerOptions,
  DateRangeSelect,
  Option,
  Input,
  CalendarStyle,
  CalendarField,
  CalendarContainerStyle
} from './style';
import { rangeDateOptions } from '../../../utils/kanban';
import {
  DATE_OPTIONS,
  ENUM_DATE_OPTIONS
} from '../../../constants/kanban.constants';

export interface IDateRange {
  start: string | null;
  end: string | null;
}

interface IProps extends HTMLAttributes<HTMLDivElement> {
  date: IDateRange;
  setDate: (p: IDateRange) => void;
  value: number;
  setValue: (p: number) => void;
  label: string;
  options: ENUM_DATE_OPTIONS[];
}

const SelectDateRangeUniqueField: FC<IProps> = ({
  date,
  setDate,
  setValue,
  value,
  label,
  options,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [customLabel, setCustomLabel] = useState({
    label: DATE_OPTIONS.filter((item) => value === Number(item.value))[0].label,
    active: value === -1
  });

  addLocale('pt-br', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'segunda',
      'terça',
      'quarta',
      'quinta',
      'sexta',
      'sábado'
    ],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro'
    ],
    monthNamesShort: [
      'jan',
      'fev',
      'mar',
      'abr',
      'mai',
      'jun',
      'jul',
      'ago',
      'set',
      'out',
      'nov',
      'dez'
    ],
    today: 'Hoje',
    clear: 'Limpar'
  });
  const handleClose = () => {
    setOpen(false);
  };

  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (value !== -1) {
      let aux: IDateRange = date;
      aux = rangeDateOptions(value.toString());
      setDate(aux);
    }

    setCustomLabel({
      label: DATE_OPTIONS.filter((item) => value === Number(item.value))[0]
        .label,
      active: value === -1
    });
    handleClose();
  }, [value]);

  const formatDateDDMMAAA = (date: Date) => {
    if (!date) return '';
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  return (
    <>
      <div style={{ ...props }}>
        <div style={{ marginRight: 10, width: '100%' }}>
          <p>{label}</p>
          <DateRangeSelect>
            <Input active={open} onClick={toggle}>
              <p style={{ whiteSpace: 'nowrap' }}>
                {!date.end && !date.start
                  ? 'Selecione uma opção'
                  : value !== -1 && !customLabel.active
                  ? DATE_OPTIONS.filter(
                      (item) => item.value === value.toString()
                    )[0].label
                  : customLabel.label}
              </p>
              <ExpandMoreIcon />
            </Input>
            {open && (
              <ContainerOptions>
                <CalendarContainerStyle>
                  <CalendarStyle
                    style={{ width: '100%' }}
                    dateFormat="dd/mm/yy"
                    locale="pt-br"
                    value={
                      date.start && date.end
                        ? [new Date(date.start), new Date(date.end)]
                        : date.start
                        ? [new Date(date.start)]
                        : date.end
                        ? [new Date(date.end)]
                        : null
                    }
                    onClearButtonClick={() => {
                      setDate({ start: null, end: null });
                    }}
                    onChange={(e: any) => {
                      if (!e.value) return;

                      setCustomLabel({
                        label: 'Personalizado',
                        active: true
                      });

                      if (e.value[0] && e.value[1]) {
                        setDate({ start: e.value[0], end: e.value[1] });
                        return;
                      }

                      if (e.value[0] && !e.value[1]) {
                        setDate({ start: e.value[0], end: '' });
                      }
                    }}
                    selectionMode="range"
                    showButtonBar
                  />
                  <CalendarField>
                    <CalendarIcon style={{ marginTop: 4 }} />

                    <p>
                      {date.start
                        ? formatDateDDMMAAA(new Date(date.start))
                        : 'Data de início'}
                    </p>

                    <ArrowRight style={{ marginTop: 5 }} />

                    <p>
                      {date.end
                        ? formatDateDDMMAAA(new Date(date.end))
                        : 'Data de término'}
                    </p>
                  </CalendarField>
                </CalendarContainerStyle>

                {options.map((item) => (
                  <Option
                    active={value === item && !customLabel.active}
                    onClick={() => {
                      setCustomLabel({
                        ...customLabel,
                        active: false
                      });
                      setValue(item);
                    }}
                  >
                    <p>
                      {
                        DATE_OPTIONS.filter((i) => Number(i.value) === item)[0]
                          .label
                      }
                    </p>
                    {value === item && !customLabel.active && (
                      <DoneRoundedIcon />
                    )}
                  </Option>
                ))}
              </ContainerOptions>
            )}
          </DateRangeSelect>
        </div>
      </div>
    </>
  );
};

export default SelectDateRangeUniqueField;
