import { FC, useEffect, useState } from 'react';
import { API } from '../../../constants/api.constants';
import { FiltersEditUser } from '../../../interfaces/addUser.interface';
import { IDataResponseModel } from '../../../interfaces/requests.interfaces';
import { get } from '../../../services/axios.service';
import CompanyFilter from './CompanyFilter';
import SearchBarUserEdit from './SearchBar';
import StatusFilter from './StatusFilter';
import SubGroupFilter from './SubGroupFilter';
import UserTypeFilter from './UserTypeFilter';
import { ClearFilters, Filters } from './styles';

interface IProps {
  setFilter: (item: FiltersEditUser) => void;
  filters: FiltersEditUser;
}

interface ISelectOption {
  options: Array<{ label: string; value: string }>;
}

const FilterUserEdit: FC<IProps> = ({ setFilter, filters }) => {
  const [groups, setGroup] = useState<ISelectOption>({
    options: [{ label: '', value: '' }]
  });
  const [companies, setCompanies] = useState<ISelectOption>({
    options: [{ label: '', value: '' }]
  });

  const getCompanies = async () => {
    const { data } = await get<IDataResponseModel<string[]>>(
      API.USUARIOS.EMPRESAS
    );

    const list: Array<{ label: string; value: string }> = data.map(
      (item: string): { label: string; value: string } => ({
        label: item,
        value: item
      })
    );

    setCompanies({
      options: [
        { label: 'Todas as empresas', value: 'todos' },
        ...list.filter((item) => item.label !== '')
      ]
    });
  };

  const getSubGroup = async () => {
    const { data } = await get<IDataResponseModel<string[]>>(
      API.USUARIOS.TODOS_SUBGRUPOS
    );

    const list: Array<{ label: string; value: string }> = data.map(
      (item: string): { label: string; value: string } => ({
        label: item,
        value: item
      })
    );

    setGroup({
      options: [
        { label: 'Todos os subgrupos', value: 'todos' },
        ...list.filter((item) => item.label !== '')
      ]
    });
  };

  const clearFilters = () => {
    setFilter({
      ...filters,
      company: '',
      status: '',
      subGroup: '',
      userType: ''
    });
  };

  useEffect(() => {
    getCompanies();
    getSubGroup();
  }, []);

  return (
    <>
      <Filters>
        <StatusFilter filters={filters} setFilter={setFilter} />
        <UserTypeFilter filters={filters} setFilter={setFilter} />
        <CompanyFilter
          filters={filters}
          setFilter={setFilter}
          companies={companies}
        />
        <SubGroupFilter
          filters={filters}
          setFilter={setFilter}
          groups={groups}
        />
        <ClearFilters
          onClick={clearFilters}
          active={Object.values(filters).some((v) => v !== '')}
        >
          Limpar filtros
        </ClearFilters>
      </Filters>
      <SearchBarUserEdit filters={filters} setFilter={setFilter} />
    </>
  );
};

export default FilterUserEdit;
