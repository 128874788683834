import { FC } from 'react';

interface IProps {
  value: string;
}

const EmptyValue: FC<IProps> = ({ value }) => {
  return <span style={{ color: '#9da7b1', textAlign: 'left' }}>{value}</span>;
};

export default EmptyValue;
