import styled from '@emotion/styled';

export const StyledCustomHeaderRow = styled.header`
  height: auto;
  padding: 12px 0 0 0;
  background-color: #f9f9f9;
  display: grid;
  align-items: center;
  grid-template-columns: 56px repeat(4, 126px) 82px 82px repeat(4, 126px);
  position: sticky;
  top: 0;
  z-index: 23;

  @media (max-width: 1280px) {
    grid-template-columns: 56px repeat(4, 120px) 82px 82px repeat(4, 126px);
  }
`;

export const StyledCustomTitleCell = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  &:last-of-type {
    height: 100%;
    background-color: white;
  }
`;
