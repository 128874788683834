import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, SubTitle, Title } from '../../components/_Atoms';
import { SVGTaskListError } from '../../components/_Atoms/Icon';
import {
  BottomFixedActions,
  Breadcrumb,
  Modal,
  Toast
} from '../../components/_Molecules';
import { IToast } from '../../components/_Molecules/Toast';
import {
  DragAndDropTaskList,
  FilterTaskList,
  TableTaskList
} from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';
import { usePagination } from '../../hooks/usePagination';
import { IResponsePost } from '../../interfaces/addMachines.interfaces';
import {
  IDataResponseModel,
  IPagination
} from '../../interfaces/requests.interfaces';
import {
  IFiltersTaskList,
  IRemetente,
  ITask,
  ITaskToUpload
} from '../../interfaces/taskList.interfaces';
import { get, post } from '../../services/axios.service';
import { handleDownloadDynamicType } from '../../services/download.service';
import { alphanumericWithSpacesAndHyphens } from '../../utils/format.utils';
import { rangeDateOptions } from '../../utils/kanban';
import { StyledBoxPage } from './styles';

const TaskList: FC = () => {
  const { push } = useHistory();
  const { totalItems, setTotalItems } = usePagination();
  const itemsPerPage = 6;
  const [resetPagination, setResetPagination] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [listaRemetentes, setListaRemetentes] = useState<IRemetente[]>([]);
  const [taskList, setTaskList] = useState<ITask[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState<ITaskToUpload>({
    file: [],
    nomeUpload: ''
  });
  const [toastList, setToast] = useState<IToast[]>([]);
  const [filters, setFilters] = useState<IFiltersTaskList>({
    status: '',
    uploadDate: rangeDateOptions('8'),
    search: '',
    remetenteIdSelected: []
  });
  const [errorUpload, setErrorUpload] = useState({
    error: false,
    message: '',
    fileName: '',
    url: ''
  });

  const handlePageChange = (page: number) => {
    getDataTable(page + 1);
    setCurrentPage(page + 1);
  };

  const getDataTable = async (page: number, noSearch?: boolean) => {
    setLoading(true);
    setNetworkError(false);

    try {
      const data = await post<IDataResponseModel<IPagination<ITask>>>(
        API.TAREFAS.HISTORICO_CARGA,
        {
          pag: {
            pageNumber: page,
            pageSize: itemsPerPage
          },
          dataInicial: filters.uploadDate.start.split('T')[0],
          dataFinal: filters.uploadDate.end.split('T')[0],
          ...(filters.remetenteIdSelected?.length > 0 && {
            remetenteIds: filters.remetenteIdSelected
          }),
          ...(filters.search && !noSearch && { busca: filters.search }),
          ...(filters.status && { status: filters.status })
        }
      );

      setTaskList(data.data.results);
      setTotalItems(data.data.totalItems);
    } catch (err) {
      setNetworkError(true);
    } finally {
      setLoading(false);
    }
  };

  const getRemetentes = async () => {
    try {
      const data = await get<IDataResponseModel<IRemetente[]>>(
        API.TAREFAS.REMETENTES
      );
      setListaRemetentes(data.data);

      if (data.data.length > 0) {
        setFilters({
          ...filters,
          remetenteIdSelected: data.data.map((remetente) => remetente.id)
        });
      }
    } catch (error) {
      setListaRemetentes([]);
    }
  };

  const loadData = async () => {
    setCurrentPage(1);
    setResetPagination(false);
    await getDataTable(1);
    setResetPagination(true);
  };

  const submitUpload = async () => {
    setLoadingUpload(true);
    setErrorUpload({ ...errorUpload, fileName: '', error: false });

    try {
      const formData = new FormData();

      file.file.forEach((file, _) => {
        formData.append(`Carga`, file);
      });

      formData.append('Nome', file.nomeUpload.trim());

      const res = await post<IResponsePost>(
        API.TAREFAS.REALIZAR_CARGA,
        formData
      );

      if (!res.success) {
        setErrorUpload({
          message: res?.message,
          error: true,
          fileName: file.file[0].name,
          url: res.data ? res.data : ''
        });

        setFile({
          file: [],
          nomeUpload: ''
        });
      } else {
        setOpenModal(false);
        showToast({
          id: toastList.length + 1,
          title: 'Carga enviada com sucesso!',
          description: 'Acompanhe no histórico de uploads.',
          type: 'sucess'
        });
      }

      loadData();
    } catch (error) {
      setErrorUpload({ ...errorUpload, error: true });
      showToast({
        id: toastList.length + 1,
        title: 'Algo deu errado!',
        description: 'Por favor, faça o upload novamente',
        type: 'error'
      });
    }

    setLoadingUpload(false);
  };

  const exportationFile = async (url: string, nameFile: string) => {
    axios
      .get(url, { responseType: 'blob' })
      .then(({ data }) => {
        handleDownloadDynamicType(data, `${nameFile}`, data.type);
      })
      .catch((error) => {
        if (error) console.error(error);
      })
      .finally(() => setOpenModal(false));
  };

  const showToast = (toast: IToast) => {
    setToast([...toastList, toast]);
  };

  useEffect(() => {
    loadData();
    getRemetentes();
  }, []);

  useEffect(() => {
    setFile({
      file: [],
      nomeUpload: ''
    });
    setErrorUpload({ error: false, fileName: '', message: '', url: '' });
  }, [openModal]);

  return (
    <StyledBoxPage>
      <Breadcrumb
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          },
          {
            routeName: 'Gestão de tarefas',
            routePath: MENU_ROUTES.TASK_LIST_MANAGEMENT
          }
        ]}
        currentRouteName="Lista de tarefas"
        noMargin
      />
      <Title style={{ marginTop: 32 }} fontSize="big">
        Lista de tarefas
      </Title>
      <SubTitle>Histórico de uploads de listas de tarefas.</SubTitle>

      <FilterTaskList
        filters={filters}
        setFilters={setFilters}
        getDataTable={loadData}
        listaRemetentes={listaRemetentes}
      />

      <TableTaskList
        loading={loading}
        search={filters.search}
        setSearch={(e) => setFilters({ ...filters, search: e })}
        taskList={taskList}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        getDataTable={getDataTable}
        itemsPerPage={itemsPerPage}
        networkError={networkError}
        resetPagination={resetPagination}
        setCurrentPage={setCurrentPage}
        getExportationFile={exportationFile}
      />

      <BottomFixedActions style={{ zIndex: 1 }}>
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          value="Voltar"
          variant="secondary"
          onClick={() => push(MENU_ROUTES.TASK_LIST_MANAGEMENT)}
        />
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          value="Fazer upload"
          onClick={() => setOpenModal(true)}
        />
      </BottomFixedActions>

      <Modal
        labelAction="Enviar carga"
        action={errorUpload.error && errorUpload.url ? undefined : submitUpload}
        widthButtonAction="217px"
        title={
          errorUpload.error && errorUpload.url
            ? 'Atualizar Hierarquia'
            : 'Upload de carga'
        }
        open={openModal}
        setOpen={setOpenModal}
        hideBackButton={!errorUpload.url}
        footerErrorMessage={errorUpload.message}
        disableAction={
          file.file.length === 0 ||
          file.nomeUpload === '' ||
          !alphanumericWithSpacesAndHyphens.test(file.nomeUpload)
        }
      >
        {errorUpload.error && errorUpload.url && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              color: '#212933',
              textAlign: 'center',
              padding: '30px 0 50px 0'
            }}
          >
            <SVGTaskListError />
            <p style={{ fontSize: '19px', fontWeight: 'bold' }}>
              Alguns erros foram encontrados
            </p>
            <p
              style={{
                maxWidth: 442,
                fontSize: '13px',
                margin: '16px 0 24px 0'
              }}
            >
              Algumas informações não foram processadas corretamente. Geramos um
              arquivo para sua retificação, faça o download do arquivo e faça o
              upload deste arquivo com as correções descritas no arquivo.
            </p>
            <Button
              onClick={() =>
                exportationFile(
                  errorUpload.url,
                  `${errorUpload.fileName.split('.')[0]}_retificado.${
                    errorUpload.fileName.split('.')[1]
                  }`
                )
              }
              width="auto"
              value="Download arquivo retificado"
            />
          </div>
        )}

        {!errorUpload.url && (
          <DragAndDropTaskList
            file={file}
            setFile={setFile}
            errorMessage={(e) => setErrorUpload({ ...errorUpload, message: e })}
            loading={loadingUpload}
          />
        )}
      </Modal>
      <Toast toastlist={toastList} position="top-right" setList={setToast} />
    </StyledBoxPage>
  );
};

export default TaskList;
