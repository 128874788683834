import { SearchInput } from '@sumup/circuit-ui';
import { FC } from 'react';
import { MACHINE_PRICES_FILTER_OPTIONS } from '../../../constants/machine-prices.constants';
import { StatusOptions } from '../../../services/filters.service';
import { Button, CustomDropdown } from '../../_Atoms';
import { SearchBar } from '../FilterUserEdit/SearchBar/styles';
import { MachinePricesFilterContainer } from './styles';

interface IFilters {
  status: StatusOptions;
  search: string;
}

interface IProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  search: string;
  setSearch: (search: string) => void;
  networkError: boolean;
}

const MachinePricesFilter: FC<IProps> = ({
  filters,
  setFilters,
  search,
  setSearch,
  networkError
}) => {
  return (
    <MachinePricesFilterContainer>
      <CustomDropdown
        label="Status"
        options={MACHINE_PRICES_FILTER_OPTIONS}
        value={filters.status}
        disabled={networkError}
        onChangeValue={(e) =>
          setFilters({
            ...filters,
            status: e as StatusOptions
          })
        }
        style={{ marginTop: 25 }}
      />
      <SearchBar style={{ margin: '25px', maxWidth: 520 }}>
        <SearchInput
          label="Buscar:"
          onClear={() => {
            setFilters({ ...filters, search: '' });
            setSearch('');
          }}
          clearLabel="clearable"
          value={search}
          name="search"
          placeholder="Digite uma pesquisa"
          onChange={(e) => {
            setSearch(e.target.value);
            if (e.target.value === '') {
              setFilters({ ...filters, search: '' });
            }
          }}
          style={{ boxShadow: '0 0 0 1px #D8DDE1' }}
          disabled={networkError}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && search !== '') {
              e.preventDefault();
              setFilters({ ...filters, search });
            }
          }}
        />

        <Button
          style={{ marginTop: 5 }}
          value="Buscar"
          onClick={() => {
            if (search !== '') {
              setFilters({ ...filters, search });
            }
          }}
        />
      </SearchBar>
    </MachinePricesFilterContainer>
  );
};

export default MachinePricesFilter;
