import { FC } from 'react';
import { ReactComponent as UserNotFound } from '../../../assets/user_not_found.svg';
import { Message, NotFound } from './styles';

interface IProps {
  message: string;
}

const NotFoundItem: FC<IProps> = ({ message }) => {
  return (
    <NotFound>
      <UserNotFound />
      <Message>{message}</Message>
    </NotFound>
  );
};

export default NotFoundItem;
