import { TreeSelect as TreeSelectComponent } from 'primereact/treeselect';
import { FC } from 'react';
import './styles.css';

interface IProps {
  options: any[];
  value: ITreeSelectValue;
  onChange: (e: any) => void;
  setSelected: (e: any) => void;
  selectionMode?: 'single' | 'multiple' | 'checkbox';
  display?: 'comma' | 'chip';
  placeholder?: string;
  filter?: boolean;
  label?: string;
  disabled?: boolean;
}

export interface ITreeSelectValue {
  [key: string]: {
    checked: boolean;
    partialChecked: boolean;
  };
}

const TreeSelect: FC<IProps> = ({
  options,
  value,
  onChange,
  setSelected,
  selectionMode,
  display = 'comma',
  placeholder = 'Selecione uma opção',
  filter = false,
  label,
  disabled = false
}) => {
  return (
    <div>
      <p>{label}</p>
      <TreeSelectComponent
        options={options}
        value={value}
        onChange={onChange}
        selectionMode={selectionMode}
        display={display}
        placeholder={placeholder}
        metaKeySelection={false}
        filter={filter}
        resetFilterOnHide
        disabled={disabled}
      />
      {Object.keys(value).length !== 0 && (
        <button
          type="button"
          onClick={() => setSelected([])}
          style={{
            zIndex: 99999,
            position: 'absolute',
            marginLeft: -85,
            marginTop: 16,
            backgroundColor: 'white',
            border: 'none'
          }}
        >
          <i
            className="pi pi-fw pi-times"
            style={{ color: '#6c757d', cursor: 'pointer ' }}
          />
        </button>
      )}
    </div>
  );
};

export default TreeSelect;
