import styled from '@emotion/styled';

export const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 16px;
  margin-top: 16px;
  animation: slide-in 0.5s ease forwards;

  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateY(-20%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const UpdatedDate = styled.div`
  font-size: 9px;
  color: #9da7b1;
  b {
    font-weight: bold;
  }
`;

export const CompanyName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const TextInfo = styled.div`
  font-size: 11px;
  color: #999;
  line-height: 1.5;

  b {
    font-weight: bold;
  }
`;
