import React from 'react';

import { Breadcrumb, MenuCardList } from '../../components/_Molecules';

import {
  OFFER_MENU_ITEMS,
  OFFER_MENU_ITEM_LEADER
} from '../../constants/menu.constants';
import { ROUTES } from '../../constants/routes.constants';
import { useAuth } from '../../contexts/auth.context';

const CampaignsManagementPage: React.FC = () => {
  const { userType } = useAuth();

  const IsLeader = userType() === 'Lider';

  return (
    <>
      <Breadcrumb
        style={IsLeader ? {} : { margin: 'auto', maxWidth: 1046 }}
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          }
        ]}
        currentRouteName="Gestão de campanhas"
      />
      <MenuCardList
        columns={IsLeader ? 'TWO' : 'THREE'}
        menuCardList={IsLeader ? OFFER_MENU_ITEM_LEADER : OFFER_MENU_ITEMS}
      />
    </>
  );
};

export default CampaignsManagementPage;
