import { SelectOption } from '@sumup/circuit-ui';

export const MACHINE_PRICES_FILTER_OPTIONS: SelectOption[] = [
  { value: 'ativos', label: 'Ativos' },
  { value: 'inativos', label: 'Inativos' },
  { value: 'todos', label: 'Todos' }
];

export const DEFAULT_MACHINE_PRICES_FILTER_OPTION = 'ativos';

export const MACHINE_PRICES_STATUS_NAME = {
  active: 'Oferta ativa',
  disabled: 'Inativado'
};
