import { SearchInput } from '@sumup/circuit-ui';
import { FC } from 'react';
import { Button } from '../../_Atoms';

interface IProps {
  search: string;
  setSearch: (p: string) => void;
  submit: () => void;
  placeholder: string;
  label: string;
  button: string;
}

const SearchBarWithButton: FC<IProps> = ({
  search,
  setSearch,
  submit,
  placeholder,
  label,
  button
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start'
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: 776,
          marginTop: 32
        }}
      >
        <SearchInput
          onClear={() => setSearch('')}
          clearLabel="clearable"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
          }}
          autoComplete="off"
          value={search}
          name="search"
          placeholder={placeholder}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              submit();
            }
          }}
          label={label}
          style={{ boxShadow: '0 0 0 1px #D8DDE1' }}
        />
      </div>
      <Button style={{ marginBottom: 16 }} onClick={submit} value={button} />
    </div>
  );
};

export default SearchBarWithButton;
