import { View } from '@sumup/icons';
import { useHistory } from 'react-router';
import { SUB_MENU_ROUTES } from '../../../constants/routes.constants';
import { STORAGE } from '../../../constants/storage.constants';
import { IMessageHistory } from '../../../interfaces/messages.iterfaces';
import { CustomPopover, CustomSpinner, CustomTooltip } from '../../_Atoms';
import {
  StyledHeader,
  StyledHeaderItem,
  StyledRow,
  StyledRowItem
} from './styles';

interface IMessageHistoryTable {
  messages?: IMessageHistory[];
  page: number;
  isLoading: boolean;
  error: boolean;
}

const MessageHistoryTable: React.FC<IMessageHistoryTable> = ({
  messages,
  page,
  isLoading,
  error
}) => {
  const router = useHistory();

  const onClickAction = (id: number, url?: string, onClick?: () => void) => {
    localStorage.setItem(
      STORAGE.HISTORY_PAGINATION_MESSAGE,
      JSON.stringify({ page })
    );
    if (url) {
      router.push(`${url}/${id}`);
    } else if (onClick) {
      onClick();
    }
  };
  return (
    <div>
      <StyledHeader>
        <StyledHeaderItem>Data de envio</StyledHeaderItem>
        <StyledHeaderItem>Remetente</StyledHeaderItem>
        <StyledHeaderItem>Assunto</StyledHeaderItem>
        <StyledHeaderItem>Destinatários</StyledHeaderItem>
        <StyledHeaderItem>Ações</StyledHeaderItem>
      </StyledHeader>

      <div style={{ minHeight: '60vh' }}>
        {error && (
          <p style={{ textAlign: 'center', padding: 24, fontSize: '13px' }}>
            O histórico de mensagens não foi carregado. Por favor, aguarde uns
            instantes e tente novamente.
          </p>
        )}
        {messages?.length === 0 && !error && !isLoading && (
          <h3
            style={{ textAlign: 'center', marginTop: '10px', fontSize: '13px' }}
          >
            Ainda não existem mensagens
          </h3>
        )}
        {isLoading && <CustomSpinner />}
        {messages &&
          !error &&
          !isLoading &&
          messages.map((message) => (
            <StyledRow key={message.id}>
              <StyledRowItem>{message.data}</StyledRowItem>
              <StyledRowItem>{message.remetente}</StyledRowItem>
              <CustomTooltip title={message.assunto}>
                <StyledRowItem>
                  {message.assunto ? (
                    message.assunto
                  ) : (
                    <p style={{ color: '#9DA5B1' }}>(Sem assunto)</p>
                  )}
                </StyledRowItem>
              </CustomTooltip>
              <StyledRowItem>Usuários individuais</StyledRowItem>
              <StyledRowItem>
                <CustomPopover
                  actions={[
                    {
                      children: {
                        color: '#3063e9',
                        text: 'Visualizar'
                      },
                      icon: () => <View color="#3063e9" />,
                      onClick: () =>
                        onClickAction(
                          message.id,
                          SUB_MENU_ROUTES.MESSAGE_HISTORY
                        )
                    }
                  ]}
                  disabled={false}
                />
              </StyledRowItem>
            </StyledRow>
          ))}
      </div>
    </div>
  );
};

export default MessageHistoryTable;
