import { FC, HTMLAttributes } from 'react';
import { ValidationMessageErrorStyle } from './styles';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  message: string;
  type?: 'warning' | 'error';
}

const ValidationMessageError: FC<IProps> = ({
  message,
  type = 'error',
  ...props
}) => {
  return (
    <ValidationMessageErrorStyle type={type} {...props}>
      {message}
    </ValidationMessageErrorStyle>
  );
};

export default ValidationMessageError;
