import ArrowBackTwoToneIcon from '@material-ui/icons/ArrowBackTwoTone';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CustomDialog from '../CustomDialog';

import {
  ROUTES,
  ROUTES_WITH_FOOTER_AND_WELCOME_MESSAGE
} from '../../../constants/routes.constants';
import { STORAGE } from '../../../constants/storage.constants';

import { useAuth } from '../../../contexts/auth.context';
import { Button } from '../../_Atoms';
import { StyledBackgroundFooter } from './styles';

const Footer: React.FC = () => {
  const { signOut } = useAuth();
  const { pathname } = useLocation();
  const router = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  const isMainMenu = () => {
    return !!(
      pathname === ROUTES.MAIN_MENU || `${pathname}/` === `${ROUTES.MAIN_MENU}/`
    );
  };

  const handleLogout = () => {
    setOpenDialog(false);
    signOut();
    localStorage.removeItem(STORAGE.USER_DATA);
  };

  const goBack = () => {
    const splitedURL = pathname.split('/');
    splitedURL.pop();
    const finalURL: string = splitedURL.join('/');
    router.push(finalURL);
  };

  const getActionOnClick = (): void => {
    isMainMenu() ? setOpenDialog(true) : goBack();
  };

  return (
    <>
      {ROUTES_WITH_FOOTER_AND_WELCOME_MESSAGE.includes(
        pathname || `${pathname}/`
      ) && (
        <StyledBackgroundFooter>
          <Button
            onClick={getActionOnClick}
            width="medium"
            IconBefore={() => (isMainMenu() ? null : <ArrowBackTwoToneIcon />)}
            value={isMainMenu() ? 'Sair' : 'Voltar'}
          />
        </StyledBackgroundFooter>
      )}

      {openDialog && (
        <CustomDialog
          title="Você tem certeza de que gostaria de sair?"
          message="Ao clicar em voltar, redirecionaremos você para a tela de login e você precisará fazer a autenticação novamente."
          handleClose={() => setOpenDialog(false)}
          primaryButton={{
            buttonTitle: 'Sair',
            onClick: () => handleLogout()
          }}
          secondaryButton={{
            buttonTitle: 'Voltar',
            onClick: () => setOpenDialog(false)
          }}
        />
      )}
    </>
  );
};

export default Footer;
