import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { FC } from 'react';
import { CustomButton, Spinner, ValidationMessageError } from '../../_Atoms';
import { Children, ModalFooter, ModalHeader } from './styles';

interface IProps {
  open: boolean;
  setOpen: (p: boolean) => void;
  title: string;
  action?: () => void;
  labelAction?: string;
  widthButtonAction?: string;
  showFooter?: boolean;
  widthAuto?: boolean;
  disableAction?: boolean;
  hideBackButton?: boolean;
  loading?: boolean;
  maxWidth?: number;
  footerErrorMessage?: string;
  labelBackButton?: string;
}

const Modal: FC<IProps> = ({
  children,
  open,
  setOpen,
  title,
  action,
  labelAction,
  widthButtonAction,
  showFooter = true,
  widthAuto = false,
  disableAction = false,
  loading = false,
  maxWidth = 1280,
  hideBackButton = false,
  footerErrorMessage = '',
  labelBackButton
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={handleClose}>
      <div
        style={{
          width: widthAuto ? 'auto' : '90vw',
          maxWidth: maxWidth || 1280
        }}
      >
        <ModalHeader>
          <p>{title}</p>
          <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
        </ModalHeader>
        <Children>{children}</Children>
        {showFooter && (
          <ModalFooter>
            {footerErrorMessage && (
              <ValidationMessageError
                message={footerErrorMessage}
                style={{ whiteSpace: 'nowrap' }}
              />
            )}
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end'
              }}
            >
              {!hideBackButton && (
                <CustomButton
                  disabled={loading}
                  style={{
                    margin: 0,
                    ...(widthButtonAction ? { width: widthButtonAction } : {})
                  }}
                  onClick={handleClose}
                >
                  {labelBackButton || 'Voltar'}
                </CustomButton>
              )}
              {action && (
                <CustomButton
                  variant="primary"
                  style={{
                    margin: '0 12px',
                    height: 40,
                    ...(widthButtonAction ? { width: widthButtonAction } : {})
                  }}
                  onClick={action}
                  disabled={disableAction}
                >
                  {loading ? (
                    <Spinner size="24px" width="2px" color="white" />
                  ) : (
                    labelAction || 'Salvar'
                  )}
                </CustomButton>
              )}
            </div>
          </ModalFooter>
        )}
      </div>
    </Dialog>
  );
};

export default Modal;
