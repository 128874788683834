import React from 'react';
import styled from '@emotion/styled';
import { Spinner, SpinnerProps } from '@sumup/circuit-ui';

const StyledSpinner = styled(Spinner)`
  color: ${(p) => p.theme.colors.p500};
`;
const StyledSpinnerBackground = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
type ICustomSpinner = SpinnerProps;
const CustomSpinner: React.FC<ICustomSpinner> = ({ ...props }) => {
  return (
    <StyledSpinnerBackground>
      <StyledSpinner {...props} />
    </StyledSpinnerBackground>
  );
};

export default CustomSpinner;
