import { FC } from 'react';
import { CountStyle } from './styles';

interface IProps {
  quantity?: number;
  total?: number;
}

const Count: FC<IProps> = ({ quantity = 0, total = 0 }) => {
  return (
    <CountStyle>
      exibindo <b>{quantity >= 0 ? quantity : 0}</b> de{' '}
      <b>{total >= 0 ? total : 0}</b>
    </CountStyle>
  );
};

export default Count;
