import styled from '@emotion/styled';

export const StyledBoxPage = styled.div`
  max-width: 1280px;
  margin: 0 auto 160px auto;
  width: 100%;
  padding: 0 12px;
`;

export const LineTable = styled.div`
  height: 72px;
`;

export const ToggleView = styled.div`
  background-color: #fafbfc;
  padding: 8px 16px;
  color: #3063e9;
  font-weight: bold;
  display: flex;
  margin: 32px 0 16px 0;
  height: 56px;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
`;

export const PaginationContainer = styled.div<{ displayNone: boolean }>`
  display: ${({ displayNone }) => (displayNone ? 'flex' : 'none')};
  justify-content: space-between;
  margin-top: 24px;
`;

export const Charts = styled.div`
  width: 100%;
  height: 506px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 16px;
  margin-left: 16px;
  box-shadow: 0px 2px 2px 0px rgba(12, 15, 20, 0.06),
    0px 1px 1px 0px rgba(12, 15, 20, 0.06),
    0px -1px 0px 0px rgba(12, 15, 20, 0.02);

  h1 {
    font-size: 17px;
    font-weight: bold;
  }

  p {
    padding: 0 16px;
  }

  div {
    display: flex;
    align-items: center;
    padding: 24px;
  }
`;

export const ChartStyle = styled.aside`
  font-size: 32px;
  font-weight: bold;
  color: white;
  background-color: #3063e9;
  border-radius: 50%;
  width: 148px;
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
