import { Container } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as ReceiveMachine } from '../../assets/receive-machine.svg';
import { ReactComponent as SendMachine } from '../../assets/send-machine.svg';
import {
  Button,
  CustomTooltip,
  Spinner,
  SubTitle,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  Card
} from '../../components/_Molecules';
import { NetworkError } from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import {
  ENUM_MACHINE,
  ENUM_TROCA_DEVOLUCAO
} from '../../constants/machineAllocate.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';
import { IResquestInfoExchangeAndReturnDetails } from '../../interfaces/exchangeAndReturn';
import { IDataResponseModel } from '../../interfaces/requests.interfaces';
import { get } from '../../services/axios.service';
import { formatCreationDate } from '../../utils/format.utils';
import { CardsContainer, ContentTable, HeaderTable } from './styles';

const ExchangeAndReturnSolicitationsDetails: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [info, setInfo] =
    useState<IResquestInfoExchangeAndReturnDetails | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();

  const getDetails = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await get<
        IDataResponseModel<IResquestInfoExchangeAndReturnDetails>
      >(API.AQUISICAO_MAQUINA.DETALHES_TROCA_DEVOLUCAO_BY_ID(id));

      if (res.success) {
        setInfo(res.data);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const checkIntervalDate = (date: string) => {
    const creationDate = new Date(date);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - creationDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 30;
  };

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <Container style={{ margin: 'auto', maxWidth: 1336 }}>
      <Breadcrumb
        currentRouteName="Detalhes solicitações Troca/Devolução"
        previousRoutes={[
          { routeName: 'Menu principal', routePath: ROUTES.MAIN_MENU },
          {
            routeName: 'Gestão de ativos',
            routePath: MENU_ROUTES.ASSET_MANAGEMENT
          },
          {
            routeName: 'Troca e Devolução',
            routePath: SUB_MENU_ROUTES.EXCHANGE_AND_RETURN
          },
          {
            routeName: 'Solicitações',
            routePath: THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS
          }
        ]}
        style={{ maxWidth: 1046, marginBottom: 36 }}
        noMargin
      />

      <Title fontSize="big">Gestão de solicitações</Title>

      <SubTitle>
        Visualize os detalhes da solicitação de Troca/Devolução
      </SubTitle>

      {error && (
        <NetworkError
          image="pencil"
          retry={getDetails}
          subtitle="Aguarde alguns instantes e tente novamente."
        />
      )}

      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', height: 300 }}>
          <Spinner size="46px" color="#3388ff" width="5px" />
        </div>
      )}

      {info && !loading && !error && (
        <>
          <p style={{ color: '#3063e9', fontWeight: 'bold', marginTop: 24 }}>
            {ENUM_TROCA_DEVOLUCAO[info.tipoSolicitacao].toLocaleUpperCase()}
          </p>

          <div>
            <HeaderTable>
              <p>ID Solicitação</p>
              <p>
                Tipo de <br /> solicitação
              </p>
              <p>Data do Registro</p>
              <p>Email do Solicitante</p>
              <p>
                Subgrupo do <br /> Solicitante
              </p>
              <p>Merchant code</p>
              <p>Motivo</p>
            </HeaderTable>
            <ContentTable>
              <p>{info.id}</p>
              <p>{ENUM_TROCA_DEVOLUCAO[info.tipoSolicitacao]}</p>
              <p>{formatCreationDate(info.dataRegitro)}</p>
              <CustomTooltip title={info.emailSolicitante}>
                <p>{info.emailSolicitante}</p>
              </CustomTooltip>
              <CustomTooltip title={info.subGrupoSolicitante}>
                <p>{info.subGrupoSolicitante}</p>
              </CustomTooltip>

              <CustomTooltip title={info.merchantCode}>
                <p>{info.merchantCode}</p>
              </CustomTooltip>

              <CustomTooltip
                title={info.motivo}
                disableHoverListener={!info.motivo}
              >
                <p>{info.motivo || '-'}</p>
              </CustomTooltip>
            </ContentTable>
          </div>

          <p style={{ color: '#3063e9', fontWeight: 'bold' }}>MÁQUINA</p>

          <CardsContainer>
            <Card.Root
              style={{
                border: '1px solid #ea7a7a',
                width:
                  info.tipoMaquinaRetirada === ENUM_MACHINE.Solo
                    ? '49%'
                    : '25%',
                marginRight: 15,
                minWidth: 320
              }}
            >
              <Card.Title
                title="Máquina retirada do cliente"
                icon={SendMachine}
              />

              <Card.Row>
                <Card.Item
                  label="Máquina"
                  value={ENUM_MACHINE[info.tipoMaquinaRetirada]}
                />
                <Card.Item
                  label="Serial da máquina"
                  value={`${info.numeroSerieMaquinaRetirada}`}
                />

                {info.tipoMaquinaRetirada === ENUM_MACHINE.Solo && (
                  <>
                    <Card.Item label="Máquina" value="Printer" />
                    <Card.Item
                      label="Serial da máquina"
                      value={info.numeroSeriePrinterRetirada}
                    />
                  </>
                )}
              </Card.Row>
            </Card.Root>

            {info.tipoSolicitacao === ENUM_TROCA_DEVOLUCAO.Troca && (
              <Card.Root
                style={{
                  border: '1px solid #8cc13f',
                  width:
                    info.tipoMaquinaEntregue !== ENUM_MACHINE.Total
                      ? '49%'
                      : '25%',
                  minWidth: 320
                }}
              >
                <Card.Title
                  title="Máquina entregue ao cliente"
                  icon={ReceiveMachine}
                />
                <Card.Row>
                  <Card.Item
                    label="Máquina"
                    value={ENUM_MACHINE[info.tipoMaquinaEntregue]}
                  />
                  <Card.Item
                    label="Serial da máquina"
                    value={info.numeroSerieMaquinaEntregue}
                  />

                  {info.tipoMaquinaEntregue !== ENUM_MACHINE.Total && (
                    <>
                      <Card.Item label="Máquina" value="Printer" />
                      <Card.Item
                        label="Serial da máquina"
                        value={`${info.numeroSeriePrinterEntregue}`}
                      />
                    </>
                  )}
                </Card.Row>
              </Card.Root>
            )}
          </CardsContainer>
        </>
      )}

      <BottomFixedActions style={{ zIndex: 1 }}>
        <Button
          variant="secondary"
          type="button"
          value="Voltar"
          onClick={() =>
            push(THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS)
          }
          width="medium"
        />

        <Button
          variant="primary"
          type="button"
          disabled={checkIntervalDate(info?.dataRegitro || '')}
          value="Editar informações"
          onClick={() =>
            push(
              `${THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS}/edit/${id}`
            )
          }
          width="large"
        />
      </BottomFixedActions>
    </Container>
  );
};

export default ExchangeAndReturnSolicitationsDetails;
