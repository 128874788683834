import styled from '@emotion/styled';

export const StylesToast = styled.div`
  .container {
    font-size: 14px;
    position: fixed;
    z-index: 9999;
    padding: 12px;
  }

  .top-right {
    top: 1rem;
    right: 1rem;
    animation: toast-in-right 0.7s;
  }

  .notification {
    margin-bottom: 1rem;
    border-radius: 4px;
    box-shadow: 0 0 10px #999;
    color: #000;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .sucess {
    background-color: #3063e9;
  }

  .error {
    background-color: #db4d4d;
  }

  .notification:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
  }

  .toast {
    height: auto;
    width: 365px;
    color: #fff;
    padding: 20px;
  }

  .container button {
    float: right;
    background: none;
    border: none;
    color: #fff;
    opacity: 0.8;
    cursor: pointer;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 300px;
    height: 18px;
  }

  .description {
    margin: 0;
    text-align: left;
  }

  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;
