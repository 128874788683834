import styled from '@emotion/styled';

export const StyledWelcomeMessage = styled.div`
  margin: 64px 0 55px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 45px;
    height: 45px;
  }
  & > h1 {
    margin-left: 20px;
  }
`;
