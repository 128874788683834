import { IOffer, IPlanoOferta } from '../interfaces/offers.interfaces';
import { formatCurrencyToNumber } from '../utils/format.utils';

export const formatOfferToPlanoOferta = (offer: IOffer): IPlanoOferta => {
  const formatedOffer: IPlanoOferta = {
    id: offer.id ?? 0,
    inicio: formatCurrencyToNumber(offer.faturamentoInicial),
    fim: formatCurrencyToNumber(offer.faturamentoFinal),
    grupoMCCId: Number(offer.grupoMccs),
    nivel: Number(offer.nivel),
    alcada: offer.alcada,
    planoId: Number(offer.planoTaxas),
    valorMaquina: offer.valorMaquina,
    remuneracao: offer.remuneracao,
    condicoes: offer.condicoes,
    tipoTransacao: Number(offer.tipoTransacao),
    removido: offer.removido ?? false
  };
  return formatedOffer;
};
