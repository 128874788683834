import { decode, JwtPayload } from 'jsonwebtoken';

import { STORAGE } from '../constants/storage.constants';

import { IAuthUserData } from '../pages/Login';

export interface IDecodedToken extends JwtPayload {
  nameid: string;
  role: string;
}

export const getToken = (): string | null => {
  const userData: IAuthUserData = JSON.parse(
    localStorage.getItem(STORAGE.USER_DATA) || '{}'
  );
  return userData.token ?? null;
};

export const isValidToken = (token: string): boolean => {
  try {
    const decoded = decode(token, { json: true }) as IDecodedToken;
    const dateNow = Date.now() / 1000;

    return dateNow <= (decoded.exp as number);
  } catch (error) {
    return false;
  }
};

export const decodeToken = (token: string): IDecodedToken | null => {
  try {
    return decode(token, { json: true }) as IDecodedToken;
  } catch (error) {
    return null;
  }
};
