import React from 'react';

import styled from '@emotion/styled';
import { InlineMessage, InlineMessageProps } from '@sumup/circuit-ui';

import { getAssetsPropostaByEnum } from '../../../constants/alcada.constants';
import { ENUM_STATUS_PROPOSTA } from '../../../enums/alcada.enums';

interface ICustomStatus {
  status: ENUM_STATUS_PROPOSTA;
}
interface IStyledInlineMessage {
  color: string;
}

const StyledInlineMessage = styled(InlineMessage)<
  InlineMessageProps & IStyledInlineMessage
>`
  height: 50px;
  padding-top: 15px;
  padding-left: 24px;
  ::before {
    left: 0;
    background-color: ${(props) => props.color};
  }
  svg {
    color: ${(props) => props.color};
  }
`;

const CustomStatus: React.FC<ICustomStatus> = ({ status }) => {
  const propostaAssets = getAssetsPropostaByEnum[status];

  return (
    <StyledInlineMessage
      color={propostaAssets.color}
      noMargin
      size="giga"
      variant="danger"
    >
      {propostaAssets.icon}
    </StyledInlineMessage>
  );
};

export default CustomStatus;
