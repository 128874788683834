import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ExternalLink } from '../../../assets/icons/external_link.svg';
import { THIRD_LEVEL_MENU_ROUTES } from '../../../constants/routes.constants';
import { ISolicitationList } from '../../../interfaces/exchangeAndReturn';
import { formatCreationDate } from '../../../utils/format.utils';
import { CustomTooltip, Spinner } from '../../_Atoms';
import { NoResultsState } from '../../_Molecules';
import { Row, TitleTable } from './styles';

interface IProps {
  data: ISolicitationList[];
  saveFilters: () => void;
  loading: boolean;
}

const ExchangeAndReturnSolicitationsTable: FC<IProps> = ({
  data,
  loading,
  saveFilters
}) => {
  const { push } = useHistory();

  return (
    <div style={{ paddingBottom: '20px' }}>
      <TitleTable>
        <p>ID Solicitação</p>
        <p>
          Tipo de <br /> Solicitação
        </p>
        <p>
          Data do <br /> Registro
        </p>
        <p>Email do Solicitante</p>
        <p>
          Subgrupo do <br /> Solicitante
        </p>
        <p>
          Merchant <br /> code
        </p>
        <p>Motivo</p>
        <p>Detalhes</p>
      </TitleTable>

      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', height: 434 }}>
          <Spinner size="46px" color="#3388ff" width="5px" />
        </div>
      )}

      <div>
        {!loading &&
          data?.map((solicitation) => (
            <Row key={solicitation.id}>
              <p>{solicitation.id}</p>
              <p>
                {solicitation.tipoSolicitacao === 1
                  ? 'Troca'
                  : solicitation.tipoSolicitacao === 2
                  ? 'Devolução'
                  : 'Outro'}
              </p>
              <p>{formatCreationDate(solicitation.dataRegitro)}</p>
              <CustomTooltip title={solicitation.emailSolicitante}>
                <p>{solicitation.emailSolicitante}</p>
              </CustomTooltip>
              <CustomTooltip title={solicitation.subGrupoSolicitante}>
                <p>{solicitation.subGrupoSolicitante}</p>
              </CustomTooltip>
              <p>{solicitation.merchantCode}</p>
              <CustomTooltip title={solicitation.motivo}>
                <p>{solicitation.motivo}</p>
              </CustomTooltip>
              <p style={{ cursor: 'pointer' }}>
                <ExternalLink
                  onClick={() => {
                    push(
                      `${THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS}/${solicitation.id}`
                    );
                    saveFilters();
                  }}
                />
              </p>
            </Row>
          ))}
      </div>

      {data.length === 0 && !loading && <NoResultsState />}
    </div>
  );
};

export default ExchangeAndReturnSolicitationsTable;
