import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';

const StyleTitle = styled.p<{ align: string; fontSize: string }>`
  font-weight: bold;
  margin: 8px 0;
  font-size: ${({ fontSize }) => {
    if (fontSize === 'small') return '12px';
    if (fontSize === 'medium') return '19px';
    if (fontSize === 'big') return '28px';
    return '19px';
  }};
  text-align: ${({ align }) => align};
`;

interface IProps extends HTMLAttributes<HTMLParagraphElement> {
  align?: 'left' | 'center' | 'right';
  fontSize?: 'small' | 'medium' | 'big';
}

const Title: FC<IProps> = ({
  align = 'left',
  fontSize = 'medium',
  children,
  ...props
}) => {
  return (
    <StyleTitle align={align} fontSize={fontSize} {...props}>
      {children}
    </StyleTitle>
  );
};

export default Title;
