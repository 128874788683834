import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import styled from '@emotion/styled';

import { CustomSpinner } from '../../components/_Atoms';
import { ApproveAlcadaActions, Breadcrumb } from '../../components/_Molecules';
import {
  StyledBody,
  StyledHeadline
} from '../../components/_Molecules/CardContainer/styles';

import {
  IDataResponseModel,
  IDetalhamentoAquisicao,
  IDetalhamentoProposta
} from '../../interfaces/requests.interfaces';

import { get } from '../../services/axios.service';

import {
  ApproveAlcadaAquisitionTable,
  ApproveAlcadaPropostaTable,
  NetworkError
} from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES
} from '../../constants/routes.constants';
import { TipoAquisicao } from '../../enums/alcada.enums';

const StyledPageTitleBox = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

const ApproveAlcadaByIdPage: React.FC = () => {
  const { id, type } = useParams<{ id?: string; type: '1' | '2' }>();
  const [aquisition, setAquisition] = useState<IDetalhamentoAquisicao>();
  const [proposal, setProposal] = useState<IDetalhamentoProposta>();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!id) return;

    const getProposal = async () => {
      setError(false);
      setLoading(true);
      try {
        if (type === '1') {
          const dataProposal = await get<
            IDataResponseModel<IDetalhamentoProposta>
          >(API.PROPOSTAS.GET_BY_ID(`${id}`));

          if (!dataProposal.success) {
            setError(true);
          }
          setProposal(dataProposal.data);
        }

        if (type === '2') {
          const dataAquisition = await get<
            IDataResponseModel<IDetalhamentoAquisicao>
          >(`${API.AQUISICAO_MAQUINA.GET_BY_ID(id)}`);

          setAquisition(dataAquisition.data);
          if (!dataAquisition.success) {
            setError(true);
          }
        }
      } catch (error) {
        setError(true);
        push(MENU_ROUTES.PROPOSALS_MANAGEMENT);
      }
    };
    getProposal();
    setLoading(false);
  }, [id]);

  return (
    <>
      <StyledPageTitleBox>
        <Breadcrumb
          currentRouteName="Detalhes da proposta"
          previousRoutes={[
            {
              routeName: 'Menu principal',
              routePath: ROUTES.MAIN_MENU
            },
            {
              routeName: 'Gestão de propostas',
              routePath: MENU_ROUTES.PROPOSALS_MANAGEMENT
            },
            {
              routeName: 'Propostas',
              routePath: SUB_MENU_ROUTES.PROPOSALS
            }
          ]}
          noMargin
        />
        <StyledHeadline style={{ marginTop: 25 }} noMargin as="h1">
          Gestão de propostas
        </StyledHeadline>
        <StyledBody as="p">
          Visualize os detalhes de propostas realizadas por promotores da SumUp.
        </StyledBody>
      </StyledPageTitleBox>

      {error && (
        <>
          <p
            style={{
              maxWidth: 1200,
              margin: 'auto',
              marginTop: 28,
              color: '#3063E9',
              fontWeight: 'bold',
              fontSize: 16
            }}
          >
            PROPOSTA
          </p>
          <NetworkError
            title="Não foi possível abrir a proposta"
            subtitle="Não foi possível visualizar essa proposta."
            secondSubtitle="Por favor, crie uma nova proposta."
            back={() => push(MENU_ROUTES.PROPOSALS_MANAGEMENT)}
          />
        </>
      )}
      {!error &&
        (!loading ? (
          <>
            {TipoAquisicao[type] === 'Venda' ? (
              <>
                {proposal && (
                  <>
                    <ApproveAlcadaPropostaTable proposta={proposal} />
                    <ApproveAlcadaActions
                      status={proposal.status}
                      idProposta={proposal.id}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {aquisition && (
                  <ApproveAlcadaAquisitionTable aquisition={aquisition} />
                )}
              </>
            )}
          </>
        ) : (
          <CustomSpinner />
        ))}
    </>
  );
};

export default ApproveAlcadaByIdPage;
