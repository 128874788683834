import styled from '@emotion/styled';
import { Body } from '@sumup/circuit-ui';

export const StyledBackground = styled.div<{ noMargin: boolean }>`
  max-width: 685px;
  ${({ noMargin }) => (noMargin ? '' : 'margin: 0 auto; padding-left: 12px')}
`;

export const StyledBody = styled(Body)`
  color: ${(props) => props.theme.colors.n500};
  font-size: 13px;
`;

export const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.n500};
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const StyledCurrentPage = styled(Body)`
  color: ${(props) => props.theme.colors.b500};
  font-size: 13px;
  font-weight: bold;
`;

export const StyledBreadcrumbDivider = styled.span`
  padding-left: 3px;
  padding-right: 10px;
`;
