import styled from '@emotion/styled';
import { Body, Button, Headline } from '@sumup/circuit-ui';

export const StyledHeadline = styled(Headline)`
  font-size: 17px;
  margin-top: -17px;
`;
export const StyledBody = styled(Body)`
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 30px;
`;
export const StyledActionsBar = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`;
export const StyledButton = styled(Button)`
  min-width: 100px;
  margin-left: 16px;
`;
