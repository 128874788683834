import { Container } from '@material-ui/core';
import axios from 'axios';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CountItensPerPage,
  CustomPagination,
  SubTitle,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  Toast
} from '../../components/_Molecules';
import { IToast } from '../../components/_Molecules/Toast';
import {
  HistoryFilesFilters,
  HistoryFilesTable
} from '../../components/_Organisms';
import { IHistoryList } from '../../components/_Organisms/HistoryFilesTable';
import { API } from '../../constants/api.constants';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';
import {
  IDataResponseModel,
  IPagination
} from '../../interfaces/requests.interfaces';
import { post } from '../../services/axios.service';
import { handleDownloadDynamicType } from '../../services/download.service';
import { rangeDateOptions } from '../../utils/kanban';

const HistoryFiles: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<IHistoryList[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [resetPagination, setResetPagination] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [filters, setFilters] = useState({
    typeRequisition: '0',
    initialLabelDateIndex: 11,
    emailIds: [],
    startDate: rangeDateOptions('11').start,
    endDate: rangeDateOptions('11').end
  });
  const [toast, setToastList] = useState<IToast[]>([]);
  const { push } = useHistory();

  const getData = async (page: number) => {
    setLoading(true);
    setError(false);

    try {
      const data = await post<IDataResponseModel<IPagination<IHistoryList>>>(
        API.ARQUIVO.LISTAR,
        {
          pageSize: itemsPerPage,
          pageNumber: page,
          ...(filters.emailIds.length > 0 && { criadorIds: filters.emailIds }),
          ...(filters.startDate && {
            dataInicial: filters.startDate
          }),
          ...(filters.endDate && {
            dataFinal: filters.endDate
          }),
          ...(filters.typeRequisition !== '0' && {
            tipoSolicitacao: Number(filters.typeRequisition)
          })
        }
      );

      if (data.success) {
        setData(data.data.results);
        setTotalItems(data.data.totalItems);
      } else {
        setError(true);
        setData([]);
      }
    } catch (error) {
      setError(true);
      setData([]);
    }

    setLoading(false);
  };

  const DownloadFile = async (id: number) => {
    const fetchUrl = `${
      process.env.REACT_APP_BASE_URL
    }${API.ARQUIVO.DOWNLOAD_BY_ID(id)}`;
    axios
      .get(fetchUrl, { responseType: 'blob' })
      .then((response) => {
        handleDownloadDynamicType(
          response.data,
          'historico-arquivo',
          response.data.type
        );
        console.log(response.data);
        setToastList([
          {
            id: 1,
            title: 'Download completo!',
            description: 'O arquivo foi baixado com sucesso.',
            type: 'sucess'
          }
        ]);
      })
      .catch((error) => {
        setToastList([
          {
            id: 1,
            title: 'Não foi possível gerar o arquivo!',
            description: 'Tente novamente.',
            type: 'sucess'
          }
        ]);
        if (error) console.error(error);
      });
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page + 1);
    await getData(page + 1);
  };

  const loadData = async () => {
    setCurrentPage(1);
    setResetPagination(false);
    await getData(1);
    setResetPagination(true);
  };

  return (
    <Container style={{ maxWidth: 1336, paddingBottom: 160 }}>
      <Breadcrumb
        currentRouteName="Histórico de arquivos"
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          },
          {
            routeName: 'Gestão de propostas',
            routePath: MENU_ROUTES.PROPOSALS_MANAGEMENT
          }
        ]}
        style={{ marginBottom: '36px' }}
        noMargin
      />
      <Title style={{ margin: '32px 0px 8px 0px' }} fontSize="big">
        Histórico de arquivos
      </Title>
      <SubTitle>Veja as propostas e relatórios de comodatos gerados</SubTitle>

      <HistoryFilesFilters
        filter={loadData}
        filters={filters}
        setFilters={setFilters}
      />

      <HistoryFilesTable
        exportFile={DownloadFile}
        error={error}
        loading={loading}
        data={data}
      />

      <Toast
        toastlist={toast}
        setList={setToastList}
        miliseconds={10000}
        position="top-right"
      />

      {totalItems > itemsPerPage && resetPagination && !error && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 24
          }}
        >
          <CountItensPerPage
            currentPage={currentPage}
            itensPerPage={itemsPerPage}
            totalItens={totalItems}
          />
          <CustomPagination
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            totalItems={totalItems}
            initialPage={currentPage}
            shouldResetPagination={resetPagination}
          />
        </div>
      )}

      <BottomFixedActions>
        <Button
          variant="secondary"
          value="Voltar"
          onClick={() => {
            push(MENU_ROUTES.PROPOSALS_MANAGEMENT);
          }}
        />
      </BottomFixedActions>
    </Container>
  );
};

export default HistoryFiles;
