import React from 'react';

import { Breadcrumb, MenuCardList } from '../../components/_Molecules';

import { USER_MENU_ITEMS } from '../../constants/menu.constants';
import { ROUTES } from '../../constants/routes.constants';

const UserManagementPage: React.FC = () => {
  return (
    <>
      <Breadcrumb
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          }
        ]}
        currentRouteName="Gestão de usuários"
      />
      <MenuCardList columns="TWO" menuCardList={USER_MENU_ITEMS} />
    </>
  );
};

export default UserManagementPage;
