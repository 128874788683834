import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES_WITH_FOOTER_AND_WELCOME_MESSAGE } from '../../../constants/routes.constants';
import { useAuth } from '../../../contexts/auth.context';
import { WelcomeMessage } from '../../_Atoms';
import { TopNavbar } from '../../_Molecules';
import {
  StyledHeaderBackground,
  StyledLoginLogo,
  StyledMarginBox
} from './styles';

export interface IUserData {
  name: string;
}

const Header: React.FC = () => {
  const { auth } = useAuth();
  const { pathname } = useLocation();

  if (!auth?.name) {
    return (
      <StyledHeaderBackground>
        <StyledLoginLogo>
          <img src="/images/sumup_logo.svg" alt="Sumup" />
        </StyledLoginLogo>
      </StyledHeaderBackground>
    );
  }

  return (
    <StyledHeaderBackground>
      <TopNavbar />
      {ROUTES_WITH_FOOTER_AND_WELCOME_MESSAGE.includes(pathname) ? (
        <WelcomeMessage name={auth.name} />
      ) : (
        <StyledMarginBox />
      )}
    </StyledHeaderBackground>
  );
};

export default Header;
