import styled from '@emotion/styled';

export const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
`;
export const Message = styled.p`
  text-align: center;
  max-width: 350px;
`;
