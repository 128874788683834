import { FC } from 'react';

const SVGDecisorNaoEncotrado: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.69982 7.99525L13.6488 4.0463C14.1171 3.5784 14.1171 2.81922 13.6488 2.35092C13.1805 1.88303 12.4221 1.88303 11.9538 2.35092L8.00485 6.29988L4.05589 2.35132C3.58799 1.88303 2.82881 1.88303 2.36051 2.35132C1.89262 2.81922 1.89262 3.5784 2.36051 4.0467L6.30947 7.99525L2.35092 11.9538C1.88303 12.4217 1.88303 13.1809 2.35092 13.6488C2.58467 13.8829 2.89194 14 3.19841 14C3.50488 14 3.81215 13.8829 4.0463 13.6488L8.00445 9.69063L11.953 13.6392C12.1872 13.8733 12.494 13.9904 12.8005 13.9904C13.107 13.9904 13.4138 13.8733 13.648 13.6392C14.1163 13.1713 14.1163 12.4121 13.648 11.9442L9.69982 7.99525Z"
        fill="#323E49"
      />
    </svg>
  );
};

export default SVGDecisorNaoEncotrado;
