import React, { memo } from 'react';
import { Select, SelectProps } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

interface ISelectField extends SelectProps {
  errorMessage?: string;
  isTableComponent?: boolean;
  alignSide?: 'center' | 'left' | 'right';
}
const SelectField: React.FC<ISelectField> = ({
  isTableComponent = false,
  alignSide = 'center',
  invalid,
  errorMessage,
  validationHint,
  ...props
}) => {
  const textValidationHint = () => {
    return invalid && errorMessage ? errorMessage : validationHint;
  };

  const StyledSelect = styled(Select)`
    & div > select {
      font-size: 13px;
      text-align: ${alignSide} !important;
      padding-right: 30px;
      box-shadow: none !important;
      &:hover,
      &:focus {
        box-shadow: none;
      }
      &:read-only {
        background-color: #fff;
      }
    }
    & > span {
      font-size: 12px;
      text-align: ${alignSide};
    }
  `;

  return isTableComponent ? (
    <StyledSelect
      {...props}
      invalid={invalid}
      validationHint={textValidationHint()}
    />
  ) : (
    <Select
      {...props}
      invalid={invalid}
      validationHint={textValidationHint()}
    />
  );
};

export default memo(SelectField);
