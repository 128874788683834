import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ReactComponent as SpilledCup } from '../../assets/spilled-cup-error.svg';
import TextEditor from '../../components/TextEditor/Editor';
import { CustomButton, CustomSpinner } from '../../components/_Atoms';
import { Breadcrumb } from '../../components/_Molecules';
import {
  StyledBody,
  StyledHeadline
} from '../../components/_Molecules/CardContainer/styles';
import { StatisticsMessageViewTable } from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES
} from '../../constants/routes.constants';
import { IDataResponseModel } from '../../interfaces/requests.interfaces';
import { get } from '../../services/axios.service';
import {
  CustomHeadline,
  DestinatariosList,
  InputDestinatarioMessage,
  InputMessage,
  Label,
  MessageError,
  StyledBoxPage,
  StyledContentBox,
  StyledForm
} from './styles';

interface IMessage {
  assunto: string;
  comunicado: string;
  destinatarios: string[];
  id: number;
  remetente: string;
}

const MessageView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [message, setMessage] = useState<IMessage>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const router = useHistory();

  const getMessage = async () => {
    setError(false);
    setIsLoading(true);
    try {
      const { data } = await get<IDataResponseModel<IMessage>>(
        API.COMUNICADO.COMUNICADO_BY_ID(id)
      );
      setMessage(data);
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMessage();
  }, []);

  return (
    <StyledBoxPage>
      {!isLoading && message && !error && (
        <>
          <StyledContentBox>
            <Breadcrumb
              noMargin
              previousRoutes={[
                {
                  routeName: 'Menu principal',
                  routePath: ROUTES.MAIN_MENU
                },
                {
                  routeName: 'Central de mensagens',
                  routePath: MENU_ROUTES.MESSAGES_CENTER
                },
                {
                  routeName: 'Histórico de mensagens',
                  routePath: SUB_MENU_ROUTES.MESSAGE_HISTORY
                }
              ]}
              currentRouteName="Visualizar mensagem"
            />
            <StyledHeadline style={{ marginTop: 25 }} noMargin as="h1">
              Visualizar mensagem
            </StyledHeadline>
            <StyledBody as="p">
              Visualize os detalhes e estatísticas da mensagem enviada para um
              único usuário ou para um grupo predefinido.
            </StyledBody>
          </StyledContentBox>

          <StyledForm>
            <CustomHeadline noMargin as="h1">
              Mensagem enviada
            </CustomHeadline>
            <InputMessage>
              <Label>Remetente:</Label>
              <p>{message.remetente}</p>
            </InputMessage>
            <InputDestinatarioMessage>
              <Label
                align={
                  message.destinatarios.length > 5 ? 'flex-start' : 'center'
                }
              >
                Destinatário:
              </Label>
              <DestinatariosList>
                {message.destinatarios.map((name: string) => (
                  <span>{name}</span>
                ))}
              </DestinatariosList>
            </InputDestinatarioMessage>
            <InputMessage>
              <Label>Assunto:</Label>
              <p style={{ color: message.assunto ? 'inherint' : '#9DA5B1' }}>
                {message.assunto || '(Sem assunto)'}
              </p>
            </InputMessage>
            <InputMessage>
              <Label>Mensagem:</Label>
            </InputMessage>
            <TextEditor readonly value={message.comunicado} />
            <StatisticsMessageViewTable idMessage={id} />
          </StyledForm>
        </>
      )}
      {isLoading && <CustomSpinner />}
      {error && (
        <MessageError>
          <p>Ops!</p>
          <p>Houve um erro ao carregar a tela</p>
          <SpilledCup />
          <p>Aguarde uns instantes e tente novamente</p>
          <div>
            <CustomButton
              onClick={() => router.push(SUB_MENU_ROUTES.MESSAGE_HISTORY)}
              variant="secondary"
            >
              Voltar
            </CustomButton>
            <CustomButton onClick={getMessage} variant="primary">
              Atualizar
            </CustomButton>
          </div>
        </MessageError>
      )}
    </StyledBoxPage>
  );
};

export default MessageView;
