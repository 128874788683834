import styled from '@emotion/styled';

export const StyledCustomCellTitle = styled.div``;
export const StyledCustomTableTitles = styled.header`
  height: 64px;
  background-color: #f9f9f9;
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: 700;
  font-size: 16px;
`;
export const StyledCustomRowContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid ${(p) => p.theme.colors.shadow};
  align-items: center;
  text-align: center;
  height: 64px;
`;
export const StyledCustomContent = styled.div`
  margin-top: 10px;
  font-size: 13px;
  margin-left: auto;
  margin-right: auto;

  p {
    overflow: hidden;
    max-width: 30ch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
