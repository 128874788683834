import React from 'react';
import { Card } from '@sumup/circuit-ui';
import { StyledBody, StyledHeadline } from './styles';

export interface IPageTitles {
  pageTitle?: string;
  cardTitle: string;
  cardSubtitle: string;
}
interface ICardContainer {
  title?: string;
  subtitle?: string;
  paddingLeft?: boolean;
}
const CardContainer: React.FC<ICardContainer> = ({
  title,
  subtitle,
  children,
  paddingLeft = false
}) => {
  return (
    <Card>
      <StyledHeadline
        style={{
          paddingLeft: paddingLeft ? '80px' : ''
        }}
        noMargin
        as="h3"
      >
        {title}
      </StyledHeadline>
      <StyledBody
        style={{
          paddingLeft: paddingLeft ? '80px' : ''
        }}
        as="p"
      >
        {subtitle}
      </StyledBody>
      {children}
    </Card>
  );
};

export default CardContainer;
