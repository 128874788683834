import { FC } from 'react';
import { TIPO_TRANSICAO_BY_ENUM } from '../../../../constants/plans.constants';
import {
  IFormAddRatePlans,
  ITaxasFlex
} from '../../../../interfaces/rate-plans.interfaces';
import { InputField, ToggleSwitch } from '../../../_Atoms';
import FlexibleInstallment from '../FlexibleInstallment';
import { StyledFixNotFlexTitle, StyledFixTitle } from '../styles';
import {
  StyledCopyValues,
  StyledCustomCellTitle,
  StyledCustomCenterContent,
  StyledCustomContent,
  StyledCustomFlexRowContent,
  StyledCustomTableTitles
} from './styles';

interface Props {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  getError: (error: string) => boolean;
  getTouch: (name: string) => boolean;
  values: IFormAddRatePlans;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (name: string, value: string | undefined | boolean) => void;
  taxas: 'taxaAntecipada' | 'taxaEconomica' | 'taxaInstantanea';
  setTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
}

const Details: FC<Props> = ({
  handleBlur,
  getError,
  getTouch,
  taxas,
  values,
  setFieldValue,
  handleChange,
  setTouched
}) => {
  const setTaxaValues = (
    index: number,
    type: 'taxaVisaMaster' | 'taxaOutrasBandeiras' | 'parceladoFlexivel'
  ) => {
    setFieldValue(
      `${taxas}[${index}].${type}`,
      values.taxaAntecipada[index][type]
    );
    setTouched(`${taxas}[${index}].${type}`, false);
  };

  const copyValues = () => {
    [0, 1, 2].forEach((index) => {
      setTaxaValues(index, 'taxaVisaMaster');
      setTaxaValues(index, 'taxaOutrasBandeiras');

      if (index === 2) {
        setTaxaValues(index, 'parceladoFlexivel');
      }
    });

    if (values.taxaAntecipada[2].parceladoFlexivel) {
      [...Array(11)].forEach((parcela, i) => {
        setFieldValue(
          `${taxas}[2].detalhamentoParcelasVisaMaster.taxa${i + 2}x`,
          values.taxaAntecipada[2].detalhamentoParcelasVisaMaster?.[
            `taxa${i + 2}x` as keyof ITaxasFlex
          ]
        );
        setFieldValue(
          `${taxas}[2].detalhamentoParcelasOutrasBandeiras.taxa${i + 2}x`,
          values.taxaAntecipada[2].detalhamentoParcelasOutrasBandeiras?.[
            `taxa${i + 2}x` as keyof ITaxasFlex
          ]
        );
      });
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <StyledCustomTableTitles>
        <StyledCustomCellTitle>Tipo de transação</StyledCustomCellTitle>
        <StyledCustomCellTitle>Visa/Master</StyledCustomCellTitle>
        <StyledCustomCellTitle>Outras bandeiras</StyledCustomCellTitle>
      </StyledCustomTableTitles>

      <StyledCustomFlexRowContent>
        <StyledCustomContent>
          <StyledFixTitle>{TIPO_TRANSICAO_BY_ENUM[1]}</StyledFixTitle>
        </StyledCustomContent>
        <StyledCustomContent>
          <InputField
            style={{ marginTop: 'auto' }}
            defaultValue={values[`${taxas}`][0].taxaVisaMaster}
            label="Porcentagem"
            isTableComponent
            onBlur={handleBlur}
            onChange={handleChange}
            value={values[`${taxas}`][0].taxaVisaMaster}
            hideLabel
            invalid={
              getError(`[${taxas}][0].taxaVisaMaster`) &&
              getTouch(`[${taxas}][0].taxaVisaMaster`)
            }
            errorMessage={
              getError(`[${taxas}][0].taxaVisaMaster`) as unknown as string
            }
            placeholder="0.0%"
            name={`${taxas}[0].taxaVisaMaster`}
          />
        </StyledCustomContent>
        <StyledCustomContent>
          <InputField
            defaultValue={values[`${taxas}`][0].taxaOutrasBandeiras}
            label="Porcentagem"
            isTableComponent
            onBlur={handleBlur}
            onChange={handleChange}
            hideLabel
            value={values[`${taxas}`][0].taxaOutrasBandeiras}
            invalid={
              getError(`[${taxas}][0].taxaOutrasBandeiras`) &&
              getTouch(`[${taxas}][0].taxaOutrasBandeiras`)
            }
            errorMessage={
              getError(`[${taxas}][0].taxaOutrasBandeiras`) as unknown as string
            }
            placeholder="0.0%"
            name={`${taxas}[0].taxaOutrasBandeiras`}
          />
        </StyledCustomContent>
      </StyledCustomFlexRowContent>

      <StyledCustomFlexRowContent>
        <StyledCustomContent>
          <StyledFixTitle>{TIPO_TRANSICAO_BY_ENUM[2]}</StyledFixTitle>
        </StyledCustomContent>
        <StyledCustomContent>
          <InputField
            defaultValue={values[`${taxas}`][1].taxaVisaMaster}
            label="Porcentagem"
            isTableComponent
            onBlur={handleBlur}
            onChange={handleChange}
            hideLabel
            value={values[`${taxas}`][1].taxaVisaMaster}
            invalid={
              getError(`[${taxas}][1].taxaVisaMaster`) &&
              getTouch(`[${taxas}][1].taxaVisaMaster`)
            }
            errorMessage={
              getError(`[${taxas}][1].taxaVisaMaster`) as unknown as string
            }
            placeholder="0.0%"
            name={`${taxas}[1].taxaVisaMaster`}
          />
        </StyledCustomContent>
        <StyledCustomContent>
          <InputField
            defaultValue={values[`${taxas}`][1].taxaOutrasBandeiras}
            label="Porcentagem"
            isTableComponent
            onBlur={handleBlur}
            onChange={handleChange}
            hideLabel
            value={values[`${taxas}`][1].taxaOutrasBandeiras}
            invalid={
              getError(`[${taxas}][1].taxaOutrasBandeiras`) &&
              getTouch(`[${taxas}][1].taxaOutrasBandeiras`)
            }
            errorMessage={
              getError(`[${taxas}][1].taxaOutrasBandeiras`) as unknown as string
            }
            placeholder="0.0%"
            name={`${taxas}[1].taxaOutrasBandeiras`}
          />
        </StyledCustomContent>
      </StyledCustomFlexRowContent>

      <StyledCustomFlexRowContent>
        <StyledCustomContent>
          <StyledFixTitle>{TIPO_TRANSICAO_BY_ENUM[3]}</StyledFixTitle>
        </StyledCustomContent>
        <StyledCustomCenterContent>
          <ToggleSwitch
            label="Parcelado flexível"
            checked={values[`${taxas}`][2].parceladoFlexivel ?? false}
            onChange={handleChange}
            id={`${taxas}[2].parceladoFlexivel`}
            name={`${taxas}[2].parceladoFlexivel`}
          />
        </StyledCustomCenterContent>
      </StyledCustomFlexRowContent>

      <StyledCustomFlexRowContent
        style={{
          display: values[taxas][2].parceladoFlexivel ? 'none' : ''
        }}
      >
        <StyledCustomContent>
          <StyledFixNotFlexTitle>Parcelado básico</StyledFixNotFlexTitle>
        </StyledCustomContent>
        <StyledCustomContent>
          <InputField
            defaultValue={values[taxas][2].taxaVisaMaster}
            label="Porcentagem"
            isTableComponent
            onBlur={handleBlur}
            onChange={handleChange}
            hideLabel
            value={values[taxas][2].taxaVisaMaster}
            invalid={
              getError(`${taxas}[2].taxaVisaMaster`) &&
              getTouch(`${taxas}[2].taxaVisaMaster`)
            }
            errorMessage={
              getError(`${taxas}[2].taxaVisaMaster`) as unknown as string
            }
            placeholder="0.0%"
            name={`${taxas}[2].taxaVisaMaster`}
          />
        </StyledCustomContent>
        <StyledCustomContent>
          <InputField
            defaultValue={values[taxas][2].taxaOutrasBandeiras}
            label="Porcentagem"
            isTableComponent
            onBlur={handleBlur}
            onChange={handleChange}
            hideLabel
            value={values[taxas][2].taxaOutrasBandeiras}
            invalid={
              getError(`${taxas}[2].taxaOutrasBandeiras`) &&
              getTouch(`${taxas}[2].taxaOutrasBandeiras`)
            }
            errorMessage={
              getError(`${taxas}[2].taxaOutrasBandeiras`) as unknown as string
            }
            placeholder="0.0%"
            name={`${taxas}[2].taxaOutrasBandeiras`}
          />
        </StyledCustomContent>
      </StyledCustomFlexRowContent>

      {[...Array(11)].map((parcela, i) => (
        <FlexibleInstallment
          taxas={taxas}
          parcela={i + 2}
          handleBlur={handleBlur}
          handleChange={handleChange}
          getError={getError}
          getTouch={getTouch}
        />
      ))}

      {(taxas.includes('taxaEconomica') || taxas.includes('taxaInstantanea')) &&
        (!values.closeAccordion[2] || !values.closeAccordion[3]) && (
          <StyledCopyValues onClick={copyValues}>
            <span className="material-symbols-outlined">file_copy</span> Copiar
            taxas de &quot;Receba em 01 dia útil&quot;
          </StyledCopyValues>
        )}
    </div>
  );
};

export default Details;
