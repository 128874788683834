import styled from '@emotion/styled';
import { Body } from '@sumup/circuit-ui';

export const StyledBoxPage = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1600px) {
    max-width: 1497px;
  }
`;
export const StyledTitleInfos = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`;
export const StyledFooter = styled.footer`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 5vh;
`;
export const StyledEntriesPerPage = styled(Body)`
  color: ${(p) => p.theme.colors.n500};
`;
