import { FC } from 'react';
import { StyledCustomHeaderRow, StyledCustomTitleCell } from './styles';

const AddDinamicOffersTableHeaders: FC = () => {
  return (
    <StyledCustomHeaderRow>
      <div />
      <StyledCustomTitleCell>
        Faturamento <br /> inicial
      </StyledCustomTitleCell>
      <StyledCustomTitleCell>
        Faturamento <br /> final
      </StyledCustomTitleCell>
      <StyledCustomTitleCell>
        Grupo de <br />
        MCCs
      </StyledCustomTitleCell>
      <StyledCustomTitleCell>
        Tipo de <br /> transação
      </StyledCustomTitleCell>
      <StyledCustomTitleCell>Nível</StyledCustomTitleCell>
      <StyledCustomTitleCell>Alçada</StyledCustomTitleCell>
      <StyledCustomTitleCell>Plano de taxas</StyledCustomTitleCell>
      <StyledCustomTitleCell>
        Valor da <br />
        máquina
      </StyledCustomTitleCell>
      <StyledCustomTitleCell>Remuneração</StyledCustomTitleCell>
      <StyledCustomTitleCell>Condições</StyledCustomTitleCell>
      <StyledCustomTitleCell />
    </StyledCustomHeaderRow>
  );
};

export default AddDinamicOffersTableHeaders;
