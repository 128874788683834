import { Input, useModal } from '@sumup/circuit-ui';
import { Close, Plus } from '@sumup/icons';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import TextEditor from '../../components/TextEditor/Editor';
import { CustomButton } from '../../components/_Atoms';
import { Breadcrumb, CustomDialog, Toast } from '../../components/_Molecules';
import {
  StyledBody,
  StyledHeadline
} from '../../components/_Molecules/CardContainer/styles';
import { IToast } from '../../components/_Molecules/Toast';
import { API } from '../../constants/api.constants';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';
import { IUser } from '../../interfaces/user-management.interfaces';
import { post } from '../../services/axios.service';
import {
  CustomHeadline,
  InputContainer,
  StyledActions,
  StyledContentBox,
  StyledDestinatarios,
  StyledForm,
  StyledInputDestinatarios,
  StyledSelectUserDialog
} from './styles';

const SendMessage: FC = () => {
  const [message, setMessage] = useState(() => {
    const draft = JSON.parse(localStorage.getItem('draft') || '{}');

    if (draft?.message) {
      return draft.message;
    }
    return '';
  });
  const [subject, setSubject] = useState('');
  const [destinatarios, setDestinatarios] = useState<IUser[]>([]);
  const { setModal, removeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [confirmationModalSubmit, setConfirmationModalSubmit] =
    useState<boolean>(false);
  const [confirmationModalNavigation, setConfirmationModalNavigation] =
    useState<boolean>(false);
  const { push } = useHistory();
  const [toastList, setToast] = useState<IToast[]>([]);

  const submit = async () => {
    setLoading(true);
    setConfirmationModalSubmit(false);

    const data = {
      mensagemHtml:
        '<body style="font-size:34px !important;">REPLACE</body>'.replace(
          'REPLACE',
          message
        ),
      assunto: subject,
      destinatarioIds: destinatarios.map((e) => e.id)
    };

    await post(API.COMUNICADO.ENVIAR, data)
      .then(() => {
        setMessage('');
        setDestinatarios([]);
        setSubject('');

        showToast({
          id: toastList.length + 1,
          title: 'Oba, mensagem enviada!',
          description: 'Acompanhe no histórico de mensagens',
          type: 'sucess'
        });

        removeDraft();
      })
      .catch(() => {
        showToast({
          id: toastList.length + 1,
          title: 'Algo deu errado!',
          description: 'Por favor, revise e tente novamente',
          type: 'error'
        });
      });

    setLoading(false);
  };

  const handleClick = () => {
    setModal({
      children: (
        <StyledSelectUserDialog
          removeModal={removeModal}
          setDestinatarios={setDestinatarios}
          destinatarios={destinatarios}
        />
      ),
      variant: 'immersive',
      preventClose: true
    });
  };

  const showToast = (toast: IToast) => {
    setToast([...toastList, toast]);
  };

  const saveDraft = () => {
    const draft: { message: string } = JSON.parse(
      localStorage.getItem('draft') || '{}'
    );

    if (!draft.message) {
      localStorage.setItem('draft', JSON.stringify({ message }));
      return;
    }

    if (message.length === 0) {
      removeDraft();
      return;
    }

    localStorage.setItem('draft', JSON.stringify({ message }));
  };

  const removeDraft = () => {
    localStorage.removeItem('draft');
  };

  useEffect(() => {
    setValidForm(message !== '' && destinatarios.length > 0);
  }, [message, subject, destinatarios]);

  useEffect(() => {
    saveDraft();
  }, [message]);

  return (
    <>
      <StyledContentBox>
        <Breadcrumb
          confirmationModal
          noMargin
          actionBeforeRedirect={() => removeDraft()}
          modalText={{
            title: 'Envio de mensagem incompleto',
            message:
              'Ao sair desta tela, um rascunho desta mensagem não será salvo e as informações serão perdidas.'
          }}
          currentRouteName="Enviar mensagem"
          previousRoutes={[
            {
              routeName: 'Menu principal',
              routePath: ROUTES.MAIN_MENU
            },
            {
              routeName: 'Central de mensagens',
              routePath: MENU_ROUTES.MESSAGES_CENTER
            }
          ]}
        />
        <StyledHeadline style={{ marginTop: 25 }} noMargin as="h1">
          Enviar mensagem
        </StyledHeadline>
        <StyledBody as="p">
          Visualize os detalhes e estatísticas de uma mensagem enviada para um
          único usuário ou para um grupo predefinido.
        </StyledBody>
      </StyledContentBox>
      <StyledForm>
        <CustomHeadline noMargin as="h1">
          Enviar mensagem
        </CustomHeadline>

        <InputContainer>
          <p>Destinatário: </p>

          <StyledInputDestinatarios>
            {destinatarios.length > 0 && (
              <StyledDestinatarios>
                {destinatarios
                  .sort((x, y) => {
                    const a = x?.nome?.toUpperCase();
                    const b = y?.nome?.toUpperCase();

                    if (a === b) return 0;

                    return a > b ? 1 : -1;
                  })
                  .map((e: IUser, index: number) => {
                    return (
                      <div>
                        <span>{e.nome}</span>
                        <Close
                          size="16"
                          style={{
                            transform: 'scale(0.65)',
                            cursor: 'pointer',
                            zIndex: 1
                          }}
                          onClick={() =>
                            setDestinatarios(
                              destinatarios.filter((e, i) => index !== i)
                            )
                          }
                        />
                      </div>
                    );
                  })}

                <span
                  style={{
                    backgroundColor: '#3063e9',
                    width: '24px',
                    height: 'auto',
                    padding: '4px',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderRadius: '4px'
                  }}
                  role="presentation"
                  onClick={handleClick}
                >
                  <Plus size="16" />
                </span>
              </StyledDestinatarios>
            )}
            {destinatarios.length === 0 && (
              <div
                role="presentation"
                style={{
                  color: 'rgb(133,133,133)',
                  cursor: 'text',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%'
                }}
                onClick={handleClick}
              >
                <span>Selecione um ou mais destinatários</span>
              </div>
            )}
          </StyledInputDestinatarios>
        </InputContainer>
        <InputContainer>
          <p>Assunto: </p>
          <Input
            name="assunto"
            label="assunto"
            placeholder="Escreva o assunto da mensagem"
            hideLabel
            autoComplete="off"
            type="input"
            onChange={(e) => {
              setSubject(e.target.value);
            }}
            value={subject}
          />
        </InputContainer>

        <TextEditor
          placeholder="Escreva aqui uma mensagem"
          setContent={setMessage}
          value={message}
        />

        <StyledActions>
          <CustomButton
            onClick={() => {
              if (message || subject || destinatarios.length > 0) {
                setConfirmationModalNavigation(true);
              } else {
                removeDraft();
                push(MENU_ROUTES.MESSAGES_CENTER);
              }
            }}
          >
            Voltar
          </CustomButton>
          <CustomButton
            variant="primary"
            disabled={!validForm}
            onClick={() => setConfirmationModalSubmit(true)}
            loadingLabel="Enviando..."
            isLoading={loading}
          >
            Enviar
          </CustomButton>
        </StyledActions>
        <Toast toastlist={toastList} position="top-right" setList={setToast} />
      </StyledForm>

      {confirmationModalSubmit && (
        <CustomDialog
          title="Deseja confirmar o envio da mensagem?"
          message="Ao clicar em Enviar, a mensagem será enviada para o grupo de usuários selecionado."
          handleClose={() => setConfirmationModalSubmit(false)}
          primaryButton={{
            buttonTitle: 'Enviar',
            onClick: () => submit()
          }}
          secondaryButton={{
            buttonTitle: 'Voltar',
            onClick: () => {
              setConfirmationModalSubmit(false);
            }
          }}
        />
      )}

      {confirmationModalNavigation && (
        <CustomDialog
          title="Envio de mensagem incompleto"
          message="Ao clicar em Voltar, esta mensagem não será salva e as informações serão perdidas."
          handleClose={() => setConfirmationModalNavigation(false)}
          primaryButton={{
            buttonTitle: 'Continuar nesta página',
            onClick: () => setConfirmationModalNavigation(false)
          }}
          secondaryButton={{
            buttonTitle: 'Voltar para página anterior',
            onClick: () => {
              removeDraft();
              push(MENU_ROUTES.MESSAGES_CENTER);
            }
          }}
        />
      )}
    </>
  );
};

export default SendMessage;
