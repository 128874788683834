import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { SearchInput, useModal } from '@sumup/circuit-ui';
import {
  ENUM_ACTION_ICON_TYPE,
  ListItem
} from '../../components/_Organisms/CustomEditListTable';

import { CustomEditListTable } from '../../components/_Organisms';

import {
  DEFAULT_OFFERS_FILTER_OPTION,
  OFFERS_FILTER_OPTIONS,
  OFFERS_STATUS_NAME
} from '../../constants/offers.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';

import { get } from '../../services/axios.service';
import {
  getFilterParamByStatus,
  StatusOptions
} from '../../services/filters.service';

import {
  Button,
  CountItensPerPage,
  CustomPagination,
  SelectField,
  SubTitle,
  Title
} from '../../components/_Atoms';
import { BottomFixedActions, Breadcrumb } from '../../components/_Molecules';
import { SearchBar } from '../../components/_Organisms/FilterUserEdit/SearchBar/styles';
import DoubleOffer from '../../components/DoubleOffer';
import { API } from '../../constants/api.constants';
import { STORAGE } from '../../constants/storage.constants';
import { usePagination } from '../../hooks/usePagination';
import {
  IDataResponseModel,
  IPagination,
  IResponseOfertas
} from '../../interfaces/requests.interfaces';

const StyledBoxPage = styled.div`
  max-width: 1280px;
  margin: 0 auto 160px auto;
  width: 100%;
  padding: 0 12px;
`;

interface IFilters {
  status: StatusOptions;
  search: string;
}

const EditOffersPage: React.FC = () => {
  const [offers, setOffers] = useState<ListItem[]>([]);
  const [filters, setFilters] = useState<IFilters>(() => {
    const { filters } = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_OFFERS) || '{}'
    );

    if (filters) {
      return filters;
    }

    return {
      status: DEFAULT_OFFERS_FILTER_OPTION,
      search: ''
    };
  });
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();
  const { colors } = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [resetPagination, setResetPagination] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const { totalItems, setTotalItems } = usePagination();
  const itemsPerPage = 6;
  const { setModal, removeModal } = useModal();
  const { active, disabled } = OFFERS_STATUS_NAME;

  const getOffers = async (page: number) => {
    setLoading(true);
    setNetworkError(false);

    const fetchUrl = `${API.OFERTAS.PAGINADO}${getFilterParamByStatus(
      filters.status
    )}&PageNumber=${page}&PageSize=${itemsPerPage}&busca=${filters.search}`;
    try {
      const { data } = await get<
        IDataResponseModel<IPagination<IResponseOfertas>>
      >(fetchUrl);
      if (data) {
        setOffers(
          data.results.map((offer) => ({
            id: offer.ofertaId,
            nome: offer.nome,
            ativo: offer.ativa,
            actions: [
              {
                url: THIRD_LEVEL_MENU_ROUTES.EDIT_OFFER,
                color: colors.p500,
                text: 'Editar',
                IconType: ENUM_ACTION_ICON_TYPE.EDIT
              },
              {
                onClick: () => handleDoubleOfferModal(offer.ofertaId),
                color: colors.p500,
                text: 'Duplicar',
                IconType: ENUM_ACTION_ICON_TYPE.ADD
              }
            ]
          }))
        );
        setTotalItems(data.totalItems);
      }
    } catch {
      setNetworkError(true);
      setOffers([]);
    } finally {
      setLoading(false);
    }
  };

  const [search, setSearch] = useState(() => {
    const { filters } = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_OFFERS) || '{}'
    );

    return filters?.search ?? '';
  });
  const handlePageChange = (page: number) => {
    getOffers(page + 1);
    setCurrentPage(page + 1);
  };

  const handleDoubleOfferModal = async (OfertaId: number) => {
    setModal({
      children: (
        <DoubleOffer
          OfertaId={OfertaId}
          removeModal={removeModal}
          getOffers={() => getOffers(1)}
        />
      ),
      variant: 'immersive',
      preventClose: true
    });
  };

  const loadData = async () => {
    const history = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_OFFERS) || '{}'
    );
    localStorage.removeItem(STORAGE.HISTORY_PAGINATION_OFFERS);

    setCurrentPage(history.currentPage ?? 1);
    setResetPagination(false);
    await getOffers(history.currentPage ?? 1);
    setResetPagination(true);
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  return (
    <StyledBoxPage>
      <Breadcrumb
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.HOME
          },
          {
            routeName: 'Gestão de campanhas',
            routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
          },
          {
            routeName: 'Ofertas',
            routePath: SUB_MENU_ROUTES.OFFERS
          }
        ]}
        currentRouteName="Editar oferta"
        noMargin
      />
      <Title style={{ marginTop: 32 }} fontSize="big">
        Lista de Ofertas
      </Title>
      <SubTitle>Gerencie, edite ou desative uma oferta cadastrada.</SubTitle>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <SelectField
          label="Status da oferta:"
          name="filters"
          options={OFFERS_FILTER_OPTIONS}
          value={filters.status}
          disabled={networkError}
          onChange={(e) =>
            setFilters({
              ...filters,
              status: e.target.value as StatusOptions
            })
          }
          style={{ marginTop: 25 }}
        />
        <SearchBar style={{ margin: '25px', maxWidth: 520 }}>
          <SearchInput
            label="Buscar:"
            onClear={() => {
              setFilters({ ...filters, search: '' });
              setSearch('');
            }}
            clearLabel="clearable"
            value={search}
            name="search"
            placeholder="Digite uma pesquisa"
            onChange={(e) => {
              setSearch(e.target.value);
              if (e.target.value === '') {
                setFilters({ ...filters, search: '' });
              }
            }}
            style={{ boxShadow: '0 0 0 1px #D8DDE1' }}
            disabled={networkError}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && search !== '') {
                e.preventDefault();
                setFilters({ ...filters, search });
              }
            }}
          />

          <Button
            style={{ marginTop: 5 }}
            value="Buscar"
            onClick={() => {
              if (search !== '') {
                setFilters({ ...filters, search });
              }
            }}
          />
        </SearchBar>
      </div>

      <div style={{ margin: 'auto', height: 450 }}>
        <CustomEditListTable
          clearSearchInput={() => {
            setSearch('');
            setFilters({ ...filters, search: '' });
          }}
          listData={offers}
          mainColumnName="Nome da oferta"
          statusName={{ active, disabled }}
          resourceUrl={API.OFERTAS.EDIT()}
          filters={filters}
          currentPage={currentPage}
          networkError={networkError}
          backRoute={() => push(SUB_MENU_ROUTES.OFFERS)}
          retry={() => loadData()}
          loading={loading}
        />
      </div>
      {totalItems > itemsPerPage && resetPagination && !networkError && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 24
          }}
        >
          <CountItensPerPage
            currentPage={currentPage}
            itensPerPage={itemsPerPage}
            totalItens={totalItems}
          />
          <CustomPagination
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            totalItems={totalItems}
            initialPage={currentPage}
          />
        </div>
      )}

      <BottomFixedActions>
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          value="Voltar"
          variant="secondary"
          onClick={() => push(SUB_MENU_ROUTES.OFFERS)}
        />
      </BottomFixedActions>
    </StyledBoxPage>
  );
};

export default EditOffersPage;
