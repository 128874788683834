import styled from '@emotion/styled';

export const ValidationMessageErrorStyle = styled.p<{
  type: 'warning' | 'error';
}>`
  padding: 8px 16px;
  color: ${({ type }) => (type === 'warning' ? '#ff9f00' : '#db4d4d')};

  &:before {
    content: ' ';
    position: absolute;
    background-color: ${({ type }) =>
      type === 'warning' ? '#ff9f00' : '#db4d4d'};
    width: 3px;
    height: 24px;
    margin-left: -12px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
