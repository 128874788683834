import { createContext, useContext, useState } from 'react';

import { IUserData } from '../components/_Organisms/Header';

import { decodeToken, isValidToken } from '../services/jwt.service';

import { getUser } from '../utils/auth-utils';

interface IAuthContext {
  auth: IUserData | null;
  signIn: (user: IUserData) => void;
  signOut: () => void;
  userType: () => string | undefined;
}

const authContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const authentication = auth();
  return (
    <authContext.Provider value={authentication}>
      {children}
    </authContext.Provider>
  );
};

export const useAuth = (): IAuthContext => {
  return useContext(authContext);
};

const getAuthInitialState = (): IUserData | null => {
  const userInfo = getUser();
  let userData: IUserData | null = null;

  if (!userInfo) return null;

  isValidToken(userInfo.token)
    ? (userData = {
        name: userInfo.name
      })
    : localStorage.clear();

  return userData;
};

export const auth = (): IAuthContext => {
  const [auth, setAuth] = useState<IUserData | null>(getAuthInitialState());

  const signIn = (user: IUserData) => {
    setAuth(user);
  };

  const signOut = () => {
    setAuth(null);
  };

  const userType = () => {
    const token = JSON.parse(localStorage.getItem('user_data') || '{}');
    const decodedToken = decodeToken(token.token);

    return decodedToken?.role?.toString();
  };

  return {
    auth,
    signIn,
    signOut,
    userType
  };
};
