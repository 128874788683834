/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { Checkbox, SelectOption } from '@sumup/circuit-ui';
import { Delete } from '@sumup/icons';
import { FieldArray, getIn, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  filtersDefault,
  opcoesTipoTransacao
} from '../../../constants/offers.constants';
import { IAddOfferForm, IOffer } from '../../../interfaces/offers.interfaces';
import { IResponseListToEdit } from '../../../interfaces/requests.interfaces';
import {
  CustomCurrencyInput,
  CustomSpinner,
  InputField,
  SelectField
} from '../../_Atoms';
import { AddNewLine, CustomDialog } from '../../_Molecules';
import NetworkError from '../NetWorkError';
import AddDinamicOffersTableHeaders from './AddDinamicOffersTableHeaders/index';
import AddDynamicOffersFilters from './AddDynamicOffersFilters';
import {
  ClearFiltersButton,
  StyledBoxToScroll,
  StyledCustomContentCell,
  StyledCustomTRContent,
  StyledGridContent,
  StyledIconBox
} from './styles';

interface IAddDynamicOffers {
  onExclusion: (offer: IOffer) => void;
  allPlans: IResponseListToEdit[];
  allGroups: IResponseListToEdit[];
  loading?: boolean;
  routeBack?: string;
  retry?: () => void;
  networkError?: boolean;
}
const AddDynamicOffers: React.FC<IAddDynamicOffers> = ({
  onExclusion,
  allGroups,
  allPlans,
  loading,
  retry,
  networkError,
  routeBack
}) => {
  const [exclusionDialog, setExclusionDialog] = useState<{
    shouldOpen: boolean;
    indexToRemove: number | null;
  }>({
    shouldOpen: false,
    indexToRemove: null
  });
  const [plansOptions, setPlansOptions] = useState<SelectOption[]>([]);
  const [mccsGroupsOptions, setMccsGroupsOptions] = useState<SelectOption[]>(
    []
  );
  const { values } = useFormikContext<IAddOfferForm>();
  const [filters, setFilters] = useState<IOffer>(filtersDefault);
  const [filteredIds, setFilteredIds] = useState<(number | undefined)[]>([]);

  const usedMccsList: string[] = values.ofertas.map(
    (oferta) => oferta.grupoMccs
  );
  const usedRatePlans: string[] = values.ofertas.map(
    (oferta) => oferta.planoTaxas
  );

  const getPlans = () => {
    if (allPlans.length) {
      const formatedPlansOptions: SelectOption[] = allPlans.flatMap((plan) =>
        plan.ativo || usedRatePlans.includes(String(plan.id))
          ? {
              label: plan.nome,
              value: String(plan.id),
              disabled: !plan.ativo
            }
          : []
      );
      setPlansOptions(formatedPlansOptions);
    } else {
      setPlansOptions([]);
    }
  };
  const getMccsGroups = async () => {
    if (allGroups.length) {
      const formatedMccsGroupsOptions: SelectOption[] = allGroups.flatMap(
        (group) =>
          group.ativo || usedMccsList.includes(String(group.id))
            ? {
                label: group.nome,
                value: String(group.id),
                disabled: !group.ativo
              }
            : []
      );

      setMccsGroupsOptions(formatedMccsGroupsOptions);
    } else {
      setMccsGroupsOptions([]);
    }
  };

  const getLabelByValue = (
    value: string | null,
    array: SelectOption[]
  ): string | null => {
    const item = array.find((obj) => obj.value === value);
    return item ? item.label : null;
  };

  const verifyFilters = (line: IOffer): boolean => {
    let lineValue;

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const filterValue = filters[key as keyof IOffer]?.toString() || '';
        lineValue = line[key as keyof IOffer]?.toString() || '';

        if (key === 'tipoTransacao') {
          lineValue = getLabelByValue(lineValue, opcoesTipoTransacao);
        }

        if (key === 'grupoMccs') {
          lineValue = getLabelByValue(lineValue, mccsGroupsOptions);
        }

        if (key === 'planoTaxas') {
          lineValue = getLabelByValue(lineValue, plansOptions);
        }

        if (
          key === 'faturamentoInicial' ||
          key === 'faturamentoFinal' ||
          key === 'nivel'
        ) {
          if (key !== 'nivel') {
            lineValue = lineValue?.replace(/[.,]/g, '');
          }

          if (
            lineValue &&
            filterValue &&
            filterValue !== '' &&
            filterValue !== '0' &&
            lineValue !== filterValue
          ) {
            return false;
          }
        }

        if (key !== 'alcada' || filters.alcada) {
          if (
            lineValue &&
            filterValue &&
            filterValue !== '' &&
            filterValue !== '0' &&
            !lineValue.toLowerCase().includes(filterValue.toLocaleLowerCase())
          ) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const incompleteLine = (line: IOffer) => {
    let lineValue;
    let defaultValue;
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        lineValue = line[key as keyof IOffer]?.toString() || '';
        defaultValue = filtersDefault[key as keyof IOffer]?.toString() || '';

        if (key !== 'alcada') {
          if (lineValue === defaultValue) {
            return true;
          }
        }
      }
    }

    return false;
  };

  useEffect(() => {
    getPlans();
    getMccsGroups();
  }, [allGroups, allPlans]);

  const filter = () => {
    setFilteredIds(
      values.ofertas
        .filter((item) => verifyFilters(item) || incompleteLine(item))
        .map((item) => item.id)
    );
  };

  useEffect(() => {
    filter();
  }, [filters]);

  return (
    <StyledGridContent>
      <StyledBoxToScroll>
        <ClearFiltersButton onClick={() => setFilters(filtersDefault)}>
          Limpar filtros
        </ClearFiltersButton>

        <AddDinamicOffersTableHeaders />

        {loading && <CustomSpinner />}

        {!loading && !networkError && (
          <FieldArray
            name="ofertas"
            render={({ form, push, remove }) => {
              const { handleChange, handleBlur, errors, touched } = form;
              const getError = (name: string) => getIn(errors, name);
              const getTouch = (name: string) => getIn(touched, name);
              const { ofertas } = values;

              return (
                <>
                  <AddDynamicOffersFilters
                    nivelOptions={values.ofertas.map(({ nivel }) => nivel)}
                    filters={filters}
                    setFilters={setFilters}
                  />
                  {!!ofertas.length &&
                    ofertas.map((oferta, index) => (
                      <div>
                        {filteredIds?.indexOf(oferta.id) !== -1 && (
                          <StyledCustomTRContent
                            key={`${index}_${ofertas.length}`}
                          >
                            <StyledCustomContentCell>
                              <p
                                style={{
                                  fontSize: 16,
                                  color: '#3063e9',
                                  fontWeight: 'bold'
                                }}
                              >
                                {index + 1}
                              </p>
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <CustomCurrencyInput
                                currency="BRL"
                                locale="pt-BR"
                                defaultValue={oferta.faturamentoInicial}
                                hideLabel
                                invalid={
                                  getError(
                                    `ofertas.${index}.faturamentoInicial`
                                  ) &&
                                  getTouch(
                                    `ofertas.${index}.faturamentoInicial`
                                  )
                                }
                                label="Faturamento inicial"
                                name={`ofertas.${index}.faturamentoInicial`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="00,00"
                              />
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <CustomCurrencyInput
                                currency="BRL"
                                locale="pt-BR"
                                defaultValue={oferta.faturamentoFinal}
                                hideLabel
                                invalid={
                                  getError(
                                    `ofertas.${index}.faturamentoFinal`
                                  ) &&
                                  getTouch(`ofertas.${index}.faturamentoFinal`)
                                }
                                label="Faturamento final"
                                name={`ofertas.${index}.faturamentoFinal`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="00,00"
                              />
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <SelectField
                                alignSide="left"
                                defaultValue={oferta.grupoMccs}
                                hideLabel
                                invalid={
                                  getError(`ofertas.${index}.grupoMccs`) &&
                                  getTouch(`ofertas.${index}.grupoMccs`)
                                }
                                isTableComponent
                                label="Grupo de MCCs"
                                name={`ofertas.${index}.grupoMccs`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                options={mccsGroupsOptions}
                                placeholder="Escolha"
                              />
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <SelectField
                                alignSide="left"
                                defaultValue={oferta.tipoTransacao}
                                hideLabel
                                invalid={
                                  getError(`ofertas.${index}.tipoTransacao`) &&
                                  getTouch(`ofertas.${index}.tipoTransacao`)
                                }
                                isTableComponent
                                label="Tipo de transação"
                                name={`ofertas.${index}.tipoTransacao`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                options={opcoesTipoTransacao}
                                placeholder="Escolha"
                              />
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <InputField
                                defaultValue={oferta.nivel}
                                hideLabel
                                type="text"
                                value={oferta.nivel}
                                pattern="[0-9]+([,\.][0-9]+)?"
                                maxLength={2}
                                invalid={
                                  getError(`ofertas.${index}.nivel`) &&
                                  getTouch(`ofertas.${index}.nivel`)
                                }
                                isTableComponent
                                label="Nível"
                                name={`ofertas.${index}.nivel`}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (
                                    (/^(?!0\d)\d{1,2}$/.test(e.target.value) ||
                                      e.target.value === '') &&
                                    e.target.value !== '0'
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                placeholder="--"
                              />
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <Checkbox
                                defaultChecked={oferta.alcada}
                                name={`ofertas.${index}.alcada`}
                                onChange={handleChange}
                                style={{ marginLeft: 10 }}
                              >
                                &nbsp;
                              </Checkbox>
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <SelectField
                                alignSide="center"
                                defaultValue={oferta.planoTaxas}
                                hideLabel
                                invalid={
                                  getError(`ofertas.${index}.planoTaxas`) &&
                                  getTouch(`ofertas.${index}.planoTaxas`)
                                }
                                isTableComponent
                                label="Plano de taxas"
                                name={`ofertas.${index}.planoTaxas`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                options={plansOptions}
                                placeholder="Escolha"
                              />
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <InputField
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                defaultValue={oferta.valorMaquina}
                                hideLabel
                                invalid={
                                  getError(`ofertas.${index}.valorMaquina`) &&
                                  getTouch(`ofertas.${index}.valorMaquina`)
                                }
                                isTableComponent
                                label="Valor da máquina"
                                name={`ofertas.${index}.valorMaquina`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Insira o valor"
                                className="textarea"
                                as="textarea"
                              />
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <InputField
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                defaultValue={oferta.remuneracao}
                                hideLabel
                                isTableComponent
                                invalid={
                                  getError(`ofertas.${index}.remuneracao`) &&
                                  getTouch(`ofertas.${index}.remuneracao`)
                                }
                                label="Remuneração"
                                name={`ofertas.${index}.remuneracao`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Insira o valor"
                                className="textarea"
                                as="textarea"
                              />
                            </StyledCustomContentCell>
                            <StyledCustomContentCell>
                              <InputField
                                defaultValue={oferta.condicoes}
                                hideLabel
                                invalid={
                                  getError(`ofertas.${index}.condicoes`) &&
                                  getTouch(`ofertas.${index}.condicoes`)
                                }
                                isTableComponent
                                label="Condições"
                                name={`ofertas.${index}.condicoes`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Insira aqui"
                                className="textarea"
                                as="textarea"
                              />
                            </StyledCustomContentCell>
                            <StyledCustomContentCell id="exclude-action">
                              <StyledIconBox
                                onClick={() => {
                                  setExclusionDialog({
                                    shouldOpen: true,
                                    indexToRemove: index
                                  });
                                }}
                              >
                                <Delete height="18" />
                              </StyledIconBox>
                            </StyledCustomContentCell>
                          </StyledCustomTRContent>
                        )}
                      </div>
                    ))}
                  <AddNewLine
                    add={() => {
                      push({
                        id: Array.from({ length: 20 }, () =>
                          Math.floor(Math.random() * 10)
                        ).join(''),
                        faturamentoInicial: '',
                        faturamentoFinal: '',
                        grupoMccs: '',
                        tipoTransacao: '',
                        nivel: 1,
                        alcada: false,
                        planoTaxas: '',
                        valorMaquina: '',
                        remuneracao: '',
                        condicoes: '',
                        removido: false
                      });
                      setFilters({ ...filters });
                    }}
                  />
                  {!!exclusionDialog.shouldOpen && (
                    <CustomDialog
                      title="A linha selecionada será excluída"
                      message="Ao clicar em Excluir, a linha selecionada será excluída e a ação não poderá ser desfeita. Tem certeza que deseja excluir a linha?"
                      handleClose={() =>
                        setExclusionDialog({
                          shouldOpen: false,
                          indexToRemove: null
                        })
                      }
                      primaryButton={{
                        buttonTitle: 'Excluir',
                        onClick: () => {
                          if (
                            Number(
                              values.ofertas[
                                exclusionDialog.indexToRemove as number
                              ].id
                            ) > 0
                          ) {
                            onExclusion({
                              ...values.ofertas[
                                exclusionDialog.indexToRemove as number
                              ],
                              removido: true
                            });
                          }

                          remove(exclusionDialog.indexToRemove as number);
                          setExclusionDialog({
                            shouldOpen: false,
                            indexToRemove: null
                          });
                        }
                      }}
                      secondaryButton={{
                        buttonTitle: 'Voltar',
                        onClick: () =>
                          setExclusionDialog({
                            shouldOpen: false,
                            indexToRemove: null
                          })
                      }}
                    />
                  )}
                </>
              );
            }}
          />
        )}

        {!!retry && routeBack && networkError && <NetworkError retry={retry} />}
      </StyledBoxToScroll>
    </StyledGridContent>
  );
};

export default AddDynamicOffers;
