import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CustomSpinner } from '../../components/_Atoms';
import AddUserPage from '../AddUser';

import { SUB_MENU_ROUTES } from '../../constants/routes.constants';

import {
  IDataResponseModel,
  IResponseGetUsuarioByID
} from '../../interfaces/requests.interfaces';
import { IAddUserForm } from '../../interfaces/user-management.interfaces';

import { API } from '../../constants/api.constants';
import { get } from '../../services/axios.service';

const EditUserByIdPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [user, setUser] = useState<IAddUserForm | null>(null);
  const { push } = useHistory();

  useEffect(() => {
    const getUserById = async () => {
      if (!id) return;

      try {
        const { data } = await get<IDataResponseModel<IResponseGetUsuarioByID>>(
          API.USUARIOS.GET_BY_ID(`${id}`)
        );
        if (data) {
          const formatedUser: IAddUserForm = {
            company: data.empresa,
            email: data.email,
            name: data.nome,
            partner_code: data.partnerCode !== null ? data.partnerCode : '',
            subgroup: data.subGrupo,
            user_type: Number(data.tipoDeUsuario),
            liderId: data.liderId ? data.liderId : 0,
            lideradosIds: data.lideradosIds
          };
          setUser(formatedUser);
        }
      } catch (error) {
        push(SUB_MENU_ROUTES.EDIT_USER);
      }
    };
    getUserById();
  }, [id]);
  return (
    <div>
      {user ? (
        <AddUserPage
          isEditable
          idToEdit={Number(id)}
          editInitialValues={user}
        />
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default EditUserByIdPage;
