import styled from '@emotion/styled';

export const DropContainer = styled.div<{
  isDragActive: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: 4px dashed #afd0fe;
  border-radius: 8px;
  height: 406px;
  align-items: center;
  text-align: center;

  ${(p) => p.isDragActive && 'border: 4px dashed #3063e9;'}
`;

export const FileSelected = styled.span`
  display: flex;
  margin: 64px 0;
  span {
    width: auto;
    border: 1px solid #d8dde1;
    padding: 6px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    p {
      display: inline-block;
      padding: 0 8px;
    }
  }
`;
