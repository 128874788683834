import { FC } from 'react';
import { PrimaryText, CountItens } from './styles';

interface IProps {
  currentPage: number;
  totalItens: number;
  itensPerPage: number;
}

const CountItensPerPage: FC<IProps> = ({
  currentPage,
  totalItens,
  itensPerPage
}) => {
  return (
    <CountItens>
      Exibindo{' '}
      <PrimaryText>
        {`${(
          itensPerPage * currentPage -
          itensPerPage +
          (totalItens === 0 ? 0 : 1)
        )
          .toString()
          .padStart(2, '0')} - 
          ${(itensPerPage * currentPage > totalItens
            ? totalItens
            : itensPerPage * currentPage
          )
            .toString()
            .padStart(2, '0')}`}{' '}
      </PrimaryText>
      de
      <PrimaryText>{` ${totalItens.toString().padStart(2, '0')}`} </PrimaryText>
    </CountItens>
  );
};

export default CountItensPerPage;
