import styled from '@emotion/styled';
import { CustomButton } from '../../_Atoms';

interface ITextAlign {
  textAlign?: 'left' | 'center' | 'right';
}

export const StyledBox = styled.div`
  max-width: 1164px;
  width: 100%;
  margin: 0 auto;
  padding: 34px 0;
  h2 {
    text-transform: uppercase;
    color: ${(p) => p.theme.colors.p500};
    margin-left: 75px;
    font-size: 16px;
  }
  & > p {
    font-size: 13px;
    margin: 15px 0 40px 75px;
    b {
      font-size: 13px;
      font-weight: 700;
    }
  }
  #condicoes-taxas {
    margin-top: 30px;
    background-color: transparent;
    color: ${(p) => p.theme.colors.p500};
  }
`;
export const StyledScrollBar = styled.div`
  @media (max-width: 1200px) {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 30px;
  }
`;
export const StyledBoxTitleRow = styled.div<{ gridColumns: string }>`
  background-color: #f9f9f9;
  height: 64px;
  border-bottom: 1px solid ${(p) => p.theme.colors.shadow};
  width: 1164px;
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) => props.gridColumns};
`;
export const StyledRow = styled.div<{ gridColumns: string }>`
  width: 1164px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.shadow};
  padding: 6px 0 6px 0;
  min-height: 64px;
  height: auto;
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) => props.gridColumns};
  #alcada-checkbox {
    margin-bottom: 15px;
  }
`;
export const StyledRowItem = styled.div<ITextAlign & { isDeactive?: boolean }>`
  font-weight: 400;
  font-size: 13px;
  text-align: ${(props) => props.textAlign ?? 'center'};
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    font-size: 13px;
    text-align: ${(props) => props.textAlign ?? 'center'};
  }
  & > div {
    width: 100%;
  }
`;
export const StyledTitleItem = styled.div<ITextAlign>`
  font-weight: 700;
  text-align: ${(props) => props.textAlign ?? 'center'};
  font-size: 14px;
  line-height: initial;
`;

export const NotFoundItem = styled.p`
  color: rgb(157, 167, 177);
`;

export const Button = styled(CustomButton)`
  width: 250px;
`;
