import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BoxProps } from '@material-ui/core';
import CustomDialog from '../CustomDialog';
import {
  StyledBackground,
  StyledBody,
  StyledBreadcrumbDivider,
  StyledCurrentPage,
  StyledLink
} from './styles';

export interface IPreviousRoute {
  routeName: string;
  routePath: string;
}
interface IBreadcrumb extends BoxProps {
  previousRoutes: IPreviousRoute[];
  currentRouteName: string;
  noMargin?: boolean;
  confirmationModal?: boolean;
  modalText?: {
    message: string;
    title: string;
  };
  actionBeforeRedirect?: () => void;
}
const Breadcrumb: React.FC<IBreadcrumb> = ({
  previousRoutes,
  currentRouteName,
  noMargin = false,
  confirmationModal = false,
  modalText,
  actionBeforeRedirect,
  ...props
}) => {
  const router = useHistory();
  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [routeClicked, setRouteClicked] = useState('');

  return (
    <StyledBackground {...props} noMargin={noMargin}>
      <StyledBody noMargin>
        {previousRoutes.length &&
          previousRoutes.map((route, index) => (
            <React.Fragment key={index}>
              <StyledLink
                key={index}
                onClick={() => {
                  if (!confirmationModal) {
                    router.push(route.routePath);
                  } else {
                    setRouteClicked(route.routePath);
                    setOpenBackDialog(true);
                  }
                }}
              >
                {route.routeName}
              </StyledLink>
              <StyledBreadcrumbDivider> &gt;</StyledBreadcrumbDivider>
            </React.Fragment>
          ))}
        <StyledCurrentPage as="span">{currentRouteName}</StyledCurrentPage>
      </StyledBody>
      {openBackDialog && (
        <CustomDialog
          title={modalText?.title ?? 'Atenção!'}
          message={
            modalText?.message ??
            'Ao sair desta tela, todas as novas informações não salvas serão perdidas.'
          }
          handleClose={() => setOpenBackDialog(false)}
          primaryButton={{
            buttonTitle: 'Sair desta tela',
            onClick: () => {
              if (actionBeforeRedirect) {
                actionBeforeRedirect();
              }

              router.push(routeClicked);
            }
          }}
          secondaryButton={{
            buttonTitle: 'Cancelar',
            onClick: () => setOpenBackDialog(false)
          }}
        />
      )}
    </StyledBackground>
  );
};

export default Breadcrumb;
