import styled from '@emotion/styled';

export const StyledReactPaginateBox = styled.div`
  ul {
    list-style-type: none;
    display: flex;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid ${(p) => p.theme.colors.n300};
      font-size: 13px;
      color: ${(p) => p.theme.colors.n700};
      a {
        padding: 7px 15px;
      }
    }
    .active {
      background-color: ${(p) => p.theme.colors.p500};
      border-color: ${(p) => p.theme.colors.p500};
      color: #fff;
    }
    .arrows-link {
      border-color: ${(p) => p.theme.colors.n200};
      svg {
        height: 13px;
        width: 13px;
      }
    }
  }
`;
