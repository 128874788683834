import { SearchInput } from '@sumup/circuit-ui';
import { FC, useEffect, useState } from 'react';
import { API } from '../../../constants/api.constants';
import {
  OPTIONS_MACHINE_ALLOCATE_STATUS,
  OPTIONS_TYPE_MACHINE_ALLOCATE
} from '../../../constants/machineAllocate.constants';
import { IFilters } from '../../../interfaces/AllocateMachines.interfaces';
import { IDataResponseModel } from '../../../interfaces/requests.interfaces';
import { get } from '../../../services/axios.service';
import { decodeToken } from '../../../services/jwt.service';
import { Button, CustomDropdown } from '../../_Atoms';
import { ClearFilters } from '../FilterUserEdit/styles';
import { Container, MultiSelectStyle, SearchContainer } from './style';

interface IProps {
  filters: IFilters;
  setFilters: (p: IFilters) => void;
  filter: () => void;
  teamOptions: { value: number; label: string }[];
  getTeamOptions: (p?: number) => void;
}
const AllocationFilters: FC<IProps> = ({
  filters,
  setFilters,
  filter,
  teamOptions,
  getTeamOptions
}) => {
  const [supervisorOptions, setSupervisorOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [loading, setLoading] = useState<{
    superVisorOptions: boolean;
    teamOptions: boolean;
  }>({
    superVisorOptions: false,
    teamOptions: false
  });

  const userInfo = decodeToken(
    JSON.parse(localStorage.getItem('user_data') || '{}').token
  );
  const getSupervisorOptions = async () => {
    setLoading({
      ...loading,
      superVisorOptions: true
    });

    try {
      const supervisorRes = await get<
        IDataResponseModel<{ id: number; descricao: string }[]>
      >(API.USUARIOS.LIDERES_DROPDOWN);

      if (supervisorRes.success) {
        setSupervisorOptions(
          supervisorRes.data.map((item) => ({
            value: item.id,
            label: item.descricao
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }

    setLoading({
      ...loading,
      superVisorOptions: false
    });
  };

  const clearFilters = () => {
    setFilters({
      supervisor: '',
      tipoMaquina: [1, 2, 3],
      atribuidas: 0,
      equipe: userInfo?.role === 'Admin' ? [] : [Number(userInfo?.nameid)],
      serialNumber: ''
    });
  };

  useEffect(() => {
    if (userInfo?.role === 'Admin') {
      getSupervisorOptions();
    }
  }, []);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex' }}>
          {userInfo?.role === 'Admin' && (
            <CustomDropdown
              label="Supervisor"
              placeholder="Selecione uma opção"
              filter
              options={supervisorOptions}
              value={filters.supervisor}
              onChangeValue={(e) => {
                setFilters({ ...filters, equipe: [], supervisor: e });
                getTeamOptions(Number(e));
              }}
              style={{ marginTop: 25, width: 264, marginRight: 20 }}
            />
          )}
          <MultiSelectStyle
            options={teamOptions}
            filter
            disabled={
              loading.teamOptions ||
              (userInfo?.role === 'Admin' && !filters.supervisor)
            }
            placeholder="Selecione uma opção"
            selected={filters.equipe}
            setSelected={(e) => setFilters({ ...filters, equipe: e })}
            label="Equipe"
          />
          <CustomDropdown
            label="Máquina atribuída"
            placeholder="Selecione uma opção"
            options={OPTIONS_MACHINE_ALLOCATE_STATUS}
            value={filters.atribuidas.toString()}
            onChangeValue={(e) =>
              setFilters({ ...filters, atribuidas: Number(e) })
            }
            style={{ marginTop: 25, minWidth: 264, marginRight: 20 }}
          />
          <MultiSelectStyle
            options={OPTIONS_TYPE_MACHINE_ALLOCATE}
            placeholder="Selecione uma opção"
            selected={filters.tipoMaquina}
            setSelected={(e) =>
              setFilters({ ...filters, tipoMaquina: e === null ? [] : e })
            }
            label="Tipo de máquina"
          />
        </div>

        <ClearFilters
          active
          onClick={clearFilters}
          style={{ right: 18, top: 25, position: 'relative' }}
        >
          Limpar filtros
        </ClearFilters>
      </div>
      <SearchContainer>
        <div style={{ marginTop: 20 }}>
          <SearchInput
            onClear={() => setFilters({ ...filters, serialNumber: '' })}
            clearLabel="clearable"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFilters({ ...filters, serialNumber: e.target.value });
            }}
            autoComplete="off"
            value={filters.serialNumber}
            name="search"
            placeholder="AAAAAAAAA"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                filter();
              }
            }}
            label="Serial Number"
            style={{ boxShadow: '0 0 0 1px #D8DDE1' }}
          />
        </div>
        <Button
          style={{ marginTop: 24 }}
          value="Filtrar"
          onClick={filter}
          loading={false}
          disabled={false}
        />
      </SearchContainer>
    </Container>
  );
};

export default AllocationFilters;
