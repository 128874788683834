import styled from '@emotion/styled';
import { Container } from '@material-ui/core';
import { Card } from '@sumup/circuit-ui';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CustomInlineMessage,
  InputField,
  SubTitle,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  CustomDialog
} from '../../components/_Molecules';
import { IPageTitles } from '../../components/_Molecules/CardContainer';
import { ITextsDialog } from '../../components/_Molecules/CustomDialog';
import { AddDynamicOffers } from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';
import {
  IAddOfferForm,
  IOffer,
  IPlanoOferta
} from '../../interfaces/offers.interfaces';
import {
  IDataResponseModel,
  IRequestCadastrarOferta,
  IResponseListToEdit
} from '../../interfaces/requests.interfaces';
import { get, post, put } from '../../services/axios.service';
import { formatOfferToPlanoOferta } from '../../services/offers.service';
import { equalsObject } from '../../utils/format.utils';
import { addOfferValidation } from '../../utils/yup-utils';

export const addOfferFormInitialValues: IAddOfferForm = {
  name: '',
  ofertas: [
    {
      id: 0,
      faturamentoInicial: '',
      faturamentoFinal: '',
      grupoMccs: '',
      tipoTransacao: '',
      nivel: 1,
      alcada: false,
      planoTaxas: '',
      valorMaquina: '',
      remuneracao: '',
      condicoes: '',
      removido: false
    }
  ]
};

const StyledCard = styled(Card)`
  margin-left: 25px;
  border: 0;
`;

const StyledContentBox = styled.div`
  width: 100%;
  margin: 0 auto 24px auto;
`;
interface IAddOfferPage {
  isEditable?: boolean;
  offerInitialValues?: IAddOfferForm;
  idOffer?: number;
}
const AddOfferPage: React.FC<IAddOfferPage> = ({
  idOffer,
  isEditable = false,
  offerInitialValues
}) => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [submitError, setSubmitError] = useState({
    isError: false,
    message: ''
  });
  const [exclusionList, setExclusionList] = useState<IPlanoOferta[]>([]);
  const [allGroups, setAllGroups] = useState<IResponseListToEdit[]>([]);
  const [allPlans, setAllPlans] = useState<IResponseListToEdit[]>([]);
  const [networkError, setNetworkError] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const { push } = useHistory();

  const handleCloseDialog = () => {
    isEditable
      ? push(THIRD_LEVEL_MENU_ROUTES.EDIT_OFFER)
      : push(SUB_MENU_ROUTES.OFFERS);
  };

  const handleSubmit = async (formData: IAddOfferForm) => {
    setLoading(true);
    setSubmitError({ message: '', isError: false });
    const plans: IPlanoOferta[] = formData.ofertas.map((oferta) => ({
      ...formatOfferToPlanoOferta(oferta)
    }));
    let formatedData: IRequestCadastrarOferta = {
      nome: formData.name,
      planosDaOferta: plans.map((item) => {
        return item?.id?.toString().length === 20
          ? { ...item, id: 0, nivel: Number(item.nivel) }
          : item;
      })
    };

    if (isEditable) {
      formatedData = {
        nome: formatedData.nome,
        planosDaOferta: formatedData.planosDaOferta.concat(exclusionList),
        id: idOffer as number
      };
    } else {
      formatedData.planosDaOferta = formatedData.planosDaOferta.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ id, ...item }) => item
      );
    }

    try {
      let res;

      isEditable
        ? (res = await put<IDataResponseModel<{ id: number }>>(
            API.OFERTAS.EDIT(`${idOffer}`),
            formatedData
          ))
        : (res = await post<IDataResponseModel<{ id: number }>>(
            API.OFERTAS.CRIAR,
            formatedData
          ));

      if (res.success) {
        setOpenDialog(true);
      }

      if (res.error) {
        setSubmitError({ isError: true, message: res.message });
      }
    } catch {
      setSubmitError({
        isError: true,
        message:
          'Houve um problema com a requisição. Por favor, tente novamente'
      });
    } finally {
      setLoading(false);
    }
  };

  const getErrorMessage = (): string => {
    if (
      submitError.message &&
      (submitError.message.includes('Nome de oferta já existente') ||
        submitError.message.includes('já existe'))
    )
      return submitError.message;

    return isEditable
      ? 'Houve um problema ao atualizar a oferta. Por favor, revise os dados e tente novamente.'
      : 'Houve um problema ao cadastrar a oferta. Por favor, revise os dados e tente novamente.';
  };

  const getSuccessModalTexts = (): ITextsDialog => {
    return isEditable
      ? {
          title: 'Alterações salvas',
          message:
            'Ao clicar em Salvar, as informações da oferta foram salvas com sucesso.'
        }
      : {
          title: 'Alterações salvas com sucesso',
          message:
            'Ao clicar em Salvar, as alterações realizadas no cadastro de um nova oferta foram salvas com sucesso.'
        };
  };

  const getBackModalTexts = (): ITextsDialog => {
    return isEditable
      ? {
          title: 'Você deseja voltar?',
          message:
            'Ao clicar em Voltar, a edição da oferta não será salva e as informações serão perdidas.',
          primaryButton: 'Continuar nesta página',
          secondaryButton: 'Voltar para página anterior'
        }
      : {
          title: 'Suas alterações não serão salvas',
          message:
            'Ao clicar em Voltar, as alterações realizadas no cadastro de uma nova oferta serão perdidas. Gostaria mesmo de voltar?',
          primaryButton: 'Continuar nesta página',
          secondaryButton: 'Voltar para página anterior'
        };
  };

  const getCardTitle = (): IPageTitles => {
    return isEditable
      ? {
          cardTitle: 'Editar oferta',
          cardSubtitle: 'Edite as informações da oferta abaixo.'
        }
      : {
          cardTitle: 'Nova oferta',
          cardSubtitle: 'Atribua um nome e cadastre uma nova oferta abaixo.'
        };
  };

  const getInitialValues = (): IAddOfferForm => {
    return offerInitialValues ?? addOfferFormInitialValues;
  };

  const handleExclusion = (offer: IOffer) => {
    const updatedArray = exclusionList.concat(formatOfferToPlanoOferta(offer));
    setExclusionList(updatedArray);
  };

  const getAllGroupsAndPlans = async () => {
    setNetworkError(false);
    setLoadingPage(true);
    try {
      const resPlans = await get<IDataResponseModel<IResponseListToEdit[]>>(
        API.PLANOS.LISTAR
      );
      const resGroups = await get<IDataResponseModel<IResponseListToEdit[]>>(
        API.GRUPOS_MCC.LISTAR
      );

      setAllPlans(resPlans.data);
      setAllGroups(resGroups.data);
    } catch (error) {
      setNetworkError(true);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getAllGroupsAndPlans();
  }, []);

  return (
    <Container style={{ marginBottom: 160 }}>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={(formValues) => handleSubmit(formValues)}
        validationSchema={addOfferValidation}
        validateOnMount
      >
        {({ handleChange, handleBlur, errors, touched, isValid, values }) => (
          <>
            <StyledContentBox>
              <Breadcrumb
                currentRouteName={isEditable ? 'Editar oferta' : 'Nova oferta'}
                previousRoutes={[
                  {
                    routeName: 'Menu Principal',
                    routePath: ROUTES.MAIN_MENU
                  },
                  {
                    routeName: 'Gestão de campanhas',
                    routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
                  },
                  {
                    routeName: 'Ofertas',
                    routePath: SUB_MENU_ROUTES.OFFERS
                  }
                ]}
                confirmationModal={!equalsObject(values, getInitialValues())}
                noMargin
              />
              <Title fontSize="big" style={{ marginTop: 32 }}>
                {getCardTitle().cardTitle}
              </Title>
              <SubTitle>{getCardTitle().cardSubtitle}</SubTitle>
            </StyledContentBox>
            <Form>
              <InputField
                defaultValue={getInitialValues().name}
                errorMessage={errors.name}
                label="Nome da oferta:"
                placeholder="Insira um nome"
                id="add-offer-name"
                invalid={!!(errors.name && touched.name)}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                disabled={networkError}
                style={{
                  maxWidth: 776,
                  width: '100%',
                  boxShadow: '0px 0px 0px 1px #d8dde1',
                  marginBottom: 16
                }}
              />
              <AddDynamicOffers
                allGroups={allGroups}
                allPlans={allPlans}
                onExclusion={handleExclusion}
                loading={loadingPage}
                networkError={networkError}
                retry={() => getAllGroupsAndPlans()}
                routeBack={
                  isEditable
                    ? THIRD_LEVEL_MENU_ROUTES.EDIT_OFFER
                    : SUB_MENU_ROUTES.OFFERS
                }
              />
              {submitError.isError && (
                <StyledCard>
                  <CustomInlineMessage
                    fontSize="12px"
                    marginTop="0px"
                    size="giga"
                    variant="danger"
                  >
                    {getErrorMessage()}
                  </CustomInlineMessage>
                </StyledCard>
              )}

              <BottomFixedActions>
                <Button
                  width="medium"
                  variant="secondary"
                  value="Voltar"
                  onClick={() =>
                    !equalsObject(values, getInitialValues())
                      ? setOpenBackDialog(true)
                      : handleCloseDialog()
                  }
                />
                <Button
                  width="medium"
                  disabled={!isValid}
                  loading={loading}
                  value="Salvar"
                  type="submit"
                />
              </BottomFixedActions>
            </Form>
          </>
        )}
      </Formik>

      {openDialog && (
        <CustomDialog
          title={getSuccessModalTexts().title}
          message={getSuccessModalTexts().message}
          handleClose={() => handleCloseDialog()}
          primaryButton={{
            buttonTitle: 'Ok',
            onClick: () => handleCloseDialog()
          }}
        />
      )}

      {openBackDialog && (
        <CustomDialog
          title={getBackModalTexts().title}
          message={getBackModalTexts().message}
          handleClose={() => setOpenBackDialog(false)}
          primaryButton={{
            buttonTitle: getBackModalTexts().primaryButton as string,
            onClick: () => setOpenBackDialog(false)
          }}
          secondaryButton={{
            buttonTitle: getBackModalTexts().secondaryButton as string,
            onClick: () => handleCloseDialog()
          }}
        />
      )}
    </Container>
  );
};

export default AddOfferPage;
