/* eslint-disable react/no-unknown-property */
import { useHistory } from 'react-router-dom';
import { ReactComponent as InfoSessionMessage } from '../../../assets/icons/info_session_message.svg';
import { RedirectButton, RedirectMessageStyle } from './style';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  pageInfo: string;
  message: string;
  route: string;
}
const RedirectMessage: React.FC<IProps> = ({
  pageInfo,
  message,
  route,
  ...props
}) => {
  const { push } = useHistory();

  return (
    <RedirectMessageStyle {...props}>
      <div>
        <InfoSessionMessage />

        <p>{message}</p>
      </div>

      <RedirectButton onClick={() => push(route)}>
        <p>{pageInfo}</p>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 16L14 12L10 8"
            stroke="#3063E9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </RedirectButton>
    </RedirectMessageStyle>
  );
};
export default RedirectMessage;
