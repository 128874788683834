import { FC, HTMLAttributes } from 'react';
import { Actions, Container } from './styles';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  align?: 'center' | 'left' | 'right';
}

const BottomFixedActions: FC<IProps> = ({
  align = 'center',
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      <Actions align={align}>{children}</Actions>
    </Container>
  );
};

export default BottomFixedActions;
