import styled from '@emotion/styled';
import { InlineMessage, InlineMessageProps } from '@sumup/circuit-ui';
import React from 'react';

interface ICustomInlineMessage extends InlineMessageProps {
  fontSize: string;
  marginTop: string;
}
const CustomInlineMessage: React.FC<ICustomInlineMessage> = ({
  fontSize,
  marginTop,
  children,
  ...rest
}) => {
  const StyledCustomInlineMessage = styled(InlineMessage)`
    font-size: ${fontSize};
    margin-top: ${marginTop};
  `;

  return (
    <StyledCustomInlineMessage {...rest}>{children}</StyledCustomInlineMessage>
  );
};

export default CustomInlineMessage;
