import styled from '@emotion/styled';
import { SelectOption } from '@sumup/circuit-ui';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { API } from '../../../constants/api.constants';
import {
  DEFAULT_RANGE_DATE,
  DEFAULT_VALUE_STATUS,
  statusPropostas
} from '../../../constants/kanban.constants';
import { ROUTES } from '../../../constants/routes.constants';
import { useAuth } from '../../../contexts/auth.context';
import {
  IActiveFilters,
  IResponseTeam,
  ITeamOptionsFormated,
  IUserTeam
} from '../../../interfaces/kanban.interface';
import { get } from '../../../services/axios.service';
import { handleDownloadXlsx } from '../../../services/download.service';
import {
  getAllIds,
  getAllKeys,
  rangeDateOptions,
  transformArrayToTreeSelectFormat
} from '../../../utils/kanban';
import {
  Button,
  CustomDropdown,
  DownloadButton,
  MultiSelect,
  TreeSelect
} from '../../_Atoms';
import { ITreeSelectValue } from '../../_Atoms/TreeSelect';
import { Breadcrumb } from '../../_Molecules';
import SelectDateRange from './selectDateRange';
import { Container, FiltersContainer, SubTitle, Title } from './styles';

interface IProps {
  onFilter: (
    status: number[],
    dateRange: { start: string; end: string },
    idsUsers: number[],
    empresa: string[],
    subGrupo: string[]
  ) => void;
  emptyBoard: boolean;
  loading: boolean;
  filtersToExportFile: IActiveFilters | null;
}

const CustomDropdownStyle = styled(CustomDropdown)`
  width: 263px;

  @media (max-width: 1500px) {
    min-width: 215px;
  }
`;

const KanbanFilters: FC<IProps> = ({
  onFilter,
  loading,
  emptyBoard,
  filtersToExportFile
}) => {
  const [selectedNodeKey, setSelectedNodeKey] = useState<ITreeSelectValue>({});
  const [teamOptions, setTeamOptions] = useState<ITeamOptionsFormated[]>([]);
  const [selectStatus, setSelectStatus] =
    useState<number[]>(DEFAULT_VALUE_STATUS);
  const [selectUsersIds, setSelectUsersIds] = useState<number[]>([]);
  const [error, setError] = useState(false);
  const [loadingExportation, setLoadingExportation] = useState(false);
  const { userType } = useAuth();
  const [date, setDate] = useState<{ start: string; end: string }>(
    rangeDateOptions(DEFAULT_RANGE_DATE)
  );
  const [initialLabelDate, setInitialLabelDate] = useState(1);
  const [companies, setCompanies] = useState<{
    allCompanies: SelectOption[];
    filteredCompanies: SelectOption[];
    selected: string;
  }>({
    allCompanies: [],
    filteredCompanies: [],
    selected: '0'
  });

  const [groups, setGroups] = useState<{
    allgroups: SelectOption[];
    filteredgroups: SelectOption[];
    selected: string;
  }>({ allgroups: [], filteredgroups: [], selected: '0' });

  const getTeamOptions = async () => {
    setError(false);
    try {
      await get<IResponseTeam>(API.VISITAS.EQUIPE).then((res) => {
        setTeamOptions(
          transformArrayToTreeSelectFormat(
            userType() !== 'Lider'
              ? [
                  {
                    id: 0,
                    nome: 'admin',
                    liderados: res.data,
                    permissao: 'Lider',
                    subGrupo: '',
                    empresa: ''
                  }
                ]
              : res.data
          )
        );
        onFilter(
          [1],
          rangeDateOptions(DEFAULT_RANGE_DATE),
          getAllIds(res.data),
          [],
          []
        );

        setCompanies({
          ...companies,
          allCompanies: getFormatedOptions(res.data, 'empresa'),
          filteredCompanies: getFormatedOptions(res.data, 'empresa')
        });

        setGroups({
          ...groups,
          allgroups: getFormatedOptions(res.data, 'subGrupo'),
          filteredgroups: getFormatedOptions(res.data, 'subGrupo')
        });
      });
    } catch (e) {
      setError(true);
    }
  };

  const getIdsSelected = (obj: any): number[] => {
    const ids: number[] = [];

    Object.keys(obj).forEach((prop) => {
      if (prop === 'key' && selectedNodeKey) {
        if (
          obj.key in selectedNodeKey &&
          selectedNodeKey[obj.key].checked &&
          obj.id !== 0
        )
          ids.push(obj.id);
      } else if (typeof obj[prop] === 'object') {
        ids.push(...getIdsSelected(obj[prop]));
      }
    });

    return ids;
  };

  const getFormatedOptions = (
    objetos: IUserTeam[],
    propriedade: 'subGrupo' | 'empresa'
  ) =>
    objetos.reduce((array: SelectOption[], objeto) => {
      const { [propriedade]: valorPropriedade, id, liderados } = objeto;
      array.push({ label: valorPropriedade, value: id });
      if (liderados && liderados.length > 0) {
        array.push(...getFormatedOptions(liderados, propriedade));
      }
      return array;
    }, []);

  const getCompanies = () => {
    if (companies.selected === '0')
      return companies.filteredCompanies
        .filter((item) => item.value !== '0')
        .map((item) => item.label);

    return companies.filteredCompanies
      .filter((item) => companies.selected === item.value)
      .map((item) => item.label);
  };

  const getGroups = () => {
    if (groups.selected === '0')
      return groups.filteredgroups
        .filter((item) => item.value !== '0')
        .map((item) => item.label);

    return groups.filteredgroups
      .filter((item) => groups.selected === item.value)
      .map((item) => item.label);
  };

  const selectAllTeam = async () => {
    const keys = getAllKeys(teamOptions);

    const initialValues: ITreeSelectValue = await keys.reduce(
      (acc: ITreeSelectValue, key: string) => {
        acc[key] = { checked: true, partialChecked: false };
        return acc;
      },
      {}
    );
    setSelectedNodeKey(initialValues);
  };

  const getExportationFile = async () => {
    setLoadingExportation(true);

    const fetchUrl = `${process.env.REACT_APP_BASE_URL}${API.VISITAS.EXPORTAR}`;
    axios
      .post(fetchUrl, filtersToExportFile, { responseType: 'blob' })
      .then((response) => handleDownloadXlsx(response.data, 'Visitas'))
      .catch((error) => {
        if (error) console.error(error);
      })
      .finally(() => setLoadingExportation(false));
  };

  useEffect(() => {
    getTeamOptions();
  }, []);

  useEffect(() => {
    selectAllTeam();
  }, [teamOptions]);

  useEffect(() => {
    setSelectUsersIds(getIdsSelected(teamOptions));

    setCompanies({
      ...companies,
      selected: '0',
      filteredCompanies: [
        ...[{ label: 'Todas as empresas', value: '0' }],
        ...companies.allCompanies
          .filter((item) =>
            getIdsSelected(teamOptions).includes(Number(item.value))
          )
          .filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.label === obj.label)
          )
          .sort((a, b) => a.label.localeCompare(b.label))
      ]
    });

    setGroups({
      ...groups,
      selected: '0',
      filteredgroups: [
        ...[{ label: 'Todos os subgrupos', value: '0' }],
        ...groups.allgroups
          .filter((item) =>
            getIdsSelected(teamOptions).includes(Number(item.value))
          )
          .filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.label === obj.label)
          )
          .sort((a, b) => a.label.localeCompare(b.label))
      ]
    });
  }, [selectedNodeKey]);

  return (
    <Container>
      <Breadcrumb
        style={{ maxWidth: 1400, padding: '0 0 16px 0', margin: 0 }}
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          }
        ]}
        currentRouteName="Gestão de visitas"
      />
      <Title>Gestão de visitas</Title>
      <SubTitle>
        Acompanhe suas visitas e propostas através do painel abaixo{' '}
      </SubTitle>
      <FiltersContainer>
        <MultiSelect
          options={statusPropostas}
          placeholder="Selecione uma opção"
          selected={selectStatus}
          setSelected={setSelectStatus}
          label="Classificação:"
        />
        <TreeSelect
          options={teamOptions}
          value={selectedNodeKey}
          onChange={({ value }) => setSelectedNodeKey(value)}
          setSelected={setSelectedNodeKey}
          selectionMode="checkbox"
          label="Equipe:"
          placeholder={
            error ? 'Falha ao carregar lista' : 'Selecione uma opção'
          }
          disabled={error}
        />
        <SelectDateRange
          style={{ width: '60%' }}
          setValue={setInitialLabelDate}
          value={initialLabelDate}
          setDate={setDate}
          date={date}
        />
        <CustomDropdownStyle
          style={{ marginRight: '16px' }}
          options={companies.filteredCompanies}
          label="Empresa do promotor"
          disabled={selectUsersIds.length === 0}
          value={companies.selected}
          onChangeValue={(e) => setCompanies({ ...companies, selected: e })}
        />
        <CustomDropdownStyle
          options={groups.filteredgroups}
          label="Subgrupo do promotor"
          disabled={selectUsersIds.length === 0}
          value={groups.selected}
          onChangeValue={(e) => {
            setGroups({ ...groups, selected: e });
          }}
        />
        <Button
          onClick={() =>
            onFilter(
              selectStatus,
              date,
              selectUsersIds,
              getCompanies(),
              getGroups()
            )
          }
          disabled={!date.end && !date.start}
          value="Filtrar"
          loading={loading}
          style={{ margin: '24px 0 0 0' }}
        />
      </FiltersContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DownloadButton
          style={{ marginTop: '-54px' }}
          submit={getExportationFile}
          label="Exportar"
          loading={loadingExportation}
          disabled={loading || emptyBoard}
        />
      </div>
    </Container>
  );
};

export default KanbanFilters;
