import { FC, memo } from 'react';

import {
  SVGAprovada,
  SVGDecisorNaoEncotrado,
  SVGInvalida,
  SVGNegociacaoDificil,
  SVGNenhumaClassificacao,
  SVGOutraClassificacao,
  SVGProcessando,
  SVGPropostaNaoAceita,
  SVGQuaseFechando,
  SVGReprovada
} from '../Icon';

interface IProps {
  status: string;
}

const getValues = (option: string) => {
  switch (option) {
    case 'EM_PROCESSAMENTO':
      return {
        icon: <SVGProcessando />,
        status: 'Em processamento',
        color: '#3063e9'
      };
    case 'APROVADA':
      return {
        icon: <SVGAprovada />,
        status: 'Aprovada',
        color: '#47995a'
      };
    case 'REPROVADA':
      return {
        icon: <SVGReprovada />,
        status: 'Reprovada',
        color: '#db4d4d'
      };
    case 'QUASE_FECHANDO':
      return {
        icon: <SVGQuaseFechando />,
        status: 'Quase fechando',
        color: '#f7941d'
      };
    case 'NEGOCIACAO_DIFICIL':
      return {
        icon: <SVGNegociacaoDificil />,
        status: 'Negociação difícil',
        color: '#3388ff'
      };
    case 'TOMADOR_NAO_ENCONTRADO':
      return {
        icon: <SVGDecisorNaoEncotrado />,
        status: 'Decisor não encontrado',
        color: '#323e49'
      };
    case 'OUTRA':
      return {
        icon: <SVGOutraClassificacao />,
        status: 'Outra classificação',
        color: '#9da7b1'
      };
    case 'NENHUMA_CLASSIFICACAO':
      return {
        icon: <SVGNenhumaClassificacao />,
        status: 'Nenhuma classificação',
        color: '#999999'
      };
    case 'NAO_ACEITA':
      return {
        icon: <SVGPropostaNaoAceita />,
        status: 'Proposta não aceita',
        color: '#db4d4d'
      };
    case 'INVALIDA':
      return {
        icon: <SVGInvalida />,
        status: 'Proposta inválida',
        color: '#d8a413'
      };
    default:
      return null;
  }
};

const StatusKanban: FC<IProps> = ({ status }) => {
  return (
    <p
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        marginTop: 4
      }}
    >
      {getValues(status)?.icon}
      <p
        style={{
          marginLeft: 5,
          fontWeight: 500,
          lineHeight: 1.2,
          color: getValues(status)?.color
        }}
      >
        {getValues(status)?.status}
      </p>
    </p>
  );
};

export default memo(StatusKanban);
