import styled from '@emotion/styled';
import { Accordion } from '@material-ui/core';

export const StyledSingleTitleBar = styled.header<{ fontSize?: string }>`
  height: 64px;
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: ${(props) => props.fontSize ?? '16px'};
`;

export const StyledFixTitle = styled.div`
  margin-bottom: 16px;
`;
export const StyledFixNotFlexTitle = styled.div`
  color: ${(p) => p.theme.colors.black};
`;

export const CustomAccordion = styled(Accordion)`
  box-shadow: none !important;
  border: none !important;

  .MuiAccordionSummary-content {
    margin-bottom: 0 !important;
  }
`;

export const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px 0 72px;
  border: 1px solid #d8dde1;
  border-radius: 8px 8px 0 0;
`;
export const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  padding: 28px 0px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
`;
