import { ThemeProvider } from '@emotion/react';
import { BaseStyles, ModalProvider } from '@sumup/circuit-ui';
import { light } from '@sumup/design-tokens';
import Routes from './routes/routes';
import './services/interceptor.service';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={light}>
      <ModalProvider>
        <BaseStyles />
        <Routes />
      </ModalProvider>
    </ThemeProvider>
  );
};

export default App;
