import { FC, useEffect, useState } from 'react';
import { API } from '../../../constants/api.constants';
import { ENUM_DATE_OPTIONS } from '../../../constants/kanban.constants';
import { CONST_STATUS_TAREFA } from '../../../constants/task-list.constants';
import { IDataResponseModel } from '../../../interfaces/requests.interfaces';
import { IFiltersTaskListFollowUp } from '../../../interfaces/taskList.interfaces';
import { get } from '../../../services/axios.service';
import { Button, SelectDateRangeUniqueField } from '../../_Atoms';
import { ClearFilters } from '../FilterUserEdit/styles';
import {
  MultiSelectContainer,
  MultiSelectStyle,
  TaskListFollowUpFiltersContainer
} from './styles';

interface IProps {
  filters: IFiltersTaskListFollowUp;
  setFilters: (p: IFiltersTaskListFollowUp) => void;
  getTableData: () => void;
  loading: boolean;
}

const TaskListFollowUpFilters: FC<IProps> = ({
  filters,
  setFilters,
  getTableData,
  loading
}) => {
  const [initialLabel, setInitialLabel] = useState({
    creation: -1,
    conclusion: -1,
    deadline: -1
  });

  const [group, setGroup] = useState<string[]>([]);
  const [taskType, setTaskType] = useState<{ value: number; label: string }[]>(
    []
  );
  const [promoters, setPromoters] = useState<
    { value: number; label: string }[]
  >([]);

  const getOptions = async () => {
    try {
      const [groupRes, promotersRes, taskTypeRes] = await Promise.allSettled([
        get<IDataResponseModel<string[]>>(API.USUARIOS.SUBGRUPOS),
        get<{ id: number; email: string }[]>(API.USUARIOS.LISTAR_PROMOTORES),
        get<IDataResponseModel<{ id: number; descricao: string }[]>>(
          API.TAREFAS.LISTAR_TIPO
        )
      ]);

      let groupResFormated: string[] = [];
      let taskTypeResFormated: number[] = [];
      let promotersResFormated: number[] = [];

      if (groupRes.status === 'fulfilled') {
        setGroup(groupRes.value.data);
        groupResFormated = groupRes.value.data;
      }

      if (promotersRes.status === 'fulfilled') {
        setPromoters(
          promotersRes.value.map((item) => ({
            value: item.id,
            label: item.email
          }))
        );
        promotersResFormated = promotersRes.value.map((item) => item.id);
      }

      if (taskTypeRes.status === 'fulfilled') {
        setTaskType(
          taskTypeRes.value.data.map((item) => ({
            value: item.id,
            label: item.descricao
          }))
        );

        taskTypeResFormated = taskTypeRes.value.data.map((item) => item.id);
      }

      setFilters({
        ...filters,
        subGrupo: groupResFormated,
        tipoTarefa: taskTypeResFormated,
        executivos: promotersResFormated
      });
    } catch (error) {
      console.error(error);
    }

    getTableData();
  };

  const clearFilters = () => {
    setFilters({
      status: [1, 2, 3, 4],
      subGrupo: group,
      executivos: promoters.map((item) => item.value),
      tipoTarefa: taskType.map((item) => item.value),
      dateCreation: { start: null, end: null },
      dateConclusion: { start: null, end: null },
      dateDeadline: { start: null, end: null }
    });

    setInitialLabel({
      creation: -1,
      conclusion: -1,
      deadline: -1
    });
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <div>
      <TaskListFollowUpFiltersContainer>
        <MultiSelectContainer>
          <MultiSelectStyle
            options={CONST_STATUS_TAREFA}
            placeholder="Selecione uma opção"
            selected={filters.status}
            setSelected={(e) => setFilters({ ...filters, status: e })}
            label="Status da tarefa"
          />
          <MultiSelectStyle
            options={group.map((item, index) => ({
              label: item || '(sem nome)',
              value: index
            }))}
            disabled={group.length === 0}
            placeholder={
              group.length === 0 && !loading
                ? 'Falha ao carregar dados'
                : 'Selecione uma opção'
            }
            selected={filters.subGrupo.map((item) => group.indexOf(item))}
            setSelected={(e) =>
              setFilters({
                ...filters,
                subGrupo: group?.filter((_, index) => e?.includes(index))
              })
            }
            label="Subgrupo"
          />
          <MultiSelectStyle
            options={promoters}
            placeholder={
              promoters.length === 0 && !loading
                ? 'Falha ao carregar dados'
                : 'Selecione uma opção'
            }
            disabled={promoters.length === 0}
            selected={filters.executivos}
            setSelected={(e) => setFilters({ ...filters, executivos: e })}
            label="Executivo"
          />
          <MultiSelectStyle
            options={taskType}
            placeholder={
              taskType.length === 0 && !loading
                ? 'Falha ao carregar dados'
                : 'Selecione uma opção'
            }
            disabled={taskType.length === 0}
            selected={filters.tipoTarefa}
            setSelected={(e) => setFilters({ ...filters, tipoTarefa: e })}
            label="Tipo de tarefa"
          />
        </MultiSelectContainer>
        <ClearFilters
          active
          onClick={clearFilters}
          style={{ right: 18, top: 10, position: 'relative' }}
        >
          Limpar filtros
        </ClearFilters>
      </TaskListFollowUpFiltersContainer>

      <div style={{ display: 'flex' }}>
        <div style={{ width: '361px' }}>
          <SelectDateRangeUniqueField
            date={filters.dateCreation}
            setDate={(e) => setFilters({ ...filters, dateCreation: e })}
            value={initialLabel.creation}
            setValue={(e) => setInitialLabel({ ...initialLabel, creation: e })}
            label="Data de criação da tarefa"
            options={[
              ENUM_DATE_OPTIONS['Exibir todo o período'],
              ENUM_DATE_OPTIONS.Hoje,
              ENUM_DATE_OPTIONS['Essa semana'],
              ENUM_DATE_OPTIONS['Ultimos 7 dias'],
              ENUM_DATE_OPTIONS['Últimos 15 Dias'],
              ENUM_DATE_OPTIONS['Últimos 30 dias'],
              ENUM_DATE_OPTIONS['Últimos 3 meses'],
              ENUM_DATE_OPTIONS['Últimos 6 meses']
            ]}
          />
        </div>

        <div style={{ width: '361px', margin: '0 20px' }}>
          <SelectDateRangeUniqueField
            date={filters.dateConclusion}
            setDate={(e) => setFilters({ ...filters, dateConclusion: e })}
            value={initialLabel.conclusion}
            setValue={(e) =>
              setInitialLabel({ ...initialLabel, conclusion: e })
            }
            label="Data de conclusão da tarefa"
            options={[
              ENUM_DATE_OPTIONS['Exibir todo o período'],
              ENUM_DATE_OPTIONS.Hoje,
              ENUM_DATE_OPTIONS['Essa semana'],
              ENUM_DATE_OPTIONS['Ultimos 7 dias'],
              ENUM_DATE_OPTIONS['Últimos 15 Dias'],
              ENUM_DATE_OPTIONS['Últimos 30 dias'],
              ENUM_DATE_OPTIONS['Últimos 3 meses'],
              ENUM_DATE_OPTIONS['Últimos 6 meses']
            ]}
          />
        </div>

        <div style={{ width: '361px' }}>
          <SelectDateRangeUniqueField
            date={filters.dateDeadline}
            setDate={(e) => setFilters({ ...filters, dateDeadline: e })}
            value={initialLabel.deadline}
            setValue={(e) => setInitialLabel({ ...initialLabel, deadline: e })}
            label="Prazo de conclusão da tarefa"
            options={[
              ENUM_DATE_OPTIONS['Exibir todo o período'],
              ENUM_DATE_OPTIONS.Hoje,
              ENUM_DATE_OPTIONS['Ultimos 7 dias'],
              ENUM_DATE_OPTIONS['Últimos 15 Dias'],
              ENUM_DATE_OPTIONS['Próximos 7 dias'],
              ENUM_DATE_OPTIONS['Próximos 15 dias']
            ]}
          />
        </div>

        <Button
          style={{ marginTop: 24 }}
          value="Filtrar"
          onClick={getTableData}
          loading={loading}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default TaskListFollowUpFilters;
