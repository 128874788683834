import React from 'react';
import { TopNavigation } from '@sumup/circuit-ui';
import ShortcutMenu from '../ShortcutMenu';
import { STORAGE } from '../../../constants/storage.constants';
import { useAuth } from '../../../contexts/auth.context';

const TopNavbar: React.FC = () => {
  const { auth, signOut, userType } = useAuth();

  const handleLogout = () => {
    signOut();
    localStorage.removeItem(STORAGE.USER_DATA);
  };

  return (
    <TopNavigation
      logo={<ShortcutMenu />}
      profileMenu={{
        label: 'Abrir menu',
        actions: [
          {
            children: 'Sair',
            onClick: handleLogout,
            destructive: true
          }
        ]
      }}
      user={{
        name: auth?.name as string,
        id: userType()
      }}
    />
  );
};

export default TopNavbar;
