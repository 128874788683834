import styled from '@emotion/styled';

export const TaskListFollowUpTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  background-color: #fafbfc;
  height: 72px;
  line-height: 20px;
`;

export const LineTable = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr) 0.5fr;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  height: 62px;
  line-height: 20px;
  border-bottom: 1px solid #eef0f2;
`;

const STATUS_COLORS = {
  1: '#DB4D4D',
  2: '#3063e9',
  3: '#47995A',
  4: '#D8A413'
};

export const TaskListStatus = styled.div<{ status: 1 | 2 | 3 | 4 }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid ${({ status }) => STATUS_COLORS[status]};
  height: 100%;
  color: ${({ status }) => STATUS_COLORS[status]};

  span {
    margin-left: 5px;
  }
`;

export const ResultValue = styled.div`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0 24px;
`;
