import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { API } from '../../../constants/api.constants';
import { SUB_MENU_ROUTES } from '../../../constants/routes.constants';
import { USER_TYPE_BY_ENUM } from '../../../constants/user.constants';
import { usePagination } from '../../../hooks/usePagination';
import { IDataResponseModel } from '../../../interfaces/requests.interfaces';
import { get } from '../../../services/axios.service';
import {
  CustomButton,
  CustomPagination,
  CustomSpinner,
  CustomTooltip
} from '../../_Atoms';
import {
  ContentBody,
  HeaderStats,
  HeaderTable,
  StyledFooter,
  TableRow,
  TableStats
} from './styles';

interface IProps {
  idMessage: string;
}

interface tableData {
  pageSize: number;
  destinatarios: destinatario[];
  totalEnvios: number;
  totalRecebidos: number;
  totalVisualizados: number;
}

interface destinatario {
  nome: string;
  email: string;
  empresa: string;
  tipoUsuario: 1 | 2;
  subgrupo: string | null;
  mensagemVisualizada: boolean;
}

const StatisticsMessageViewTable: FC<IProps> = ({ idMessage }) => {
  const [data, setData] = useState<tableData>();
  const { totalItems, setTotalItems } = usePagination();
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;
  const router = useHistory();
  const [error, setError] = useState(false);

  const handlePageChange = async (page: number) => {
    await loadMessages(page + 1);
  };

  const loadMessages = async (page: number) => {
    setIsLoading(true);
    try {
      const response = await get<IDataResponseModel<tableData>>(
        API.COMUNICADO.ESTATISTICAS(idMessage, page, itemsPerPage)
      );
      setData(response.data);
      setTotalItems(response.data.totalEnvios);
    } catch (error) {
      setError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadMessages(1);
  }, []);

  return data && totalItems && !error ? (
    <div>
      <HeaderStats>
        <h1>Estatísticas de visualização</h1>
        <span>
          <p>Usuários que visualizaram esta mensagem</p>
          <p>
            {data.totalVisualizados} de {data.totalRecebidos}
          </p>
        </span>
      </HeaderStats>
      <ContentBody>
        {isLoading && <CustomSpinner />}

        {!error && !isLoading && (
          <TableStats>
            <HeaderTable>
              <p>Nome</p>
              <p>Email</p>
              <p>Empresa</p>
              <p>Tipo de usuário</p>
              <p>Subgrupo</p>
              <p>Mensagem visualizada</p>
            </HeaderTable>
            {data.destinatarios.map((item: destinatario) => {
              return (
                <TableRow>
                  <CustomTooltip title={item.nome}>
                    <p>{item.nome}</p>
                  </CustomTooltip>

                  <CustomTooltip title={item.email}>
                    <p>{item.email}</p>
                  </CustomTooltip>
                  <CustomTooltip title={item.empresa}>
                    <p>{item.empresa}</p>
                  </CustomTooltip>
                  <p>{USER_TYPE_BY_ENUM[item.tipoUsuario]}</p>
                  <p>{item.subgrupo || <span>(Sem Grupo)</span>}</p>
                  <p>{item.mensagemVisualizada ? 'Sim' : 'Não'}</p>
                </TableRow>
              );
            })}
          </TableStats>
        )}
      </ContentBody>
      <StyledFooter>
        <CustomButton
          onClick={() => router.push(SUB_MENU_ROUTES.MESSAGE_HISTORY)}
          id="back-button"
          variant="secondary"
        >
          Voltar
        </CustomButton>
        <CustomPagination
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          totalItems={totalItems}
        />
        <p>{itemsPerPage} cadastros por página</p>
      </StyledFooter>
    </div>
  ) : (
    <p style={{ textAlign: 'center', padding: '48px 0' }}>
      Não foi possível carregar a lista de usuários
    </p>
  );
};

export default StatisticsMessageViewTable;
