import { FC } from 'react';
import { FiltersEditUser } from '../../../../interfaces/addUser.interface';
import { CustomSelectFields } from '../styles';

const CompanyFilter: FC<{
  filters: FiltersEditUser;
  setFilter: (p: FiltersEditUser) => void;
  companies: { options: Array<{ label: string; value: string }> };
}> = ({ filters, setFilter, companies }) => (
  <CustomSelectFields
    selected={!!filters.company}
    label="Empresa"
    onChange={(e) => {
      setFilter({ ...filters, company: e.target.value });
    }}
    value={filters.company}
    options={companies.options}
    name="company"
    placeholder="Selecione uma opção"
  />
);

export default CompanyFilter;
