import { SelectOption } from '@sumup/circuit-ui';
import { ENUM_TIPO_TAXA, ENUM_TIPO_TRANSACAO } from '../enums/rate-plans.enum';
import { IFormAddRatePlans } from '../interfaces/rate-plans.interfaces';

export const TIPO_TRANSICAO_BY_ENUM = {
  1: 'Débito',
  2: 'Crédito à vista',
  3: 'Crédito parcelado'
};

export const TIPO_TAXA_BY_ENUM = {
  0: 'Não informado',
  1: 'Débito',
  2: 'Receba em 01 dia útil',
  3: 'Receba em 30 dias por parcela',
  4: 'Receba na mesma hora'
};

export const FILTER_OPTIONS_PLANS: SelectOption[] = [
  { value: 'ativos', label: 'Planos ativos' },
  { value: 'inativos', label: 'Planos inativados' },
  { value: 'todos', label: 'Todos os planos' }
];

export const DEFAULT_FILTER_OPTION_PLANS = 'ativos';

export const PLANS_STATUS_NAME = {
  active: 'Plano ativo',
  disabled: 'Inativado'
};

export const CREATE_INITIAL_VALUES_PLANS: IFormAddRatePlans = {
  nome: '',
  url: '',
  closeAccordion: [false, false, false],
  taxaAntecipada: [
    {
      taxaId: 0,
      tipoTaxa: ENUM_TIPO_TAXA.PLANO_ANTECIPADO,
      tipoTransacao: ENUM_TIPO_TRANSACAO.DEBITO,
      taxaVisaMaster: '',
      taxaOutrasBandeiras: '',
      parceladoFlexivel: false
    },
    {
      taxaId: 0,
      tipoTaxa: ENUM_TIPO_TAXA.PLANO_ANTECIPADO,
      tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA,
      taxaVisaMaster: '',
      taxaOutrasBandeiras: '',
      parceladoFlexivel: false
    },
    {
      taxaId: 0,
      tipoTaxa: ENUM_TIPO_TAXA.PLANO_ANTECIPADO,
      tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO,
      taxaVisaMaster: '',
      taxaOutrasBandeiras: '',
      parceladoFlexivel: false,
      detalhamentoParcelasVisaMaster: {
        taxa2x: '',
        taxa3x: '',
        taxa4x: '',
        taxa5x: '',
        taxa6x: '',
        taxa7x: '',
        taxa8x: '',
        taxa9x: '',
        taxa10x: '',
        taxa11x: '',
        taxa12x: ''
      },
      detalhamentoParcelasOutrasBandeiras: {
        taxa2x: '',
        taxa3x: '',
        taxa4x: '',
        taxa5x: '',
        taxa6x: '',
        taxa7x: '',
        taxa8x: '',
        taxa9x: '',
        taxa10x: '',
        taxa11x: '',
        taxa12x: ''
      }
    }
  ],
  taxaEconomica: [
    {
      tipoTaxa: ENUM_TIPO_TAXA.PLANO_ECONOMICO,
      tipoTransacao: ENUM_TIPO_TRANSACAO.DEBITO,
      taxaVisaMaster: '',
      taxaOutrasBandeiras: '',
      parceladoFlexivel: false
    },
    {
      tipoTaxa: ENUM_TIPO_TAXA.PLANO_ECONOMICO,
      tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA,
      taxaVisaMaster: '',
      taxaOutrasBandeiras: '',
      parceladoFlexivel: false
    },
    {
      tipoTaxa: ENUM_TIPO_TAXA.PLANO_ECONOMICO,
      tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO,
      taxaVisaMaster: '',
      taxaOutrasBandeiras: '',
      parceladoFlexivel: false,
      detalhamentoParcelasVisaMaster: {
        taxa2x: '',
        taxa3x: '',
        taxa4x: '',
        taxa5x: '',
        taxa6x: '',
        taxa7x: '',
        taxa8x: '',
        taxa9x: '',
        taxa10x: '',
        taxa11x: '',
        taxa12x: ''
      },
      detalhamentoParcelasOutrasBandeiras: {
        taxa2x: '',
        taxa3x: '',
        taxa4x: '',
        taxa5x: '',
        taxa6x: '',
        taxa7x: '',
        taxa8x: '',
        taxa9x: '',
        taxa10x: '',
        taxa11x: '',
        taxa12x: ''
      }
    }
  ],
  taxaInstantanea: [
    {
      tipoTaxa: ENUM_TIPO_TAXA.PLANO_INSTANTANEO,
      tipoTransacao: ENUM_TIPO_TRANSACAO.DEBITO,
      taxaVisaMaster: '',
      taxaOutrasBandeiras: '',
      parceladoFlexivel: false
    },
    {
      tipoTaxa: ENUM_TIPO_TAXA.PLANO_INSTANTANEO,
      tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_A_VISTA,
      taxaVisaMaster: '',
      taxaOutrasBandeiras: '',
      parceladoFlexivel: false
    },
    {
      tipoTaxa: ENUM_TIPO_TAXA.PLANO_INSTANTANEO,
      tipoTransacao: ENUM_TIPO_TRANSACAO.CREDITO_PARCELADO,
      taxaVisaMaster: '',
      taxaOutrasBandeiras: '',
      parceladoFlexivel: false,
      detalhamentoParcelasVisaMaster: {
        taxa2x: '',
        taxa3x: '',
        taxa4x: '',
        taxa5x: '',
        taxa6x: '',
        taxa7x: '',
        taxa8x: '',
        taxa9x: '',
        taxa10x: '',
        taxa11x: '',
        taxa12x: ''
      },
      detalhamentoParcelasOutrasBandeiras: {
        taxa2x: '',
        taxa3x: '',
        taxa4x: '',
        taxa5x: '',
        taxa6x: '',
        taxa7x: '',
        taxa8x: '',
        taxa9x: '',
        taxa10x: '',
        taxa11x: '',
        taxa12x: ''
      }
    }
  ]
};
