import React, { memo } from 'react';
import styled from '@emotion/styled';
import { CurrencyInput, CurrencyInputProps } from '@sumup/circuit-ui';

const StyledCurrencyInput = styled(CurrencyInput)`
  font-size: 13px;
  text-align: center;
  padding-left: 35px;
  box-shadow: none;
  &:hover,
  &:focus {
    box-shadow: none;
  }
  &:read-only {
    background-color: #fff;
  }
`;
type ICustomCurrencyInput = CurrencyInputProps;
const CustomCurrencyInput: React.FC<ICustomCurrencyInput> = ({
  currency,
  label,
  ...props
}) => {
  return (
    <StyledCurrencyInput
      {...props}
      label={label}
      currency={currency}
      autoComplete="off"
    />
  );
};

export default memo(CustomCurrencyInput);
