import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ROUTES } from '../constants/routes.constants';
import { useAuth } from '../contexts/auth.context';

type PublicRouteProps = RouteProps;

const PublicRoute: React.FC<PublicRouteProps> = ({ children, ...rest }) => {
  const { auth } = useAuth();

  return auth ? (
    <Redirect to={ROUTES.MAIN_MENU} />
  ) : (
    <Route {...rest}>{children}</Route>
  );
};

export default PublicRoute;
