import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowLeft } from '@sumup/icons';
import { CustomButton, CustomTooltip } from '../../_Atoms';
import CustomDialog, { ITextsDialog } from '../CustomDialog';

import { ENUM_STATUS_PROPOSTA } from '../../../enums/alcada.enums';

import {
  MENU_ROUTES,
  SUB_MENU_ROUTES
} from '../../../constants/routes.constants';

import { API } from '../../../constants/api.constants';
import { patch } from '../../../services/axios.service';
import { StyledBackIcon, StyledButtonsGroup } from './styles';

interface IApproveModal extends ITextsDialog {
  shouldApprove: boolean;
}
interface IApproveAlcadaActions {
  status: ENUM_STATUS_PROPOSTA;
  idProposta: number;
}
const ApproveAlcadaActions: React.FC<IApproveAlcadaActions> = ({
  status,
  idProposta
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [textDialog, setTextDialog] = useState<IApproveModal>(
    {} as IApproveModal
  );
  const { push } = useHistory();

  const handleCloseDialog = () => {
    // TODO integrate with approve/reprove endpoint from API
    push(SUB_MENU_ROUTES.PROPOSALS);
  };

  const handlePatchProposalByApproval = async (shouldApprove: boolean) => {
    try {
      await patch(API.PROPOSTAS.EDITAR(`${idProposta}`), {
        status: shouldApprove
          ? ENUM_STATUS_PROPOSTA.APROVADA
          : ENUM_STATUS_PROPOSTA.REPROVADA
      });
      handleCloseDialog();
    } catch (error) {
      // TODO adicionar mensagem de feedback de erro
      handleCloseDialog();
    }
  };

  const handleOpenDialog = (isApproveDialog: boolean) => {
    isApproveDialog
      ? setTextDialog({
          title: 'A proposta será aprovada',
          message:
            'Ao clicar em Aprovar, a proposta exibida na tela anterior será aprovada. Gostaria mesmo de aprovar?',
          primaryButton: 'Aprovar',
          shouldApprove: true
        })
      : setTextDialog({
          title: 'A proposta será reprovada',
          message:
            'Ao clicar em Reprovar, a proposta exibida na tela anterior será reprovada. Gostaria mesmo de reprovar?',
          primaryButton: 'Reprovar',
          shouldApprove: false
        });
    setOpenDialog(true);
  };

  return (
    <>
      <StyledButtonsGroup>
        {status === ENUM_STATUS_PROPOSTA.EM_PROCESSAMENTO ? (
          <>
            <StyledBackIcon
              onClick={() => push(MENU_ROUTES.PROPOSALS_MANAGEMENT)}
            >
              <CustomTooltip title="Voltar">
                <div>
                  <ArrowLeft />
                </div>
              </CustomTooltip>
            </StyledBackIcon>
            <CustomButton
              onClick={() => handleOpenDialog(false)}
              variant="secondary"
              type="button"
              stretch
            >
              Reprovar
            </CustomButton>
            <CustomButton
              onClick={() => handleOpenDialog(true)}
              variant="primary"
              type="button"
              stretch
            >
              Aprovar
            </CustomButton>
          </>
        ) : (
          <CustomButton
            onClick={() => push(SUB_MENU_ROUTES.PROPOSALS)}
            variant="secondary"
            type="button"
            stretch
          >
            Voltar
          </CustomButton>
        )}
      </StyledButtonsGroup>

      {openDialog && (
        <CustomDialog
          title={textDialog.title}
          message={textDialog.message}
          handleClose={() => setOpenDialog(false)}
          primaryButton={{
            buttonTitle: textDialog.primaryButton as string,
            onClick: () =>
              handlePatchProposalByApproval(textDialog.shouldApprove)
          }}
          secondaryButton={{
            buttonTitle: 'Voltar',
            onClick: () => setOpenDialog(false)
          }}
        />
      )}
    </>
  );
};

export default ApproveAlcadaActions;
