import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import styled from '@emotion/styled';
import { Checkbox, Headline, SelectOption } from '@sumup/circuit-ui';
import {
  CustomButton,
  CustomInlineMessage,
  CustomSpinner,
  FormTip,
  InputField,
  SelectField,
  SelectSearchable
} from '../../components/_Atoms';
import {
  Breadcrumb,
  CardContainer,
  CustomDialog
} from '../../components/_Molecules';
import { ITextsDialog } from '../../components/_Molecules/CustomDialog';

import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES
} from '../../constants/routes.constants';
import {
  USER_TYPE_DEFAULT_VALUE,
  USER_TYPE_OPTIONS,
  USER_TYPE_OPTIONS_LEADER
} from '../../constants/user.constants';

import {
  IDataResponseModel,
  IRequestCadastroUsuario
} from '../../interfaces/requests.interfaces';
import { IAddUserForm } from '../../interfaces/user-management.interfaces';

import { addUserValidation } from '../../utils/yup-utils';

import { get, post, put } from '../../services/axios.service';

import GridAddUsers from '../../components/SelectUserDialog/GridAddUsers/index';
import { API } from '../../constants/api.constants';
import { useAuth } from '../../contexts/auth.context';
import { IAddUser, IAddUserPage } from '../../interfaces/addUser.interface';
import { equalsObject } from '../../utils/format.utils';
import { CustomCabecalho, StyledBoxPage, StyledButtonsGroup } from './styles';

const ADD_INITIAL_VALUES: IAddUserForm = {
  name: '',
  email: '',
  company: '',
  user_type: USER_TYPE_DEFAULT_VALUE,
  partner_code: '',
  subgroup: '',
  liderId: -2
};

const SelectFieldCustom = styled(SelectField)`
  option[value='0'] {
    font-weight: bold;
  }
`;

const AddUserPage: React.FC<IAddUserPage> = ({
  isEditable = false,
  idToEdit,
  editInitialValues
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingAllData, setLoadingAllData] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [hasError, setHasError] = useState({ isError: false, message: '' });
  const { push } = useHistory();
  const [users, setUsers] = useState<IAddUser[]>([]);
  const [usersChecked, setUsersChecked] = useState<IAddUser[]>([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersError, setUsersError] = useState(false);
  const [checkAllUsers, setCheckAllUsers] = useState(false);
  const [leaders, setLeaders] = useState<SelectOption[]>([]);
  const [groupsSuggestions, setGroupsSuggestions] = useState<string[]>([]);
  const { userType } = useAuth();

  const getInitialValues = (): IAddUserForm => {
    return editInitialValues || ADD_INITIAL_VALUES;
  };

  const getHeadlineText = (): string => {
    return isEditable ? 'Editar usuário' : 'Cadastrar novo usuário';
  };

  const getErrorMessage = (): string => {
    return isEditable
      ? 'Houve um problema ao editar o usuário, tente novamente.'
      : 'Houve um problema ao cadastrar um usuário, tente novamente.';
  };

  const getSuccessModalTexts = (): ITextsDialog => {
    return isEditable
      ? {
          title: 'Alterações salvas',
          message:
            'Ao clicar em Salvar, o cadastro deste usuário foi incluso no sistema e as informações foram salvas com sucesso.'
        }
      : {
          title: 'Usuário cadastrado com sucesso',
          message:
            'A senha será gerada automaticamente e enviada para o e-mail do usuário.'
        };
  };

  const getBackModalTexts = (): ITextsDialog => {
    return isEditable
      ? {
          title: 'Você deseja voltar?',
          message:
            'Ao clicar em Voltar, a edição de cadastro deste usuário não será salva e as informações serão perdidas.',
          primaryButton: 'Voltar',
          secondaryButton: 'Cancelar'
        }
      : {
          title: 'Cadastro de usuário incompleto',
          message:
            'As informações de cadastro não foram totalmente preenchidas e não serão salvas. Deseja sair mesmo assim?',
          primaryButton: 'Sair',
          secondaryButton: 'Voltar'
        };
  };

  const submitForm = async (formData: IAddUserForm) => {
    setLoading(true);
    setHasError({ isError: false, message: '' });

    const bodyRequest: IRequestCadastroUsuario = {
      nome: formData.name.trim(),
      email: formData.email.trim(),
      empresa: formData.company.trim(),
      partnerCode:
        Number(formData.user_type) !== 2 && Number(formData.user_type) !== 3
          ? null
          : formData.partner_code.trim(),
      subGrupo: formData.subgroup.trim(),
      tipoDeUsuario: Number(formData.user_type),
      liderId: Number(formData.liderId) <= 0 ? null : Number(formData.liderId),
      lideradosIds:
        Number(formData.user_type) === 3
          ? usersChecked.map((item) => item.id)
          : []
    };

    try {
      isEditable
        ? await put(API.USUARIOS.EDITAR(`${idToEdit}`), bodyRequest)
        : await post(API.USUARIOS.CRIAR, bodyRequest);
      setOpenDialog(true);
      setUsersChecked([]);
    } catch (error) {
      const message = `${error}`
        .split(':')
        .filter(
          (_, index) => index > (`${error}`.split(':').length > 2 ? 1 : 0)
        )
        .toString();

      setHasError({
        isError: true,
        message: `Erro: ${message}`
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    isEditable
      ? push(SUB_MENU_ROUTES.EDIT_USER)
      : push(MENU_ROUTES.USER_MANAGEMENT);
  };

  const handleCheckbox = (user: IAddUser, checked: boolean) => {
    const checkedList: Array<IAddUser> = checked
      ? [...usersChecked, user]
      : usersChecked.filter((item) => item.id !== user.id);

    setUsersChecked(checkedList);
    setCheckAllUsers(
      users.every((user) =>
        checkedList.some((checked) => checked.id === user.id)
      )
    );
  };

  const getData = async () => {
    setUsersLoading(true);
    setUsersError(false);
    setLoadingAllData(true);

    try {
      const { data } = await get<IDataResponseModel<string[]>>(
        API.USUARIOS.TODOS_SUBGRUPOS
      );
      setGroupsSuggestions(data);
    } catch (error) {
      setGroupsSuggestions([]);
    }

    try {
      const res = await get<IDataResponseModel<IAddUser[]>>(
        API.USUARIOS.LISTAR_MEMBRO_TIME
      );

      if (res.success) handleUserFetch(res.data);

      if (res.error) setUsersError(true);
    } catch {
      setUsersError(true);
    } finally {
      setUsersLoading(false);
    }

    try {
      const res = await get<IDataResponseModel<{ id: number; nome: string }[]>>(
        API.USUARIOS.LISTAR_LIDERES
      );

      if (res.success) handleLeadersFetch(res.data);

      if (res.error) setLeaders([{ label: res.message, value: -1 }]);
    } catch {
      setLeaders([{ label: 'Não foi possível carregar a lista', value: -1 }]);
    }
    setLoadingAllData(false);
  };

  const handleUserFetch = (users: IAddUser[]) => {
    let data: IAddUser[] = users;

    if (isEditable) {
      data = data.filter((user) => user.id !== idToEdit);
      setUsersChecked(data.filter((user) => user.liderId === idToEdit));
    }

    setUsers(data);
  };

  const handleLeadersFetch = (leaders: { id: number; nome: string }[]) => {
    const options: SelectOption[] = [];

    if (!isEditable) {
      options.push({
        label: 'Selecione uma liderança',
        value: -2
      });
    }

    if (isEditable && editInitialValues?.liderId !== 0) {
      const editLeader = leaders.find(
        (leader) => leader.id === editInitialValues?.liderId
      );

      if (editLeader) {
        options.push({
          label: editLeader.nome,
          value: Number(editInitialValues?.liderId)
        });
      }
    }

    options.push({
      label: 'Nenhuma liderança',
      value: 0
    });

    const leadersOptions = leaders
      .map((leader) => {
        return { label: leader.nome, value: leader.id };
      })
      .filter(
        (leader) =>
          !isEditable || leader.value !== Number(editInitialValues?.liderId)
      );

    setLeaders(options.concat(leadersOptions));

    if (leaders.length === 0) {
      setLeaders(options);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return loadingAllData ? (
    <CustomSpinner />
  ) : (
    <StyledBoxPage>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={addUserValidation}
        validateOnMount
        onSubmit={submitForm}
        validateOnChange
      >
        {({
          handleChange,
          handleBlur,
          isValid,
          errors,
          touched,
          values,
          setFieldValue
        }) => (
          <>
            <div style={{ maxWidth: 704, margin: 'auto' }}>
              <Breadcrumb
                confirmationModal={!equalsObject(values, getInitialValues())}
                noMargin
                currentRouteName={
                  isEditable ? 'Editar usuário' : 'Cadastrar novo usuário'
                }
                previousRoutes={[
                  {
                    routeName: 'Menu principal',
                    routePath: ROUTES.MAIN_MENU
                  },
                  {
                    routeName: 'Gestão de usuários',
                    routePath: MENU_ROUTES.USER_MANAGEMENT
                  }
                ]}
              />
              <Headline style={{ marginTop: 25 }} as="h2" size="four">
                {getHeadlineText()}
              </Headline>
              <CardContainer
                title="Dados do usuário"
                subtitle="Esses dados são necessários para cadastrar um novo usuário na base de
    promotores e/ou administradores da SumUp."
              >
                <Form>
                  <InputField
                    errorMessage={errors.name}
                    defaultValue={getInitialValues().name}
                    id="add-user-name"
                    invalid={!!(errors.name && touched.name)}
                    label="Nome:"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Insira o nome do usuário a ser cadastrado"
                    type="text"
                  />
                  <InputField
                    errorMessage={errors.email}
                    defaultValue={getInitialValues().email}
                    id="add-user-email"
                    invalid={!!(errors.email && touched.email)}
                    label="E-mail:"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="seuemail@exemplo.com.br"
                    type="email"
                  />
                  <InputField
                    errorMessage={errors.company}
                    defaultValue={getInitialValues().company}
                    id="add-user-company"
                    invalid={!!(errors.company && touched.company)}
                    label="Empresa:"
                    name="company"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Insira o nome da empresa aqui"
                    type="text"
                  />
                  <SelectField
                    errorMessage={errors.user_type}
                    defaultValue={getInitialValues().user_type}
                    id="add-user-user_type"
                    invalid={!!errors.user_type}
                    label="Tipo de usuário:"
                    options={
                      userType() === 'Lider'
                        ? USER_TYPE_OPTIONS_LEADER
                        : USER_TYPE_OPTIONS
                    }
                    name="user_type"
                    onChange={handleChange}
                  />
                  {(Number(values.user_type) === 2 ||
                    Number(values.user_type) === 3) && (
                    <>
                      <SelectFieldCustom
                        errorMessage={errors.liderId}
                        defaultValue="Selecione um lider"
                        id="liderId"
                        invalid={!!errors.liderId && !!touched.liderId}
                        label="Liderança de vendas:"
                        options={leaders}
                        name="liderId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={leaders.some((i) => i.value === -1)}
                      />
                      <InputField
                        errorMessage={errors.partner_code}
                        defaultValue={getInitialValues().partner_code}
                        id="partner_code"
                        invalid={
                          !!(errors.partner_code && touched.partner_code)
                        }
                        label="Partner code:"
                        name="partner_code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Insira o partner code referente ao promotor"
                        type="text"
                        value={values.partner_code}
                      />
                    </>
                  )}
                  <SelectSearchable
                    name="subgroup"
                    errorMessage={errors.subgroup}
                    invalid={!!(errors.subgroup && touched.subgroup)}
                    placeholder="Insira um subgrupo"
                    initialValue={getInitialValues().subgroup}
                    setFieldValue={setFieldValue}
                    label="Subgrupo:"
                    onBlur={handleBlur}
                    options={groupsSuggestions}
                  />
                  {hasError.isError && (
                    <CustomInlineMessage
                      fontSize="12px"
                      marginTop="0px"
                      size="giga"
                      variant="danger"
                    >
                      {hasError.message ?? getErrorMessage()}
                    </CustomInlineMessage>
                  )}
                </Form>
              </CardContainer>
            </div>
            {Number(values.user_type) === 3 && (
              <div style={{ width: 1200, margin: '5vh auto' }}>
                <p style={{ width: 800, margin: 'auto' }}>
                  Selecione os membros do time dessa liderança:
                </p>
                <CustomCabecalho>
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Checkbox
                      onChange={(el: { target: { checked: boolean } }) => {
                        setCheckAllUsers(el.target.checked);

                        if (el.target.checked) {
                          setUsersChecked(
                            users.filter((user) =>
                              !isEditable
                                ? !user.nomeLider
                                : !user.nomeLider || idToEdit === user.liderId
                            )
                          );
                        } else {
                          setUsersChecked([]);
                        }
                      }}
                      checked={
                        !users.every((item) => item.nomeLider)
                          ? checkAllUsers
                          : false
                      }
                      defaultChecked={false}
                      style={{ marginTop: -15 }}
                    />
                  </div>
                  <p>Tipo de usuário</p>
                  <p>Nome</p>
                  <p>Empresa</p>
                  <p>Subgrupo</p>
                  <p>Liderança de Vendas</p>
                </CustomCabecalho>
                <GridAddUsers
                  users={users}
                  loadingGetUsers={usersLoading}
                  error={usersError}
                  usersChecked={usersChecked}
                  handleCheckbox={handleCheckbox}
                  preSelectbyIdLeader={idToEdit}
                />
                <FormTip>
                  <span
                    style={{
                      fontSize: '0.85em'
                    }}
                  >
                    <b>Atenção:</b> Você selecionou <b>{usersChecked.length}</b>{' '}
                    membros para o seu time. Caso a seleção esteja correta,
                    clique em <b>&quot;Salvar&quot;</b>.
                  </span>
                </FormTip>
              </div>
            )}
            <StyledButtonsGroup>
              <CustomButton
                variant="secondary"
                type="button"
                stretch
                onClick={() => {
                  if (!equalsObject(values, getInitialValues())) {
                    setOpenBackDialog(true);
                  } else {
                    handleCloseDialog();
                  }
                }}
              >
                Voltar
              </CustomButton>
              <CustomButton
                disabled={!isValid}
                onClick={() => submitForm(values)}
                isLoading={loading}
                loadingLabel="Carregando..."
                variant="primary"
                type="submit"
                stretch
              >
                Salvar
              </CustomButton>
            </StyledButtonsGroup>
          </>
        )}
      </Formik>

      {openDialog && (
        <CustomDialog
          title={getSuccessModalTexts().title}
          message={getSuccessModalTexts().message}
          handleClose={() => handleCloseDialog()}
          primaryButton={{
            buttonTitle: 'Ok',
            onClick: () => handleCloseDialog()
          }}
        />
      )}

      {openBackDialog && (
        <CustomDialog
          title={getBackModalTexts().title}
          message={getBackModalTexts().message}
          handleClose={() => setOpenBackDialog(false)}
          primaryButton={{
            buttonTitle: getBackModalTexts().primaryButton as string,
            onClick: () => handleCloseDialog()
          }}
          secondaryButton={{
            buttonTitle: getBackModalTexts()?.secondaryButton as string,
            onClick: () => setOpenBackDialog(false)
          }}
        />
      )}
    </StyledBoxPage>
  );
};

export default AddUserPage;
