import { Headline } from '@sumup/circuit-ui';
import { StyledWelcomeMessage } from './styles';

interface IWelcomeMessageProps {
  name: string;
}

const WelcomeMessage: React.FC<IWelcomeMessageProps> = ({ name }) => {
  return (
    <StyledWelcomeMessage>
      <img src="/images/sumup_brand_logo.svg" alt="Sumup" />
      <Headline noMargin size="four" as="h1">
        {`Olá, ${name}`}
      </Headline>
    </StyledWelcomeMessage>
  );
};

export default WelcomeMessage;
