import React from 'react';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Checkbox } from '@sumup/circuit-ui';
import { IFormAddRatePlans } from '../../../interfaces/rate-plans.interfaces';
import Details from './Details';
import {
  AccordionTitle,
  CheckBoxContainer,
  CustomAccordion,
  Title
} from './styles';

const AddPlansTable: React.FC = () => {
  const { values } = useFormikContext<IFormAddRatePlans>();
  const taxas: ['taxaAntecipada', 'taxaEconomica', 'taxaInstantanea'] = [
    'taxaAntecipada',
    'taxaEconomica',
    'taxaInstantanea'
  ];

  return (
    <FieldArray
      name="taxas"
      render={({ form }) => {
        const {
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched
        } = form;
        const getError = (name: string) => getIn(errors, name);
        const getTouch = (name: string) => getIn(touched, name);

        return (
          <>
            {!![
              ...values.taxaAntecipada,
              ...values.taxaEconomica,
              values.taxaInstantanea
            ].length &&
              [
                ...values.taxaAntecipada,
                ...values.taxaEconomica,
                values.taxaInstantanea
              ].map(
                (taxa, index) =>
                  index % 3 === 0 && (
                    <CustomAccordion
                      expanded={!values.closeAccordion[index / 3]}
                    >
                      <AccordionSummary
                        expandIcon={null}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        style={{ padding: 0 }}
                      >
                        <AccordionTitle>
                          <Title>
                            {index === 0 && 'Receba em 01 dia útil'}
                            {index === 3 && 'Receba em 30 dias por parcela'}
                            {index === 6 && 'Receba na mesma hora'}
                          </Title>
                          {index !== 0 && (
                            <CheckBoxContainer>
                              <Checkbox
                                style={{ marginTop: -18 }}
                                checked={values.closeAccordion[index / 3]}
                                name={`closeAccordion.${index / 3}`}
                                onChange={handleChange}
                              />
                              <p>Desabilitar tipo de recebimento</p>
                            </CheckBoxContainer>
                          )}
                        </AccordionTitle>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <Details
                          handleBlur={handleBlur}
                          getError={getError}
                          getTouch={getTouch}
                          taxas={taxas[index / 3]}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          values={values}
                          setTouched={setFieldTouched}
                        />
                      </AccordionDetails>
                    </CustomAccordion>
                  )
              )}
          </>
        );
      }}
    />
  );
};

export default AddPlansTable;
