import styled from '@emotion/styled';

export const StyledCustomRow = styled.div`
  height: 64px;
  background-color: #f9f9f9;
  padding: 0 50px;
  display: grid;
  align-items: center;
  grid-template-columns: 80px 100px auto;
  font-size: 16px;
`;
export const StyledCustomRowContent = styled.div`
  height: 64px;
  border-bottom: 1px solid #ededed;
  padding: 0 50px;
  display: grid;
  align-items: center;
  grid-template-columns: 80px 100px auto;
  font-size: 16px;
`;
export const StyledCustomHeaderCell = styled.div`
  font-weight: 700;
`;
export const StyledCustomCell = styled.div`
  font-weight: 400;
  font-size: 13px;
`;
export const StyledSelectionBox = styled.div`
  margin-bottom: 15px;
  max-height: 300px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 30px ${(p) => p.theme.colors.p700};
    -webkit-box-shadow: inset 0 0 30px ${(p) => p.theme.colors.p700};
    border-radius: 6px;
  }
`;
