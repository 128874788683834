import { SearchInput } from '@sumup/circuit-ui';
import { FC, useEffect, useState } from 'react';
import { API } from '../../../constants/api.constants';
import { ENUM_DATE_OPTIONS } from '../../../constants/kanban.constants';
import { STORAGE } from '../../../constants/storage.constants';
import { IFiltersSolicitations } from '../../../interfaces/exchangeAndReturn';
import { IDataResponseModel } from '../../../interfaces/requests.interfaces';
import { get } from '../../../services/axios.service';
import { rangeDateOptions } from '../../../utils/kanban';
import {
  Button,
  CustomDropdown,
  SelectDateRangeUniqueField
} from '../../_Atoms';
import { ClearFilters } from '../FilterUserEdit/styles';
import {
  ContainerFilters,
  Filtros,
  MultiSelectStyle,
  MultiSelectStyleEmail,
  SearchBar
} from './styles';

interface IProps {
  filters: IFiltersSolicitations;
  setFilters: (filters: IFiltersSolicitations) => void;
  getData: () => void;
}

const ExchangeAndReturnSolicitationsFilter: FC<IProps> = ({
  filters,
  setFilters,
  getData
}) => {
  const [subGroupsOptions, setSubGroups] = useState({
    options: [{ label: '', value: -1 }]
  });
  const [emails, setEmails] = useState({
    options: [{ label: '', value: -1 }]
  });

  const getOptions = async () => {
    try {
      const [groupsRes, emailRes] = await Promise.allSettled([
        get<IDataResponseModel<string[]>>(
          API.AQUISICAO_MAQUINA.SUBGRUPO_PROMOTORES
        ),
        get<IDataResponseModel<{ id: number; descricao: string }[]>>(
          API.AQUISICAO_MAQUINA.LISTA_EMAIL_SOLICITANTES
        )
      ]);

      let groupsResFormated: string[] = [];
      let emailResFormated: number[] = [];

      if (groupsRes.status === 'fulfilled') {
        groupsResFormated = groupsRes.value.data;

        setSubGroups({
          options: groupsRes.value.data.map((groups, index) => ({
            label: groups,
            value: index
          }))
        });
      }

      if (emailRes.status === 'fulfilled') {
        setEmails({
          options: emailRes.value.data.map((email) => ({
            label: email.descricao,
            value: email.id
          }))
        });
        emailResFormated = emailRes.value.data.map((email) => email.id);
      }

      const history = JSON.parse(
        localStorage.getItem(STORAGE.HISTORY_PAGINATION_MACHINE_EXCHANGE) ||
          '{}'
      );

      setFilters({
        ...filters,
        subgrupo: history?.subgrupo || groupsResFormated,
        email: history?.email || emailResFormated
      });

      getData();
    } catch (error) {
      setSubGroups({
        options: [{ label: 'Não foi possível carregar', value: -2 }]
      });
      setEmails({
        options: [{ label: 'Não foi possível carregar', value: -2 }]
      });
    }
  };

  const clearFilters = () => {
    setFilters({
      tipoSolicitacao: '0',
      subgrupo: subGroupsOptions.options.map((i) => i.label),
      email: emails.options.map((i) => i.value),
      dataInicial: rangeDateOptions('11').start,
      datafinal: rangeDateOptions('11').end,
      initialLabelDateIndex: 11,
      tipoMaquina: [1, 2],
      search: ''
    });
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <ContainerFilters>
      <Filtros>
        <CustomDropdown
          value={filters.tipoSolicitacao}
          onChangeValue={(e) => setFilters({ ...filters, tipoSolicitacao: e })}
          options={[
            { label: 'Todos', value: '0' },
            { label: 'Troca', value: '1' },
            { label: 'Devolução', value: '2' }
          ]}
          style={{ width: 224, marginRight: 10 }}
          label="Tipo de solicitação"
          placeholder="Selecione uma opção"
        />

        <MultiSelectStyle
          options={subGroupsOptions.options
            .map((option) => ({
              label: option.label,
              value: Number(option.value)
            }))
            .filter((i) => i.value !== -1)}
          filter
          disabled={
            subGroupsOptions.options.some((i) => i.value === -2) ||
            subGroupsOptions.options.length === 0
          }
          placeholder={
            subGroupsOptions.options.some((i) => i.value === -2)
              ? subGroupsOptions.options[0].label
              : subGroupsOptions.options.length === 0
              ? 'Nenhum resultado encontrado'
              : 'Selecione uma opção'
          }
          selected={subGroupsOptions.options
            .filter((i) => filters.subgrupo.indexOf(i.label) > -1)
            .map((i) => Number(i.value))}
          setSelected={(e) => {
            setFilters({
              ...filters,
              subgrupo: e
                ? e.map((e) => {
                    return subGroupsOptions.options[e].label;
                  })
                : []
            });
          }}
          label="Subgrupo do promotor"
        />

        <div
          style={{
            width: '100%',
            maxWidth: 360,
            fontSize: 14,
            marginRight: 10
          }}
        >
          <SelectDateRangeUniqueField
            date={{
              start: filters.dataInicial,
              end: filters.datafinal
            }}
            setDate={(e) =>
              setFilters({ ...filters, datafinal: e.end, dataInicial: e.start })
            }
            value={filters.initialLabelDateIndex}
            setValue={(e) =>
              setFilters({ ...filters, initialLabelDateIndex: e })
            }
            label="Data de solicitação"
            options={[
              ENUM_DATE_OPTIONS['Exibir todo o período'],
              ENUM_DATE_OPTIONS.Hoje,
              ENUM_DATE_OPTIONS['Essa semana'],
              ENUM_DATE_OPTIONS['Ultimos 7 dias'],
              ENUM_DATE_OPTIONS['Últimos 15 Dias'],
              ENUM_DATE_OPTIONS['Últimos 30 dias'],
              ENUM_DATE_OPTIONS['Últimos 3 meses'],
              ENUM_DATE_OPTIONS['Últimos 6 meses']
            ]}
          />
        </div>
        <MultiSelectStyle
          selected={filters.tipoMaquina}
          setSelected={(e) => setFilters({ ...filters, tipoMaquina: e })}
          options={[
            { label: 'Total', value: 1 },
            { label: 'Solo', value: 2 }
          ]}
          style={{ width: 224, marginRight: 10 }}
          label="Tipo de máquina"
          placeholder="Selecione uma opção"
        />

        <ClearFilters
          active
          onClick={clearFilters}
          style={{ right: 18, top: 37, position: 'absolute' }}
        >
          Limpar filtros
        </ClearFilters>
      </Filtros>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <MultiSelectStyleEmail
            options={emails.options}
            disabled={
              emails.options.length === 0 ||
              emails.options.some((i) => i.value === -2)
            }
            placeholder={
              emails.options.some((i) => i.value === -2)
                ? emails.options[0].label
                : emails.options.length === 0
                ? 'Nenhum resultado encontrado'
                : 'Selecione uma opção'
            }
            selected={filters.email}
            setSelected={(e) => {
              setFilters({ ...filters, email: e });
            }}
            label="Email do solicitante"
          />
          <SearchBar style={{ maxWidth: 670, marginLeft: 10 }}>
            <SearchInput
              label="Buscar"
              onClear={() => {
                setFilters({ ...filters, search: '' });
              }}
              style={{
                border: '1px solid #ced4da',
                borderRadius: 4,
                height: 49,
                boxShadow: 'none'
              }}
              clearLabel="clearable"
              autoComplete="off"
              value={filters.search}
              name="search"
              placeholder="Busque por Serial Number, Merchant Code, Partner Code ou Nome do solicitante"
              onChange={(e) => {
                setFilters({ ...filters, search: e.target.value });
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && filters.search !== '') {
                  e.preventDefault();
                  getData();
                }
              }}
            />
          </SearchBar>
        </div>
        <Button value="Filtrar" style={{ marginTop: 24 }} onClick={getData} />
      </div>
    </ContainerFilters>
  );
};

export default ExchangeAndReturnSolicitationsFilter;
