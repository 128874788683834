import React from 'react';
import { CustomProviderDialog } from '../../_Atoms';
import {
  StyledActionsBar,
  StyledBody,
  StyledButton,
  StyledHeadline
} from './styles';

interface ITextAndMessage {
  title: string;
  message: string;
}
export interface ITextsDialog extends ITextAndMessage {
  primaryButton?: string;
  secondaryButton?: string;
}
interface ICustomDialogButton {
  buttonTitle: string;
  onClick: () => void;
}
interface ICustomDialog extends ITextAndMessage {
  handleClose: () => void;
  primaryButton?: ICustomDialogButton;
  secondaryButton?: ICustomDialogButton;
  preventClose?: boolean;
}
const CustomDialog: React.FC<ICustomDialog> = ({
  title,
  message,
  handleClose,
  primaryButton,
  secondaryButton,
  preventClose = true
}) => {
  return preventClose ? (
    <CustomProviderDialog
      variant="immersive"
      onClose={handleClose}
      preventClose
    >
      <StyledHeadline noMargin as="h3">
        {title}
      </StyledHeadline>
      <StyledBody>{message}</StyledBody>
      <StyledActionsBar>
        {secondaryButton && (
          <StyledButton
            size="kilo"
            variant="secondary"
            type="button"
            onClick={secondaryButton.onClick}
          >
            {secondaryButton.buttonTitle}
          </StyledButton>
        )}
        {primaryButton && (
          <StyledButton
            size="kilo"
            variant="primary"
            type="button"
            onClick={primaryButton.onClick}
          >
            {primaryButton.buttonTitle}
          </StyledButton>
        )}
      </StyledActionsBar>
    </CustomProviderDialog>
  ) : (
    <CustomProviderDialog
      closeButtonLabel="fechar"
      variant="immersive"
      onClose={handleClose}
    >
      <StyledHeadline noMargin as="h3">
        {title}
      </StyledHeadline>
      <StyledBody>{message}</StyledBody>
      <StyledActionsBar>
        {secondaryButton && (
          <StyledButton
            size="kilo"
            variant="secondary"
            type="button"
            onClick={secondaryButton.onClick}
          >
            {secondaryButton.buttonTitle}
          </StyledButton>
        )}
        {primaryButton && (
          <StyledButton
            size="kilo"
            variant="primary"
            type="button"
            onClick={primaryButton.onClick}
          >
            {primaryButton.buttonTitle}
          </StyledButton>
        )}
      </StyledActionsBar>
    </CustomProviderDialog>
  );
};

export default CustomDialog;
