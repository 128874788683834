import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '../../_Atoms';

export const BannerContainer = styled.div`
  position: relative;
  border-radius: 6px;
  border: 3px solid #3063e9;
  padding: 24px;
  background-color: #edf4fc;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  height: auto;
`;

export const BannerTitle = styled.h1`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 12px;
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const BannerActionButton = styled(Button)`
  background-color: white;
  color: #3063e9;
  border: none;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
