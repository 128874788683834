import React from 'react';
import { ModalProps, ModalProvider } from '@sumup/circuit-ui';
import { Modal } from '@sumup/circuit-ui/dist/cjs/components/Modal/Modal';

const CustomProviderDialog: React.FC<ModalProps> = (props) => {
  const initialModal = { id: 'custom-dialog', component: Modal, ...props };

  return (
    <ModalProvider initialState={[initialModal]}>
      <div />
    </ModalProvider>
  );
};

export default CustomProviderDialog;
