import { FC } from 'react';
import { USER_TYPE_FILTER_OPTIONS } from '../../../../constants/user.constants';
import { FiltersEditUser } from '../../../../interfaces/addUser.interface';
import { CustomSelectFields } from '../styles';

const UserTypeFilter: FC<{
  filters: FiltersEditUser;
  setFilter: (p: FiltersEditUser) => void;
}> = ({ filters, setFilter }) => (
  <CustomSelectFields
    selected={!!filters.userType}
    label="Tipo de usuário"
    onChange={(e) => {
      setFilter({
        ...filters,
        userType: e.target.value
      });
    }}
    value={filters.userType}
    options={USER_TYPE_FILTER_OPTIONS}
    name="userType"
    placeholder="Selecione uma opção"
  />
);

export default UserTypeFilter;
