import { useTheme } from '@emotion/react';
import { Container } from '@material-ui/core';
import { useModal } from '@sumup/circuit-ui';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DoubleMachineGroup from '../../components/DoubleMachinesGroup';
import {
  Button,
  CountItensPerPage,
  CustomPagination,
  SubTitle,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  NoResultsState
} from '../../components/_Molecules';
import {
  CustomEditListTable,
  MachinePricesFilter
} from '../../components/_Organisms';
import {
  ENUM_ACTION_ICON_TYPE,
  ListItem
} from '../../components/_Organisms/CustomEditListTable';
import { API } from '../../constants/api.constants';
import {
  DEFAULT_MACHINE_PRICES_FILTER_OPTION,
  MACHINE_PRICES_STATUS_NAME
} from '../../constants/machine-prices.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';
import { get } from '../../services/axios.service';
import {
  StatusOptions,
  getFilterParamByStatus
} from '../../services/filters.service';

export interface IResponseMachineGroups {
  data: {
    results: {
      id: number;
      nome: string;
      ativa: boolean;
    }[];
    pageSize: number;
    totalItems: number;
  };
}
interface IFilters {
  status: StatusOptions;
  search: string;
}

const EditMachinePrice: FC = () => {
  const [filters, setFilters] = useState<IFilters>({
    status: DEFAULT_MACHINE_PRICES_FILTER_OPTION,
    search: ''
  });
  const { colors } = useTheme();
  const [networkError, setNetworkError] = useState(false);
  const { push } = useHistory();
  const [search, setSearch] = useState('');
  const { active, disabled } = MACHINE_PRICES_STATUS_NAME;
  const itemsPerPage = 6;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resetPagination, setResetPagination] = useState(true);
  const [machineGroups, setMachineGroups] = useState<ListItem[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const { setModal, removeModal } = useModal();

  const getMachineGroups = async (page: number) => {
    setLoading(true);
    setNetworkError(false);

    const fetchUrl = `${API.MAQUINAS.PAGINADO}${getFilterParamByStatus(
      filters.status
    )}&PageNumber=${page}&PageSize=${itemsPerPage}&busca=${filters.search}`;
    try {
      const request: IResponseMachineGroups = await get(fetchUrl);
      if (request) {
        setMachineGroups(
          request.data.results.map((machineGroup) => ({
            id: machineGroup.id,
            nome: machineGroup.nome,
            ativo: machineGroup.ativa,
            actions: [
              {
                url: THIRD_LEVEL_MENU_ROUTES.EDIT_MACHINE_PRICES,
                color: colors.p500,
                text: 'Editar',
                IconType: ENUM_ACTION_ICON_TYPE.EDIT
              },
              {
                onClick: () => handleDoubleModal(machineGroup.id),
                color: colors.p500,
                text: 'Duplicar',
                IconType: ENUM_ACTION_ICON_TYPE.ADD
              }
            ]
          }))
        );
        setTotalItems(request.data.totalItems);
      }
    } catch {
      setNetworkError(true);
      setMachineGroups([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    getMachineGroups(page + 1);
    setCurrentPage(page + 1);
  };

  const handleDoubleModal = async (MachineGroupId: number) => {
    setModal({
      children: (
        <DoubleMachineGroup
          id={MachineGroupId}
          removeModal={removeModal}
          getMachinesGroup={() => getMachineGroups(1)}
        />
      ),
      variant: 'immersive',
      preventClose: true
    });
  };

  const loadData = async () => {
    setCurrentPage(1);
    setResetPagination(false);
    await getMachineGroups(1);
    setResetPagination(true);
  };

  useEffect(() => {
    loadData();
  }, [filters]);
  return (
    <Container style={{ maxWidth: 1332, marginBottom: 130 }}>
      <Breadcrumb
        style={{ margin: 0, padding: 0 }}
        currentRouteName="Editar preço"
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          },
          {
            routeName: 'Gestão de campanhas',
            routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
          },
          {
            routeName: 'Preços de máquinas',
            routePath: SUB_MENU_ROUTES.MACHINES_PRICES
          }
        ]}
      />
      <Title fontSize="big" style={{ margin: '32px 0 8px 0' }}>
        Editar preços de máquinas
      </Title>
      <SubTitle>
        Gerencie, edite ou desative um preço de máquinas cadastrado.
      </SubTitle>

      <MachinePricesFilter
        filters={filters}
        setFilters={setFilters}
        networkError={false}
        search={search}
        setSearch={setSearch}
      />

      <CustomEditListTable
        clearSearchInput={() => {
          setSearch('');
          setFilters({ ...filters, search: '' });
        }}
        listData={machineGroups}
        resourceUrl={API.MAQUINAS.CRIAR}
        mainColumnName="Nome"
        currentPage={currentPage}
        statusName={{ active, disabled }}
        networkError={networkError}
        filters={filters}
        loading={loading}
      />

      {machineGroups.length === 0 &&
        !loading &&
        !networkError &&
        !filters?.search && (
          <NoResultsState
            title="Nenhum preço de máquina encontrado"
            image="secondary"
          />
        )}

      {totalItems > itemsPerPage && resetPagination && !networkError && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 24
          }}
        >
          <CountItensPerPage
            currentPage={currentPage}
            itensPerPage={itemsPerPage}
            totalItens={totalItems}
          />
          <CustomPagination
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            totalItems={totalItems}
            initialPage={currentPage}
          />
        </div>
      )}

      <BottomFixedActions style={{ zIndex: 0 }}>
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          value="Voltar"
          variant="secondary"
          onClick={() => push(SUB_MENU_ROUTES.MACHINES_PRICES)}
        />
      </BottomFixedActions>
    </Container>
  );
};

export default EditMachinePrice;
