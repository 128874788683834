/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */

import {
  IColuna,
  IResponseGetMoreCards,
  ITeamOptionsFormated,
  IUserTeam
} from '../interfaces/kanban.interface';

export const transformArrayToTreeSelectFormat = (
  arr: IUserTeam[],
  parentKey = ''
): ITeamOptionsFormated[] => {
  const output: ITeamOptionsFormated[] = [];
  arr.forEach((item, index) => {
    const key = parentKey ? `${parentKey}-${index}` : `${index}`;
    const transformedItem: ITeamOptionsFormated = {
      key,
      id: item.id,
      label: item.nome,
      children: []
    };
    if (item.liderados.length > 0) {
      transformedItem.children = transformArrayToTreeSelectFormat(
        item.liderados,
        key
      );
    }
    output.push(transformedItem);
  });
  return output;
};

export const getIdByKey = (
  arr: ITeamOptionsFormated[],
  key: string
): number | ITeamOptionsFormated[] | undefined => {
  for (const obj of arr) {
    if (obj.key === key) {
      return obj.id;
    }
    if (obj.children.length > 0) {
      const result = getIdByKey(obj.children, key);
      if (result) {
        return result;
      }
    }
  }
};

export const getCurrentWeekRange = (): { start: Date; end: Date } => {
  const currentDate = new Date();
  const startOfWeek = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDate.getDay()
  );
  const endOfWeek = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDate.getDay() + 6
  );

  return { start: startOfWeek, end: endOfWeek };
};

export const getToday = (): { start: Date; end: Date } => {
  const currentDate = new Date();
  const today = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  return { start: today, end: today };
};

export const formatDateDDMMAAAA = (dateString: string): string => {
  if (!dateString) return '';
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

export const getLastWeekRange = (): { start: Date; end: Date } => {
  const currentDate = new Date();
  const startOfWeek = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDate.getDay() - 7
  );
  const endOfWeek = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDate.getDay() - 1
  );

  return { start: startOfWeek, end: endOfWeek };
};

export const getPreviousMonthRange = (): { start: Date; end: Date } => {
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );

  return { start: startOfMonth, end: endOfMonth };
};

export const getCurrentMonthRange = (): { start: Date; end: Date } => {
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endOfMonth = currentDate;

  return { start: startOfMonth, end: endOfMonth };
};

export const convertDate = (dateString: string): string => {
  const date = new Date(dateString);
  const isoDate = date.toISOString();
  return isoDate;
};

export const getAllIds = (team: IUserTeam[]): number[] =>
  team.reduce((acc, cur) => {
    acc.push(cur.id);
    if (cur.liderados.length > 0) {
      acc.push(...getAllIds(cur.liderados));
    }
    return acc;
  }, [] as number[]);

export const getAllKeys = (data: ITeamOptionsFormated[]): string[] => {
  return data.reduce((keys: string[], item: ITeamOptionsFormated) => {
    keys.push(item.key);
    if (item.children.length > 0) {
      keys.push(...getAllKeys(item.children));
    }
    return keys;
  }, []);
};

export const getAllPeriod = (): { start: Date; end: Date } => {
  const startOfRange = new Date(0);
  const endOfRange = new Date();

  return { start: startOfRange, end: endOfRange };
};

export const getLastDaysRange = (range: number): { start: Date; end: Date } => {
  const currentDate = new Date();
  const startOfRange = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - range
  );
  const endOfRange = currentDate;

  return { start: startOfRange, end: endOfRange };
};

export const getNextDaysRange = (range: number): { start: Date; end: Date } => {
  const currentDate = new Date();
  const startOfRange = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1
  );
  const endOfRange = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + range
  );

  return { start: startOfRange, end: endOfRange };
};

export const rangeDateOptions = (
  option: string
): { start: string; end: string } => {
  let dateRange: { start: Date; end: Date } = getCurrentWeekRange();
  switch (option) {
    case '1':
      dateRange = getCurrentWeekRange();
      break;
    case '2':
      dateRange = getLastWeekRange();
      break;
    case '3':
      dateRange = getLastDaysRange(30);
      break;
    case '4':
      dateRange = getCurrentMonthRange();
      break;
    case '5':
      dateRange = getPreviousMonthRange();
      break;
    case '6':
      dateRange = getLastDaysRange(90);
      break;
    case '7':
      dateRange = getToday();
      break;
    case '8':
      dateRange = getAllPeriod();
      break;
    case '9':
      dateRange = getLastDaysRange(15);
      break;
    case '10':
      dateRange = getLastDaysRange(180);
      break;
    case '11':
      dateRange = getLastDaysRange(7);
      break;
    case '12':
      dateRange = getNextDaysRange(7);
      break;
    case '13':
      dateRange = getNextDaysRange(15);
      break;
    default:
      break;
  }
  return {
    start: convertDate(dateRange.start.toDateString()),
    end: convertDate(dateRange.end.toDateString())
  };
};

export const addNewsCard = (
  dados: IColuna[],
  coluna: number,
  novosValores: IResponseGetMoreCards
): IColuna[] => {
  const indexColuna = dados.findIndex((item) => item.coluna === coluna);

  const novoArray = dados.map((item, index) => {
    if (index === indexColuna) {
      return {
        ...item,
        cards: [...item.cards, ...novosValores.data.cards]
      };
    }
    return item;
  });

  return novoArray;
};
