import CardIcon from './CardIcon';
import CardItem from './CardItem';
import CardRoot from './CardRoot';
import CardRow from './CardRow';
import CardTitle from './CardTitle';

export const Card = {
  Root: CardRoot,
  Icon: CardIcon,
  Item: CardItem,
  Title: CardTitle,
  Row: CardRow
};
