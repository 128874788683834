import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CustomSpinner } from '../../components/_Atoms';
import AddMccGroupPage from '../AddMccGroup';

import {
  IDataResponseModel,
  IRequestAtualizacaoMccs,
  IResponseGrupoMccById
} from '../../interfaces/requests.interfaces';

import { API } from '../../constants/api.constants';
import { get } from '../../services/axios.service';

const EditMccsGroupByIdPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [mccsGroup, setMccsGroup] = useState<IRequestAtualizacaoMccs | null>(
    null
  );

  useEffect(() => {
    if (!id) return;

    const getMccGroupById = async () => {
      try {
        const { data }: IDataResponseModel<IResponseGrupoMccById> = await get(
          API.GRUPOS_MCC.GET_BY_ID(id)
        );
        if (data.ativo) {
          const formatedMccsGroup: IRequestAtualizacaoMccs = {
            nome: data.nome,
            mccIds: data.mccIds.map((mcc) => String(mcc)),
            id: data.id
          };
          setMccsGroup(formatedMccsGroup);
        }
      } catch (error) {
        setMccsGroup(null);
      }
    };

    getMccGroupById();
  }, [id]);
  return mccsGroup ? (
    <AddMccGroupPage isEditable editMccGroupInitialValues={mccsGroup} />
  ) : (
    <CustomSpinner />
  );
};

export default EditMccsGroupByIdPage;
