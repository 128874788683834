import React from 'react';
import { Body } from '@sumup/circuit-ui';
import { Add } from '@sumup/icons';
import { StyledButtonBox } from './styles';

interface IAddNewLine {
  add: () => void;
}
const AddNewLine: React.FC<IAddNewLine> = ({ add }) => {
  return (
    <StyledButtonBox onClick={() => add()}>
      <Add width="16" />
      <Body noMargin variant="highlight" size="two" as="span">
        Adicionar nova linha
      </Body>
    </StyledButtonBox>
  );
};

export default AddNewLine;
