import styled from '@emotion/styled';
import { DateInput } from '@sumup/circuit-ui';
import { MultiSelect } from '../../_Atoms';

export const Filtros = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1340px;
  position: relative;
  margin: 0 auto 24px auto;

  div {
    white-space: nowrap;
  }
`;

export const CustomDateInput = styled(DateInput)<{ active?: boolean }>`
  opacity: ${({ active }) => (active ? '0.3' : '1')};
`;

export const SearchBar = styled.div`
  position: relative;
  max-width: 1340px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 24px auto;
  label {
    width: 100%;
  }

  button[title='clearable'] {
    background-color: transparent;
  }
`;

export const SearchIcon = styled.div<{ active: boolean }>`
  padding: 10px;
  height: 48px;
  width: 48px;
  background-color: ${({ active }) => (active ? '#3063e9' : '#9da7b1de')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 6px;
  margin-left: 30px;
  cursor: pointer;
`;

export const ContainerFilters = styled.div`
  width: 100%;
  max-width: 1340px;
  flex-direction: column;
  margin: auto;
  display: flex;
`;

export const MultiSelectStyle = styled(MultiSelect)`
  font-size: 14px;

  .p-multiselect {
    margin-right: 10px;
    min-width: 224px;
    max-width: 224px;

    @media (max-width: 1500px) {
      min-width: 210px;
      max-width: 210px;
    }
  }
`;
