import { FC } from 'react';

interface IProps {
  label: string;
  value: string;
}

const CardItem: FC<IProps> = ({ label, value }) => {
  return (
    <div style={{ paddingRight: 24 }}>
      <p style={{ fontSize: 13 }}>{label}</p>
      <p
        style={{
          fontSize: 16,
          fontWeight: value ? 'bold' : 'normal',
          color: value ? 'black' : 'gray'
        }}
      >
        {value || '(vazio)'}
      </p>
    </div>
  );
};

export default CardItem;
