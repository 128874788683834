import styled from '@emotion/styled';

export const HeaderTable = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 2fr 1fr 1fr 2fr;
  font-size: 13px;
  padding: 15px 20px;
  font-weight: bold;
  text-align: center;
  background-color: #f9f9f9;
  margin-top: 24px;
  align-items: center;
  p {
    padding: 0 6px;
  }
`;

export const ContentTable = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 2fr 1fr 1fr 2fr;
  font-size: 13px;
  text-align: center;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 48px;
  p {
    padding: 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;
