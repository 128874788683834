import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomSpinner } from '../../components/_Atoms';
import { API } from '../../constants/api.constants';
import { IMaquinas } from '../../interfaces/addMachines.interfaces';
import { get } from '../../services/axios.service';
import { formatNumberToCurrency } from '../../utils/format.utils';
import AddMachinePrice from '../AddMachinePrice';

const EditMachinePricePricesById: React.FC = () => {
  interface IResponseMachineGroup {
    data: {
      id: number;
      nome: string;
      maquinas: [
        {
          id: number;
          nome: string;
          valorOriginal: number;
          valorVenda: number;
        }
      ];
    };
  }

  interface IMachineGroup {
    nomeGrupoMaquina: string;
    maquinas: IMaquinas[];
  }

  const { id } = useParams<{ id: string }>();
  const [machineGroup, setMachineGroup] = useState<IMachineGroup | null>(null);

  useEffect(() => {
    if (!id) return;
    const getMachineGroup = async () => {
      try {
        const request: IResponseMachineGroup = await get(
          API.MAQUINAS.EDITAR(`${id}`)
        );
        if (request) {
          setMachineGroup({
            nomeGrupoMaquina: request.data.nome,
            maquinas: request.data.maquinas.map((maquina) => {
              return {
                id: maquina.id,
                nomeMaquina: maquina.nome,
                valorOriginal: formatNumberToCurrency(maquina.valorOriginal),
                valorVenda: formatNumberToCurrency(maquina.valorVenda)
              };
            })
          });
        }
      } catch (error) {
        setMachineGroup(null);
      }
    };
    getMachineGroup();
  }, [id]);

  return machineGroup ? (
    <AddMachinePrice initialValuesToEdit={machineGroup} id={id} />
  ) : (
    <CustomSpinner />
  );
};

export default EditMachinePricePricesById;
