import styled from '@emotion/styled';

export const StyledButtonBox = styled.div`
  border: 0;
  display: flex;
  width: 160px;
  justify-content: space-between;
  align-items: center;
  margin-left: 37px;
  margin-top: 22px;
  cursor: pointer;
  svg,
  span {
    color: ${(p) => p.theme.colors.p500};
  }
`;
