import { SelectOption } from '@sumup/circuit-ui';
import { IOffer } from '../interfaces/offers.interfaces';

export const OFFERS_FILTER_OPTIONS: SelectOption[] = [
  { value: 'ativos', label: 'Ofertas ativas' },
  { value: 'inativos', label: 'Ofertas inativadas' },
  { value: 'todos', label: 'Todas as ofertas' }
];

export const DEFAULT_OFFERS_FILTER_OPTION = 'ativos';

export const OFFERS_STATUS_NAME = {
  active: 'Oferta ativa',
  disabled: 'Inativado'
};

export enum OPCOES_TIPO_TRANSACAO {
  'Á vista' = 1,
  'Parcelado'
}

export const opcoesTipoTransacao: SelectOption[] = [
  {
    value: '1',
    label: 'À vista'
  },
  {
    value: '2',
    label: 'Parcelado'
  },
  {
    value: '3',
    label: 'Todos'
  }
];

export const filtersDefault: IOffer = {
  faturamentoInicial: '',
  faturamentoFinal: '',
  grupoMccs: '',
  tipoTransacao: '',
  nivel: 0,
  alcada: false,
  planoTaxas: '',
  valorMaquina: '',
  remuneracao: '',
  condicoes: ''
};
