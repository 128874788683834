import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';
import { SVGExport } from '../Icon';
import CustomSpinner from '../Spinner';

const DownloadButtonStyle = styled.div<{ disabled: boolean }>`
  height: 48px;
  width: auto;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(e) => (e.disabled ? e.theme.colors.n300 : e.theme.colors.p500)};
  font-weight: bold;
  cursor: ${(e) => (e.disabled ? 'default' : 'pointer')};

  p {
    font-size: 16px;
    margin: 0 4px;
  }
`;

interface IProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  loading?: boolean;
  disabled?: boolean;
  submit: () => void;
}

const DownloadButton: FC<IProps> = ({
  label,
  loading,
  submit,
  disabled = false,
  ...props
}) => {
  const onSubmit = () => {
    if (!loading && !disabled) {
      submit();
    }
  };

  return (
    <DownloadButtonStyle disabled={disabled} onClick={onSubmit} {...props}>
      {!loading ? (
        <>
          <SVGExport color={!disabled ? '#3063E9' : '#D8DDE1'} />
          <p>{label}</p>
        </>
      ) : (
        <CustomSpinner />
      )}
    </DownloadButtonStyle>
  );
};

export default DownloadButton;
