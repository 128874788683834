import styled from '@emotion/styled';
import { MultiSelect } from '../../_Atoms';

export const MultiSelectStyle = styled(MultiSelect)`
  font-size: 14px;
  margin-left: 15px;

  .p-multiselect {
    width: 302px;
  }
`;
