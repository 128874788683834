import React from 'react';
import { Body } from '@sumup/circuit-ui';
import { ReactComponent as InfoSessionMessage } from '../../../assets/icons/info_session_message.svg';
import { StyledBoxFormTip } from './styles';

const FormTip: React.FC = ({ children }) => {
  return (
    <StyledBoxFormTip>
      <InfoSessionMessage />
      <Body noMargin as="p">
        {children}
      </Body>
    </StyledBoxFormTip>
  );
};

export default FormTip;
