import { Checkbox } from '@sumup/circuit-ui';
import { FC } from 'react';
import { IOffer } from '../../../../interfaces/offers.interfaces';
import { CustomCurrencyInput } from '../../../_Atoms';
import { FiltersContent } from './styles';

interface IProps {
  filters: IOffer;
  setFilters: (p: IOffer) => void;
  nivelOptions: number[];
}

const AddDynamicOffersFilters: FC<IProps> = ({
  filters,
  setFilters,
  nivelOptions
}) => {
  return (
    <FiltersContent>
      <div />
      <CustomCurrencyInput
        currency="BRL"
        locale="pt-BR"
        label=" "
        value={filters.faturamentoInicial}
        onChange={(e) => {
          setFilters({
            ...filters,
            faturamentoInicial: e.target.value.replace(/[.,]/g, '')
          });
        }}
        placeholder="00,00"
      />
      <CustomCurrencyInput
        currency="BRL"
        locale="pt-BR"
        label=" "
        value={filters.faturamentoFinal}
        onChange={(e) => {
          setFilters({
            ...filters,
            faturamentoFinal: e.target.value.replace(/[.,]/g, '')
          });
        }}
        placeholder="00,00"
      />
      <input
        placeholder="Filtrar"
        value={filters.grupoMccs}
        onChange={(e) => {
          setFilters({ ...filters, grupoMccs: e.target.value });
        }}
        type="text"
      />
      <input
        placeholder="Filtrar"
        value={filters.tipoTransacao}
        onChange={(e) =>
          setFilters({ ...filters, tipoTransacao: e.target.value })
        }
        type="text"
      />
      <div id="nivel">
        <select
          value={filters.nivel}
          placeholder="Filtrar"
          onChange={(e) =>
            setFilters({ ...filters, nivel: Number(e.target.value) })
          }
        >
          <option value={0}>--</option>
          {Array.from(
            new Set(
              nivelOptions.map((value) => Number(value)).sort((a, b) => a - b)
            )
          )
            .filter((value) => value !== 0)
            .map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
        </select>
      </div>
      <Checkbox
        checked={filters.alcada}
        onChange={(e) => setFilters({ ...filters, alcada: e.target.checked })}
        style={{ marginLeft: 30, marginTop: 5 }}
      >
        &nbsp;
      </Checkbox>
      <input
        value={filters.planoTaxas}
        placeholder="Filtrar"
        onChange={(e) => setFilters({ ...filters, planoTaxas: e.target.value })}
        type="text"
      />
      <input
        placeholder="Filtrar"
        value={filters.valorMaquina}
        onChange={(e) =>
          setFilters({ ...filters, valorMaquina: e.target.value })
        }
        type="text"
      />
      <input
        placeholder="Filtrar"
        value={filters.remuneracao}
        onChange={(e) =>
          setFilters({ ...filters, remuneracao: e.target.value })
        }
        type="text"
      />
      <input
        value={filters.condicoes}
        placeholder="Filtrar"
        onChange={(e) => setFilters({ ...filters, condicoes: e.target.value })}
        type="text"
      />
    </FiltersContent>
  );
};

export default AddDynamicOffersFilters;
