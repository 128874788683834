import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from '@emotion/styled';
import { Form, Formik, FormikProps } from 'formik';

import { Body, Button, Card } from '@sumup/circuit-ui';
import {
  CustomInlineMessage,
  InputField,
  SimpleLink
} from '../../components/_Atoms';
import { IUserData } from '../../components/_Organisms/Header';

import { useAuth } from '../../contexts/auth.context';

import { ROUTES } from '../../constants/routes.constants';
import { STORAGE } from '../../constants/storage.constants';

import { ILoginForm } from '../../interfaces/login.interfaces';
import {
  IRequestLogin,
  IResponseAuthLogin
} from '../../interfaces/requests.interfaces';

import { loginValidation } from '../../utils/yup-utils';

import { API } from '../../constants/api.constants';
import { post } from '../../services/axios.service';
import { decodeToken } from '../../services/jwt.service';

const StyledCard = styled(Card)`
  max-width: 298px;
  margin: 0 auto;
`;

export interface IAuthUserData extends IUserData {
  token: string;
  refreshToken: string;
}
const LoginPage: React.FC = () => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const [hasError, setHasError] = useState({ isError: false, message: '' });
  const { push } = useHistory();

  const formikChangesObserver = (formikChanges: FormikProps<ILoginForm>) => {
    setFormIsValid(
      formikChanges?.values !== formikChanges?.initialValues &&
        !!formikChanges?.isValid
    );
  };

  const submitForm = async (formData: ILoginForm) => {
    setLoading(true);
    setHasError({
      isError: false,
      message: ''
    });

    const loginRequest: IRequestLogin = {
      email: formData.email,
      senha: formData.password
    };

    try {
      const request: IResponseAuthLogin = await post(
        API.AUTH.LOGIN,
        loginRequest
      );

      const decodedToken = decodeToken(request.token);
      if (decodedToken?.role === 'Admin' || decodedToken?.role === 'Lider') {
        if (request.primeiroAcesso) {
          push({
            pathname: ROUTES.CHANGE_PASSWORD,
            search: `?token=${request.token}`
          });
          return;
        }
        const user: IUserData = {
          name: request.usuario
        };
        const authUser: IAuthUserData = {
          ...user,
          token: request.token,
          refreshToken: request.refreshToken
        };
        localStorage.setItem(STORAGE.USER_DATA, JSON.stringify(authUser));
        signIn(user);
        push(ROUTES.MAIN_MENU);
      } else {
        setLoading(false);
        setHasError({
          isError: true,
          message: 'O usuário não possui permissão de acesso.'
        });
      }
    } catch (error) {
      setLoading(false);
      setHasError({
        isError: true,
        message: 'E-mail e/ou senha incorreto(s).'
      });
    }
  };

  return (
    <div>
      <StyledCard>
        <Body size="one" variant="highlight">
          Sistema Administrativo
        </Body>

        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={loginValidation}
          innerRef={formikChangesObserver}
          onSubmit={submitForm}
        >
          {({ handleChange, handleBlur, errors, touched }) => (
            <Form>
              <InputField
                id="signup-email"
                invalid={!!(errors.email && touched.email)}
                errorMessage={errors.email}
                label="E-mail:"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="seuemail@exemplo.com"
                type="email"
              />
              <InputField
                id="signup-password"
                invalid={!!(errors.password && touched.password)}
                errorMessage={errors.password}
                label="Senha:"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="*********"
                type="password"
              />
              <SimpleLink
                route={ROUTES.FORGET_PASSWORD}
                text="Esqueceu sua senha?"
              />
              {hasError && (
                <CustomInlineMessage
                  fontSize="12px"
                  marginTop="0px"
                  size="giga"
                  variant="danger"
                >
                  {hasError.message}
                </CustomInlineMessage>
              )}
              <Button
                id="submit-button"
                disabled={!formIsValid}
                isLoading={loading}
                loadingLabel="Carregando..."
                stretch
                variant="primary"
                type="submit"
              >
                Acessar
              </Button>
            </Form>
          )}
        </Formik>
      </StyledCard>
    </div>
  );
};

export default LoginPage;
