import styled from '@emotion/styled';
import { Body } from '@sumup/circuit-ui';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  CustomButton,
  CustomPagination,
  FormTip
} from '../../components/_Atoms';
import { Breadcrumb } from '../../components/_Molecules';
import {
  StyledBody,
  StyledHeadline
} from '../../components/_Molecules/CardContainer/styles';
import { MessageHistoryTable } from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';
import { STORAGE } from '../../constants/storage.constants';
import { usePagination } from '../../hooks/usePagination';
import { IMessageHistory } from '../../interfaces/messages.iterfaces';
import {
  IDataResponseModel,
  IPagination
} from '../../interfaces/requests.interfaces';
import { get } from '../../services/axios.service';

const StyledContentBox = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;
`;
const StyledBoxPage = styled.div`
  max-width: 1182px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 95vh;

  @media (min-width: 1600px) {
    max-width: 1497px;
  }
`;
const StyledFooter = styled.footer`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 48px 0;
`;
const StyledEntriesPerPage = styled(Body)`
  color: ${(p) => p.theme.colors.n500};
`;

const MessageHistory: FC = () => {
  const { push } = useHistory();
  const { totalItems, setTotalItems } = usePagination();
  const itemsPerPage = 10;
  const [mensagens, setMensagens] = useState<IMessageHistory[]>([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(() => {
    const page = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_MESSAGE) || '{}'
    );
    return Number(page?.page) || 1;
  });

  const handlePageChange = (page: number) => {
    loadMessages(page + 1);
    setCurrentPage(page + 1);
  };

  const loadMessages = async (page: number) => {
    setIsLoading(true);
    try {
      const response: IDataResponseModel<IPagination<IMessageHistory>> =
        await get(API.COMUNICADO.HISTORICO(page, itemsPerPage));
      setMensagens(response.data.results);
      setTotalItems(response.data.totalItems);
    } catch (error) {
      setMensagens([]);
      setError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadMessages(currentPage);
    localStorage.removeItem(STORAGE.HISTORY_PAGINATION_MESSAGE);
  }, [setMensagens]);

  return (
    <StyledBoxPage>
      <div>
        <StyledContentBox>
          <Breadcrumb
            noMargin
            previousRoutes={[
              {
                routeName: 'Menu principal',
                routePath: ROUTES.MAIN_MENU
              },
              {
                routeName: 'Central de mensagens',
                routePath: MENU_ROUTES.MESSAGES_CENTER
              }
            ]}
            currentRouteName="Histórico de mensagens"
          />
          <StyledHeadline style={{ marginTop: 25 }} noMargin as="h1">
            Histórico de mensagens
          </StyledHeadline>
          <StyledBody as="p">
            Visualize os detalhes e estatísticas das mensagens enviadas através
            da Central de Mensagens.
          </StyledBody>
        </StyledContentBox>

        <MessageHistoryTable
          isLoading={isLoading}
          messages={mensagens}
          page={currentPage}
          error={error}
        />
        <FormTip>
          <b>ATENÇÃO:</b> Mensagens recebidas no prazo de{' '}
          <b>90 dias anteriores à data atual</b> não poderão mais ser
          visualizadas na Central de Mensagens, devido ao{' '}
          <b>prazo de expiração</b>.
        </FormTip>
      </div>

      <StyledFooter>
        <CustomButton
          onClick={() => push(MENU_ROUTES.MESSAGES_CENTER)}
          id="back-button"
          variant="secondary"
        >
          Voltar
        </CustomButton>
        {totalItems > 0 && !error && (
          <>
            <CustomPagination
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              totalItems={totalItems}
              initialPage={currentPage}
            />
            <StyledEntriesPerPage>
              {itemsPerPage} cadastros por página
            </StyledEntriesPerPage>
          </>
        )}
      </StyledFooter>
    </StyledBoxPage>
  );
};

export default MessageHistory;
