import styled from '@emotion/styled';
import { DateInput } from '@sumup/circuit-ui';
import { Calendar } from 'primereact/calendar';

export const DateRangeSelect = styled.div`
  padding: 0px;
  width: 100%;
  z-index: 1000;
  position: relative;
`;

export const Input = styled.div<{ active: boolean }>`
  margin: 0;
  height: 48px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 6px;
  cursor: pointer;
  color: #495057;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  ${(e) => (e.active ? 'border: 1px solid #3388ff;' : '')}

  :hover {
    border: 1px solid #3388ff;
  }
`;

export const ContainerOptions = styled.div`
  height: auto;
  width: 100%;
  min-width: 350px;
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  z-index: 40;
  overflow-y: auto;
  padding: 4px 0;
  border-radius: 6px;
`;

export const Option = styled.div<{ active: boolean }>`
  height: 40px;
  padding: 8px 16px;
  display: flex;
  align-item: center;
  justify-content: space-between;

  ${({ active }) =>
    active
      ? 'background-color: #edf4fc; font-weight: bold; color: #3063E9;'
      : ''}

  :hover {
    background-color: #edf4fc;
    cursor: pointer;
  }
`;

export const HandleCloseArea = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  zindex: 999;
  opacity: 0.3;
`;

export const CustomDate = styled(DateInput)`
  /* width: 230px; */
  margin-right: 16px;
  margin-bottom: 0px;
  border: 1px solid #ced4da;
  box-shadow: none;
  border-radius: 6px;

  &:hover {
    border: 1px solid #3388ff;
    box-shadow: none;
  }

  label {
    margin-bottom: 0;
  }
`;

export const CalendarStyle = styled(Calendar)`
  input {
    color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
  }
  user-select: none;
`;

export const CalendarContainerStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
`;

export const CalendarField = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  position: absolute;
  bottom: 26px;
  z-index: -999;
  width: 90%;

  p {
    color: #3069e9;
    font-weight: bold;
    margin: 0 4px;
    font-size: 14px;
    white-space: nowrap;
  }
`;
