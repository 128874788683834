/* eslint-disable prefer-destructuring */
import styled from '@emotion/styled';
import { Headline } from '@sumup/circuit-ui';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CustomButton,
  CustomInlineMessage,
  FormTip,
  InputField
} from '../../components/_Atoms';
import {
  Breadcrumb,
  CardContainer,
  CustomDialog
} from '../../components/_Molecules';
import { IPageTitles } from '../../components/_Molecules/CardContainer';
import { ITextsDialog } from '../../components/_Molecules/CustomDialog';
import { AddPlansTable } from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import { CREATE_INITIAL_VALUES_PLANS } from '../../constants/plans.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';
import { IFormAddRatePlans } from '../../interfaces/rate-plans.interfaces';
import { IDataResponseModel } from '../../interfaces/requests.interfaces';
import { post, put } from '../../services/axios.service';
import { equalsObject } from '../../utils/format.utils';
import { addRatePlansValidation } from '../../utils/yup-utils';

const StyledButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const StyledBoxPage = styled.div`
  max-width: 992px;
  margin: 0 auto;
  width: 100%;
`;

interface IAddRatePlansPage {
  isEditable?: boolean;
  initialValues: IFormAddRatePlans;
}
const AddRatePlansPage: React.FC<IAddRatePlansPage> = ({
  isEditable = false,
  initialValues
}) => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { push } = useHistory();
  let initialValuesForm: IFormAddRatePlans = initialValues;
  if (!isEditable) {
    initialValuesForm = CREATE_INITIAL_VALUES_PLANS;
  }

  const handleCloseDialog = () => {
    isEditable
      ? push(THIRD_LEVEL_MENU_ROUTES.EDIT_RATE_PLANS)
      : push(SUB_MENU_ROUTES.RATE_PLANS);
  };

  const handleSubmit = async (formData: IFormAddRatePlans) => {
    let taxas = [...formData.taxaAntecipada];

    if (!formData.closeAccordion[1]) {
      taxas = [...taxas, ...formData.taxaEconomica];
    }
    if (!formData.closeAccordion[2]) {
      taxas = [...taxas, ...formData.taxaInstantanea];
    }

    const formatedData = {
      id: formData.id ?? 0,
      nome: formData.nome,
      url: formData.url,
      taxas
    };

    setLoading(true);
    setHasError(false);
    try {
      let res;

      isEditable
        ? (res = await put<IDataResponseModel<{ id: number }>>(
            API.PLANOS.EDITAR(`${initialValues.id}`),
            formatedData
          ))
        : (res = await post<IDataResponseModel<{ id: number }>>(
            API.PLANOS.CRIAR,
            formatedData
          ));

      if (res.success) setOpenDialog(true);

      if (res.error) setHasError(true);
    } catch (error) {
      setLoading(false);
      setHasError(true);
    }
  };

  const getSuccessModalTexts = (): ITextsDialog => {
    return isEditable
      ? {
          title: 'Alterações salvas',
          message:
            'Ao clicar em Salvar, as edições deste plano de taxas foram salvas com sucesso.'
        }
      : {
          title: 'Alterações salvas com sucesso',
          message:
            'Ao clicar em Salvar, as alterações realizadas no cadastro de novo plano foram salvas com sucesso.'
        };
  };

  const getBackModalTexts = (): ITextsDialog => {
    return isEditable
      ? {
          title: 'Você deseja voltar?',
          message:
            'Ao clicar em Voltar, a edição de plano de taxas não será salva e as informações serão perdidas.',
          primaryButton: 'Continuar nesta página',
          secondaryButton: 'Voltar para página anterior'
        }
      : {
          title: 'Suas alterações não serão salvas',
          message:
            'Ao clicar em Voltar, as alterações realizadas no cadastro de novo plano de taxas serão perdidas. Gostaria mesmo de voltar?',
          primaryButton: 'Continuar nesta página',
          secondaryButton: 'Voltar para página anterior'
        };
  };

  const getErrorMessage = (): string => {
    return isEditable
      ? 'Houve um problema ao editar o plano de taxas, tente novamente.'
      : 'Houve um problema ao cadastrar um plano de taxas, tente novamente.';
  };

  const getPageTitles = (): IPageTitles => {
    return isEditable
      ? {
          pageTitle: 'Editar plano de taxas',
          cardTitle: 'Edite um plano de taxas',
          cardSubtitle: 'Edite um plano de taxas existente.'
        }
      : {
          pageTitle: 'Novo plano de taxas',
          cardTitle: 'Cadastre um novo plano de taxas',
          cardSubtitle: 'Atribua um nome e cadastre um novo plano de taxas.'
        };
  };

  return (
    <StyledBoxPage>
      {initialValues && (
        <Formik
          initialValues={initialValuesForm}
          onSubmit={(v) => handleSubmit(v)}
          validationSchema={addRatePlansValidation}
          validateOnMount
          validateOnChange
          validateOnBlur
          isInitialValid
        >
          {({ handleChange, handleBlur, errors, touched, values }) => (
            <>
              <Breadcrumb
                noMargin
                confirmationModal={!equalsObject(initialValues, values)}
                currentRouteName={
                  isEditable ? 'Editar plano de taxas' : 'Novo plano de taxas'
                }
                previousRoutes={[
                  {
                    routeName: 'Menu principal',
                    routePath: ROUTES.MAIN_MENU
                  },
                  {
                    routeName: 'Gestão de campanhas',
                    routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
                  },
                  {
                    routeName: 'Planos de taxas',
                    routePath: SUB_MENU_ROUTES.RATE_PLANS
                  }
                ]}
              />
              <Headline style={{ marginTop: 25 }} as="h2" size="four">
                {getPageTitles().pageTitle}
              </Headline>
              <CardContainer
                title={getPageTitles().cardTitle}
                subtitle={getPageTitles().cardSubtitle}
              >
                <Form>
                  <InputField
                    errorMessage={errors.nome}
                    value={values.nome}
                    id="add-rate-plans-nome"
                    invalid={!!(errors.nome && touched.nome)}
                    label={
                      isEditable
                        ? 'Nome plano de taxas:'
                        : 'Nome do novo plano de taxas:'
                    }
                    name="nome"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Insira o nome de um plano de taxas a ser cadastrado"
                    type="text"
                  />
                  <InputField
                    errorMessage={errors.url}
                    value={values.url}
                    id="add-rate-plans-url"
                    invalid={!!(errors.url && touched.url)}
                    label="URL:"
                    name="url"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Insira a URL"
                    type="url"
                  />
                  <FormTip>
                    Os dados da tabela devem seguir o exemplo: <b>10%</b>{' '}
                    ou&nbsp;
                    <b>10,5% + 10.12%</b> seguido de qualquer texto.
                  </FormTip>
                  <AddPlansTable />
                  {hasError && (
                    <CustomInlineMessage
                      fontSize="12px"
                      marginTop="0px"
                      size="giga"
                      variant="danger"
                    >
                      {getErrorMessage()}
                    </CustomInlineMessage>
                  )}
                  <StyledButtonsGroup>
                    <CustomButton
                      variant="secondary"
                      type="button"
                      stretch
                      onClick={() =>
                        !equalsObject(initialValues, values)
                          ? setOpenBackDialog(true)
                          : handleCloseDialog()
                      }
                      style={{ minWidth: '146px' }}
                    >
                      Voltar
                    </CustomButton>
                    <CustomButton
                      isLoading={loading}
                      loadingLabel="Carregando..."
                      variant="primary"
                      type="submit"
                      style={{ width: 'auto', padding: '8px 24px' }}
                    >
                      Salvar plano
                    </CustomButton>
                  </StyledButtonsGroup>
                  {openDialog && (
                    <CustomDialog
                      title={getSuccessModalTexts().title}
                      message={getSuccessModalTexts().message}
                      handleClose={() => handleCloseDialog()}
                      primaryButton={{
                        buttonTitle: 'Ok',
                        onClick: () => {
                          handleCloseDialog();
                        }
                      }}
                    />
                  )}

                  {openBackDialog && (
                    <CustomDialog
                      title={getBackModalTexts().title}
                      message={getBackModalTexts().message}
                      handleClose={() => setOpenBackDialog(false)}
                      primaryButton={{
                        buttonTitle: getBackModalTexts()
                          .primaryButton as string,
                        onClick: () => setOpenBackDialog(false)
                      }}
                      secondaryButton={{
                        buttonTitle: getBackModalTexts()
                          .secondaryButton as string,
                        onClick: () => handleCloseDialog()
                      }}
                    />
                  )}
                </Form>
              </CardContainer>
            </>
          )}
        </Formik>
      )}
    </StyledBoxPage>
  );
};

export default AddRatePlansPage;
