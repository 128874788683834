import styled from '@emotion/styled';

export const ColumnStyle = styled.div`
  width: 100%;
  max-width: 265px;
  height: 100%;
  border-radius: 10px;
`;

export const Content = styled.div`
  background-color: #eef0f2;
  height: 100%;
  padding: 8px 24px 12px 24px;
`;
