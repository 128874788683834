import { formatFileNameDownload } from '../utils/format.utils';

export const handleDownloadXlsx = (
  data: ArrayBuffer,
  fileNamePrefix: string,
  fileNameWithDate = true
): void => {
  const link = document.createElement('a');
  link.download = fileNameWithDate
    ? `${formatFileNameDownload(fileNamePrefix)}.xlsx`
    : `${fileNamePrefix}.xlsx`;
  link.href = window.URL.createObjectURL(
    new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
  );

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const handleDownloadCSV = (
  data: ArrayBuffer,
  fileNamePrefix: string
): void => {
  const link = document.createElement('a');
  link.download = `${formatFileNameDownload(fileNamePrefix)}.csv`;
  link.href = window.URL.createObjectURL(
    new Blob([data], {
      type: 'text/csv'
    })
  );

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const handleDownloadDynamicType = (
  data: ArrayBuffer,
  fileNamePrefix: string,
  type: string
): void => {
  const link = document.createElement('a');
  link.download = `${fileNamePrefix}`;
  link.href = window.URL.createObjectURL(new Blob([data], { type }));

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
