import styled from '@emotion/styled';
import { MultiSelect } from '../../_Atoms';

export const MultiSelectStyle = styled(MultiSelect)`
  .p-multiselect {
    min-width: 224px !important;
    margin-right: 20px;
  }
`;

export const TaskListFollowUpFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
`;

export const MultiSelectContainer = styled.div`
  display: flex;
  margin: 20px 0;
`;
