import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from '@emotion/styled';
import { Form, Formik } from 'formik';

import { Body, Button, Card } from '@sumup/circuit-ui';
import { CustomInlineMessage, InputField } from '../../components/_Atoms';
import { CustomDialog } from '../../components/_Molecules';

import { forgotPasswordValidation } from '../../utils/yup-utils';

import { ROUTES } from '../../constants/routes.constants';

import { IForgotPasswordForm } from '../../interfaces/login.interfaces';

import { API } from '../../constants/api.constants';
import { IDataResponseModel } from '../../interfaces/requests.interfaces';
import { post } from '../../services/axios.service';

const StyledCard = styled(Card)`
  max-width: 298px;
  margin: 0 auto;
  padding-bottom: 110px;

  #submit-button {
    margin: 15px 0;
  }
`;
const ForgotPasswordPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { push } = useHistory();

  const submitForm = async (formData: IForgotPasswordForm) => {
    setHasError(false);
    setLoading(true);

    const formatedData: IForgotPasswordForm = {
      email: formData.email.trim()
    };

    try {
      const res = await post<IDataResponseModel<null>>(
        API.USUARIOS.TROCAR_SENHA,
        formatedData
      );

      if (res.success) setOpenDialog(true);

      if (res.error) setHasError(true);
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledCard>
        <Body size="one" variant="highlight">
          Sistema Administrativo
        </Body>

        <Formik
          initialValues={{
            email: ''
          }}
          validateOnMount
          validationSchema={forgotPasswordValidation}
          onSubmit={submitForm}
        >
          {({ handleChange, handleBlur, isValid, errors, touched }) => (
            <Form>
              <InputField
                id="signup-email"
                invalid={!!(errors.email && touched.email)}
                errorMessage={errors.email}
                label="Por favor, insira o seu e-mail:"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="seuemail@exemplo.com"
                type="email"
              />
              <Button
                id="submit-button"
                disabled={!isValid}
                isLoading={loading}
                loadingLabel="Carregando..."
                stretch
                variant="primary"
                type="submit"
              >
                Enviar senha
              </Button>
              {hasError && (
                <CustomInlineMessage
                  fontSize="12px"
                  marginTop="0px"
                  size="giga"
                  variant="danger"
                >
                  Houve um problema ao tentar recuperar a senha, tente
                  novamente.
                </CustomInlineMessage>
              )}
              <Button
                id="back-button"
                stretch
                variant="secondary"
                type="button"
                onClick={() => push(ROUTES.LOGIN)}
              >
                Voltar para login
              </Button>
            </Form>
          )}
        </Formik>
      </StyledCard>
      {openDialog && (
        <CustomDialog
          handleClose={() => setOpenDialog(false)}
          primaryButton={{
            buttonTitle: 'Ok',
            onClick: () => push(ROUTES.LOGIN)
          }}
          message="Acesse seu e-mail e confira a senha que enviamos para que você possa realizar o login."
          title="Senha enviada com sucesso"
        />
      )}
    </>
  );
};

export default ForgotPasswordPage;
