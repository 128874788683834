import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import { Headline } from '@sumup/circuit-ui';
import MccsSelection from '../../components/MccsSelection';
import {
  CustomButton,
  CustomInlineMessage,
  CustomSpinner,
  InputField
} from '../../components/_Atoms';
import { IPageTitles } from '../../components/_Molecules/CardContainer';
import { ITextsDialog } from '../../components/_Molecules/CustomDialog';

import { IAddMccGroupForm } from '../../interfaces/mccs.interfaces';
import {
  IDataResponseModel,
  IRequestAtualizacaoMccs,
  IRequestCadastroMccs,
  IResponseRamoAtividades,
  ISubmitError
} from '../../interfaces/requests.interfaces';

import { get, post, put } from '../../services/axios.service';

import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';

import {
  Breadcrumb,
  CardContainer,
  CustomDialog,
  NotFoundItem
} from '../../components/_Molecules';
import { NetworkError } from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import { equalsObject } from '../../utils/format.utils';
import { addMccGroupValidation } from '../../utils/yup-utils';

const addMccGroupInitialValues: IAddMccGroupForm = {
  nome: '',
  mccIds: []
};

const StyledButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const StyledBoxPage = styled.div`
  max-width: 704px;
  margin: 0 auto;
  width: 100%;
`;
interface IAddMccGroupPage {
  isEditable?: boolean;
  editMccGroupInitialValues?: IRequestAtualizacaoMccs;
}
const AddMccGroupPage: React.FC<IAddMccGroupPage> = ({
  isEditable = false,
  editMccGroupInitialValues
}) => {
  const [allMccs, setAllMccs] = useState<IResponseRamoAtividades[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [submitError, setSubmitError] = useState<ISubmitError>({
    showError: false,
    message: ''
  });
  const { push } = useHistory();

  const handleCloseDialog = () => {
    isEditable
      ? push(THIRD_LEVEL_MENU_ROUTES.EDIT_MCC_GROUP)
      : push(SUB_MENU_ROUTES.MCCS_GROUPS);
  };

  const getSuccessModalTexts = (): ITextsDialog => {
    return isEditable
      ? {
          title: 'Alterações salvas',
          message:
            'Ao clicar em Salvar, as informações do grupo MCC foram salvas com sucesso.'
        }
      : {
          title: 'Alterações salvas com sucesso',
          message:
            'Ao clicar em Salvar, as alterações realizadas no cadastro de novo plano foram salvas com sucesso.'
        };
  };

  const getBackModalTexts = (): ITextsDialog => {
    return isEditable
      ? {
          title: 'Você deseja voltar?',
          message:
            'Ao clicar em Voltar, a edição do grupo MCC não será salva e as informações serão perdidas.',
          primaryButton: 'Continuar nesta página',
          secondaryButton: 'Voltar para página anterior'
        }
      : {
          title: 'Suas alterações não serão salvas',
          message:
            'Ao clicar em Voltar, as alterações realizadas no cadastro de novo plano de taxas serão perdidas. Gostaria mesmo de voltar?',
          primaryButton: 'Continuar nesta página',
          secondaryButton: 'Voltar para página anterior'
        };
  };

  const getCardTitle = (): IPageTitles => {
    return isEditable
      ? {
          pageTitle: 'Editar grupo MCC',
          cardTitle: 'Edite um grupo MCC',
          cardSubtitle: 'Edite as informações do grupo MCC cadastrado.'
        }
      : {
          pageTitle: 'Novo grupo MCC',
          cardTitle: 'Crie um novo grupo MCCs',
          cardSubtitle:
            'Atribua um nome e selecione os MCCs que farão parte do novo grupo cadastrado.'
        };
  };

  const handleSubmit = async (formData: IAddMccGroupForm) => {
    setLoading(true);
    setSubmitError({ showError: false, message: '' });

    const formatedData: IRequestCadastroMccs = {
      nome: formData.nome,
      mccIds: formData.mccIds.map((mcc) => Number(mcc))
    };

    try {
      let res;

      isEditable
        ? (res = await put<IDataResponseModel<{ id: number }>>(
            API.GRUPOS_MCC.EDITAR(`${editMccGroupInitialValues?.id}`),
            formatedData
          ))
        : (res = await post<IDataResponseModel<{ id: number }>>(
            API.GRUPOS_MCC.CRIAR,
            formatedData
          ));

      if (res.success) {
        setOpenDialog(true);
      }

      if (res?.error === 'grupo_existente') {
        setSubmitError({
          showError: true,
          message: res.message
        });
      }
    } catch (error) {
      setSubmitError({
        showError: true,
        message: 'Houve algum erro com a requisição, tente novamente'
      });
    }
    setLoading(false);
  };

  const getInitialValues = (): IAddMccGroupForm => {
    return editMccGroupInitialValues ?? addMccGroupInitialValues;
  };

  const getAllMccs = async () => {
    setLoadingPage(true);
    setNetworkError(false);

    try {
      const { data } = await get<IDataResponseModel<IResponseRamoAtividades[]>>(
        '/mcc'
      );
      if (data) setAllMccs(data);
    } catch (err) {
      setAllMccs([]);
      setNetworkError(true);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getAllMccs();
  }, []);

  return (
    <StyledBoxPage>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={addMccGroupValidation}
        validateOnMount
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ handleChange, handleBlur, isValid, errors, touched, values }) => (
          <>
            <Breadcrumb
              noMargin
              confirmationModal={!equalsObject(values, getInitialValues())}
              currentRouteName={
                isEditable ? 'Editar grupo MCC' : 'Novo grupo MCC'
              }
              previousRoutes={[
                {
                  routeName: 'Menu principal',
                  routePath: ROUTES.MAIN_MENU
                },
                {
                  routeName: 'Gestão de campanhas',
                  routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
                },
                {
                  routeName: 'Grupos de MCCs',
                  routePath: SUB_MENU_ROUTES.MCCS_GROUPS
                }
              ]}
            />
            <Headline style={{ marginTop: 25 }} as="h2" size="four">
              {getCardTitle().pageTitle}
            </Headline>
            <CardContainer
              title={getCardTitle().cardTitle}
              subtitle={getCardTitle().cardSubtitle}
            >
              <Form>
                <InputField
                  label="Nome do grupo:"
                  name="nome"
                  placeholder="Insira o nome do grupo a ser cadastrado"
                  id="add-mcc-group-name"
                  defaultValue={getInitialValues().nome}
                  onBlur={handleBlur}
                  invalid={!!(errors.nome && touched.nome) && !networkError}
                  errorMessage={errors.nome}
                  onChange={handleChange}
                  disabled={networkError || allMccs.length === 0}
                />

                <MccsSelection mccsList={allMccs} />

                {submitError.showError && (
                  <CustomInlineMessage
                    fontSize="12px"
                    marginTop="0px"
                    size="giga"
                    variant="danger"
                  >
                    {submitError.message}
                  </CustomInlineMessage>
                )}

                {loadingPage && <CustomSpinner />}

                {networkError && (
                  <NetworkError
                    back={() => push(SUB_MENU_ROUTES.MCCS_GROUPS)}
                    retry={() => getAllMccs()}
                  />
                )}

                {allMccs.length === 0 && !loadingPage && !networkError && (
                  <NotFoundItem message="A lista está vazia" />
                )}

                {!networkError && (
                  <StyledButtonsGroup>
                    <CustomButton
                      variant="secondary"
                      type="button"
                      stretch
                      onClick={() =>
                        !equalsObject(values, getInitialValues())
                          ? setOpenBackDialog(true)
                          : handleCloseDialog()
                      }
                    >
                      Voltar
                    </CustomButton>
                    <CustomButton
                      isLoading={loading}
                      disabled={!isValid}
                      loadingLabel="Carregando..."
                      variant="primary"
                      type="submit"
                      stretch
                    >
                      Salvar
                    </CustomButton>
                  </StyledButtonsGroup>
                )}
              </Form>
            </CardContainer>
          </>
        )}
      </Formik>

      {openDialog && (
        <CustomDialog
          title={getSuccessModalTexts().title}
          message={getSuccessModalTexts().message}
          handleClose={() => handleCloseDialog()}
          primaryButton={{
            buttonTitle: 'Ok',
            onClick: () => handleCloseDialog()
          }}
        />
      )}

      {openBackDialog && (
        <CustomDialog
          title={getBackModalTexts().title}
          message={getBackModalTexts().message}
          handleClose={() => setOpenBackDialog(false)}
          primaryButton={{
            buttonTitle: getBackModalTexts().primaryButton as string,
            onClick: () => setOpenBackDialog(false)
          }}
          secondaryButton={{
            buttonTitle: getBackModalTexts().secondaryButton as string,
            onClick: () => handleCloseDialog()
          }}
        />
      )}
    </StyledBoxPage>
  );
};

export default AddMccGroupPage;
