import styled from '@emotion/styled';
import { SelectField } from '../../components/_Atoms';
import { splitStringWithDelimiter } from '../../utils/format.utils';

export const StyledBox = styled.main`
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
`;
export const StyledInlineButtons = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledSelectField = styled(SelectField)<{
  isEmpty: boolean;
  tooltip: string;
}>`
  select {
    color: ${({ isEmpty }) => (isEmpty ? '#3063e9' : '#000')};
  }

  @keyframes grow {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1); /* Ajuste o valor conforme necessário */
      opacity: 1;
    }
  }

  &:hover {
    &::after {
      // eslint-disable-next-line no-useless-escape, no-useless-escape, no-useless-escape
      content: '${({ tooltip }) => splitStringWithDelimiter(tooltip)}';
      display: block;
      position: absolute;
      background-color: #323e49;
      padding: 5px 10px;
      height: auto;
      border-radius: 4px;
      color: white;
      z-index: 999;
      height: auto;
      font-size: 13px;
      top: 20px;
      line-height: 1.5;
      white-space: pre;
      left: 150px;
      animation: grow 0.1s ease-in-out;
    }
  }

  select option {
    color: #000;
  }
  svg {
    color: ${({ isEmpty }) => (isEmpty ? '#3063e9' : '#000')};
  }
`;
