import React from 'react';
import { Breadcrumb, MenuCardList } from '../../components/_Molecules';
import { IPreviousRoute } from '../../components/_Molecules/Breadcrumb';
import {
  LEADER_TASK_LIST_MENU_ITEMS,
  TASK_LIST_MENU_ITEMS
} from '../../constants/menu.constants';
import { ROUTES } from '../../constants/routes.constants';
import { useAuth } from '../../contexts/auth.context';

const TaskListManagement: React.FC = () => {
  const { userType } = useAuth();
  const previousRoutesList: IPreviousRoute[] = [
    {
      routeName: 'Menu principal',
      routePath: ROUTES.MAIN_MENU
    }
  ];
  return (
    <>
      <Breadcrumb
        previousRoutes={previousRoutesList}
        currentRouteName="Gestão de tarefas"
      />
      <MenuCardList
        columns="TWO"
        menuCardList={
          userType() === 'Lider'
            ? LEADER_TASK_LIST_MENU_ITEMS
            : TASK_LIST_MENU_ITEMS
        }
      />
    </>
  );
};

export default TaskListManagement;
