import styled from '@emotion/styled';
import { Col, Row } from '@sumup/circuit-ui';

export const InlineElements = styled(Row)`
  padding-top: 10px !important;
  margin: 0 auto !important;
`;

export const ColWrapper = styled(Col)`
  margin-top: 15px;
`;
