import styled from '@emotion/styled';

export const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.p500};
  font-size: 12px;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
