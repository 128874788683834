import { FC } from 'react';
import { ReactComponent as EmptyBox } from '../../../assets/empty-box.svg';
import { ReactComponent as NoResults } from '../../../assets/no_results.svg';
import { Button, SubTitle, Title } from '../../_Atoms';
import { Container } from './styles';

interface IProps {
  title?: string;
  subtitle?: string;
  subtitle2?: string;
  action?: () => void;
  buttonLabel?: string;
  image?: 'primary' | 'secondary';
}

const NoResultsState: FC<IProps> = ({
  title = 'Nenhum resultado encontrado',
  subtitle = 'Não encontramos resultados com os termos utilizados.',
  subtitle2 = 'Tente novamente utilizando outros termos.',
  action,
  buttonLabel = 'Resetar busca',
  image = 'primary'
}) => {
  return (
    <Container>
      {image === 'primary' ? <NoResults /> : <EmptyBox />}
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <SubTitle>{subtitle2}</SubTitle>
      {action && (
        <Button
          style={{ marginTop: 24 }}
          value={buttonLabel}
          width="auto"
          onClick={action}
        />
      )}
    </Container>
  );
};

export default NoResultsState;
