import { FC, useEffect, useState, HTMLAttributes } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import styled from '@emotion/styled';
import { DATE_OPTIONS } from '../../../../constants/kanban.constants';
import {
  ContainerOptions,
  DateRangeSelect,
  Option,
  Input,
  CustomDate
} from './style';
import { convertDate, rangeDateOptions } from '../../../../utils/kanban';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  date: { start: string; end: string };
  setDate: (p: { start: string; end: string }) => void;
  value: number;
  setValue: (p: number) => void;
}

const CustomDateStyle = styled(CustomDate)`
  width: 100%;
  max-width: 263px;
  min-width: 210px;
`;

const SelectDateRange: FC<IProps> = ({
  date,
  setDate,
  setValue,
  value,
  ...props
}) => {
  // const [value, setValue] = useState(1);
  const [open, setOpen] = useState(false);
  const [customInputEndDate, setCustomInputEndDate] = useState('');
  const [customInputStartDate, setCustomInputStartDate] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (value !== -1) {
      let aux: { start: string; end: string } = date;
      aux = rangeDateOptions(value.toString());
      setCustomInputEndDate('');
      setCustomInputStartDate('');
      setDate(aux);
    } else {
      setDate({
        end: customInputEndDate,
        start: customInputStartDate
      });
    }
    handleClose();
  }, [value, customInputEndDate, customInputStartDate]);

  return (
    <>
      <div
        style={{
          ...props,
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr'
        }}
      >
        <div style={{ marginRight: 16, width: '100%', maxWidth: 263 }}>
          <p>Período:</p>
          <DateRangeSelect>
            <Input active={open} onClick={toggle}>
              <p style={{ whiteSpace: 'nowrap' }}>
                {value !== -1
                  ? DATE_OPTIONS.filter(
                      (item) => item.value === value.toString()
                    )[0].label
                  : 'Personalizado'}
              </p>
              <ExpandMoreIcon />
            </Input>
            {open && (
              <ContainerOptions>
                <Option active={value === 7} onClick={() => setValue(7)}>
                  <p>Hoje</p>
                  {value === 7 && <DoneRoundedIcon />}
                </Option>
                <Option active={value === 1} onClick={() => setValue(1)}>
                  <p>Essa semana</p>
                  {value === 1 && <DoneRoundedIcon />}
                </Option>
                <Option active={value === 2} onClick={() => setValue(2)}>
                  <p>Semana passada</p>
                  {value === 2 && <DoneRoundedIcon />}
                </Option>
                <Option active={value === 3} onClick={() => setValue(3)}>
                  <p>Últimos 30 dias</p>
                  {value === 3 && <DoneRoundedIcon />}
                </Option>
                <Option active={value === 4} onClick={() => setValue(4)}>
                  <p>Mês atual</p>
                  {value === 4 && <DoneRoundedIcon />}
                </Option>
                <Option active={value === 5} onClick={() => setValue(5)}>
                  <p>Mês anterior</p>
                  {value === 5 && <DoneRoundedIcon />}
                </Option>
                <Option active={value === 6} onClick={() => setValue(6)}>
                  <p>Últimos 3 meses</p>
                  {value === 6 && <DoneRoundedIcon />}
                </Option>
                <Option active={value === 8} onClick={() => setValue(8)}>
                  <p>Todo o período</p>
                  {value === 8 && <DoneRoundedIcon />}
                </Option>
                <Option active={value === -1} onClick={() => setValue(-1)}>
                  <p>Personalizado</p>
                  {value === -1 && <DoneRoundedIcon />}
                </Option>
              </ContainerOptions>
            )}
          </DateRangeSelect>
        </div>
        {value === -1 ? (
          <>
            <CustomDateStyle
              onChange={(e) => {
                const start = e.target.value ? convertDate(e.target.value) : '';
                setCustomInputStartDate(start);
              }}
              label="Data inicial"
            />
            <CustomDateStyle
              onChange={(e) => {
                const end = e.target.value ? convertDate(e.target.value) : '';
                setCustomInputEndDate(end);
              }}
              label="Data final"
            />
          </>
        ) : (
          <>
            <CustomDateStyle disabled value="" label="Data inicial" />
            <CustomDateStyle disabled value="" label="Data final" />
          </>
        )}
      </div>
    </>
  );
};

export default SelectDateRange;
