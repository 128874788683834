import styled from '@emotion/styled';

export const StyledHeader = styled.header`
  padding-left: 43px;
  height: 64px;
  background-color: #f9f9f9;
  position: sticky;
  top: 0;
  z-index: 101;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 12% 16% 18% 15% 17% 17%;
`;
export const StyledRow = styled.div`
  padding-left: 43px;
  height: 64px;
  border-bottom: 1px solid ${(p) => p.theme.colors.shadow};
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 12% 16% 18% 15% 17% 17% auto;
`;
export const StyledHeaderItem = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledRowItem = styled.div<{ disabled: boolean }>`
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  overflow: hidden;
  max-width: 25ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto;

  & > div {
    width: 100%;
  }
  color: ${(props) => (props.disabled ? '#9DA7B1' : 'inherit')};
`;

export const FilterIcon = styled.div<{ active: boolean }>`
  font-size: 16px;
  margin-left: 12px;
  color: ${({ active }) => (active ? '#3063e9' : '#9DA7B180')};
`;
