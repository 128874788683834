import styled from '@emotion/styled';

export const TableTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 16px;
  text-align: center;
  font-size: 13px;
  width: 720px;
  height: 62px;
  line-height: 20px;
  font-weight: bold;
  background-color: #f9f9f9;
`;

export const TableValues = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 16px;
  text-align: center;
  font-size: 13px;
  width: 720px;
  height: 62px;
  line-height: 20px;
`;
