import styled from '@emotion/styled';
import { SelectField } from '../../_Atoms';

export const Filters = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 24px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (min-width: 1600px) {
    max-width: 997px;
  }
`;

export const ClearFilters = styled.p<{ active: boolean }>`
  top: 40%;
  right: -150px;
  white-space: nowrap;
  color: ${({ active }) => (active ? '#3063e9' : '#9da7b1de')};
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};
  font-weight: bold;
  position: absolute;
`;

export const CustomSelectFields = styled(SelectField)<{ selected: boolean }>`
  width: 23%;

  select {
    opacity: ${({ selected }) => (selected ? '1' : '0.5')};
  }
`;
