import styled from '@emotion/styled';
import { FC } from 'react';

const StyleSubtitle = styled.p`
  font-size: 13px;
`;

const SubTitle: FC = ({ children }) => {
  return <StyleSubtitle>{children}</StyleSubtitle>;
};

export default SubTitle;
