import { Checkbox, Headline, SearchInput } from '@sumup/circuit-ui';
import { Search } from '@sumup/icons';
import React, { useEffect, useState } from 'react';
import { API } from '../../constants/api.constants';
import { USER_TYPE_FILTER_OPTIONS } from '../../constants/user.constants';
import { IDataResponseModel } from '../../interfaces/requests.interfaces';
import { IUser } from '../../interfaces/user-management.interfaces';
import { get } from '../../services/axios.service';
import { FormTip } from '../_Atoms';
import { CustomSelectFields } from '../_Organisms/FilterUserEdit/styles';
import GridUsers from './GridUsers';
import {
  BtnGroup,
  Cabecalho,
  Content,
  Header,
  MainContainer,
  SearchBar,
  SearchIcon,
  StyledBody,
  StyledButton,
  StyledCardFooter
} from './styles';

interface ISelectUserDialog {
  removeModal: () => void;
  setDestinatarios: (destinatarios: IUser[]) => void;
  destinatarios: IUser[];
}

const SelectUserDialog: React.FC<ISelectUserDialog> = ({
  removeModal,
  setDestinatarios,
  destinatarios
}) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [loadingGetUsers, setLoadingGetUsers] = useState(false);
  const [usersChecked, setUsersChecked] = useState<IUser[]>([]);
  const [error, setError] = useState(false);
  const [filters, setFilters] = useState({
    userType: '',
    company: '',
    subGroup: '',
    search: ''
  });
  const [search, setSearch] = useState('');
  const [checkAll, setCheckAll] = useState(false);

  const groups = {
    options: Array.from(new Set(users.map(({ subGrupo }) => subGrupo)))
      .map((subgrupo) => {
        return { label: subgrupo, value: subgrupo };
      })
      .filter((item) => item.label !== '')
  };
  groups.options.unshift({ label: 'Todos os grupos', value: 'todos' });

  const companies = {
    options: Array.from(new Set(users.map(({ empresa }) => empresa))).map(
      (empresa) => {
        return { label: empresa, value: empresa };
      }
    )
  };

  companies.options.unshift({ label: 'Todas as empresas', value: 'todos' });

  const handleSelectUsers = () => {
    setDestinatarios(usersChecked);
    removeModal();
  };

  const handleCheckbox = (user: IUser, checked: boolean) => {
    const listChecked = checked
      ? [...usersChecked, user]
      : usersChecked.filter((u) => u.id !== user.id);

    setCheckAll(
      hasFilters()
        ? filteredUsers.every((item) =>
            listChecked.some(({ id }) => item.id === id)
          )
        : users.every((item) => listChecked.some(({ id }) => item.id === id))
    );
    setUsersChecked(listChecked);
  };

  const handleAllCheckbox = (checked: boolean) => {
    return checked
      ? setUsersChecked(
          hasFilters()
            ? [
                ...usersChecked,
                ...users.filter((user) =>
                  filteredUsers.some(
                    ({ id }) =>
                      user.id === id &&
                      usersChecked.every((item) => item.id !== id)
                  )
                )
              ]
            : users
        )
      : setUsersChecked(
          hasFilters()
            ? usersChecked.filter(
                (item) => !filteredUsers.some(({ id }) => item.id === id)
              )
            : []
        );
  };

  const getUsers = async () => {
    setLoadingGetUsers(true);
    try {
      const request = await get<IDataResponseModel<IUser[]>>(
        API.COMUNICADO.USUARIOS
      );
      setUsers(request.data);
    } catch (error) {
      setUsers([]);
      setError(true);
    } finally {
      setLoadingGetUsers(false);
    }
  };

  useEffect(() => {
    getUsers();
    setUsersChecked(destinatarios);
  }, []);

  useEffect(() => {
    const newArray = users.filter(
      ({ tipoDeUsuario, empresa, subGrupo, nome }) => {
        let match = false;

        if (filters.userType) {
          match = filters.userType === tipoDeUsuario.toString();
          if (filters.userType === 'todos') match = true;
          if (!match) return false;
        }

        if (filters.subGroup) {
          match = filters.subGroup === subGrupo;
          if (filters.subGroup === 'todos') match = true;
          if (!match) return false;
        }

        if (filters.company) {
          match = filters.company === empresa;
          if (filters.company === 'todos') match = true;
          if (!match) return false;
        }

        if (filters.search) {
          match =
            nome
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                filters.search
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              ) ||
            subGrupo
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                filters.search
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              ) ||
            empresa
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                filters.search
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              );
        }

        return match;
      }
    );

    setFilteredUsers(newArray);

    if (users.length > 1) {
      setCheckAll(
        hasFilters()
          ? newArray.every((item) =>
              usersChecked.some(({ id }) => item.id === id)
            )
          : users.every((item) => usersChecked.some(({ id }) => item.id === id))
      );
    }
  }, [filters]);

  const hasFilters = () =>
    filters.company || filters.search || filters.subGroup || filters.userType;

  const pesquisa = (s: string) => {
    const newArray = filteredUsers.length > 0 ? filteredUsers : users;

    setFilteredUsers(
      newArray.filter((item) => {
        return (
          item.nome?.toLowerCase().includes(s?.toLowerCase()) ||
          item.empresa?.toLowerCase().includes(s?.toLowerCase()) ||
          item.subGrupo?.toLowerCase().includes(s?.toLowerCase())
        );
      })
    );
  };

  return (
    <MainContainer>
      <Content>
        <Header>
          <Headline size="four" as="h4">
            Usuários individuais
          </Headline>

          <StyledBody>
            Selecione um destinatário ou mais deste grupo, para receber a
            mensagem que será enviada:
          </StyledBody>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <CustomSelectFields
              selected={!!filters.userType}
              label="Tipo de usuário:"
              onChange={(e) => {
                setFilters({
                  ...filters,
                  userType: e.target.value
                });
              }}
              value={filters.userType}
              options={USER_TYPE_FILTER_OPTIONS}
              name="userType"
              placeholder="Selecione uma opção"
            />
            <CustomSelectFields
              selected={!!filters.company}
              label="Empresa:"
              onChange={(e) => {
                setFilters({ ...filters, company: e.target.value });
              }}
              value={filters.company}
              options={companies.options}
              name="company"
              placeholder="Selecione uma opção"
            />
            <CustomSelectFields
              selected={!!filters.subGroup}
              label="Subgrupo:"
              onChange={(e) => {
                setFilters({ ...filters, subGroup: e.target.value });
              }}
              value={filters.subGroup}
              options={groups.options}
              name="subGroup"
              placeholder="Selecione uma opção"
            />
            <div
              style={{
                color:
                  filters.company !== '' ||
                  filters.subGroup !== '' ||
                  filters.userType !== ''
                    ? '#3063e9'
                    : '#9da7b1de',
                fontWeight: 'bold',
                cursor: 'pointer'
              }}
              aria-hidden="true"
              onClick={() =>
                setFilters({
                  ...filters,
                  userType: '',
                  company: '',
                  subGroup: ''
                })
              }
            >
              Limpar filtros
            </div>
          </div>

          <SearchBar>
            <SearchInput
              label="Buscar:"
              autoComplete="off"
              onClear={() => {
                setSearch('');
                setFilters({ ...filters, search: '' });
              }}
              clearLabel="clearable"
              value={search}
              name="search"
              placeholder="Digite uma pesquisa"
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value === '') {
                  setFilters({ ...filters, search: '' });
                  setSearch('');
                  pesquisa('');
                }
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && search !== '') {
                  e.preventDefault();
                  setFilters({ ...filters, search });
                  pesquisa(search);
                }
              }}
            />
            <SearchIcon
              active={search !== ''}
              onClick={() => {
                if (search !== '') {
                  setFilters({ ...filters, search });
                  pesquisa(search);
                }
              }}
            >
              <Search size="24" />
            </SearchIcon>
          </SearchBar>
        </Header>

        <Cabecalho>
          <div
            style={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Checkbox
              onChange={(el: { target: { checked: boolean } }) => {
                handleAllCheckbox(el.target.checked);
                setCheckAll(el.target.checked);
              }}
              checked={checkAll}
              defaultChecked={checkAll}
              style={{ marginTop: -15 }}
            />
          </div>
          <p>Tipo de usuário</p>
          <p>Nome</p>
          <p>Empresa</p>
          <p>Subgrupo</p>
        </Cabecalho>

        <GridUsers
          error={error}
          handleCheckbox={handleCheckbox}
          users={
            filteredUsers.length > 0 || hasFilters() ? filteredUsers : users
          }
          usersChecked={usersChecked}
          loadingGetUsers={loadingGetUsers}
        />
      </Content>
      <StyledCardFooter align="right">
        {!error && !loadingGetUsers && (
          <FormTip>
            <span
              style={{
                fontSize: '0.85em'
              }}
            >
              <b>Atenção:</b> Você selecionou <b>{usersChecked.length}</b>{' '}
              destinatários da lista de <b>Usuários Individuais.</b> Caso a
              seleção esteja correta, clique em{' '}
              <b>&quot;Escrever mensagem&quot;</b>.
            </span>
          </FormTip>
        )}

        <BtnGroup>
          <StyledButton
            size="giga"
            variant="secondary"
            type="button"
            onClick={removeModal}
          >
            Voltar
          </StyledButton>
          <StyledButton
            loadingLabel="Carregando..."
            variant="primary"
            size="giga"
            type="button"
            disabled={error || loadingGetUsers}
            onClick={handleSelectUsers}
          >
            Escrever mensagem
          </StyledButton>
        </BtnGroup>
      </StyledCardFooter>
    </MainContainer>
  );
};

export default SelectUserDialog;
