import styled from '@emotion/styled';
import { ElementType, FC, HTMLAttributes } from 'react';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  icon?: ElementType;
}

const ContainerStyle = styled.div`
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const CardTitle: FC<IProps> = ({ title, icon: Icon, ...rest }) => {
  return (
    <ContainerStyle {...rest}>
      {title}
      {Icon && <Icon style={{ marginLeft: 16 }} />}
    </ContainerStyle>
  );
};

export default CardTitle;
