import React, { memo, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from '@sumup/icons';
import { StyledReactPaginateBox } from './styles';

interface ICustomPagination {
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  totalItems: number;
  shouldResetPagination?: boolean;
  initialPage?: number;
}
const CustomPagination: React.FC<ICustomPagination> = ({
  itemsPerPage,
  onPageChange,
  totalItems,
  shouldResetPagination = false,
  initialPage = 1
}) => {
  // We start with an empty list of items.
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [forcePage, setForcePage] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    setPageCount(Math.ceil(totalItems / itemsPerPage));
  }, [itemOffset, itemsPerPage, totalItems]);

  // Invoke when user click to request another page.
  const handlePageClick = (selected: number) => {
    const newOffset = (selected * itemsPerPage) % totalItems;
    onPageChange(selected);
    setForcePage(selected);
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (shouldResetPagination) {
      handlePageClick(0);
      setForcePage(0);
    }
  }, [shouldResetPagination]);
  return (
    <>
      <StyledReactPaginateBox>
        <ReactPaginate
          breakLabel="..."
          previousClassName="arrows-link"
          previousLinkClassName="arrows-link"
          nextClassName="arrows-link"
          nextLinkClassName="arrows-link"
          activeClassName="active"
          onPageChange={(e) => handlePageClick(e.selected)}
          forcePage={forcePage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          nextLabel={<ChevronRight size="16" />}
          previousLabel={<ChevronLeft size="16" />}
          initialPage={initialPage - 1}
        />
      </StyledReactPaginateBox>
    </>
  );
};

export default memo(CustomPagination);
