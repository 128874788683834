import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

interface Props {
  size?: string;
  color?: string;
  width?: string;
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerStyle = styled.div<Props>`
  border: ${(e) => e.width} solid
    ${(props) => (props.color ? props.color : props.theme.colors.p500)};
  border-top: ${(e) => e.width} solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: ${({ size }) => size || '14px'};
  height: ${({ size }) => size || '14px'};
  animation: ${spin} 1s linear infinite;
  margin: auto;
`;

const Spinner: React.FC<Props> = ({ size, color, width = '2px' }) => {
  return <SpinnerStyle id="spinner" size={size} color={color} width={width} />;
};

export default Spinner;
