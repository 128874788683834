import styled from '@emotion/styled';

export const StyledHeader = styled.header`
  padding-left: 43px;
  height: 64px;
  background-color: #f9f9f9;
  position: sticky;
  top: 0;
  z-index: 101;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 18% 21% 27% 23% 5%;
`;
export const StyledRow = styled.div`
  padding-left: 43px;
  height: 64px;
  border-bottom: 1px solid ${(p) => p.theme.colors.shadow};
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 18% 21% 27% 23% 5%;
`;
export const StyledHeaderItem = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledRowItem = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  & > div {
    width: 100%;
  }
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
