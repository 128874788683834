import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomSpinner } from '../../components/_Atoms';
import { API } from '../../constants/api.constants';
import { IAddOfferForm } from '../../interfaces/offers.interfaces';
import {
  IDataResponseModel,
  IResponseOfertaById
} from '../../interfaces/requests.interfaces';
import { get } from '../../services/axios.service';
import { formatNumberToCurrency } from '../../utils/format.utils';
import AddOfferPage from '../AddOffer';

const EditOfferByIdPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [offer, setOffer] = useState<IAddOfferForm | null>(null);

  useEffect(() => {
    if (!id) return;
    const getOffer = async () => {
      try {
        const { data } = await get<IDataResponseModel<IResponseOfertaById>>(
          API.OFERTAS.GET(id)
        );
        if (data) {
          setOffer({
            name: data.nome,
            ofertas: data.planosDaOferta.map((offer) => ({
              id: offer.id,
              alcada: offer.alcada,
              condicoes: offer.condicoes,
              faturamentoFinal: formatNumberToCurrency(offer.fim),
              faturamentoInicial: formatNumberToCurrency(offer.inicio),
              grupoMccs: String(offer.grupoMCCId),
              nivel: offer.nivel,
              planoTaxas: String(offer.planoId),
              remuneracao: offer.remuneracao,
              removido: offer.removido ?? false,
              tipoTransacao: String(offer.tipoTransacao),
              valorMaquina: offer.valorMaquina
            }))
          });
        }
      } catch (error) {
        setOffer(null);
      }
    };
    getOffer();
  }, [id]);

  return offer ? (
    <AddOfferPage isEditable offerInitialValues={offer} idOffer={Number(id)} />
  ) : (
    <CustomSpinner />
  );
};

export default EditOfferByIdPage;
