import React from 'react';
import MenuCard from '../MenuCard';
import { MenuItem } from '../../../interfaces/menu.interfaces';
import { ColWrapper, InlineElements } from './styles';

interface IProps {
  menuCardList: MenuItem[];
  columns: 'TWO' | 'THREE';
}
const MenuCardList: React.FC<IProps> = ({ menuCardList, columns }) => {
  const getSpanCols = () => {
    return columns === 'THREE' ? { kilo: 6, mega: 4 } : { kilo: 6, mega: 6 };
  };
  return (
    <>
      {menuCardList.length && (
        <InlineElements
          data-testid="inline-elements"
          css={{ maxWidth: columns === 'THREE' ? '1046px' : '685px' }}
        >
          {menuCardList.map((item, index) => (
            <ColWrapper span={getSpanCols()} key={index}>
              <MenuCard {...item} />
            </ColWrapper>
          ))}
        </InlineElements>
      )}
    </>
  );
};

export default MenuCardList;
