import { MenuItem } from '../interfaces/menu.interfaces';
import {
  MENU_ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from './routes.constants';

import { ReactComponent as AssetManagement } from '../assets/asset_management_button.svg';
import { ReactComponent as HistoryFiles } from '../assets/computer.svg';
import { ReactComponent as ExtractReport } from '../assets/extract_report.svg';
import { ReactComponent as AddItem } from '../assets/icons/add_item.svg';
import { ReactComponent as AddUser } from '../assets/icons/add_user.svg';
import { ReactComponent as ApprovalByAuthority } from '../assets/icons/approval_by_authority.svg';
import { ReactComponent as CampaignsManagement } from '../assets/icons/campaigns_management.svg';
import { ReactComponent as EditUser } from '../assets/icons/edit_user.svg';
import { ReactComponent as PriceMachines } from '../assets/icons/machines_prices.svg';
import { ReactComponent as MCCsGroups } from '../assets/icons/mccs_groups.svg';
import { ReactComponent as MessagesHistory } from '../assets/icons/message_history_icon.svg';
import { ReactComponent as MessagesCenter } from '../assets/icons/message_icon.svg';
import { ReactComponent as Offers } from '../assets/icons/offers.svg';
import { ReactComponent as RatePlans } from '../assets/icons/rate_plans.svg';
import { ReactComponent as SendMessage } from '../assets/icons/send_messages_icon.svg';
import { ReactComponent as UserManagement } from '../assets/icons/user_management.svg';
import { ReactComponent as KanbanLeads } from '../assets/kanban_button.svg';
import { ReactComponent as Machine } from '../assets/machine.svg';
import { ReactComponent as Proposal } from '../assets/proposal.svg';
import { ReactComponent as TasksList } from '../assets/task_list.svg';
import { ReactComponent as MachineCompliance } from '../assets/machine_compliance.svg';

export const MENU_ITEMS: MenuItem[] = [
  {
    title: 'Gestão de usuários',
    description: 'Administre os usuários cadastrados na plataforma',
    thumbnail: UserManagement,
    routeToDetails: MENU_ROUTES.USER_MANAGEMENT
  },
  {
    title: 'Gestão de campanhas',
    description: 'Administre as campanhas cadastradas na plataforma',
    thumbnail: CampaignsManagement,
    routeToDetails: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
  },
  {
    title: 'Gestão de propostas',
    description: 'Administre as ofertas feitas pelos promotores de vendas',
    thumbnail: ApprovalByAuthority,
    routeToDetails: MENU_ROUTES.PROPOSALS_MANAGEMENT
  },
  {
    title: 'Gestão de tarefas',
    description: 'Faça upload e acompanhe as listas de tarefas',
    thumbnail: TasksList,
    routeToDetails: MENU_ROUTES.TASK_LIST_MANAGEMENT
  },
  {
    title: 'Gestão de visitas',
    description: 'Faça o acompanhamento dos promotores e suas visitas',
    thumbnail: KanbanLeads,
    routeToDetails: MENU_ROUTES.KANBAN
  },
  {
    title: 'Gestão de ativos',
    description: 'Administre seus ativos e de sua equipe',
    thumbnail: AssetManagement,
    routeToDetails: MENU_ROUTES.ASSET_MANAGEMENT
  },
  {
    title: 'Central de mensagens',
    description: 'Envie e acompanhe as estatísticas de mensagens',
    thumbnail: MessagesCenter,
    routeToDetails: MENU_ROUTES.MESSAGES_CENTER
  }
];

export const MENU_ITEMS_LEADER = MENU_ITEMS.filter(
  (item) => !['Gestão de campanhas'].includes(item.title)
);

export const MESSAGE_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Enviar mensagem',
    description: 'Contate os usuários cadastrados na plataforma',
    thumbnail: SendMessage,
    routeToDetails: SUB_MENU_ROUTES.SEND_MESSAGE
  },
  {
    title: 'Histórico de mensagens',
    description: 'Veja as mensagens enviadas e suas estatísticas',
    thumbnail: MessagesHistory,
    routeToDetails: SUB_MENU_ROUTES.MESSAGE_HISTORY
  }
];

export const USER_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Cadastrar novo usuário',
    description: 'Insira novos usuários na base de dados',
    thumbnail: AddUser,
    routeToDetails: SUB_MENU_ROUTES.ADD_USER
  },
  {
    title: 'Editar usuários',
    description: 'Edite as informações de usuários já cadastrados',
    thumbnail: EditUser,
    routeToDetails: SUB_MENU_ROUTES.EDIT_USER
  }
];

export const OFFER_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Grupos de MCCs',
    description: 'Gerencie os grupos de MCCs cadastrados na base',
    thumbnail: MCCsGroups,
    routeToDetails: SUB_MENU_ROUTES.MCCS_GROUPS
  },
  {
    title: 'Planos de taxas',
    description: 'Gerencie os planos de taxas SumUp',
    thumbnail: RatePlans,
    routeToDetails: SUB_MENU_ROUTES.RATE_PLANS
  },
  {
    title: 'Preços de máquinas',
    description: 'Configure o valor padrão de venda das máquinas',
    thumbnail: PriceMachines,
    routeToDetails: SUB_MENU_ROUTES.MACHINES_PRICES
  },
  {
    title: 'Ofertas',
    description: 'Gerencie as ofertas da SumUp',
    thumbnail: Offers,
    routeToDetails: SUB_MENU_ROUTES.OFFERS
  },
  {
    title: 'Campanhas',
    description: 'Gerencie as campanhas da SumUp',
    thumbnail: EditUser,
    routeToDetails: SUB_MENU_ROUTES.CAMPAIGNS
  }
];

export const OFFER_MENU_ITEM_LEADER = OFFER_MENU_ITEMS.filter(
  (item) => !['Preços de máquinas'].includes(item.title)
);

export const MCCS_GROUPS_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Novo Grupo MCC',
    description: 'Cadastre um novo grupo MCC na sua base de dados',
    thumbnail: AddUser,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.ADD_MCC_GROUP
  },
  {
    title: 'Editar Grupo MCC',
    description: 'Edite as informações de um grupo MCC já cadastrado',
    thumbnail: EditUser,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.EDIT_MCC_GROUP
  }
];

export const RATE_PLANS_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Novo plano de taxas',
    description: 'Cadastre um novo plano de taxas em sua base de dados',
    thumbnail: AddUser,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.ADD_RATE_PLANS
  },
  {
    title: 'Editar plano de taxas',
    description: 'Edite as informações de um plano de taxas já cadastrado',
    thumbnail: EditUser,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.EDIT_RATE_PLANS
  }
];

export const OFFERS_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Nova oferta',
    description: 'Cadastre uma nova oferta em sua base de dados',
    thumbnail: AddUser,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.ADD_OFFER
  },
  {
    title: 'Editar oferta',
    description: 'Edite as informações de uma oferta já cadastrada',
    thumbnail: EditUser,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.EDIT_OFFER
  }
];

export const MACHINES_PRICES_ITEMS: MenuItem[] = [
  {
    title: 'Novo preço',
    description: 'Crie um novo preço de máquina no sistema',
    thumbnail: AddItem,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.ADD_MACHINE_PRICES
  },
  {
    title: 'Editar preços',
    description: 'Edite preços de máquinas já cadastrados',
    thumbnail: SendMessage,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.EDIT_MACHINE_PRICES
  }
];

export const PROPOSAL_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Propostas',
    description: 'Visualize, aprove ou reprove propostas já criadas',
    thumbnail: Proposal,
    routeToDetails: SUB_MENU_ROUTES.PROPOSALS
  },
  {
    title: 'Relatório de comodato',
    description: 'Extraia as imagens das vendas via comodato',
    thumbnail: ExtractReport,
    routeToDetails: SUB_MENU_ROUTES.EXTRACT_REPORT
  },
  {
    title: 'Histórico de arquivos',
    description: 'Veja as propostas e relatórios de comodatos gerados',
    thumbnail: HistoryFiles,
    routeToDetails: SUB_MENU_ROUTES.HISTORY_FILES
  }
];

export const TASK_LIST_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Lista de tarefas',
    description: 'Faça e consulte uploads de lista de tarefas',
    thumbnail: Proposal,
    routeToDetails: SUB_MENU_ROUTES.TASK_LIST
  },
  {
    title: 'Gerenciar tarefas',
    description: 'Faça o acompanhamento das tarefas',
    thumbnail: ExtractReport,
    routeToDetails: SUB_MENU_ROUTES.TASK_LISK_FOLLOW_UP
  }
];

export const LEADER_TASK_LIST_MENU_ITEMS = TASK_LIST_MENU_ITEMS.filter(
  (item) => !['Lista de tarefas'].includes(item.title)
);

export const LEADER_PROPOSAL_MENU_ITEMS = PROPOSAL_MENU_ITEMS.filter(
  (item) => !['Relatório de comodato'].includes(item.title)
);

export const ASSET_MANAGEMENT: MenuItem[] = [
  {
    title: 'Controle de máquinas',
    description: "Consulte SN's disponíveis para venda por usuário",
    thumbnail: Proposal,
    routeToDetails: SUB_MENU_ROUTES.ASSET_CONTROL
  },
  {
    title: 'Atribuição de máquinas',
    description: 'Gerencie a atribuição de máquinas do seu estoque',
    thumbnail: Machine,
    routeToDetails: SUB_MENU_ROUTES.MACHINE_ALLOCATION
  },
  {
    title: 'Histórico de atribuições',
    description: 'Consulte o histórico das máquinas reatribuídas',
    thumbnail: MachineCompliance,
    routeToDetails: SUB_MENU_ROUTES.MACHINE_HISTORY_ALLOCATION
  }
  // {
  //   title: 'Troca / Devolução',
  //   description: 'Gerencie as trocas e devoluções de máquinas',
  //   thumbnail: MachineExchange,
  //   routeToDetails: SUB_MENU_ROUTES.EXCHANGE_AND_RETURN
  // }
];

export const MACHINE_EXCHANGE: MenuItem[] = [
  {
    title: 'Solicitações',
    description: 'Veja as solicitações de troca e devolução',
    thumbnail: Proposal,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS
  },
  {
    title: 'Trocar / Devolver',
    description: 'Realize o registro de troca ou devolução de máquinas',
    thumbnail: Machine,
    routeToDetails: THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_REGISTER
  }
];
