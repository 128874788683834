import { RadioButton } from '@sumup/circuit-ui';
import { FC, useEffect, useState } from 'react';
import { API } from '../../../constants/api.constants';
import { USER_TYPE_BY_ENUM } from '../../../constants/user.constants';
import { usePagination } from '../../../hooks/usePagination';
import { get } from '../../../services/axios.service';
import { buildQueryParams } from '../../../services/filters.service';
import { calculateTextWidth } from '../../../utils/format.utils';
import {
  CountItensPerPage,
  CustomPagination,
  CustomTooltip,
  EmptyValue,
  Spinner
} from '../../_Atoms';
import { NoResultsState } from '../../_Molecules';
import {
  Container,
  PaginationContainer,
  ResultOneLine,
  ResultValue,
  SpinnerContainer,
  TableAssetManagementHeader,
  TableAssetManagementResults
} from './styles';

interface Results {
  id: number;
  nome: string;
  tipoDeUsuario: 1 | 2 | 3;
  email: string;
  empresa: string;
  subgrupo: string;
  lider: string;
  partnerCode: string;
}

interface IProps {
  setPartnerCode: (p: { name: string; partnerCode: string }) => void;
  search: string;
  setSearch: (p: string) => void;
}

const TableAssetManagement: FC<IProps> = ({
  setPartnerCode,
  search,
  setSearch
}) => {
  const [values, setValues] = useState<Results[]>([]);
  const [selected, setSelected] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const { totalItems, setTotalItems } = usePagination();
  const [currentPage, setCurrentPage] = useState(1);
  const itensPerPage = 6;

  const getUsers = async (page: number) => {
    setLoading(true);
    setError(false);
    const queryParams = buildQueryParams({
      Busca: search.trim(),
      PageNumber: `${page}`,
      PageSize: `${itensPerPage}`
    });

    try {
      const request = await get<{
        data: { results: Results[]; totalItems: number };
      }>(`${API.CONTROLE_ATIVOS.LISTAR}${queryParams}`);

      setValues(request.data.results);
      setTotalItems(request.data.totalItems);
    } catch (e) {
      setValues([]);
      setError(true);
    }
    setLoading(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page + 1);
    getUsers(page + 1);
    setSelected(-1);
    setPartnerCode({ name: '', partnerCode: '' });
  };

  const handleSelect = (item: Results) => {
    if (item.id !== selected) {
      setPartnerCode({
        name: item.nome,
        partnerCode: item.partnerCode
      });
      setSelected(item.id);
    } else {
      setSelected(-1);
      setPartnerCode({ name: '', partnerCode: '' });
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setResetPagination(false);
      await getUsers(1);
      setResetPagination(true);
    };
    loadInitialData();
    setCurrentPage(1);
  }, [search]);

  return (
    <Container>
      <TableAssetManagementHeader>
        <p />
        <p>Tipo</p>
        <p>Nome</p>
        <p>E-mail</p>
        <p>Empresa</p>
        <p>Subgrupo</p>
        <p>Liderança de vendas</p>
        <p>Partner code</p>
      </TableAssetManagementHeader>

      {values && !loading ? (
        values.map((item) => {
          return (
            <TableAssetManagementResults
              onClick={() => handleSelect(item)}
              selected={selected === item.id}
            >
              <RadioButton
                style={{ marginLeft: 20, zIndex: -1 }}
                checked={selected === item.id}
              />
              <ResultValue>{USER_TYPE_BY_ENUM[item.tipoDeUsuario]}</ResultValue>

              <CustomTooltip
                disableHoverListener={calculateTextWidth(13, item.nome) < 210}
                title={item.nome}
              >
                <ResultValue>
                  {item.nome || <EmptyValue value="[Sem nome]" />}
                </ResultValue>
              </CustomTooltip>

              <CustomTooltip title={item.email}>
                <ResultOneLine>{item.email}</ResultOneLine>
              </CustomTooltip>

              <CustomTooltip
                disableHoverListener={
                  calculateTextWidth(13, item.empresa) < 210
                }
                title={item.empresa}
              >
                <ResultValue>
                  {item.empresa || <EmptyValue value="[Sem nome]" />}
                </ResultValue>
              </CustomTooltip>

              <CustomTooltip
                disableHoverListener={
                  calculateTextWidth(13, item.subgrupo) < 210
                }
                title={item.subgrupo}
              >
                <ResultValue>
                  {item.subgrupo || <EmptyValue value="[Sem nome]" />}
                </ResultValue>
              </CustomTooltip>

              <CustomTooltip
                disableHoverListener={calculateTextWidth(13, item.lider) < 210}
                title={item.lider}
              >
                <ResultValue>
                  {item.lider || <EmptyValue value="Nenhuma liderança" />}
                </ResultValue>
              </CustomTooltip>
              <CustomTooltip
                title={item.partnerCode}
                disableHoverListener={
                  calculateTextWidth(13, item.partnerCode) < 100
                }
              >
                <ResultOneLine>
                  {item.partnerCode || <EmptyValue value="[Vazio]" />}
                </ResultOneLine>
              </CustomTooltip>
            </TableAssetManagementResults>
          );
        })
      ) : (
        <SpinnerContainer>
          <Spinner size="46px" color="#3388ff" width="5px" />
        </SpinnerContainer>
      )}
      {!values.length && !error && !loading && (
        <NoResultsState action={() => setSearch('')} />
      )}

      {values.length > 0 && (
        <PaginationContainer>
          <CountItensPerPage
            currentPage={currentPage}
            itensPerPage={itensPerPage}
            totalItens={totalItems}
          />
          {resetPagination && (
            <CustomPagination
              onPageChange={handlePageChange}
              itemsPerPage={itensPerPage}
              totalItems={totalItems}
              initialPage={currentPage}
            />
          )}
        </PaginationContainer>
      )}
    </Container>
  );
};

export default TableAssetManagement;
