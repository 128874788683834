import styled from '@emotion/styled';

export const RedirectMessageStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 24px;
  font-size: 13px;
  font-weight: bold;
  background-color: #eaeffd;
  color: #3063e9;
  border-radius: 8px;
  div {
    display: flex;
    align-items: center;

    p {
      margin-left: 10px;
      font-weight: normal;
    }
  }
`;

export const RedirectButton = styled.div`
  display: flex;
  cursor: pointer;

  p {
    font-weight: bold !important;
  }
`;
