import styled from '@emotion/styled';
import { Body, Headline } from '@sumup/circuit-ui';

export const StyledHeadline = styled(Headline)`
  font-size: 14px;
`;

export const StyledBody = styled(Body)`
  font-size: 12px;
`;
