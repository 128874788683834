import React from 'react';
import { useHistory } from 'react-router';
import { Body, InlineElements } from '@sumup/circuit-ui';
import { MenuItem } from '../../../interfaces/menu.interfaces';
import { StyledCard, StyledHeadline } from './styles';

const MenuCard: React.FC<MenuItem> = ({
  description,
  routeToDetails,
  thumbnail: Thumbnail,
  title
}) => {
  const router = useHistory();

  return (
    <StyledCard onClick={() => router.push(routeToDetails)}>
      <InlineElements>
        <div>
          <StyledHeadline noMargin as="h2" size="four">
            {title}
          </StyledHeadline>
          <Body noMargin size="two">
            {description}
          </Body>
        </div>
        <div>
          <Thumbnail height="63px" />
        </div>
      </InlineElements>
    </StyledCard>
  );
};

export default MenuCard;
