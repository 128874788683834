import React, { memo, useState } from 'react';
import { Input, InputProps } from '@sumup/circuit-ui';
import { View, Hide } from '@sumup/icons';
import { StyledIcon, StyledInput } from './styles';

interface IInputFIeld extends InputProps {
  errorMessage?: string;
  isTableComponent?: boolean;
}

const InputField: React.FC<IInputFIeld> = (props: IInputFIeld) => {
  const {
    isTableComponent = false,
    errorMessage,
    validationHint,
    invalid,
    ...rest
  } = props;

  const [inputType, setInputType] = useState(rest.type);

  const handleClickShowPassword = () => {
    const typechanged = inputType === 'text' ? 'password' : 'text';
    setInputType(typechanged);
  };

  const PasswordVisibility: React.FC = () => {
    return (
      <>
        {rest.type === 'password' && (
          <StyledIcon id="toggle-password" onClick={handleClickShowPassword}>
            {inputType === 'password' ? <Hide /> : <View />}
          </StyledIcon>
        )}
      </>
    );
  };

  const textValidationHint = () => {
    return invalid && errorMessage ? errorMessage : validationHint;
  };

  return isTableComponent ? (
    <StyledInput
      {...props}
      name={rest.name}
      label={rest.label}
      placeholder={rest.placeholder}
      validationHint={textValidationHint()}
      autoComplete="off"
      disabled={rest.disabled}
      onChange={rest.onChange}
      type={inputType}
      renderSuffix={() => <PasswordVisibility />}
    />
  ) : (
    <Input
      {...props}
      name={rest.name}
      label={rest.label}
      placeholder={rest.placeholder}
      validationHint={textValidationHint()}
      autoComplete="off"
      disabled={rest.disabled}
      onChange={rest.onChange}
      type={inputType}
      renderSuffix={() => <PasswordVisibility />}
    />
  );
};

export default memo(InputField);
