import styled from '@emotion/styled';

export const StyledContentBox = styled.div`
  width: 1340px;
  margin: 25px auto;
`;

export const StyledListBox = styled.div`
  max-width: 1340px;
  width: 100%;
  margin: 0 auto 20px auto;
`;
export const StyledPaginationBox = styled.div`
  display: flex;
  justify-content: center;
`;
