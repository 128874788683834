import { FC } from 'react';

const SVGNenhumaClassificacao: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8.5C14 11.8137 11.3137 14.5 8 14.5C4.68667 14.5 2 11.8137 2 8.5C2 5.18667 4.68667 2.5 8 2.5C11.3137 2.5 14 5.18667 14 8.5Z"
        fill="#F6CC1B"
      />
      <path
        d="M5.83284 9.16659C6.29308 9.16659 6.66618 8.64425 6.66618 7.99992C6.66618 7.35559 6.29308 6.83325 5.83284 6.83325C5.37261 6.83325 4.99951 7.35559 4.99951 7.99992C4.99951 8.64425 5.37261 9.16659 5.83284 9.16659Z"
        fill="#664500"
      />
      <path
        d="M10.1663 9.16659C10.6266 9.16659 10.9997 8.64425 10.9997 7.99992C10.9997 7.35559 10.6266 6.83325 10.1663 6.83325C9.7061 6.83325 9.33301 7.35559 9.33301 7.99992C9.33301 8.64425 9.7061 9.16659 10.1663 9.16659Z"
        fill="#664500"
      />
      <path
        d="M10.3325 11.1667H5.66585C5.48185 11.1667 5.33252 11.0177 5.33252 10.8333C5.33252 10.649 5.48185 10.5 5.66585 10.5H10.3325C10.5169 10.5 10.6659 10.649 10.6659 10.8333C10.6659 11.0177 10.5169 11.1667 10.3325 11.1667Z"
        fill="#664500"
      />
    </svg>
  );
};

export default SVGNenhumaClassificacao;
