import styled from '@emotion/styled';
import { DateInput } from '@sumup/circuit-ui';
import { MultiSelect } from '../../_Atoms';

export const CustomDate = styled(DateInput)`
  width: 248px;
  margin-right: 24px;
  margin-bottom: 0px;
  border: 1px solid #ced4da;
  box-shadow: none;
  border-radius: 6px;

  @media (max-width: 1500px) {
    width: 170px;
  }

  &:hover {
    border: 1px solid #3388ff;
    box-shadow: none;
  }

  label {
    margin-bottom: 0;
  }
`;

export const MultiSelectStyle = styled(MultiSelect)`
  .p-multiselect {
    min-width: 264px !important;
    margin-right: 20px;
  }
`;

export const FilterTaskListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const FilterTaskListTop = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
`;

export const FilterTaskListBottom = styled.div`
  display: flex;
  margin-bottom: 20px;

  div {
    width: 100%;

    input {
      width: 100%;
    }
  }
`;

export const TaskListStatus = styled.div<{ status: string }>`
  height: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  text-transform: capitalize;
  border-left: 3px solid
    ${({ status }) =>
      status === 'completo'
        ? '#47995A'
        : status === 'parcial'
        ? '#D8A413'
        : '#DB4D4D'};

  color: ${({ status }) =>
    status === 'completo'
      ? '#47995A'
      : status === 'parcial'
      ? '#D8A413'
      : '#DB4D4D'};

  svg {
    margin-right: 13px;
    path {
      stroke: ${({ status }) =>
        status === 'completo'
          ? '#47995A'
          : status === 'parcial'
          ? '#D8A413'
          : '#DB4D4D'};
    }
  }
`;
