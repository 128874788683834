import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import { ROUTES } from '../../constants/routes.constants';
import { useAuth } from '../../contexts/auth.context';

const RouteNotFoundPage: React.FC = () => {
  const { auth } = useAuth();
  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (params.has('idImagem')) {
      localStorage.setItem(
        'ImagemDownload',
        JSON.stringify(params.get('idImagem'))
      );

      if (!auth) alert('Autentique-se para visualizar a imagem');
    } else {
      localStorage.removeItem('ImagemDownload');
    }
  }, []);

  return auth ? (
    <Redirect to={ROUTES.MAIN_MENU} />
  ) : (
    <Redirect to={ROUTES.LOGIN} />
  );
};

export default RouteNotFoundPage;
