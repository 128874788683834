/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar_red.svg';
import { ReactComponent as InfoSessionMessage } from '../../../assets/icons/info_session_message.svg';

import { ReactComponent as Arrow } from '../../../assets/icons/arrow_right.svg';
import { ReactComponent as Done } from '../../../assets/icons/done.svg';
import { ReactComponent as Loading } from '../../../assets/icons/loading.svg';
import { ReactComponent as Trash } from '../../../assets/trash.svg';
import {
  IMachine,
  IMachineView
} from '../../../interfaces/AllocateMachines.interfaces';
import { CustomDropdown, CustomTooltip, FormTip, Spinner } from '../../_Atoms';
import { CustomDialog, Modal, NoResultsState } from '../../_Molecules';
import {
  ActionButton,
  AllocationListContainer,
  DeleteAllSelections,
  InfoContainer,
  Line,
  MachineName,
  ModalHeaders,
  ModalMain,
  PermissionContainer,
  PermissionOptions,
  PromoterName,
  SKU,
  SerialNumber,
  SpinnerContainer,
  Status,
  StatusModal,
  ViewAllSelectionsContainer,
  ViewAllSelectionsCount,
  ViewAllSelectionsOptions,
  ViewAllSelectionsText
} from './style';

interface IProps {
  data: IMachine[];
  loading: boolean;
  teamOptions: { value: number; label: string }[];
  selectedList: IMachineView[];
  setSelectedList: (p: IMachineView[]) => void;
  viewAllSelected: boolean;
  setViewAllSelected: (p: boolean) => void;
}

const AllocationList: FC<IProps> = ({
  data,
  loading,
  teamOptions,
  selectedList,
  setSelectedList,
  viewAllSelected,
  setViewAllSelected
}) => {
  const [dataCopy, setDataCopy] = useState<IMachineView[]>([]);
  const [selectedItem, setSelectedItem] = useState<IMachineView>({
    atribuida: false,
    nomeMaquina: '',
    nomePromotor: '',
    promotorId: 0,
    serialNumber: '',
    sku: '',
    newPromotorId: '0',
    newPromotorName: ''
  });
  const [modalOption, setModalOption] = useState('0');
  const [openModal, setOpenModal] = useState(false);
  const [permissionToEdit, setPermissionToEdit] = useState({
    showMessage: false,
    permission: false,
    dirt: false
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    setDataCopy(
      data.map((item: IMachine) => ({
        ...item,
        selected: false,
        newPromotorId: '',
        newPromotorName: ''
      }))
    );
  }, [data]);

  const getSelectedListItem = (item: IMachine) => {
    return selectedList.filter(
      (s) =>
        s.atribuida === item.atribuida &&
        s.nomeMaquina === item.nomeMaquina &&
        s.nomePromotor === item.nomePromotor &&
        s.promotorId === item.promotorId &&
        s.serialNumber === item.serialNumber &&
        s.sku === item.sku
    )[0];
  };

  const handleRemove = (item: IMachineView) => {
    const newList = selectedList.filter(
      (s) =>
        s.atribuida !== item.atribuida ||
        s.nomeMaquina !== item.nomeMaquina ||
        s.nomePromotor !== item.nomePromotor ||
        s.promotorId !== item.promotorId ||
        s.serialNumber !== item.serialNumber ||
        s.sku !== item.sku
    );
    return newList;
  };

  const equalSelectedList = (item: IMachineView) => {
    return selectedList.some(
      (s) =>
        s.atribuida === item.atribuida &&
        s.nomeMaquina === item.nomeMaquina &&
        s.nomePromotor === item.nomePromotor &&
        s.promotorId === item.promotorId &&
        s.serialNumber === item.serialNumber &&
        s.sku === item.sku
    );
  };

  const generateLine = (item: IMachineView) => {
    return (
      <Line key={item.serialNumber} selected={equalSelectedList(item)}>
        <InfoContainer>
          {equalSelectedList(item) && (
            <div>
              <Trash
                style={{ cursor: 'pointer', width: 28, marginTop: 5 }}
                onClick={() => {
                  setSelectedList(handleRemove(getSelectedListItem(item)));
                  if (selectedList.length === 1) {
                    setViewAllSelected(false);
                  }
                }}
              />
            </div>
          )}

          <Status color={!item.atribuida ? '#3063e9' : '#47995a'}>
            {item.atribuida ? (
              <>
                <Done />
                <p>Máquina atribuida</p>
              </>
            ) : (
              <>
                <Loading />
                <p>Máquina não atribuida</p>
              </>
            )}
          </Status>

          <MachineName>
            <p>{item.nomeMaquina}</p>
          </MachineName>

          <SerialNumber>
            <p>Serial Number: {item.serialNumber}</p>
          </SerialNumber>
          <SKU>
            <p>SKU: {item.sku}</p>
          </SKU>
          <PromoterName>
            Executivo:
            <CustomTooltip
              title={
                getSelectedListItem(item)?.newPromotorName ||
                item.nomePromotor ||
                ''
              }
            >
              <b>
                {getSelectedListItem(item)?.newPromotorName ||
                  item.nomePromotor ||
                  'nenhum'}
              </b>
            </CustomTooltip>
          </PromoterName>
        </InfoContainer>
        <div>
          <ActionButton
            onClick={() => {
              setOpenModal(true);
              setSelectedItem(item);
              setModalOption(getSelectedListItem(item)?.newPromotorId);

              setPermissionToEdit({
                permission: !item.nomePromotor,
                showMessage: !!item.nomePromotor,
                dirt: false
              });
            }}
          >
            {!item.atribuida ? 'Atribuir' : 'Reatribuir'}
          </ActionButton>
        </div>
      </Line>
    );
  };

  return (
    <AllocationListContainer>
      {selectedList.length > 0 && (
        <ViewAllSelectionsContainer>
          <ViewAllSelectionsText>
            <InfoSessionMessage />
            <p>
              Você tem {selectedList.length}{' '}
              {selectedList.length === 1
                ? 'máquina selecionada'
                : 'máquinas selecionadas'}
            </p>
          </ViewAllSelectionsText>

          <ViewAllSelectionsOptions>
            <ViewAllSelectionsCount
              onClick={() => setViewAllSelected(!viewAllSelected)}
            >
              Ver máquinas selecionadas
            </ViewAllSelectionsCount>
            <DeleteAllSelections onClick={() => setOpenDeleteModal(true)}>
              <CalendarIcon />
              Limpar seleção de máquinas
            </DeleteAllSelections>
          </ViewAllSelectionsOptions>
        </ViewAllSelectionsContainer>
      )}

      {!loading &&
        (!viewAllSelected ? dataCopy : selectedList).map((item: IMachineView) =>
          generateLine(item)
        )}

      {data.length === 0 && dataCopy.length === 0 && !loading && (
        <NoResultsState image="primary" title="Nenhuma máquina encontrada" />
      )}

      {loading && (
        <SpinnerContainer>
          <Spinner size="46px" color="#3388ff" width="5px" />
        </SpinnerContainer>
      )}

      <Modal
        title="Atribuir / Reatribuir Máquinas"
        open={openModal}
        setOpen={setOpenModal}
        widthAuto
        action={() => {
          const newListSelected = handleRemove(selectedItem);

          setSelectedList(
            newListSelected.concat({
              ...selectedItem,
              newPromotorId: modalOption,
              newPromotorName: teamOptions.filter(
                (op) => Number(op.value) === Number(modalOption)
              )[0]?.label
            })
          );
          setOpenModal(false);
        }}
        labelAction="Atribuir"
        disableAction={!permissionToEdit.permission || !modalOption}
      >
        <div style={{ width: 720, backgroundColor: 'white' }}>
          <ModalHeaders bold>
            <p>Máquina atribuida</p>
            <p>Tipo de máquina</p>
            <p>Serial number</p>
            <p>SKU</p>
          </ModalHeaders>
          <ModalHeaders>
            <StatusModal
              color={!selectedItem?.atribuida ? '#3063e9' : '#47995a'}
            >
              {selectedItem?.atribuida ? (
                <>
                  <Done />
                  <p>Máquina atribuida</p>
                </>
              ) : (
                <>
                  <Loading />
                  <p>Máquina não atribuida</p>
                </>
              )}
            </StatusModal>
            <p>{selectedItem?.nomeMaquina}</p>
            <p>{selectedItem?.serialNumber}</p>
            <p>{selectedItem?.sku}</p>
          </ModalHeaders>
          <ModalMain>
            <div>
              <p style={{ fontWeight: 'bold', marginBottom: 10 }}>
                Atribuído a
              </p>
              <p>{selectedItem?.nomePromotor || 'ninguém'}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Arrow />
            </div>
            <div>
              <p style={{ fontWeight: 'bold' }}>Atribuir a</p>
              <CustomDropdown
                options={teamOptions.filter(
                  (item) =>
                    item.label !== selectedItem.nomePromotor &&
                    item.value !== selectedItem.promotorId
                )}
                onChangeValue={(e) => setModalOption(e)}
                value={modalOption}
                label=""
                placeholder="Selecione uma opção"
                style={{ marginTop: 14, minWidth: 264 }}
              />
            </div>
          </ModalMain>
          {permissionToEdit.showMessage && !!modalOption && (
            <PermissionContainer>
              <FormTip>
                <b>Atenção:</b> Esta máquina já está atribuída a um executivo,
                deseja manter esta atribuição?
                <PermissionOptions
                  selected={permissionToEdit.permission}
                  onClick={() =>
                    setPermissionToEdit({
                      permission: true,
                      showMessage: true,
                      dirt: true
                    })
                  }
                >
                  Sim
                </PermissionOptions>
                <span style={{ fontSize: 16 }}>/</span>
                <PermissionOptions
                  selected={
                    !permissionToEdit.permission && permissionToEdit.dirt
                  }
                  onClick={() =>
                    setPermissionToEdit({
                      permission: false,
                      showMessage: true,
                      dirt: true
                    })
                  }
                >
                  Não
                </PermissionOptions>
              </FormTip>
            </PermissionContainer>
          )}
        </div>
      </Modal>

      {openDeleteModal && (
        <CustomDialog
          title="Limpar a seleção de máquinas"
          message="Você deseja limpar todas as máquinas atribuídas da seleção de máquinas?"
          handleClose={() => setOpenDeleteModal(false)}
          primaryButton={{
            buttonTitle: 'Sim',
            onClick: () => {
              setSelectedList([]);
              setOpenDeleteModal(false);
              setViewAllSelected(false);
            }
          }}
          secondaryButton={{
            buttonTitle: 'Voltar',
            onClick: () => setOpenDeleteModal(false)
          }}
        />
      )}
    </AllocationListContainer>
  );
};

export default AllocationList;
