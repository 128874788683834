import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from '@emotion/styled';
import { Body, Headline } from '@sumup/circuit-ui';
import {
  CustomButton,
  CustomPagination,
  CustomSpinner
} from '../../components/_Atoms';
import { FilterUserEdit, UserList } from '../../components/_Organisms';

import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';

import { get } from '../../services/axios.service';
import { buildQueryParams } from '../../services/filters.service';

import { usePagination } from '../../hooks/usePagination';

import { Breadcrumb } from '../../components/_Molecules';
import { API } from '../../constants/api.constants';
import { STORAGE } from '../../constants/storage.constants';
import { FiltersEditUser } from '../../interfaces/addUser.interface';
import {
  IDataResponseModel,
  IPagination,
  IRequestPaginatedQueryParams,
  IResponseLogin
} from '../../interfaces/requests.interfaces';
import {
  StyledBoxPage,
  StyledEntriesPerPage,
  StyledFooter,
  StyledTitleInfos
} from './styles';

const EditUserPage: React.FC = () => {
  const [usersPaginated, setUsersPaginated] = useState<IResponseLogin[]>([]);
  const { push } = useHistory();
  const { totalItems, setTotalItems } = usePagination();
  const itemsPerPage = 20;
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FiltersEditUser>(() => {
    const history = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_USER_EDIT) || '{}'
    );

    return {
      company: history.company ?? '',
      status: history.status ?? 'ativos',
      subGroup: history.subGroup ?? '',
      userType: history.userType ?? '',
      search: history.search ?? ''
    };
  });
  const [resetPagination, setResetPagination] = useState(true);
  const [currentPage, setCurrentPage] = useState(() => {
    const history = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_USER_EDIT) || '{}'
    );

    return history.page ? history.page : 1;
  });

  const handleQueryParams = (page: number): string => {
    const params: IRequestPaginatedQueryParams = {
      PageSize: String(itemsPerPage),
      PageNumber: String(page),
      ...(filters.company &&
        filters.company !== 'todos' && { Empresa: filters.company }),
      ...(filters.status &&
        filters.status !== 'todos' && {
          StatusAtivo: String(filters.status === 'ativos')
        }),
      ...(filters.subGroup &&
        filters.subGroup !== 'todos' && { SubGrupo: filters.subGroup }),
      ...(filters.userType &&
        filters.userType !== 'todos' && { TipoUsuario: filters.userType }),
      ...(filters.search && { CampoBusca: filters.search })
    };

    return buildQueryParams(params);
  };

  const loadUsers = async (page: number) => {
    setLoading(true);
    setUsersPaginated([]);
    const queryParams = handleQueryParams(page);
    const path = `${API.USUARIOS.CARREGAR_USUARIOS}${queryParams}`;
    try {
      const { data } = await get<
        IDataResponseModel<IPagination<IResponseLogin>>
      >(path);

      if (data.results) {
        setUsersPaginated(data.results);
        setTotalItems(data.totalItems);
      }
    } catch {
      setUsersPaginated([]);
    }
    setLoading(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page + 1);
    loadUsers(page + 1);
  };

  useEffect(() => {
    const loadData = async () => {
      const history = JSON.parse(
        localStorage.getItem(STORAGE.HISTORY_PAGINATION_USER_EDIT) || '{}'
      );
      localStorage.removeItem(STORAGE.HISTORY_PAGINATION_USER_EDIT);

      setCurrentPage(history.page ?? 1);

      setResetPagination(false);
      await loadUsers(history.page ?? 1);
      setResetPagination(true);
    };

    loadData();
  }, [filters]);

  const BreadcrumbContainer = styled.div`
    max-width: 1000px;
    margin: auto;
    margin-bottom: 25px;
  `;

  return (
    <StyledBoxPage>
      <BreadcrumbContainer>
        <Breadcrumb
          noMargin
          previousRoutes={[
            {
              routeName: 'Menu principal',
              routePath: ROUTES.MAIN_MENU
            },
            {
              routeName: 'Gestão de usuários',
              routePath: MENU_ROUTES.USER_MANAGEMENT
            }
          ]}
          currentRouteName="Editar usuários"
        />
      </BreadcrumbContainer>
      <StyledTitleInfos>
        <Headline size="four" as="h1">
          Editar usuários
        </Headline>
        <Body size="two" as="p">
          Gerencie seu banco de dados de usuários cadastrados no sistema
          administrativo da SumUp:
        </Body>
      </StyledTitleInfos>

      <FilterUserEdit setFilter={setFilters} filters={filters} />

      {!loading && (
        <UserList
          page={currentPage}
          filters={filters}
          userList={usersPaginated}
        />
      )}

      {loading && (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <CustomSpinner />
        </div>
      )}

      <StyledFooter>
        <CustomButton
          onClick={() => push(MENU_ROUTES.USER_MANAGEMENT)}
          id="back-button"
          variant="secondary"
        >
          Voltar
        </CustomButton>

        {totalItems > 20 && resetPagination && (
          <>
            <CustomPagination
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              totalItems={totalItems}
              initialPage={currentPage}
            />

            <StyledEntriesPerPage>
              {totalItems > 20 && `${itemsPerPage} cadastros por página`}
            </StyledEntriesPerPage>
          </>
        )}
      </StyledFooter>
    </StyledBoxPage>
  );
};

export default EditUserPage;
