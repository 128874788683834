import styled from '@emotion/styled';

export const SearchIcon = styled.div<{ active: boolean }>`
  padding: 10px;
  height: 48px;
  width: 48px;
  background-color: ${({ active }) => (active ? '#3063e9' : '#9da7b1de')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 2px;
  margin-left: 30px;
  cursor: pointer;
`;

export const SerialResultsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: flex-start;
  gap: 24px;
  padding: 24px;
  font-size: 13px;
  max-height: 336px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    padding: 0 30px;
    background-color: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3388ff;
    border-radius: 20px;
    border: 4px solid white;
  }

  span {
    font-weight: bold;
    height: 28px;
  }
`;

export const TableResults = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 24px;
  height: 56px;
`;
export const ModalContent = styled.div`
  display: flex;
  max-height: 64px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eef0f2;

  p {
    font-size: 16px;

    span {
      color: #3063e9;
      font-weight: bold;
    }
  }
`;

export const ModalSearchBar = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalLoading = styled.div`
  height: 232px;
  display: flex;
  align-items: center;
`;
