import styled from '@emotion/styled';

export const StyledBoxTable = styled.div`
  margin-bottom: 24px;
  @media (max-width: 1200px) {
    overflow-x: auto;
  }
`;
export const StyledHeaderRow = styled.header`
  height: 64px;
  background-color: #f9f9f9;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr repeat(8, 0.8fr) 0.5fr;
  top: 0;
  z-index: 23;
`;
export const StyledRow = styled.div`
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.shadow};
  padding: 6px 0 0 0;
  height: 64px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr repeat(8, 0.8fr) 0.5fr;
`;

export const StyledHeaderCell = styled.div`
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  line-height: initial;
  :first-child {
    padding-left: 24px;
  }
  :last-child {
    padding-right: 24px;
  }
`;
export const StyledRowItem = styled.p`
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 8px;

  :first-child {
    padding: 0;
  }

  :last-child {
    div {
      display: flex;
      justify-content: center;
    }
  }
  span {
    font-size: 13px;
  }
  & > div {
    width: 100%;
  }
`;
export const StyledIconBox = styled.div`
  cursor: pointer;
`;

export const StatusNotaFiscal = styled.span<{ color: string }>`
  display: flex;
  color: ${({ color }) => color};
  text-align: center;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }
`;
