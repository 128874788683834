import { ChangeEvent, FC, HTMLAttributes } from 'react';
import ValidationMessageError from '../ValidationMessageError';
import { Container, CounterCaracteres, InputStyle } from './styles';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  value: string;
  setValue: (p: string) => void;
  counter?: boolean;
  maxCounter?: number;
  label: string;
  placeholder: string;
  name?: string;
  errorMessage?: string;
  handleChangeEvent?: (p: ChangeEvent<HTMLInputElement>) => void;
}

const InputLabel: FC<IProps> = ({
  value,
  setValue,
  handleChangeEvent,
  counter = false,
  maxCounter = 140,
  label,
  placeholder,
  name,
  errorMessage = '',
  ...props
}) => {
  return (
    <Container {...props}>
      <div>
        <InputStyle
          label={label}
          value={value}
          autoComplete="off"
          onChange={(e) => {
            setValue(e.target.value);

            if (handleChangeEvent) {
              handleChangeEvent(e);
            }
          }}
          placeholder={placeholder}
          type="text"
          name={name}
          maxLength={maxCounter}
          error={!!errorMessage}
        />
        {counter && (
          <CounterCaracteres>
            {value.length}/{maxCounter} caracteres
          </CounterCaracteres>
        )}
      </div>
      {errorMessage && (
        <ValidationMessageError
          style={{ padding: 16, marginTop: 15, marginLeft: 16 }}
          message={errorMessage}
        />
      )}
    </Container>
  );
};

export default InputLabel;
