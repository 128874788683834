import { FC } from 'react';
import { useHistory } from 'react-router';
import { Button, SubTitle, Title } from '../../components/_Atoms';
import { BottomFixedActions, Breadcrumb } from '../../components/_Molecules';
import { HistoryAllocationTable } from '../../components/_Organisms';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';
import { StyledBoxPage } from './style';

const MachineHistoryAllocation: FC = () => {
  const { push } = useHistory();

  return (
    <StyledBoxPage>
      <Breadcrumb
        style={{ margin: 0, padding: 0 }}
        currentRouteName="Histórico de atribuições"
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          },
          {
            routeName: 'Gestão de ativos',
            routePath: MENU_ROUTES.ASSET_MANAGEMENT
          }
        ]}
      />
      <Title style={{ margin: '32px 0px 8px 0px' }} fontSize="big">
        Histórico de atribuição
      </Title>
      <SubTitle>
        Veja os arquivos gerados para atribuição das máquinas entre seu time
      </SubTitle>

      <HistoryAllocationTable />

      <BottomFixedActions style={{ zIndex: 1 }}>
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          value="Voltar"
          variant="secondary"
          onClick={() => push(MENU_ROUTES.ASSET_MANAGEMENT)}
        />
      </BottomFixedActions>
    </StyledBoxPage>
  );
};

export default MachineHistoryAllocation;
