import styled from '@emotion/styled';
import React, { FC } from 'react';

interface IProps {
  children: React.ReactNode;
}

const ContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardRow: FC<IProps> = ({ children }) => {
  return <ContainerStyle>{children}</ContainerStyle>;
};

export default CardRow;
