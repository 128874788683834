import styled from '@emotion/styled';
import { Button } from '@sumup/circuit-ui';

export const StyledButton = styled(Button)`
  margin: 10px 0 20px 16px;
  padding: 7px;
  width: 101px;
`;

export const StyledButtonBox = styled.div`
  text-align: center;
`;
