import styled from '@emotion/styled';
import { StyledHeadline } from '../../components/_Molecules/CardContainer/styles';
import SelectUserDialog from '../../components/SelectUserDialog';

export const StyledContentBox = styled.div`
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
`;
export const StyledForm = styled.div`
  max-width: 1164px;
  margin: auto;
`;
export const CustomHeadline = styled(StyledHeadline)`
  background-color: #f9f9f9;
  width: 100%;
  padding: 20px 48px;
`;
export const InputContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 160px auto;
  align-items: start;
  margin: 28px 0;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-left: 48px;
  }
`;
export const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const StyledSelectUserDialog = styled(SelectUserDialog)`
  width: 70%;
`;
export const StyledDestinatarios = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px 8px;
  max-height: 234px;
  height: auto;
  align-items: center;
  overflow-y: auto;
  padding: 12px 12px 12px 0;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3063e9;
    border-radius: 2px;
  }

  div {
    width: 100%;
    height: 25px;
    background-color: #d8dde1;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      max-width: 15ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
export const StyledInputDestinatarios = styled.div`
  min-height: 48px;
  border: 1px solid rgb(133, 133, 133);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;
