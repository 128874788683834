import styled from '@emotion/styled';

export const PrimaryText = styled.span`
  color: ${(e) => e.theme.colors.b500};
  font-weight: bold;
  font-size: 13px;
`;

export const CountItens = styled.span`
  font-size: 13px;
  color: ${(e) => e.theme.colors.n500};
`;
