import { Headline } from '@sumup/circuit-ui';
import React from 'react';
import { IPlano } from '../../interfaces/requests.interfaces';
import { CustomTooltip } from '../_Atoms';
import {
  MainContainer,
  StyledBody,
  StyledButton,
  StyledCardFooter,
  StyledHeaderCell,
  StyledHeaderRow,
  StyledRow,
  StyledRowItem,
  StyledRowsContainer
} from './styles';

interface IParcelasDetalhadasDialog {
  removeModal: () => void;
  plano: IPlano;
  index: number;
}

const ParcelasDetalhadasDialog: React.FC<IParcelasDetalhadasDialog> = ({
  removeModal,
  plano,
  index
}) => {
  return (
    <MainContainer>
      <Headline size="four" as="h4">
        Detalhamento de Parcelas
      </Headline>
      <StyledBody>
        Vizualize as taxas distribuídas de acordo com a bandeira e/ou parcelas.
      </StyledBody>
      <StyledHeaderRow>
        <StyledHeaderCell style={{ paddingLeft: '25px' }}>
          Parcelas
        </StyledHeaderCell>
        <StyledHeaderCell style={{ paddingLeft: '35px' }}>
          Visa/Master
        </StyledHeaderCell>
        <StyledHeaderCell>Outras Bandeiras</StyledHeaderCell>
      </StyledHeaderRow>
      <StyledRowsContainer>
        {plano.taxas[index].parcelas.map((element) => {
          return (
            <StyledRow>
              <CustomTooltip title={element.quantidadeParcelas}>
                <StyledRowItem>{element.quantidadeParcelas}</StyledRowItem>
              </CustomTooltip>

              <CustomTooltip title={element.porcentagemVisaMaster}>
                <StyledRowItem>{element.porcentagemVisaMaster}</StyledRowItem>
              </CustomTooltip>

              <CustomTooltip title={element.porcentagemOutrasBandeiras}>
                <StyledRowItem>
                  {element.porcentagemOutrasBandeiras}
                </StyledRowItem>
              </CustomTooltip>
            </StyledRow>
          );
        })}
      </StyledRowsContainer>
      <StyledCardFooter align="right">
        <StyledButton
          size="giga"
          variant="secondary"
          type="button"
          onClick={removeModal}
        >
          Voltar
        </StyledButton>
      </StyledCardFooter>
    </MainContainer>
  );
};

export default ParcelasDetalhadasDialog;
