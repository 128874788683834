import { FC } from 'react';

const SVGDownload: FC<{ color?: string }> = ({ color = '#3063E9' }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 14V16.625H4.375V14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 8.75L10.5 12.25L7 8.75" fill={color} />
      <path
        d="M10.5 4.375V12.25M10.5 12.25L14 8.75M10.5 12.25L7 8.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SVGDownload;
