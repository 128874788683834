import { DateInput } from '@sumup/circuit-ui';
import { useState } from 'react';
import { IDataResponseModel } from '../../../interfaces/requests.interfaces';
import { post } from '../../../services/axios.service';
import { ValidationMessageError } from '../../_Atoms';
import Modal from '../Modal';
import { IToast } from '../Toast';
import { DateInputContainer, ExportModalContainer, SubTitle } from './style';

interface IProps {
  open: boolean;
  setOpen: (p: boolean) => void;
  title: string;
  subtitle: string;
  urlToExport: string;
  maxDays: number;
  action: (p: any) => void;
  setToastList: (item: IToast[]) => void;
}
const RangeDateModal: React.FC<IProps> = ({
  open,
  setOpen,
  title,
  subtitle,
  maxDays,
  urlToExport,
  action,
  setToastList
}) => {
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    isError: false,
    message: ''
  });

  const checkDateInterval = (): boolean => {
    const timeDifference = Math.abs(
      new Date(dateStart).getTime() - new Date(dateEnd).getTime()
    );
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysDifference < maxDays;
  };

  const checkDateOrder = (startDate: Date, endDate: Date): boolean => {
    return startDate <= endDate;
  };

  const exportDownload = async () => {
    setLoading(true);
    action(false);
    setError({ isError: false, message: '' });

    try {
      const response = await post<IDataResponseModel<null>>(urlToExport, {
        dataInicial: dateStart,
        dataFinal: dateEnd
      });

      if (response.success) {
        action(true);
        setOpen(false);
        setDateEnd('');
        setDateStart('');
        setToastList([
          {
            id: 1,
            title: 'Arquivo em processamento',
            description: 'Acompanhe pelo histórico de arquivos.',
            type: 'sucess'
          }
        ]);
      }
    } catch (error) {
      if (error) {
        setError({
          isError: true,
          message: 'Não foi possível iniciar o processamento. Tente novamente.'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      maxWidth={760}
      open={open}
      setOpen={!loading ? setOpen : () => null}
      title={title}
      showFooter
      loading={loading}
      action={exportDownload}
      disableAction={
        !checkDateInterval() ||
        !checkDateOrder(new Date(dateStart), new Date(dateEnd)) ||
        !dateStart ||
        !dateEnd ||
        loading
      }
      labelAction="Exportar"
    >
      <ExportModalContainer>
        <SubTitle>{subtitle}</SubTitle>
        <DateInputContainer>
          <DateInput
            value={dateStart}
            onChange={(e) => setDateStart(e.target.value)}
            label="Data inicial"
            style={{ marginRight: 16, width: 224 }}
          />
          <DateInput
            value={dateEnd}
            onChange={(e) => setDateEnd(e.target.value)}
            label="Data final"
            style={{ marginRight: 16, width: 224 }}
          />
        </DateInputContainer>
        <ValidationMessageError
          type="warning"
          style={{ color: 'black' }}
          message={`O período máximo de exportação é de ${maxDays} dias`}
        />
        {!checkDateOrder(new Date(dateStart), new Date(dateEnd)) &&
          dateStart &&
          dateEnd && (
            <ValidationMessageError message="A data inicial não pode ser maior que a data final de seleção" />
          )}

        {!loading && error.isError && (
          <ValidationMessageError message={error.message} type="error" />
        )}
      </ExportModalContainer>
    </Modal>
  );
};

export default RangeDateModal;
