/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { FC, useCallback, useEffect, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { TOOLBAR_OPTIONS } from './TextEditorModules.constants';
import { StyleEditor, InvalidFormatImage, Container } from './styles';

interface IProps {
  placeholder?: string;
  setContent?: (value?: any) => void;
  value?: string;
  readonly?: boolean;
}

const TextEditor: FC<IProps> = ({
  placeholder,
  setContent,
  value,
  readonly = false
}) => {
  const [quill, setQuill] = useState<any>();
  const [isInvalidImage, setIsInvalidImage] = useState(false);
  const wrapperRef = useCallback((wrapper: any): void => {
    if (wrapper === null) return;

    const Block = Quill.import('blots/block');
    class HeaderBlot extends Block {
      static create(node: number) {
        const block = super.create(node);
        if (node.toString() === '1')
          block.setAttribute('style', 'font-size: 72px !important;');
        if (node.toString() === '2')
          block.setAttribute('style', 'font-size: 48px !important;');
        return block;
      }

      static formats(node: any) {
        return HeaderBlot.tagName.indexOf(node.tagName) + 1;
      }
    }
    HeaderBlot.blotName = 'header';
    HeaderBlot.tagName = ['H1', 'H2'];
    Quill.register(HeaderBlot);

    const editor = document.createElement('div');
    wrapper.append(editor);

    setUpQuill();
    const quill: Quill = new Quill(editor, {
      theme: 'snow',
      modules: {
        toolbar: TOOLBAR_OPTIONS
      },
      readOnly: readonly,
      placeholder
    });

    setUpQuill();
    if (readonly && value) {
      quill.root.innerHTML = value;
    }

    if (value) {
      quill.root.innerHTML = value;
    }

    setQuill(quill);
  }, []);

  const setUpQuill = () => {
    const AlignStyle = Quill.import('attributors/style/align');
    const BackgroundStyle = Quill.import('attributors/style/background');
    const ColorStyle = Quill.import('attributors/style/color');
    const DirectionStyle = Quill.import('attributors/style/direction');
    const FontStyle = Quill.import('attributors/style/font');
    const Bold = Quill.import('formats/bold');
    const Link = Quill.import('formats/link');
    const Image = Quill.import('formats/image');

    Bold.tagName = 'B';

    const icons = Quill.import('ui/icons');
    icons.color =
      '<span class="material-icons material-icons.md-18">text_format</span>';
    icons.background =
      '<span class="material-icons material-icons.md-18">font_download</span>';
    icons.bold = '<span class="material-icons">format_bold</span>';
    icons.italic = '<span class="material-icons">format_italic</span>';
    icons.align[''] = '<span class="material-icons">format_align_left</span>';
    icons.align.right =
      '<span class="material-icons">format_align_right</span>';
    icons.align.justify =
      '<span class="material-icons">format_align_justify</span>';
    icons.align.center =
      '<span class="material-icons">format_align_center</span>';
    icons.list.ordered =
      '<span class="material-icons">format_list_numbered</span>';
    icons.list.bullet =
      '<span class="material-icons">format_list_bulleted</span>';

    icons.underline = '<span class="material-icons">format_underlined</span>';
    icons.strike = '<span class="material-icons">strikethrough_s</span>';
    icons.link = '<span class="material-icons">link</span>';
    icons.image = '<span class="material-icons">image</span>';
    icons.blockquote = '<span class="material-icons">format_quote</span>';
    icons.script.super = '<span class="material-icons">superscript</span>';
    icons.script.sub = '<span class="material-icons">subscript</span>';
    icons.header[1] = '<span class="ql-header header_icon">H1</span>';
    icons.header[2] = '<span class="ql-header header_icon">H2</span>';

    Link.sanitize = (url: string) => {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `http://${url}`;
      }
      return url;
    };

    Image.sanitize = (image64: string) => {
      if (image64.indexOf('image') !== -1) {
        return image64;
      }
      showMessageImageError();
      return 'invalid format';
    };

    Quill.register(icons, true);
    Quill.register(Bold, true);
    Quill.register(AlignStyle, true);
    Quill.register(BackgroundStyle, true);
    Quill.register(ColorStyle, true);
    Quill.register(DirectionStyle, true);
    Quill.register(FontStyle, true);
  };

  const showMessageImageError = async () => {
    setIsInvalidImage(true);

    setTimeout(() => {
      setIsInvalidImage(false);
    }, 10000);
  };

  useEffect(() => {
    if (quill == null) return;

    const handler = (delta: any, oldDelta: any, source: string) => {
      if (source !== 'user') return;

      let html: string = quill.root.innerHTML;

      html = html.replace('<img src="invalid format">', '');
      html = html === '<p><br></p>' ? '' : html;

      if (!readonly && setContent) {
        setContent(html);
      }
    };

    quill.on('text-change', handler);
  }, [quill]);

  useEffect(() => {
    if (value === '' && !!quill) {
      quill.root.innerHTML = '';
    }
  }, [value]);

  return (
    <Container>
      <StyleEditor readonly={readonly} id="container" ref={wrapperRef} />
      {isInvalidImage && (
        <InvalidFormatImage>
          <p className="material-icons">error</p>
          <p>
            <b>Atenção: </b> Não foi possível carregar a imagem pois o formato
            não é compatível. Por favor, substitua por uma imagem no formato{' '}
            <b> .jpeg, .png, .gif .bmp </b> ou <b>x-icon</b> e tente novamente.
          </p>
        </InvalidFormatImage>
      )}
    </Container>
  );
};

export default TextEditor;
