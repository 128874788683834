import { useTheme } from '@emotion/react';
import { ChevronDown, ChevronUp, Confirm, Info } from '@sumup/icons';
import React, { ReactElement, useState } from 'react';
import StatusIcon from '../StatusIcon';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem
} from './styles';

interface IOptions {
  value: string;
  component: ReactElement;
}

const options: IOptions[] = [
  { value: 'active', component: <Confirm color="#47995a" /> },
  {
    value: 'disabled',
    component: <Info transform="rotate(180)" color="#DB4D4D" />
  }
];

export interface IStatusName {
  active: string;
  disabled: string;
}
interface ICustomIconSelect {
  active: boolean;
  idItem: number;
  onOptionChange: (value: boolean, idItem: number) => void;
  statusName: IStatusName;
}
const CustomIconSelect: React.FC<ICustomIconSelect> = ({
  active,
  idItem,
  onOptionChange,
  statusName
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IOptions | null>(
    active ? options[0] : options[1]
  );
  const { colors } = useTheme();

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (currentValue: IOptions) => {
    setSelectedOption(currentValue);
    onOptionChange(currentValue.value === 'active', idItem);
    setIsOpen(false);
  };

  const isActive = (): boolean => {
    return selectedOption?.value === 'active';
  };

  const onClickOutsideListener = () => {
    setIsOpen(false);
    document.removeEventListener('click', onClickOutsideListener);
  };

  return (
    <DropDownContainer>
      <DropDownHeader
        onMouseLeave={() => {
          document.addEventListener('click', onClickOutsideListener);
        }}
        onClick={toggling}
      >
        <StatusIcon warning active={isActive()}>
          {isActive() ? statusName.active : statusName.disabled}
        </StatusIcon>
        {isOpen ? (
          <ChevronUp color={colors.n500} />
        ) : (
          <ChevronDown color={colors.n500} />
        )}
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map((option, index) => (
              <ListItem onClick={() => onOptionClicked(option)} key={index}>
                {option.component}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default CustomIconSelect;
