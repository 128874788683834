import { FC } from 'react';

const SVGReprovada: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.85693 10.1429L10.1426 5.85718M5.85693 5.85718L10.1426 10.1429"
        stroke="#DB4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="#DB4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SVGReprovada;
