import { Edit } from '@sumup/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from '../../../constants/api.constants';
import { SUB_MENU_ROUTES } from '../../../constants/routes.constants';
import { STORAGE } from '../../../constants/storage.constants';
import { USER_TYPE_BY_ENUM } from '../../../constants/user.constants';
import { FiltersEditUser } from '../../../interfaces/addUser.interface';
import { IResponseLogin } from '../../../interfaces/requests.interfaces';
import { post } from '../../../services/axios.service';
import { CustomIconSelect, CustomPopover, CustomTooltip } from '../../_Atoms';
import { NotFoundItem } from '../../_Molecules';
import {
  FilterIcon,
  StyledHeader,
  StyledHeaderItem,
  StyledRow,
  StyledRowItem
} from './styles';

interface IUserList {
  userList: IResponseLogin[];
  filters: FiltersEditUser;
  page: number;
}
const UserList: React.FC<IUserList> = ({ userList, filters, page }) => {
  const [userListToEdit, setUserListToEdit] = useState(userList);
  const { push } = useHistory();

  const onClickAction = (id: number, url?: string, onClick?: () => void) => {
    localStorage.setItem(
      STORAGE.HISTORY_PAGINATION_USER_EDIT,
      JSON.stringify({ ...filters, page })
    );
    if (url) {
      push(`${url}/${id}`);
    } else if (onClick) {
      onClick();
    }
  };

  const handleOptionChange = async (value: boolean, idItem: number) => {
    try {
      value
        ? await post(API.USUARIOS.ATIVAR(idItem), {})
        : await post(API.USUARIOS.DESATIVAR(idItem), {});
    } catch (error) {
      // TODO add an error message to user
      JSON.stringify(error);
    }
    const userListUpdated = userListToEdit.map((user) =>
      user.id === idItem ? { ...user, ativo: value } : user
    );
    setUserListToEdit(userListUpdated);
  };

  return (
    <>
      <StyledHeader>
        <StyledHeaderItem>
          Status{' '}
          <FilterIcon active={filters.status !== ''} className="material-icons">
            filter_alt
          </FilterIcon>
        </StyledHeaderItem>
        <StyledHeaderItem>Nome</StyledHeaderItem>
        <StyledHeaderItem>E-mail</StyledHeaderItem>
        <StyledHeaderItem>
          Tipo de usuário{' '}
          <FilterIcon
            active={filters.userType !== ''}
            className="material-icons"
          >
            filter_alt
          </FilterIcon>
        </StyledHeaderItem>
        <StyledHeaderItem>
          Empresa{' '}
          <FilterIcon
            active={filters.company !== ''}
            className="material-icons"
          >
            filter_alt
          </FilterIcon>
        </StyledHeaderItem>
        <StyledHeaderItem>
          Subgrupo{' '}
          <FilterIcon
            active={filters.subGroup !== ''}
            className="material-icons"
          >
            filter_alt
          </FilterIcon>
        </StyledHeaderItem>
      </StyledHeader>

      {userListToEdit.length === 0 && (
        <NotFoundItem message="Nenhum usuário foi encontrado" />
      )}

      {userListToEdit.length > 0 &&
        userListToEdit.map((user) => (
          <StyledRow key={user.id}>
            <StyledRowItem disabled={!user.ativo}>
              <CustomIconSelect
                active={user.ativo}
                idItem={user.id}
                onOptionChange={handleOptionChange}
                statusName={{
                  active: 'Cadastro ativo',
                  disabled: 'Cadastro inativo'
                }}
              />
            </StyledRowItem>

            <CustomTooltip title={user.nome}>
              <StyledRowItem disabled={!user.ativo}>{user.nome}</StyledRowItem>
            </CustomTooltip>

            <CustomTooltip title={user.email}>
              <StyledRowItem disabled={!user.ativo}>{user.email}</StyledRowItem>
            </CustomTooltip>

            <StyledRowItem disabled={!user.ativo}>
              {USER_TYPE_BY_ENUM[user.tipoDeUsuario]}
            </StyledRowItem>

            <CustomTooltip title={user.empresa}>
              <StyledRowItem disabled={!user.ativo}>
                {user.empresa}
              </StyledRowItem>
            </CustomTooltip>

            <CustomTooltip title={user.subGrupo}>
              <StyledRowItem disabled={!user.ativo}>
                {user.subGrupo || '(Sem grupo)'}
              </StyledRowItem>
            </CustomTooltip>

            <StyledRowItem disabled={!user.ativo}>
              <CustomPopover
                actions={[
                  {
                    children: {
                      color: '#3063e9',
                      text: 'Editar'
                    },
                    icon: () => <Edit size="16" color="#3063e9" />,
                    onClick: () =>
                      onClickAction(user.id, SUB_MENU_ROUTES.EDIT_USER)
                  }
                ]}
                disabled={!user.ativo}
              />
            </StyledRowItem>
          </StyledRow>
        ))}
    </>
  );
};

export default UserList;
