import { ButtonHTMLAttributes, FC } from 'react';
import styled from '@emotion/styled';
import { IconProps } from '@material-ui/core';
import CustomSpinner from '../Spinner';

export const StyleButton = styled.button<{
  width: string;
  variant: string;
  disabled: boolean;
}>`
  ${(e) =>
    e.variant === 'primary'
      ? `background-color: ${e.theme.colors.b500};
         color: ${e.theme.colors.white};
         border: none;`
      : `background-color: ${e.theme.colors.white};
         color: ${e.theme.colors.n900};
         border: 1px solid ${e.theme.colors.n500};`}
  height: 48px;
  min-width: 110px;
  white-space: nowrap;
  opacity: ${(e) => (e.disabled ? 0.5 : 1)};
  width: ${(e) =>
    e.width === 'small'
      ? '110px;'
      : e.width === 'medium'
      ? '175px;'
      : e.width === 'large'
      ? '250px;'
      : 'auto;'};
  text-align: center;
  padding: 3px 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin: 0 16px;
  cursor: ${(e) => (e.disabled ? 'normal' : 'pointer')};

  div {
    display: flex;
    justify-content: center;
  }
`;

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: string;
  loading?: boolean;
  width?: 'small' | 'medium' | 'auto' | 'large';
  variant?: 'primary' | 'secondary';
  IconBefore?: FC<IconProps>;
}

const Button: FC<IProps> = ({
  value,
  loading = false,
  type = 'button',
  variant = 'primary',
  disabled = false,
  width = 'small',
  IconBefore,
  ...props
}) => {
  return (
    <StyleButton
      width={width}
      disabled={disabled || loading}
      {...props}
      type={type}
      variant={variant}
    >
      {!loading ? (
        <div>
          {IconBefore && (
            <div style={{ transform: 'scale(0.8)', fontWeight: 'bold' }}>
              <IconBefore />
            </div>
          )}
          {value}
        </div>
      ) : (
        <CustomSpinner size="16px" color="#fff" />
      )}
    </StyleButton>
  );
};

export default Button;
