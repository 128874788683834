import styled from '@emotion/styled';

export const StyledHeaderBackground = styled.div`
  width: 100%;
  text-align: center;
`;

export const StyledMarginBox = styled.div`
  margin-bottom: 32px;
`;

export const StyledLoginLogo = styled.div`
  margin-top: 70px;
  margin-bottom: 126px;
`;
