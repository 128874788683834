import styled from '@emotion/styled';
import { DateInput } from '@sumup/circuit-ui';

export const DateRangeSelect = styled.div`
  padding: 0px;
  width: 100%;
  max-width: 263px;
  z-index: 1000;
  position: relative;

  @media (max-width: 1500px) {
    width: 213px;
  }
`;

export const Input = styled.div<{ active: boolean }>`
  margin: 0;
  height: 48px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 6px;
  cursor: pointer;
  color: #495057;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  ${(e) => (e.active ? 'border: 1px solid #3388ff;' : '')}

  :hover {
    border: 1px solid #3388ff;
  }
`;

export const ContainerOptions = styled.div`
  height: auto;
  width: 100%;
  min-width: 200px;
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  z-index: 40;
  overflow-y: auto;
  padding: 4px 0;
  border-radius: 6px;
`;

export const Option = styled.div<{ active: boolean }>`
  height: 40px;
  padding: 8px 16px;
  display: flex;
  align-item: center;
  justify-content: space-between;

  ${({ active }) =>
    active
      ? 'background-color: #edf4fc; font-weight: bold; color: #3063E9;'
      : ''}

  :hover {
    background-color: #edf4fc;
    cursor: pointer;
  }
`;

export const CalendarContainer = styled.div`
  position: absolute;
  bottom: -335px;
  left: 240px;
  z-index: 9999;
  transform: scale(0.97);

  button {
    display: none;
  }
  @media (max-width: 1400px) {
    transform: scale(0.7);
    left: 156px;
  }
`;

export const HandleCloseArea = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  zindex: 999;
  opacity: 0.3;
`;

export const CustomDate = styled(DateInput)`
  width: 230px;
  margin-right: 16px;
  margin-bottom: 0px;
  border: 1px solid #ced4da;
  box-shadow: none;
  border-radius: 6px;

  @media (max-width: 1500px) {
    width: 170px;
  }

  &:hover {
    border: 1px solid #3388ff;
    box-shadow: none;
  }

  label {
    margin-bottom: 0;
  }
`;
