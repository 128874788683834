/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react';
import { API } from '../../../constants/api.constants';
import { ENUM_DATE_OPTIONS } from '../../../constants/kanban.constants';
import { IDataResponseModel } from '../../../interfaces/requests.interfaces';
import { get } from '../../../services/axios.service';
import { rangeDateOptions } from '../../../utils/kanban';
import {
  Button,
  CustomDropdown,
  SelectDateRangeUniqueField
} from '../../_Atoms';
import { MultiSelectStyle } from './style';

interface IProps {
  filters: any;
  setFilters: (p: any) => void;
  filter: () => void;
}
const HistoryFilesFilters: FC<IProps> = ({ filters, setFilters, filter }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailsOptions, setEmailsOptions] = useState<
    { value: number; label: string }[]
  >([]);

  const getEmailOptions = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await get<
        IDataResponseModel<{ id: number; descricao: string }[]>
      >(API.ARQUIVO.EMAIL_SOLICITANTES);

      if (res.success && res.data) {
        setEmailsOptions(
          res.data.map((item) => ({
            value: item.id,
            label: item.descricao
          }))
        );
      }
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    getEmailOptions();
    filter();
  }, []);

  const clearFilters = () => {
    setFilters({
      typeRequisition: '0',
      initialLabelDateIndex: 11,
      emailIds: [],
      startDate: rangeDateOptions('11').start,
      endDate: rangeDateOptions('11').end
    });
  };

  return (
    <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
      <div style={{ marginTop: 14 }}>
        <CustomDropdown
          value={filters.typeRequisition}
          onChangeValue={(e) => setFilters({ ...filters, typeRequisition: e })}
          options={[
            { label: 'Todos', value: '0' },
            { label: 'Merchant', value: '1' },
            { label: 'Serial', value: '2' },
            { label: 'Comodato', value: '3' }
          ]}
          placeholder="Selecione uma opção"
          style={{ width: 224, marginRight: 10, marginTop: -13 }}
          label="Tipo de arquivo"
        />
      </div>
      <MultiSelectStyle
        style={{ marginRight: 10 }}
        label="E-mail do solicitante"
        options={emailsOptions}
        placeholder={
          error
            ? 'Erro ao buscar e-mails'
            : !emailsOptions.length && !loading
            ? 'Nenhuma opção disponível'
            : 'Selecione uma opção'
        }
        selected={filters.emailIds}
        disabled={error || loading || !emailsOptions.length}
        setSelected={(e) => setFilters({ ...filters, emailIds: e })}
      />

      <div style={{ width: '100%', maxWidth: 300 }}>
        <SelectDateRangeUniqueField
          style={{ minWidth: 264 }}
          date={{ start: filters.startDate, end: filters.endDate }}
          setDate={(e) =>
            setFilters({ ...filters, endDate: e.end, startDate: e.start })
          }
          value={filters.initialLabelDateIndex}
          setValue={(e) => setFilters({ ...filters, initialLabelDateIndex: e })}
          label="Periodo"
          options={[
            ENUM_DATE_OPTIONS['Exibir todo o período'],
            ENUM_DATE_OPTIONS.Hoje,
            ENUM_DATE_OPTIONS['Ultimos 7 dias'],
            ENUM_DATE_OPTIONS['Últimos 15 Dias'],
            ENUM_DATE_OPTIONS['Próximos 7 dias'],
            ENUM_DATE_OPTIONS['Próximos 15 dias']
          ]}
        />
      </div>

      <p
        style={{
          margin: '18px 20px 0 26px',
          color: '#3063e9',
          fontWeight: 'bold',
          cursor: 'pointer'
        }}
        onClick={clearFilters}
        onKeyDown={clearFilters}
      >
        Limpar filtros
      </p>

      <Button
        style={{ marginTop: 24 }}
        value="Filtrar"
        onClick={filter}
        loading={loading}
      />
    </div>
  );
};

export default HistoryFilesFilters;
