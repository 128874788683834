import React from 'react';
import { ToggleStyle } from './styles';

interface IToggleSwitch {
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  disabled?: boolean;
  label?: string;
}

const ToggleSwitch: React.FC<IToggleSwitch> = ({
  checked,
  onChange,
  name,
  id,
  disabled,
  label
}) => {
  return (
    <>
      <ToggleStyle>
        <input
          type="checkbox"
          id={id}
          name={name}
          className="react-switch-checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <label htmlFor={id} className="react-switch-label">
          <span className="react-switch-button" />
        </label>
        <label
          htmlFor={id}
          style={{
            verticalAlign: 'middle',
            alignItems: 'center',
            textAlign: 'center',
            marginLeft: '12px'
          }}
        >
          {label}
        </label>
      </ToggleStyle>
    </>
  );
};

export default ToggleSwitch;
