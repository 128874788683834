import React from 'react';

import { useTheme } from '@emotion/react';
import { Confirm, Info } from '@sumup/icons';
import CustomTooltip from '../Tooltip';

interface IStatusIcon {
  active: boolean;
  warning?: boolean;
}
const StatusIcon: React.FC<IStatusIcon> = ({
  active,
  warning = false,
  children
}) => {
  const theme = useTheme();
  const getCurrentColor = () => {
    if (active) {
      return theme.colors.success;
    }

    return warning ? theme.colors.danger : theme.colors.n500;
  };
  return (
    <CustomTooltip title={children?.toString() || ''}>
      <div>
        {active ? (
          <Confirm color={getCurrentColor()} size="24" />
        ) : (
          <Info color={getCurrentColor()} size="24" transform="rotate(180)" />
        )}
      </div>
    </CustomTooltip>
  );
};

export default StatusIcon;
