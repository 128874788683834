import styled from '@emotion/styled';

export const Title = styled.p`
  font-size: 19px;
  margin-top: 32px;
  font-weight: bold;
`;

export const Subtitle = styled.p`
  font-size: 17px;
`;

export const SubtitleContainer = styled.div`
  margin: 16px 0 24px 0;
`;

export const NetWorkErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 42px;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
