import styled from '@emotion/styled';

export const SearchBar = styled.div`
  position: relative;
  max-width: 997px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px auto;

  label {
    width: 100%;
  }
`;

export const SearchIcon = styled.div<{ active: boolean }>`
  padding: 10px;
  height: 48px;
  width: 48px;
  background-color: ${({ active }) => (active ? '#3063e9' : '#9da7b1de')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 2px;
  margin-left: 30px;
  cursor: pointer;
`;
