import styled from '@emotion/styled';

export const BoardContent = styled.div`
  display: grid;
  height: 60vh;
  min-height: 560px;
  max-width: 1400px;
  width: 90vw;
  justify-content: space-between;
  grid-template-columns: repeat(5, 19%);
  margin: 0 auto;
  overflow-y: scroll;
  padding-right: 4px;

  &::-webkit-scrollbar {
    padding: 0 30px; /* width of the entire scrollbar */
    background-color: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999999; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid white;
  }
`;

export const BoardHeader = styled.div`
  display: grid;
  height: auto;
  max-width: 1400px;
  width: 90vw;
  grid-template-columns: repeat(5, 19%);
  margin: 50px auto 0 auto;
  justify-content: space-between;
  overflow-y: scroll;
  padding-right: 4px;

  &::-webkit-scrollbar {
    opacity: 0;
  }
`;

export const Container = styled.div`
  margin-bottom: 56px;
`;

export const FooterStyle = styled.div`
  display: grid;
  height: auto;
  max-width: 1400px;
  width: 90vw;
  grid-template-columns: repeat(5, 19%);
  margin: 0 auto;
  justify-content: space-between;
  overflow-y: scroll;
  padding-right: 4px;

  div {
    width: 100%;
    height: 20px;
    background-color: #eef0f2;
    border-radius: 0 0 20px 20px;
  }

  &::-webkit-scrollbar {
    opacity: 0;
  }
`;
