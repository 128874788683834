import React from 'react';

import { Breadcrumb, MenuCardList } from '../../components/_Molecules';

import { ASSET_MANAGEMENT } from '../../constants/menu.constants';
import { ROUTES } from '../../constants/routes.constants';

const AssetManagement: React.FC = () => {
  return (
    <>
      <Breadcrumb
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          }
        ]}
        currentRouteName="Gestão de ativos"
      />
      <MenuCardList columns="TWO" menuCardList={ASSET_MANAGEMENT} />
    </>
  );
};

export default AssetManagement;
