import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';

export const InputChipStyled = styled.div`
  width: auto;
  padding: 2px 8px;
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 13px;
  background-color: #d8dde1;
  border-radius: 4px;
`;

type IProps = HTMLAttributes<HTMLDivElement>;

const InputChip: FC<IProps> = ({ children, ...props }) => {
  return <InputChipStyled {...props}>{children}</InputChipStyled>;
};

export default InputChip;
