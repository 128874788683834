import { FC } from 'react';
import { Breadcrumb, MenuCardList } from '../../components/_Molecules';
import { IPreviousRoute } from '../../components/_Molecules/Breadcrumb';
import { MACHINES_PRICES_ITEMS } from '../../constants/menu.constants';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';

const MachinePricesManagement: FC = () => {
  const previousRoutesList: IPreviousRoute[] = [
    {
      routeName: 'Menu principal',
      routePath: ROUTES.MAIN_MENU
    },
    {
      routeName: 'Gestão de campanhas',
      routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
    }
  ];

  return (
    <>
      <Breadcrumb
        previousRoutes={previousRoutesList}
        currentRouteName="Preços de máquinas"
      />
      <MenuCardList columns="TWO" menuCardList={MACHINES_PRICES_ITEMS} />
    </>
  );
};

export default MachinePricesManagement;
