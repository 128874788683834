import styled from '@emotion/styled';

export const ExtractReportTableHeader = styled.div`
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 13px;
  font-weight: bold;
  background-color: #fafbfc;
  padding: 16px 0;
`;

export const ExtractReportFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0 40px 0;
`;

export const ExtractReportRowItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 1px solid #eef0f2;
  border-top: none;
  height: 72px;
  align-items: center;
  text-align: center;
  font-size: 13px;

  span {
    text-align: center !important;
  }

  p {
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    white-space: nowrap;
  }
`;

export const SpinnerContainer = styled.div`
  height: 432px;
  display: flex;
  align-items: center;
`;

export const ContentModalImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px;
  height: auto;
  padding: 16px;
  min-height: 650px;
`;

export const PaginatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;
