/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Done } from '../../../assets/icons/done.svg';
import { ReactComponent as ExternalLink } from '../../../assets/icons/external_link.svg';
import { ReactComponent as Loading } from '../../../assets/icons/loading.svg';
import { ReactComponent as WarningDone } from '../../../assets/icons/warning_done.svg';
import { ENUM_STATUS_TAREFA } from '../../../enums/taskListStatus.enums';
import { ITaskGraph, ITaskView } from '../../../interfaces/taskList.interfaces';
import { formatDateDDMMAAAA } from '../../../utils/kanban';
import { CustomTooltip, Spinner } from '../../_Atoms';
import { NoResultsState } from '../../_Molecules';
import {
  LineTable,
  ResultValue,
  TaskListFollowUpTableContainer,
  TaskListStatus
} from './styles';

interface IProps {
  loading: boolean;
  values?: ITaskGraph;
  setSelected: (value: ITaskView) => void;
  setOpenModal: (value: boolean) => void;
}
const TaskListFollowUpTable: FC<IProps> = ({
  loading,
  values,
  setSelected,
  setOpenModal
}) => {
  return (
    <>
      <TaskListFollowUpTableContainer>
        <p>
          Status da <br /> tarefa{' '}
        </p>
        <p>
          Nome do <br /> promotor
        </p>
        <p>
          Empresa do <br /> promotor
        </p>
        <p>
          Subgrupo do <br /> promotor
        </p>
        <p>
          Título da <br /> tarefa
        </p>
        <p>
          Data da criação <br /> da tarefa
        </p>
        <p>
          Dias que faltam <br /> conclusão da tarefa
        </p>
        <p>detalhes</p>
      </TaskListFollowUpTableContainer>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', height: 434 }}>
          <Spinner size="46px" color="#3388ff" width="5px" />
        </div>
      ) : (
        <>
          {values &&
            values.tarefas.results.map((item: ITaskView) => (
              <LineTable>
                <TaskListStatus status={item.status}>
                  {item.status === 4 && <WarningDone />}
                  {item.status === 3 && <Done />}
                  {item.status === 2 && <Loading />}
                  {item.status === 1 && <Close />}
                  <span>{ENUM_STATUS_TAREFA[item.status]}</span>
                </TaskListStatus>
                <CustomTooltip title={item.nomePromotor}>
                  <ResultValue>{item.nomePromotor || 'sem nome'}</ResultValue>
                </CustomTooltip>
                <CustomTooltip title={item.empresaPromotor}>
                  <ResultValue>
                    {item.empresaPromotor || 'sem empresa'}
                  </ResultValue>
                </CustomTooltip>
                <CustomTooltip title={item.subGrupoPromotor}>
                  <ResultValue>{item.subGrupoPromotor}</ResultValue>
                </CustomTooltip>
                <CustomTooltip title={item.titulo}>
                  <ResultValue>{item.titulo || 'Sem subgrupo'}</ResultValue>
                </CustomTooltip>
                <p>{formatDateDDMMAAAA(item.dataCriacao)}</p>
                <p>{item.contadorDias} dias</p>
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelected(item);
                    setOpenModal(true);
                  }}
                >
                  <ExternalLink />
                </p>
              </LineTable>
            ))}
        </>
      )}
      {values?.tarefas?.results.length === 0 && !loading && <NoResultsState />}
    </>
  );
};

export default TaskListFollowUpTable;
