import { SearchInput } from '@sumup/circuit-ui';
import { FC, useEffect, useState } from 'react';
import {
  ALCADA_FILTER_OPTIONS,
  statusNotaFiscal,
  tipoAquisicao,
  tipoOferta
} from '../../../constants/alcada.constants';
import { API } from '../../../constants/api.constants';
import { ENUM_DATE_OPTIONS } from '../../../constants/kanban.constants';
import { IDataResponseModel } from '../../../interfaces/requests.interfaces';
import { get } from '../../../services/axios.service';
import { rangeDateOptions } from '../../../utils/kanban';
import {
  Button,
  CustomDropdown,
  SelectDateRangeUniqueField
} from '../../_Atoms';
import { ClearFilters } from '../FilterUserEdit/styles';
import {
  ContainerFilters,
  Filtros,
  MultiSelectStyle,
  SearchBar
} from './styles';

export interface IFilterAlcada {
  status: number[];
  startDate: string | null;
  endDate: string | null;
  initialLabelDateIndex: number;
  notaFiscal: number[];
  search: string;
  subGroup: string[];
  typeOffer: string;
  typeProposal: string;
}

interface IProps {
  filters: IFilterAlcada;
  setFilters: (filters: IFilterAlcada) => void;
  getData: () => void;
  serialButtomValidation: (p: boolean) => void;
}

const FilterAlcada: FC<IProps> = ({
  filters,
  setFilters,
  getData,
  serialButtomValidation
}) => {
  const [subGroupsOptions, setSubGroups] = useState({
    options: [{ label: '', value: -1 }]
  });

  const getOptions = async () => {
    try {
      const [groupsRes] = await Promise.allSettled([
        get<IDataResponseModel<string[]>>(API.PROPOSTAS.SUBGRUPOS)
      ]);

      let groupsResFormated: string[] = [];

      if (groupsRes.status === 'fulfilled') {
        groupsResFormated = groupsRes.value.data;

        setSubGroups({
          options: groupsRes.value.data.map((groups, index) => ({
            label: groups,
            value: index
          }))
        });
      }

      setFilters({
        ...filters,
        subGroup: groupsResFormated
      });
    } catch (error) {
      setSubGroups({
        options: [{ label: 'Não foi possível carregar', value: -2 }]
      });
    }
  };

  const clearFilters = () => {
    setFilters({
      status: ALCADA_FILTER_OPTIONS.map((x) => Number(x.value)).filter(
        (i) => i !== -1
      ),
      startDate: rangeDateOptions('11').start,
      endDate: rangeDateOptions('11').end,
      initialLabelDateIndex: 11,
      search: '',
      notaFiscal: [0, 1, 2],
      subGroup: subGroupsOptions.options.map((i) => i.label),
      typeOffer: '0',
      typeProposal: '0'
    });
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <ContainerFilters>
      <Filtros>
        <MultiSelectStyle
          options={ALCADA_FILTER_OPTIONS.map((option) => ({
            label: option.label,
            value: Number(option.value)
          })).filter((i) => i.value !== -1)}
          filter
          placeholder="Selecione uma opção"
          selected={filters.status}
          setSelected={(e) => setFilters({ ...filters, status: e })}
          label="Status de proposta"
        />

        <MultiSelectStyle
          options={statusNotaFiscal}
          placeholder="Selecione uma opção"
          selected={filters.notaFiscal}
          setSelected={(e) => {
            setFilters({ ...filters, notaFiscal: e });
          }}
          label="Status da Nota Fiscal"
        />
        <MultiSelectStyle
          options={subGroupsOptions.options
            .map((option) => ({
              label: option.label,
              value: Number(option.value)
            }))
            .filter((i) => i.value !== -1)}
          filter
          disabled={subGroupsOptions.options.some((i) => i.value === -2)}
          placeholder={
            subGroupsOptions.options.some((i) => i.value === -2)
              ? subGroupsOptions.options[0].label
              : subGroupsOptions.options.length === 0
              ? 'Nenhum resultado encontrado'
              : 'Selecione uma opção'
          }
          selected={subGroupsOptions.options
            .filter((i) => filters.subGroup.indexOf(i.label) > -1)
            .map((i) => Number(i.value))}
          setSelected={(e) => {
            setFilters({
              ...filters,
              subGroup: e
                ? e.map((e) => {
                    return subGroupsOptions.options[e].label;
                  })
                : []
            });
          }}
          label="Subgrupo do promotor"
        />

        <div style={{ width: '100%', maxWidth: 300, fontSize: 14 }}>
          <SelectDateRangeUniqueField
            date={{ start: filters.startDate, end: filters.endDate }}
            setDate={(e) =>
              setFilters({ ...filters, endDate: e.end, startDate: e.start })
            }
            value={filters.initialLabelDateIndex}
            setValue={(e) =>
              setFilters({ ...filters, initialLabelDateIndex: e })
            }
            label="Período"
            options={[
              ENUM_DATE_OPTIONS['Exibir todo o período'],
              ENUM_DATE_OPTIONS.Hoje,
              ENUM_DATE_OPTIONS['Essa semana'],
              ENUM_DATE_OPTIONS['Ultimos 7 dias'],
              ENUM_DATE_OPTIONS['Últimos 15 Dias'],
              ENUM_DATE_OPTIONS['Últimos 30 dias'],
              ENUM_DATE_OPTIONS['Últimos 3 meses'],
              ENUM_DATE_OPTIONS['Últimos 6 meses']
            ]}
          />
        </div>

        <ClearFilters
          active
          onClick={clearFilters}
          style={{ right: 18, top: 37, position: 'absolute' }}
        >
          Limpar filtros
        </ClearFilters>
      </Filtros>

      <SearchBar>
        <div style={{ display: 'flex' }}>
          <CustomDropdown
            value={filters.typeOffer}
            onChangeValue={(e) => {
              serialButtomValidation(Number(e) !== 1);
              setFilters({ ...filters, typeOffer: e });
            }}
            options={tipoOferta}
            style={{ width: 224, marginRight: 10, marginTop: -13 }}
            label="Tipo de oferta"
            placeholder="Selecione uma opção"
          />
          <CustomDropdown
            value={filters.typeProposal}
            onChangeValue={(e) => setFilters({ ...filters, typeProposal: e })}
            options={tipoAquisicao}
            placeholder="Selecione uma opção"
            style={{ width: 224, marginRight: 10, marginTop: -13 }}
            label="Comodato ou venda"
          />
        </div>
        <SearchInput
          label="Buscar"
          onClear={() => {
            setFilters({ ...filters, search: '' });
            getData();
          }}
          style={{
            border: '1px solid #ced4da',
            borderRadius: 4,
            height: 49,
            boxShadow: 'none'
          }}
          clearLabel="clearable"
          autoComplete="off"
          value={filters.search}
          name="search"
          placeholder="Digite uma pesquisa"
          onChange={(e) => {
            setFilters({ ...filters, search: e.target.value });
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && filters.search !== '') {
              e.preventDefault();
              getData();
            }
          }}
        />
        <Button value="Filtrar" style={{ marginTop: 6 }} onClick={getData} />
      </SearchBar>
    </ContainerFilters>
  );
};

export default FilterAlcada;
