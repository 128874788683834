import styled from '@emotion/styled';
import { Card, Headline } from '@sumup/circuit-ui';

export const StyledHeadline = styled(Headline)`
  margin-bottom: 12px;
  font-size: 17px;
`;

export const StyledCard = styled(Card)`
  margin-top: 20px;
  padding: 27px;
  border: 1px solid #d8dde1;
  transition: 0.15s;
  border-radius: 4px;
  :hover {
    border: 1px solid;
    border-color: ${(p) => p.theme.colors.p500};
    transition: 0.15s;
    cursor: pointer;
  }
  @media (min-width: 800px) {
    max-width: 325px;
  }
`;
