import {
  ICampaigns,
  IEditCampaignsForm
} from '../interfaces/campaigns-management.interfaces';
import { IResponseCampanhas } from '../interfaces/requests.interfaces';

export const concatAllCampaigns = (
  campaigns: ICampaigns[],
  subGroups: string[]
): IEditCampaignsForm => {
  return {
    campanhas: campaigns
      .map((campanha) => ({
        ofertaId: String(campanha.ofertaId),
        subGrupo: campanha.subGrupo,
        grupoMaquinasId: campanha.grupoMaquinasId,
        campanhaId: campanha.campanhaId,
        permiteEmitirNF: campanha.permiteEmitirNF,
        permiteComodato: campanha.permiteComodato,
        permiteWhatsApp: campanha.permiteWhatsApp,
        permiteHistoricoCliente: campanha.permiteHistoricoCliente,
        permiteGravacao: campanha.permiteGravacao,
        permiteMenuMinhasVisitas: campanha.permiteMenuMinhasVisitas,
        permiteComodatoSemEnvioContrato:
          campanha.permiteComodatoSemEnvioContrato,
        permiteListaSN: campanha.permiteListaSN
      }))
      .concat(
        subGroups.map((subgrupo) => ({
          ofertaId: '',
          grupoMaquinasId: 0,
          subGrupo: subgrupo,
          campanhaId: 0,
          permiteEmitirNF: false,
          permiteComodato: false,
          permiteWhatsApp: false,
          permiteHistoricoCliente: false,
          permiteGravacao: false,
          permiteMenuMinhasVisitas: false,
          permiteComodatoSemEnvioContrato: false,
          permiteListaSN: false
        }))
      )
      .sort((x, y) => x.campanhaId - y.campanhaId)
  };
};

export const getCampaignsFormated = (
  data: IResponseCampanhas
): IEditCampaignsForm => {
  return {
    campanhas: data.campanhas.map((campanha) => ({
      ofertaId: String(campanha.ofertaId),
      grupoMaquinasId: campanha.grupoMaquinasId,
      subGrupo: campanha.subGrupo,
      campanhaId: campanha.campanhaId,
      permiteEmitirNF: campanha.permiteEmitirNF,
      permiteComodato: campanha.permiteComodato,
      permiteWhatsApp: campanha.permiteWhatsApp,
      permiteHistoricoCliente: campanha.permiteHistoricoCliente,
      permiteGravacao: campanha.permiteGravacao,
      permiteMenuMinhasVisitas: campanha.permiteMenuMinhasVisitas,
      permiteComodatoSemEnvioContrato: campanha.permiteComodatoSemEnvioContrato,
      permiteListaSN: campanha.permiteListaSN
    }))
  };
};
