import styled from '@emotion/styled';
import { StyledHeadline } from '../../components/_Molecules/CardContainer/styles';

export const StyledContentBox = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledBoxPage = styled.div`
  max-width: 1164px;
  margin: 0 auto;
  width: 100%;
`;

export const CustomHeadline = styled(StyledHeadline)`
  background-color: #f9f9f9;
  width: 100%;
  padding: 20px 48px;
`;

export const StyledForm = styled.div`
  max-width: 1164px;
  margin: auto;
`;

export const InputMessage = styled.div`
  display: grid;
  width: 100%;
  max-height: 234px;
  grid-template-columns: 160px auto;
  padding: 12px 48px;
  font-size: 14px;
`;

export const Label = styled.p<{ align?: string; color?: string }>`
  font-weight: 600;
  display: flex;
  color: ${({ color }) => color || 'inherit'};
  align-items: ${({ align }) => align || 'center'};
`;

export const DestinatariosList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px 8px;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  padding: 12px 12px 12px 0;
  width: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3063e9;
    border-radius: 2px;
  }

  span {
    overflow: hidden;
    padding: 0 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid black;
    height: 25px;
    border-radius: 4px;
  }
`;

export const InputDestinatarioMessage = styled.div`
  display: grid;
  grid-template-columns: 160px auto;
  width: 100%;
  height: auto;
  max-height: 234px;
  padding: 12px 48px;
  font-size: 14px;
`;

export const MessageError = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 48px;

  p {
    font-weight: bold;

    &:last-of-type {
      margin: 12px 0 48px;
      font-weight: normal;
    }
  }

  div {
    display: flex;
  }
`;
