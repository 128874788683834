import { SearchInput } from '@sumup/circuit-ui';
import { Search } from '@sumup/icons';
import { FC, useState } from 'react';
import { FiltersEditUser } from '../../../../interfaces/addUser.interface';
import { SearchBar, SearchIcon } from './styles';

interface IProps {
  setFilter: (p: FiltersEditUser) => void;
  filters: FiltersEditUser;
}

const SearchBarUserEdit: FC<IProps> = ({ setFilter, filters }) => {
  const [search, setSearch] = useState<string>(filters.search);

  return (
    <SearchBar>
      <SearchInput
        label="Buscar:"
        onClear={() => {
          setSearch('');
          setFilter({ ...filters, search: '' });
        }}
        clearLabel="clearable"
        value={search}
        name="search"
        placeholder="Digite uma pesquisa"
        onChange={(e) => {
          setSearch(e.target.value);
          if (e.target.value === '') {
            setFilter({ ...filters, search: '' });
          }
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && search !== '') {
            e.preventDefault();
            setFilter({ ...filters, search });
          }
        }}
      />
      <SearchIcon
        active={search !== ''}
        data-testid="search-icon"
        onClick={() => {
          if (search !== '') {
            setFilter({ ...filters, search });
          }
        }}
      >
        <Search size="24" />
      </SearchIcon>
    </SearchBar>
  );
};

export default SearchBarUserEdit;
