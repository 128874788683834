import styled from '@emotion/styled';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const OptionsContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  width: 654px;
  height: auto;
  max-height: 130px;
  z-index: 999;
  margin-top: -10px;
  overflow-y: auto;
  filter: drop-shadow(0px -1px 0px rgba(12, 15, 20, 0.02))
    drop-shadow(0px 4px 4px rgba(12, 15, 20, 0.06))
    drop-shadow(0px 8px 8px rgba(12, 15, 20, 0.06));
`;
export const Item = styled.p<{ selected: boolean; hover?: boolean }>`
  padding: 8px 16px;
  background-color: ${(e) => (e.selected ? e.theme.colors.b100 : '')};
  color: ${(e) => (e.selected ? e.theme.colors.p500 : 'black')};
  font-weight: ${(e) => (e.selected ? 'bold' : 'normal')};
  display: flex;
  justify-content: space-between;
  background-color: ${(e) => (e.hover ? e.theme.colors.n200 : '')};

  &:hover {
    background-color: ${(e) =>
      e.selected ? e.theme.colors.b100 : e.theme.colors.n200};
  }
`;
export const InputChip = styled.div`
  width: auto;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  height: 28px;
  position: absolute;
  font-size: 13px;
  background-color: ${(e) => e.theme.colors.n300};
  border-radius: 4px;
  z-index: 10;
  top: 34px;
  left: 8px;
`;

export const AddNewSubgroup = styled.p<{ selected: boolean; hover?: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  color: ${(e) => (e.selected ? e.theme.colors.p500 : e.theme.colors.n500)};
  font-weight: ${(e) => (e.selected ? 'bold' : 'normal')};
  margin-bottom: 8px;
  cursor: pointer;
  span {
    color: ${(e) => (e.selected ? e.theme.colors.p500 : e.theme.colors.n900)};
  }

  background-color: ${(e) =>
    e.hover && !e.selected
      ? e.theme.colors.n200
      : e.selected
      ? e.theme.colors.b100
      : ''};
`;

export const CircleCross = styled(HighlightOffIcon)<{ invalid: boolean }>`
  position: absolute;
  font-size: 19px;
  right: 15px;
  top: 36px;
  z-index: 10;
  color: ${(e) => (e.invalid ? e.theme.colors.r500 : '#9da7b1')};
  cursor: pointer;
`;
