import { FC } from 'react';

const SVGProcessando: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="#3063E9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="0.666667"
        cy="0.666667"
        r="0.666667"
        transform="matrix(-1 0 0 1 8.6665 7.33325)"
        fill="#3063E9"
        stroke="#3063E9"
        strokeWidth="0.5"
        strokeMiterlimit="16"
      />
      <ellipse
        cx="0.666667"
        cy="0.666667"
        rx="0.666667"
        ry="0.666667"
        transform="matrix(-1 0 0 1 11.3335 7.33325)"
        fill="#3063E9"
        stroke="#3063E9"
        strokeWidth="0.5"
        strokeMiterlimit="16"
      />
      <circle
        cx="0.666667"
        cy="0.666667"
        r="0.666667"
        transform="matrix(-1 0 0 1 6 7.33325)"
        fill="#3063E9"
        stroke="#3063E9"
        strokeWidth="0.5"
        strokeMiterlimit="16"
      />
    </svg>
  );
};

export default SVGProcessando;
