import styled from '@emotion/styled';

export const ExportModalContainer = styled.div`
  min-width: 569px;
  padding: 24px;
`;

export const SubTitle = styled.div`
  margin-bottom: 16px;
`;

export const DateInputContainer = styled.div`
  display: flex;
`;
