import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { Headline, SearchInput, useModal } from '@sumup/circuit-ui';
import { Search } from '@sumup/icons';
import {
  ENUM_ACTION_ICON_TYPE,
  ListItem
} from '../../components/_Organisms/CustomEditListTable';

import {
  CustomButton,
  CustomPagination,
  CustomSpinner,
  SelectField
} from '../../components/_Atoms';
import { Breadcrumb, CardContainer } from '../../components/_Molecules';
import { CustomEditListTable } from '../../components/_Organisms';

import {
  DEFAULT_FILTER_OPTION_PLANS,
  FILTER_OPTIONS_PLANS,
  PLANS_STATUS_NAME
} from '../../constants/plans.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';
import { get } from '../../services/axios.service';
import {
  StatusOptions,
  getFilterParamByStatus
} from '../../services/filters.service';

import DoubleRatePlans from '../../components/DoubleRatePlans';
import {
  SearchBar,
  SearchIcon
} from '../../components/_Organisms/FilterUserEdit/SearchBar/styles';
import { API } from '../../constants/api.constants';
import { STORAGE } from '../../constants/storage.constants';
import { usePagination } from '../../hooks/usePagination';
import {
  IDataResponseModel,
  IPagination,
  IResponseListToEdit
} from '../../interfaces/requests.interfaces';

const StyledBoxPage = styled.div`
  max-width: 704px;
  margin: 0 auto;
  width: 100%;
`;
const StyledBoxButton = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

interface IFilters {
  status: StatusOptions;
  search: string;
}

const EditRatePlansPage: React.FC = () => {
  const [filters, setFilters] = useState<IFilters>(() => {
    const { filters } = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_PLANS) || '{}'
    );

    if (filters) {
      return filters;
    }

    return {
      status: DEFAULT_FILTER_OPTION_PLANS,
      search: ''
    };
  });
  const { totalItems, setTotalItems } = usePagination();
  const itemsPerPage = 20;

  const [search, setSearch] = useState(() => {
    const { filters } = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_PLANS) || '{}'
    );

    return filters?.search ?? '';
  });
  const [listPlans, setListPlans] = useState<ListItem[]>([]);
  const [resetPagination, setResetPagination] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { push } = useHistory();
  const { colors } = useTheme();
  const { active, disabled } = PLANS_STATUS_NAME;
  const { setModal, removeModal } = useModal();

  const getPlans = async (page: number) => {
    setLoading(true);
    const params = getFilterParamByStatus(filters.status);
    const fetchUrl = `${API.PLANOS.PAGINADO}${
      params === '' ? '?' : params
    }&PageSize=${String(itemsPerPage)}&PageNumber=${page}${
      filters.search ? `&CampoBusca=${filters.search}` : ''
    }`;
    try {
      const { data } = await get<
        IDataResponseModel<IPagination<IResponseListToEdit>>
      >(fetchUrl);
      if (data) {
        setListPlans(
          data.results.map((plan) => ({
            ...plan,
            actions: [
              {
                url: THIRD_LEVEL_MENU_ROUTES.EDIT_RATE_PLANS,
                color: colors.p500,
                text: 'Editar',
                IconType: ENUM_ACTION_ICON_TYPE.EDIT
              },
              {
                onClick: () => handleDoubleRatePlans(plan.id),
                color: colors.p500,
                text: 'Duplicar',
                IconType: ENUM_ACTION_ICON_TYPE.ADD
              }
            ]
          }))
        );
        setTotalItems(data.totalItems);
      }
    } catch {
      setListPlans([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    getPlans(page + 1);
    setCurrentPage(page + 1);
  };

  const handleDoubleRatePlans = async (id: number) => {
    setModal({
      children: (
        <DoubleRatePlans
          planId={id}
          removeModal={removeModal}
          getPlans={() => getPlans(1)}
        />
      ),
      variant: 'immersive',
      preventClose: true
    });
  };

  useEffect(() => {
    const loadData = async () => {
      const history = JSON.parse(
        localStorage.getItem(STORAGE.HISTORY_PAGINATION_PLANS) || '{}'
      );
      localStorage.removeItem(STORAGE.HISTORY_PAGINATION_PLANS);

      setCurrentPage(history.currentPage ?? 1);
      setResetPagination(false);
      await getPlans(history.currentPage ?? 1);
      setResetPagination(true);
    };
    loadData();
  }, [filters]);

  return (
    <StyledBoxPage>
      <Breadcrumb
        currentRouteName="Editar plano de taxas"
        noMargin
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.HOME
          },
          {
            routeName: 'Gestão de campanhas',
            routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
          },
          {
            routeName: 'Planos de taxas',
            routePath: SUB_MENU_ROUTES.RATE_PLANS
          }
        ]}
      />
      <Headline style={{ marginTop: 25 }} as="h2" size="four">
        Editar plano de taxas
      </Headline>
      <CardContainer
        title="Lista de planos de taxas"
        subtitle="Gerencie, edite ou remova um plano de taxas."
      >
        <SelectField
          label="Status:"
          name="filters"
          options={FILTER_OPTIONS_PLANS}
          onChange={(e) =>
            setFilters({ ...filters, status: e.target.value as StatusOptions })
          }
          value={filters.status}
        />
        <SearchBar>
          <SearchInput
            label="Buscar:"
            onClear={() => {
              setFilters({ ...filters, search: '' });
              setSearch('');
            }}
            clearLabel="clearable"
            value={search}
            name="search"
            placeholder="Digite uma pesquisa"
            onChange={(e) => {
              setSearch(e.target.value);
              if (e.target.value === '') {
                setFilters({ ...filters, search: '' });
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && search !== '') {
                e.preventDefault();
                setFilters({ ...filters, search });
              }
            }}
          />
          <SearchIcon
            active={search !== ''}
            onClick={() => {
              if (search !== '') {
                setFilters({ ...filters, search });
              }
            }}
            style={{ marginTop: 5 }}
          >
            <Search size="24" />
          </SearchIcon>
        </SearchBar>
        {loading ? (
          <CustomSpinner />
        ) : (
          <CustomEditListTable
            listData={listPlans}
            mainColumnName="Nome do plano"
            statusName={{ active, disabled }}
            resourceUrl={API.PLANOS.EDITAR()}
            currentPage={currentPage}
            filters={filters}
          />
        )}
        {totalItems > itemsPerPage && resetPagination && (
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}
          >
            <CustomPagination
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              totalItems={totalItems}
              initialPage={currentPage}
            />
          </div>
        )}

        <StyledBoxButton>
          <CustomButton
            onClick={() => push(SUB_MENU_ROUTES.RATE_PLANS)}
            variant="secondary"
            id="back-button"
          >
            Voltar
          </CustomButton>
        </StyledBoxButton>
      </CardContainer>
    </StyledBoxPage>
  );
};

export default EditRatePlansPage;
