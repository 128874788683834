import styled from '@emotion/styled';

export const StyledActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledContentBox = styled.div`
  width: 670px;
  margin: 0 auto;
`;
