import styled from '@emotion/styled';

export const StyledCustomFlexRowContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #d8dde1;
`;

export const StyledCustomContent = styled.div`
  font-size: 13px;
  min-height: 64px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    margin: auto;
    padding: 0px;
  }
`;
