import { useHistory } from 'react-router';
import { StyledBackgroundLogo } from './styles';

const ShortcutMenu: React.FC = () => {
  const router = useHistory();
  return (
    <StyledBackgroundLogo onClick={() => router.push('/menu')}>
      <img src="/images/sumup_logo.svg" alt="Sumup - Força de vendas" />
    </StyledBackgroundLogo>
  );
};

export default ShortcutMenu;
