/* eslint-disable react/jsx-no-undef */
import CloseIcon from '@material-ui/icons/Close';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import { FC } from 'react';
import Dropzone from 'react-dropzone';
import { alphanumericWithSpacesAndHyphens } from '../../../utils/format.utils';
import {
  Button,
  InputField,
  Spinner,
  SubTitle,
  Title,
  ValidationMessageError
} from '../../_Atoms';
import { SVGTaskListFile } from '../../_Atoms/Icon';
import { DropContainer, FileSelected } from './styles';

interface IProps {
  errorMessage: (p: string) => void;
  file: { file: File[]; nomeUpload: string };
  setFile: (p: { file: File[]; nomeUpload: string }) => void;
  loading: boolean;
}
const DragAndDropTaskList: FC<IProps> = ({
  errorMessage,
  file,
  setFile,
  loading
}) => {
  return !loading ? (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #eef0f2'
        }}
      >
        <p style={{ margin: '0 8px 0 30px', fontWeight: 'bold' }}>
          Nome da carga:
        </p>
        <InputField
          value={file.nomeUpload}
          onChange={(e) => {
            setFile({ ...file, nomeUpload: e.target.value.trimStart() });
          }}
          style={{ height: '40px' }}
          maxLength={60}
          label=" "
          type="text"
        />

        {!alphanumericWithSpacesAndHyphens.test(file.nomeUpload) &&
          file.nomeUpload !== '' && (
            <ValidationMessageError
              style={{ marginLeft: 14 }}
              message="Neste campo são aceitos apenas caracteres de letra, número e hífen ( - )."
            />
          )}
      </div>
      <div style={{ padding: '16px 75px' }}>
        {file.file.length === 0 ? (
          <Dropzone
            accept={{
              'application/*': ['.xlsx', '.xls', '.csv']
            }}
            multiple={false}
            onDropAccepted={(e: File[]) => {
              setFile({
                ...file,
                file: e
              });
              errorMessage('');
            }}
            onDropRejected={() => {
              errorMessage(
                'Arquivo não compatível. São aceitos apenas arquivos em formato xlsx e CSV.'
              );
            }}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <DropContainer {...getRootProps()} isDragActive={isDragActive}>
                <SVGTaskListFile />
                <Title>Upload de arquivo</Title>
                <SubTitle>
                  Abra o arquivo a partir da seleção ou arraste <br /> o mesmo
                  para a área de upload.
                </SubTitle>
                <Button
                  style={{ marginTop: 24 }}
                  width="auto"
                  value="Selecionar arquivo"
                />
                <input type="file" {...getInputProps()} />
              </DropContainer>
            )}
          </Dropzone>
        ) : (
          <FileSelected>
            <span>
              <DoneRoundedIcon />
              <p>{file.file[0]?.name}</p>
              <CloseIcon
                onClick={() => setFile({ ...file, file: [] })}
                style={{ cursor: 'pointer', color: '#3063e9' }}
              />
            </span>
          </FileSelected>
        )}
      </div>
    </>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '274px'
      }}
    >
      <Spinner size="36px" color="#3063e9" />
    </div>
  );
};

export default DragAndDropTaskList;
