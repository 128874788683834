import React, { ReactNode, useState } from 'react';
import { useTheme } from '@emotion/react';
import { More } from '@sumup/icons';
import { StyledChildrenBox, StyledIcon, StyledPopover } from './styles';

export interface CustomIconProps extends React.SVGProps<SVGSVGElement> {
  /**
   * Choose between the 16 or 24 pixels size. Defaults to `24`.
   */
  size?: '16' | '24' | '32';
}
interface ICustomPopoverAction {
  onClick: () => void;
  children: {
    color: string;
    text: string;
  };
  icon: React.FC<CustomIconProps>;
}
interface ICustomPopover {
  actions: ICustomPopoverAction[];
  disabled?: boolean;
}

const CustomPopover: React.FC<ICustomPopover> = ({
  actions,
  disabled = false
}) => {
  const [openToggle, setOpenToggle] = useState(false);
  const { colors } = useTheme();

  return (
    <StyledPopover
      placement="left"
      isOpen={openToggle}
      actions={actions.map((action) => ({
        onClick: action.onClick,
        children: (
          <StyledChildrenBox
            noMargin
            color={action.children.color}
            size="one"
            as="p"
          >
            {action.children.text}
          </StyledChildrenBox>
        ) as ReactNode & string,
        icon: () => <action.icon color={action.children.color} />
      }))}
      onToggle={() =>
        disabled ? setOpenToggle(false) : setOpenToggle(!openToggle)
      }
      component={(props) => (
        <StyledIcon {...props}>
          <More
            className={disabled ? 'options-disabled' : 'options'}
            color={disabled ? colors.n300 : colors.n700}
          />
        </StyledIcon>
      )}
    />
  );
};

export default CustomPopover;
