import React, { memo, useEffect, useState, useRef } from 'react';
import { Input, SearchInput } from '@sumup/circuit-ui';
import { Close } from '@sumup/icons';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import ControlPointRoundedIcon from '@material-ui/icons/ControlPointRounded';
import {
  AddNewSubgroup,
  CircleCross,
  InputChip,
  Item,
  OptionsContainer
} from './styles';

interface IProps {
  options: string[];
  name: string;
  initialValue: string;
  label: string;
  invalid?: boolean;
  errorMessage?: string;
  placeholder?: string;
  onBlur: (e: any) => void;
  setFieldValue?: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => void;
}

interface Option {
  index: number;
  item: string;
}

const SelectSearchable: React.FC<IProps> = ({
  options,
  name,
  initialValue,
  invalid = false,
  label,
  errorMessage = '',
  placeholder,
  onBlur,
  setFieldValue
}) => {
  const initialOptionsValues: Option[] = options.map((item, index) => {
    return { index, item };
  });

  const [value, setValue] = useState<string>(initialValue || '');
  const [optionsList] =
    useState<{ index: number; item: string }[]>(initialOptionsValues);
  const [optionsFilteredList, setOptionsFilteredList] =
    useState<{ index: number; item: string }[]>(initialOptionsValues);

  const containerRef = useRef(null);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(
    !initialValue ? -1 : options.indexOf(initialValue)
  );
  const [open, setOpen] = useState(false);

  const getSubgrupoSelected = (index: number) => {
    return optionsList.filter((item) => item.index === index)[0]?.item;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && selectedOptionIndex > 0 && open) {
      setValue('');
      setSelectedOptionIndex(-1);
    }

    let indexAux;
    if (event.key === 'ArrowDown' && open) {
      event.preventDefault();
      indexAux = hoverIndex === optionsFilteredList.length ? 0 : hoverIndex + 1;
      setHoverIndex(indexAux);
      scroll(indexAux);
    }
    if (event.key === 'ArrowUp' && open) {
      event.preventDefault();
      indexAux = hoverIndex <= 0 ? optionsFilteredList.length : hoverIndex - 1;
      setHoverIndex(indexAux);
      scroll(indexAux);
    }
    if (event.key === 'Enter' && open) {
      event.preventDefault();
      if (hoverIndex >= 0 && hoverIndex !== optionsFilteredList.length) {
        setSelectedOptionIndex(optionsFilteredList[hoverIndex].index);
        setValue(getSubgrupoSelected(optionsFilteredList[hoverIndex].index));
        setOpen(false);
        return;
      }
      if (selectedOptionIndex === -1 && value) {
        setSelectedOptionIndex(options.length);
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    setOptionsFilteredList(
      optionsList.filter((nome) =>
        nome.item?.toUpperCase().startsWith(value?.toUpperCase())
      )
    );
    setHoverIndex(-1);
  }, [value]);

  useEffect(() => {
    setHoverIndex(-1);
  }, [open]);

  useEffect(() => {
    if (setFieldValue) {
      setFieldValue(name, selectedOptionIndex !== -1 ? value : '');
    }
  }, [selectedOptionIndex]);

  const scroll = (hoverIndex: number) => {
    const itemElement = document.getElementById(`item-${hoverIndex}`);
    itemElement?.scrollIntoView({ behavior: 'auto' });
  };

  return (
    <div>
      {selectedOptionIndex === -1 && (
        <div style={{ position: 'relative' }}>
          {value && selectedOptionIndex === -1 && (
            <CircleCross invalid={invalid} onClick={() => setValue('')} />
          )}
          <SearchInput
            placeholder={placeholder}
            label={label}
            value={value}
            name={name}
            autoComplete="off"
            onKeyDown={handleKeyDown}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setOpen(true)}
            onBlur={(e) => {
              onBlur(e);
              setOpen(false);
            }}
            validationHint={open || !invalid ? '' : errorMessage}
            invalid={invalid}
          />
        </div>
      )}
      {selectedOptionIndex !== -1 && (
        <div style={{ position: 'relative' }}>
          <InputChip>
            <span style={{ paddingRight: 8 }}>
              {selectedOptionIndex === options.length
                ? value
                : getSubgrupoSelected(selectedOptionIndex)}
            </span>
            <Close
              size="16"
              onMouseDown={() => {
                setValue('');
                setSelectedOptionIndex(-1);
              }}
              style={{ scale: '0.7', cursor: 'pointer' }}
            />
          </InputChip>
          <Input
            name={name}
            label={label}
            value=""
            autoComplete="off"
            onKeyDown={handleKeyDown}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            invalid={invalid}
            validationHint={open || !invalid ? '' : errorMessage}
          />
        </div>
      )}

      {open && (
        <OptionsContainer ref={containerRef}>
          {selectedOptionIndex !== -1 &&
            selectedOptionIndex !== options.length && (
              <Item id="item-0" onClick={() => setOpen(false)} selected>
                <p>{getSubgrupoSelected(selectedOptionIndex)}</p>
                <DoneRoundedIcon />
              </Item>
            )}
          {optionsFilteredList.map((option, index) => {
            if (selectedOptionIndex !== option.index && option.item) {
              return (
                <Item
                  id={`item-${index}`}
                  selected={false}
                  hover={hoverIndex === index}
                  onMouseDown={() => {
                    setSelectedOptionIndex(option.index);
                    setValue(getSubgrupoSelected(option.index));
                    setOpen(false);
                  }}
                >
                  {option.item}{' '}
                </Item>
              );
            }
            return null;
          })}
          {optionsFilteredList.length === 0 && value && (
            <p style={{ color: '#d8dde1', padding: '8px 16px' }}>
              Nenhuma Correspondência
            </p>
          )}

          {value && options.findIndex((v) => v === value) === -1 && (
            <AddNewSubgroup
              id={`item-${optionsFilteredList.length}`}
              selected={selectedOptionIndex === options.length}
              onMouseDown={() => {
                if (value) {
                  setSelectedOptionIndex(options.length);
                  setOpen(false);
                }
              }}
              hover={hoverIndex === optionsFilteredList.length}
            >
              <ControlPointRoundedIcon
                fontSize="small"
                style={{ marginRight: 8 }}
              />
              <span>Adicionar novo subgrupo</span>
            </AddNewSubgroup>
          )}
        </OptionsContainer>
      )}
    </div>
  );
};

export default memo(SelectSearchable);
