import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as GraphIcon } from '../../assets/icons/graph.svg';
import { ReactComponent as TableIcon } from '../../assets/icons/table.svg';
import {
  Button,
  Chart,
  CountItensPerPage,
  CustomPagination,
  SubTitle,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  NoResultsState
} from '../../components/_Molecules';
import {
  TaskListFollowUpFilters,
  TaskListFollowUpModal,
  TaskListFollowUpTable
} from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';
import { IDataResponseModel } from '../../interfaces/requests.interfaces';
import {
  IFiltersTaskListFollowUp,
  ITaskGraph,
  ITaskView
} from '../../interfaces/taskList.interfaces';
import { post } from '../../services/axios.service';
import {
  ChartContainer,
  ChartStyle,
  Charts,
  PaginationContainer,
  StyledBoxPage,
  ToggleView
} from './styles';

const TaskListFollowUp: FC = () => {
  const [filters, setFilters] = useState<IFiltersTaskListFollowUp>({
    status: [1, 2, 3, 4],
    subGrupo: [],
    executivos: [],
    tipoTarefa: [],
    dateCreation: { start: '', end: '' },
    dateConclusion: { start: '', end: '' },
    dateDeadline: { start: '', end: '' }
  });

  const [values, setValues] = useState<ITaskGraph>();
  const [selected, setSelected] = useState<ITaskView>();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();

  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(-1);
  const [resetPagination, setResetPagination] = useState(true);
  const [showTable, setShowTable] = useState(true);

  const getData = async (page: number) => {
    setLoading(true);

    try {
      const { data } = await post<IDataResponseModel<ITaskGraph>>(
        API.TAREFAS.LISTAR,
        {
          pag: {
            pageNumber: page,
            pageSize: itemsPerPage
          },
          ...(filters.dateCreation.start && {
            dataInicialCriacao: filters.dateCreation.start
          }),
          ...(filters.dateCreation.end && {
            dataFinalCriacao: filters.dateCreation.end
          }),
          ...(filters.dateConclusion.start && {
            dataInicialConclusao: filters.dateConclusion.start
          }),
          ...(filters.dateConclusion.end && {
            dataFinalConclusao: filters.dateConclusion.end
          }),
          ...(filters.dateDeadline.start && {
            dataInicialPrazo: filters.dateDeadline.start
          }),
          ...(filters.dateDeadline.end && {
            dataFinalPrazo: filters.dateDeadline.end
          }),
          ...(filters?.status?.length > 0 && {
            status: filters?.status
          }),
          ...(filters.subGrupo.length > 0 && {
            polo: filters.subGrupo.filter((item) => item !== null)
          }),
          ...(filters.executivos?.length > 0 && {
            executivo: filters.executivos
          }),
          ...(filters.tipoTarefa?.length > 0 && {
            tipoTarefaId: filters.tipoTarefa
          })
        }
      );

      setValues(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    setCurrentPage(1);
    setResetPagination(false);
    await getData(1);
    setResetPagination(true);
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page + 1);
    await getData(page + 1);
  };

  return (
    <StyledBoxPage>
      <Breadcrumb
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          },
          {
            routeName: 'Gestão de tarefas',
            routePath: MENU_ROUTES.TASK_LIST_MANAGEMENT
          }
        ]}
        currentRouteName="Gerenciar tarefas"
        noMargin
      />
      <Title style={{ marginTop: 32 }} fontSize="big">
        Gerenciar tarefas
      </Title>
      <SubTitle>Visualize e gerencie as tarefas do seu time</SubTitle>
      <TaskListFollowUpFilters
        getTableData={loadData}
        loading={loading}
        filters={filters}
        setFilters={setFilters}
      />
      <ToggleView onClick={() => setShowTable(!showTable)}>
        {!showTable ? <TableIcon /> : <GraphIcon />}
        <p>{!showTable ? 'Visualizar Tabela' : 'Visualizar Dashboard'}</p>
      </ToggleView>
      {showTable ? (
        <TaskListFollowUpTable
          loading={loading}
          values={values}
          setSelected={setSelected}
          setOpenModal={setOpenModal}
        />
      ) : values?.tarefas?.results.length === 0 ? (
        <NoResultsState />
      ) : (
        <Charts
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start'
          }}
        >
          <Chart
            data={[
              {
                name: 'Atrasadas',
                value: Number(values?.quantidadeTarefasAtrasada) || 0,
                color: '#db4d4d'
              },
              {
                name: 'No Prazo',
                value: Number(values?.quantidadeTarefasNoPrazo) || 0,
                color: '#3063e9'
              },
              {
                name: 'Finalizadas c/ atraso',
                value:
                  Number(values?.quantidadeTarefasConcluidasAtrasadas) || 0,
                color: '#d8a413'
              },
              {
                name: 'Finalizadas',
                value: Number(values?.quantidadeTarefasConcluidas) || 0,
                color: '#47995a'
              }
            ].sort((a, b) => a.value - b.value)}
          />

          <ChartContainer>
            <h1>Tarefas concluídas fora do prazo</h1>
            <div>
              <ChartStyle>
                {Number.isInteger(values?.porcentagemConcluidasAtrasada)
                  ? values?.porcentagemConcluidasAtrasada.toString()
                  : values?.porcentagemConcluidasAtrasada.toFixed(2) || 0}
                %
              </ChartStyle>

              <p>
                {Number.isInteger(values?.porcentagemConcluidasAtrasada)
                  ? values?.porcentagemConcluidasAtrasada.toString()
                  : values?.porcentagemConcluidasAtrasada.toFixed(2) || 0}
                % das tarefas finalizadas
                <br />
                foram fora do prazo
              </p>
            </div>
          </ChartContainer>
        </Charts>
      )}
      {values?.tarefas && values.tarefas.totalItems > itemsPerPage && (
        <PaginationContainer displayNone={showTable}>
          <CountItensPerPage
            currentPage={currentPage}
            itensPerPage={itemsPerPage}
            totalItens={values.tarefas.totalItems}
          />
          <CustomPagination
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            totalItems={values.tarefas.totalItems}
            initialPage={currentPage}
            shouldResetPagination={resetPagination}
          />
        </PaginationContainer>
      )}
      {selected && (
        <TaskListFollowUpModal
          selected={selected}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
      <BottomFixedActions style={{ zIndex: 1 }}>
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          value="Voltar"
          variant="secondary"
          onClick={() => push(MENU_ROUTES.TASK_LIST_MANAGEMENT)}
        />
      </BottomFixedActions>
    </StyledBoxPage>
  );
};

export default TaskListFollowUp;
