import axios from 'axios';
import { FC, useState } from 'react';
import { ReactComponent as Done } from '../../../assets/icons/done.svg';
import { ReactComponent as Loading } from '../../../assets/icons/loading.svg';
import { API } from '../../../constants/api.constants';
import { IMachineView } from '../../../interfaces/AllocateMachines.interfaces';
import { IDataResponseModel } from '../../../interfaces/requests.interfaces';
import { post } from '../../../services/axios.service';
import { handleDownloadCSV } from '../../../services/download.service';
import { CustomTooltip, Spinner } from '../../_Atoms';
import { Modal } from '../../_Molecules';
import { IToast } from '../../_Molecules/Toast';
import {
  ConfirmationModalLine,
  ConfirmationModalLineHeader,
  ContainerModalConfirmation,
  ContainerModalLines,
  StatusModal
} from './style';

interface IProps {
  selectedList: IMachineView[];
  setSelectedList: (p: IMachineView[]) => void;
  open: boolean;
  setOpen: (p: boolean) => void;
  toastList: IToast[];
  setToast: (p: IToast[]) => void;
  setViewAllSelected: (p: boolean) => void;
  getMachinesData: () => void;
}
const AllocationConfirmationList: FC<IProps> = ({
  open,
  setOpen,
  selectedList,
  setSelectedList,
  toastList,
  setToast,
  setViewAllSelected,
  getMachinesData
}) => {
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);

    const request = selectedList.map((item) => ({
      atribuida: item.atribuida,
      antigoProprietarioId: item.promotorId,
      novoProprietarioId: item.newPromotorId,
      serialNumber: item.serialNumber,
      productType: item.nomeMaquina,
      sku: item.sku
    }));

    try {
      const res = await post<IDataResponseModel<string>>(
        API.CONTROLE_ATIVOS.SALVAR_ATRIBUICAO,
        request
      );

      if (res.success) {
        setSelectedList([]);
        setViewAllSelected(false);
        getMachinesData();

        await DownloadFile(res.data);

        setToast([
          {
            id: toastList.length + 1,
            title: 'Arquivo de atribuição gerado!',
            description:
              'Um arquivo com as atribuições foi baixado para sua máquina.',
            type: 'sucess'
          }
        ]);
      } else {
        setToast([
          {
            id: toastList.length + 1,
            title: 'Algo deu errado!',
            description: 'Por favor, faça a geração do arquivo novamente.',
            type: 'error'
          }
        ]);
      }
    } catch (error) {
      setToast([
        {
          id: toastList.length + 1,
          title: 'Algo deu errado!',
          description: 'Por favor, faça a geração do arquivo novamente.',
          type: 'error'
        }
      ]);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const DownloadFile = (url: string) => {
    axios
      .get(url, { responseType: 'blob' })
      .then((response) =>
        handleDownloadCSV(response.data, 'historico-atribuicao')
      )
      .catch(() => {
        setToast([
          {
            id: 0,
            title: 'Tempo excedido!',
            description: 'A exportação demorou demais para ser processada.',
            type: 'error'
          }
        ]);
      });
  };

  const handleOpen = (e: boolean) => {
    setOpen(e);
    if (!e) setToast([]);
  };

  return (
    <Modal
      title="Confira as máquinas selecionadas para atribuição"
      open={open}
      setOpen={loading ? () => null : handleOpen}
      action={submit}
      labelAction="Gerar arquivo"
      widthButtonAction="auto"
      disableAction={loading}
    >
      <ContainerModalConfirmation>
        <p style={{ marginLeft: -12, fontSize: 12 }}>
          Confira as atribuições de máquinas, confirme e gere o arquivo para o
          upload no sistema.
        </p>
        {!loading ? (
          <>
            <ConfirmationModalLineHeader>
              <p>Atribuída</p>
              <p>Tipo de máquina</p>
              <p>Serial number</p>
              <p>SKU</p>
              <p>Executivo</p>
            </ConfirmationModalLineHeader>

            <ContainerModalLines>
              {selectedList.map((item) => (
                <ConfirmationModalLine>
                  <StatusModal color={!item?.atribuida ? '#3063e9' : '#47995a'}>
                    {item?.atribuida ? (
                      <>
                        <Done />
                        <p>Máquina atribuida</p>
                      </>
                    ) : (
                      <>
                        <Loading />
                        <p>Máquina não atribuida</p>
                      </>
                    )}
                  </StatusModal>
                  <CustomTooltip title={item.nomeMaquina || ''}>
                    <p>{item.nomeMaquina}</p>
                  </CustomTooltip>
                  <CustomTooltip title={item.serialNumber}>
                    <p>{item.serialNumber}</p>
                  </CustomTooltip>
                  <CustomTooltip title={item.sku}>
                    <p>{item.sku}</p>
                  </CustomTooltip>
                  <CustomTooltip title={item.newPromotorName}>
                    <p style={{ fontWeight: 'bold' }}>{item.newPromotorName}</p>
                  </CustomTooltip>
                </ConfirmationModalLine>
              ))}
            </ContainerModalLines>
          </>
        ) : (
          <div
            style={{
              height: 74 * (selectedList.length + 1),
              maxHeight: 600,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Spinner size="46px" color="#3388ff" width="5px" />
          </div>
        )}
      </ContainerModalConfirmation>
    </Modal>
  );
};

export default AllocationConfirmationList;
