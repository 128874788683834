import styled from '@emotion/styled';

export const StyledCustomTitleCell = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  &:last-of-type {
    height: 100%;
    background-color: white;
  }
`;
export const StyledCustomContentCell = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  * {
    margin-bottom: 0px !important;
  }

  &:nth-child(-n + 11) {
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }

  &:nth-child(1) {
    border-left: 1px solid #e0e0e0;
  }
`;

export const StyledCustomTRContent = styled.div`
  height: 72px;
  display: grid;
  grid-template-columns: 56px repeat(4, 126px) 82px 82px repeat(4, 126px) 56px;

  @media (max-width: 1280px) {
    grid-template-columns: 56px repeat(4, 120px) 82px 82px repeat(4, 126px) 56px;
  }

  &:nth-child(2n - 1) {
    div:last-child {
      div {
        background-color: white;
      }
    }

    div:nth-child(-n + 11) {
      background-color: #f4f9ff;
      label {
        div {
          select {
            background-color: #f4f9ff;
          }
          textarea {
            background-color: #f4f9ff;
          }
        }
      }
      input {
        background-color: #f4f9ff;
      }
    }
  }
`;
export const StyledGridContent = styled.div`
  max-width: 1240px;
  width: 100%;
  min-height: 600px;
  margin: 0 auto;
`;
export const StyledIconBox = styled.div`
  margin-bottom: 5px;
  svg {
    color: ${(p) => p.theme.colors.p500};
    cursor: pointer;
  }
`;
export const StyledBoxToScroll = styled.div`
  width: 100%;
  position: relative;
  backgroundcolor: red;
`;

export const ClearFiltersButton = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  font-weight: bold;
  color: #3063e9;
  cursor: pointer;
`;
