import { FC, memo, useState } from 'react';
import { KanbanBoard, KanbanFilters } from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import {
  IActiveFilters,
  IColuna,
  IResponseBoard,
  IResponseGetMoreCards
} from '../../interfaces/kanban.interface';
import { post } from '../../services/axios.service';
import { addNewsCard } from '../../utils/kanban';

const Kanban: FC = () => {
  const [board, setBoard] = useState<IColuna[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loadingGetMoreCards, setLoadingGetMoreCards] = useState({
    coluna: 0,
    loading: false
  });
  const [activeFilters, setActiveFilters] = useState<IActiveFilters | null>(
    null
  );

  const onFilter = (
    status: number[],
    dateRange: { start: string; end: string },
    idsUsers: number[],
    companies: string[],
    subGroups: string[]
  ): void => {
    const statusFormated = status ? (status.length === 12 ? [1] : status) : [];
    getData(
      dateRange.end,
      dateRange.start,
      statusFormated,
      idsUsers,
      companies,
      subGroups
    );
    setLoadingFilter(true);
  };

  const getMoreCards = async (coluna: number, quantidadeExibidos: number) => {
    setError(false);
    setLoadingGetMoreCards({ coluna, loading: true });

    const params = {
      classificacao: activeFilters?.classificacao,
      equipe: activeFilters?.equipe,
      dataInicial: activeFilters?.dataInicial || null,
      dataFinal: activeFilters?.dataFinal || null,
      empresas: activeFilters?.empresas,
      subGrupos: activeFilters?.subGrupos,
      coluna,
      quantidadeExibidos
    };

    try {
      await post<IResponseGetMoreCards>(API.VISITAS.VER_MAIS, params).then(
        (res) => {
          setBoard(addNewsCard(board, res.data.coluna, res));
        }
      );
    } catch (e) {
      setError(true);
    }
    setLoadingGetMoreCards({ coluna, loading: false });
  };

  const getData = async (
    endDate: string,
    initialDate: string,
    status: number[],
    idsUsers: number[],
    companies: string[],
    subGroups: string[]
  ) => {
    setLoading(true);
    setError(false);

    const params: IActiveFilters = {
      classificacao: status,
      equipe: idsUsers,
      dataInicial: initialDate || null,
      dataFinal: endDate || null,
      empresas: companies,
      subGrupos: subGroups
    };

    try {
      await post<IResponseBoard>(API.VISITAS.LISTAR, params).then((res) => {
        setBoard(res.data);
      });
    } catch (e) {
      setBoard([]);
      setError(true);
    }

    setLoading(false);
    setLoadingFilter(false);
    setActiveFilters(params);
  };

  return (
    <>
      <KanbanFilters
        loading={loadingFilter}
        onFilter={onFilter}
        emptyBoard={board.every((coluna) => coluna.total === 0)}
        filtersToExportFile={activeFilters}
      />
      <KanbanBoard
        error={error}
        loading={loading}
        data={board}
        getMoreCards={getMoreCards}
        loadingGetMoreCards={loadingGetMoreCards}
      />
    </>
  );
};

export default memo(Kanban);
