import styled from '@emotion/styled';

export const TitleTable = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1.5fr 1fr 1fr 1.5fr 0.3fr;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  background-color: #fafbfc;
  height: 72px;
  line-height: 20px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1.5fr 1fr 1fr 1.5fr 0.3fr;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  text-align: center;
  font-size: 13px;
  height: 62px;
  border-bottom: 1px solid #eef0f2;

  p {
    width: 100%;
    text-overflow: ellipsis;
    padding: 0 12px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    white-space: nowrap;
  }
`;
