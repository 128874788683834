import { FC } from 'react';
import { FiltersEditUser } from '../../../../interfaces/addUser.interface';
import { CustomSelectFields } from '../styles';

const SubGroupFilter: FC<{
  filters: FiltersEditUser;
  setFilter: (p: FiltersEditUser) => void;
  groups: { options: Array<{ label: string; value: string }> };
}> = ({ filters, setFilter, groups }) => (
  <CustomSelectFields
    selected={!!filters.subGroup}
    label="Subgrupo"
    onChange={(e) => {
      setFilter({ ...filters, subGroup: e.target.value });
    }}
    value={filters.subGroup}
    options={groups.options}
    name="subGroup"
    placeholder="Selecione uma opção"
  />
);

export default SubGroupFilter;
