import { CardFooter, Headline } from '@sumup/circuit-ui';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { API } from '../../constants/api.constants';
import {
  IDataResponseModel,
  IRequestDuplicarOferta,
  ISubmitError
} from '../../interfaces/requests.interfaces';
import { post } from '../../services/axios.service';
import { doubleRatePlan } from '../../utils/yup-utils';
import { CustomButton, InputField } from '../_Atoms';
import { StyledActionsBar, StyledContentBox } from './styles';

interface IDoubleRatePlans {
  planId: number;
  removeModal: () => void;
  getPlans: () => void;
}

const DoubleRatePlans: React.FC<IDoubleRatePlans> = ({
  planId,
  removeModal,
  getPlans
}) => {
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<ISubmitError>({
    showError: false,
    message: ''
  });

  const handleSubmit = async (formValues: { name: string }) => {
    setLoading(true);
    setSubmitError({ showError: false, message: '' });

    const formatedData: IRequestDuplicarOferta = {
      id: planId,
      nome: formValues.name
    };
    try {
      const res = await post<IDataResponseModel<{ id: number }>>(
        API.PLANOS.DUPLICAR,
        formatedData
      );

      if (res.success) {
        getPlans();
        removeModal();
      }

      if (res.error) {
        setSubmitError({
          showError: true,
          message: res.message
        });
      }
    } catch (err) {
      setSubmitError({
        showError: true,
        message:
          'Houve um problema com a requisição. Por favor, tente novamente.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Headline size="three" as="h3">
        Duplicar Plano de Taxas
      </Headline>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={(formValues) => handleSubmit(formValues)}
        validationSchema={doubleRatePlan}
        validateOnMount
      >
        {({ handleChange, handleBlur, errors, touched, isValid }) => (
          <Form>
            <StyledActionsBar>
              <StyledContentBox>
                <InputField
                  errorMessage={
                    errors.name != null ? errors.name : submitError.message
                  }
                  label="Insira um novo nome para o plano de taxa duplicado:"
                  placeholder="Nome do plano de taxa duplicado"
                  id="double-rate-plan-name"
                  invalid={
                    !!(errors.name && touched.name) || submitError.showError
                  }
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                />
              </StyledContentBox>
            </StyledActionsBar>
            <CardFooter align="right">
              <CustomButton
                variant="secondary"
                type="button"
                stretch
                onClick={removeModal}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                isLoading={loading}
                disabled={!isValid}
                loadingLabel="Salvando..."
                variant="primary"
                type="submit"
                stretch
              >
                Salvar
              </CustomButton>
            </CardFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DoubleRatePlans;
