export enum ENUM_STATUS_PROCESSAMENTO {
  'Não iniciado' = 1,
  'Em processamento' = 2,
  'Finalizado' = 3,
  'Falha' = 4
}

export enum ENUM_TIPO_SOLICITACAO {
  'Propostas por merchant' = 1,
  'Propostas por serial' = 2,
  'Propostas por Comodato' = 3
}
