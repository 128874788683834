import styled from '@emotion/styled';

export const TableHeader = styled.div`
  display: grid;
  height: 72px;
  align-items: center;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  font-size: 13px;
  font-weight: bold;
  background-color: #fafbfc;
`;

export const SpinnerContainer = styled.div`
  min-height: 432px;
  display: flex;
  align-items: center;
`;

export const TableValue = styled.div`
  display: grid;
  height: 72px;
  align-items: center;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  font-size: 13px;
  border-bottom: 1px solid ${(p) => p.theme.colors.shadow};
  background-color: #fff;
`;

export const ResultValue = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40ch;
  margin: auto;
  padding: 0 24px;
  text-align: center;
  white-space: nowrap;
`;
