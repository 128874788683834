import { Checkbox, SearchInput, SelectOption } from '@sumup/circuit-ui';
import { FieldArray, Form, Formik, getIn } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  StyledCustomTD,
  StyledCustomTH,
  StyledCustomTRContent,
  StyledCustomTRTitle
} from '../../components/CustomTable';
import {
  Button,
  CustomButton,
  CustomInlineMessage,
  CustomSpinner,
  CustomTooltip,
  SubTitle,
  Title
} from '../../components/_Atoms';
import {
  Breadcrumb,
  CustomDialog,
  NotFoundItem
} from '../../components/_Molecules';
import { NetworkError } from '../../components/_Organisms';
import { SearchBar } from '../../components/_Organisms/FilterUserEdit/SearchBar/styles';
import { API } from '../../constants/api.constants';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';
import { IEditCampaignsForm } from '../../interfaces/campaigns-management.interfaces';
import {
  IRequestCadastroCampanhas,
  IResponseCampanhas,
  IResponseOfertas
} from '../../interfaces/requests.interfaces';
import { get, post } from '../../services/axios.service';
import {
  concatAllCampaigns,
  getCampaignsFormated
} from '../../services/campaigns.service';
import { equalsObject } from '../../utils/format.utils';
import { editCampaignsValidation } from '../../utils/yup-utils';
import { StyledBox, StyledInlineButtons, StyledSelectField } from './styles';

interface ICampaignsForm extends IEditCampaignsForm {
  search: string;
}

const CampaignsPage: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [offers, setOffers] = useState<SelectOption[]>([]);
  const [machineGroups, setMachineGroups] = useState<SelectOption[]>([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [errorOnLoadData, setErrorOnLoadData] = useState(true);
  const [campaigns, setCampaigns] = useState<ICampaignsForm>({
    campanhas: [],
    search: ''
  });

  const router = useHistory();

  const handleCloseDialog = () => {
    router.push(MENU_ROUTES.CAMPAIGNS_MANAGEMENT);
  };

  const handleSubmit = async (formData: IEditCampaignsForm) => {
    if (errorOnLoadData) return;

    setHasError(false);
    setLoading(true);

    const formatedData: IRequestCadastroCampanhas = {
      campanhas: formData.campanhas
        .map((campanha) => ({
          campanhaId: campanha.campanhaId as number,
          grupoMaquinasId: Number(campanha.grupoMaquinasId),
          ofertaId: Number(campanha.ofertaId),
          subGrupo: campanha.subGrupo,
          permiteEmitirNF: campanha.permiteEmitirNF,
          permiteComodato: campanha.permiteComodato,
          permiteWhatsApp: campanha.permiteWhatsApp,
          permiteHistoricoCliente: campanha.permiteHistoricoCliente,
          permiteGravacao: campanha.permiteGravacao,
          permiteMenuMinhasVisitas: campanha.permiteMenuMinhasVisitas,
          permiteComodatoSemEnvioContrato:
            campanha.permiteComodatoSemEnvioContrato,
          permiteListaSN: campanha.permiteListaSN
        }))
        .filter((campanha) => campanha.ofertaId !== 0)
    };

    try {
      await post(API.CAMPANHAS.POST, formatedData);
      setOpenDialog(true);
    } catch (error) {
      setLoading(false);
      setHasError(true);
    }
  };

  const getOfferById = (id: string | number) => {
    const offer = offers.find((offer) => offer.value.toString() === id)?.label;
    if (typeof offer === 'string') {
      return offer;
    }
    return 'Sem oferta';
  };

  const getMachineGroupsById = (id: string | number) => {
    const group = machineGroups.find(
      (group) => Number(group.value) === Number(id)
    )?.label;
    if (typeof group === 'string') {
      return group;
    }
    return 'Selecionar preços';
  };

  const getCampaigns = async () => {
    setLoadingPage(true);

    try {
      const res = await get<{ data: IResponseCampanhas }>(API.CAMPANHAS.GET);

      const offersResponse = res.data.ofertas;
      const grupoMaquinas = res.data.gruposMaquinas;
      const subgruposResponse = res.data.subGrupos;
      const campanhasResponse = res.data.campanhas;

      // remove os subgrupos que já estão listados na lista de campanhas
      const filteredSubgroups = subgruposResponse.filter(
        (subgrupo) =>
          !campanhasResponse.some((campanha) => campanha.subGrupo === subgrupo)
      );

      const { campanhas }: IEditCampaignsForm = !filteredSubgroups.length
        ? getCampaignsFormated(res.data)
        : concatAllCampaigns(campanhasResponse, filteredSubgroups);

      setOffers(
        offersResponse.flatMap((response: IResponseOfertas) =>
          response.ativa ||
          campanhasResponse.some(
            (campanha) => campanha.ofertaId === response.ofertaId
          )
            ? {
                label: response.nome,
                value: response.ofertaId,
                disabled: !response.ativa
              }
            : []
        )
      );

      setMachineGroups(
        grupoMaquinas.map((response) => ({
          label: response.descricao,
          value: response.id,
          disabled: !response.ativo
        }))
      );

      setCampaigns({
        ...campaigns,
        campanhas: campanhas.sort((a, b) => {
          return a.subGrupo.localeCompare(b.subGrupo);
        })
      });
      setErrorOnLoadData(false);
    } catch (e) {
      setErrorOnLoadData(true);
    } finally {
      setLoadingPage(false);
    }
  };
  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <>
      <StyledBox style={{ padding: '0 20px' }}>
        <Formik
          initialValues={campaigns}
          enableReinitialize
          validationSchema={editCampaignsValidation}
          onSubmit={(values) => handleSubmit(values)}
          validateOnMount
        >
          {({ values, handleChange, handleBlur }) => (
            <>
              <Breadcrumb
                confirmationModal={!equalsObject(campaigns, values)}
                noMargin
                currentRouteName="Campanhas"
                previousRoutes={[
                  {
                    routeName: 'Menu principal',
                    routePath: ROUTES.MAIN_MENU
                  },
                  {
                    routeName: 'Gestão de campanhas',
                    routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
                  }
                ]}
              />
              <Title style={{ margin: '32px 0px 8px 0px' }} fontSize="big">
                Campanhas
              </Title>
              <SubTitle>
                Visualize e cadastre ofertas atreladas a cada subgrupo
                cadastrado no banco de dados.
              </SubTitle>
              <form
                onSubmit={(e: any) => {
                  setCampaigns({
                    ...values,
                    search: e.target[0].value
                  });
                  e.preventDefault();
                }}
                style={{
                  width: '100%',
                  maxWidth: '1200px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <p />
                <SearchBar style={{ margin: '32px 0px' }}>
                  <SearchInput
                    label="Digite sua busca"
                    autoComplete="off"
                    onClear={() => setCampaigns({ ...values, search: '' })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '') {
                        setCampaigns({
                          ...values,
                          search: e.target.value
                        });
                      }
                    }}
                    clearLabel="clearable"
                    name="search"
                    placeholder="Buscar campanha"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setCampaigns({
                          ...values,
                          search: e.currentTarget.value
                        });
                      }
                    }}
                  />
                </SearchBar>
                <Button
                  type="submit"
                  value="Buscar"
                  style={{ marginTop: 8, width: 110 }}
                />
              </form>
              <Form>
                <FieldArray
                  name="campanhas"
                  render={({ form }) => {
                    const { campanhas } = values;
                    const { errors, touched } = form;
                    const getError = (name: string) => getIn(errors, name);
                    const getTouch = (name: string) => getIn(touched, name);
                    return (
                      <>
                        <Box>
                          <StyledCustomTRTitle>
                            <StyledCustomTH>Subgrupo</StyledCustomTH>
                            <StyledCustomTH>Oferta</StyledCustomTH>
                            <StyledCustomTH>Preço de máquinas</StyledCustomTH>
                            <StyledCustomTH>
                              Emissão de Nota <br /> Fiscal
                            </StyledCustomTH>
                            <StyledCustomTH>
                              Contrato de <br />
                              Comodato
                            </StyledCustomTH>
                            <StyledCustomTH>
                              Envio de proposta <br /> para todos os clientes
                            </StyledCustomTH>
                            <StyledCustomTH>
                              Acesso ao <br /> &quot;Meus clientes&quot;
                            </StyledCustomTH>
                            <StyledCustomTH>
                              Acesso ao <br /> &quot;Minhas visitas&quot;
                            </StyledCustomTH>
                            <StyledCustomTH>
                              Habilitar novo <br /> fluxo de comodato
                            </StyledCustomTH>
                            <StyledCustomTH>Gravação de tela</StyledCustomTH>
                            <StyledCustomTH>
                              Controle de <br /> ativos
                            </StyledCustomTH>
                            <StyledCustomTH />
                          </StyledCustomTRTitle>
                          {!!campanhas?.length &&
                            campanhas.map((campanha, index) => (
                              <StyledCustomTRContent
                                displayNone={campanha.subGrupo
                                  ?.toLowerCase()
                                  .includes(values.search.toLowerCase())}
                                key={index}
                              >
                                <StyledCustomTD>
                                  <CustomTooltip
                                    title={campanha.subGrupo}
                                    placement="right"
                                    arrow
                                    style={{
                                      maxWidth: '260px',
                                      width: 'auto',
                                      margin: 'auto'
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 13,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        margin: 'auto',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '200px',
                                        width: 'auto'
                                      }}
                                    >
                                      {campanha.subGrupo || (
                                        <span style={{ color: '#9DA7B1' }}>
                                          (Sem nome)
                                        </span>
                                      )}
                                    </p>
                                  </CustomTooltip>
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <StyledSelectField
                                    tooltip={getOfferById(campanha.ofertaId)}
                                    defaultValue={campanha.ofertaId}
                                    errorMessage={getError(
                                      `campanhas.${campanha.campanhaId}.ofertaId`
                                    )}
                                    hideLabel
                                    invalid={
                                      getError(
                                        `campanhas.${campanha.campanhaId}.ofertaId`
                                      ) &&
                                      getTouch(
                                        `campanhas.${campanha.campanhaId}.ofertaId`
                                      )
                                    }
                                    style={{ marginBottom: 0 }}
                                    isTableComponent
                                    label="Oferta"
                                    name={`campanhas.${index}.ofertaId`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    options={offers}
                                    placeholder="Sem oferta"
                                    isEmpty={
                                      getOfferById(campanha.ofertaId) ===
                                      'Sem oferta'
                                    }
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <StyledSelectField
                                    tooltip={getMachineGroupsById(
                                      campanha.grupoMaquinasId
                                    )}
                                    defaultValue={campanha.grupoMaquinasId}
                                    errorMessage={getError(
                                      `campanhas.${campanha.grupoMaquinasId}.grupoMaquinasId`
                                    )}
                                    hideLabel
                                    invalid={
                                      getError(
                                        `campanhas.${campanha.grupoMaquinasId}.grupoMaquinasId`
                                      ) &&
                                      getTouch(
                                        `campanhas.${campanha.grupoMaquinasId}.grupoMaquinasId`
                                      )
                                    }
                                    style={{ marginBottom: 0 }}
                                    isTableComponent
                                    label="Grupo de maquinas"
                                    name={`campanhas.${index}.grupoMaquinasId`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    options={machineGroups}
                                    placeholder="Selecionar preços"
                                    isEmpty={
                                      getMachineGroupsById(
                                        campanha.grupoMaquinasId
                                      ) === 'Selecionar preços'
                                    }
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <Checkbox
                                    defaultChecked={campanha.permiteEmitirNF}
                                    disabled={!campanha.ofertaId}
                                    value={`${campanha.permiteEmitirNF}`}
                                    onChange={handleChange}
                                    style={{ marginTop: '5px' }}
                                    name={`campanhas.${index}.permiteEmitirNF`}
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <Checkbox
                                    defaultChecked={
                                      values.campanhas[index].permiteComodato
                                    }
                                    disabled={!values.campanhas[index].ofertaId}
                                    value={`${values.campanhas[index].permiteComodato}`}
                                    onChange={handleChange}
                                    style={{ marginTop: '5px' }}
                                    name={`campanhas.${index}.permiteComodato`}
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <Checkbox
                                    defaultChecked={campanha.permiteWhatsApp}
                                    disabled={!campanha.ofertaId}
                                    value={`${campanha.permiteWhatsApp}`}
                                    style={{ marginTop: '5px' }}
                                    onChange={handleChange}
                                    name={`campanhas.${index}.permiteWhatsApp`}
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <Checkbox
                                    defaultChecked={
                                      campanha.permiteHistoricoCliente
                                    }
                                    disabled={!campanha.ofertaId}
                                    value={`${campanha.permiteHistoricoCliente}`}
                                    style={{ marginTop: '5px' }}
                                    onChange={handleChange}
                                    name={`campanhas.${index}.permiteHistoricoCliente`}
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <Checkbox
                                    defaultChecked={
                                      campanha.permiteMenuMinhasVisitas
                                    }
                                    disabled={!campanha.ofertaId}
                                    value={`${campanha.permiteMenuMinhasVisitas}`}
                                    style={{ marginTop: '5px' }}
                                    onChange={handleChange}
                                    name={`campanhas.${index}.permiteMenuMinhasVisitas`}
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <Checkbox
                                    defaultChecked={
                                      campanha.permiteComodatoSemEnvioContrato
                                    }
                                    disabled={!campanha.ofertaId}
                                    value={`${campanha.permiteComodatoSemEnvioContrato}`}
                                    style={{ marginTop: '5px' }}
                                    onChange={handleChange}
                                    name={`campanhas.${index}.permiteComodatoSemEnvioContrato`}
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <Checkbox
                                    defaultChecked={campanha.permiteGravacao}
                                    disabled={!campanha.ofertaId}
                                    value={`${campanha.permiteGravacao}`}
                                    style={{ marginTop: '5px' }}
                                    onChange={handleChange}
                                    name={`campanhas.${index}.permiteGravacao`}
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD>
                                  <Checkbox
                                    defaultChecked={campanha.permiteListaSN}
                                    disabled={!campanha.ofertaId}
                                    value={`${campanha.permiteListaSN}`}
                                    style={{ marginTop: '5px' }}
                                    onChange={handleChange}
                                    name={`campanhas.${index}.permiteListaSN`}
                                  />
                                </StyledCustomTD>
                                <StyledCustomTD />
                              </StyledCustomTRContent>
                            ))}
                        </Box>
                        {!!campanhas?.length &&
                          values.search &&
                          !campanhas.some(
                            ({ subGrupo }) =>
                              subGrupo &&
                              subGrupo
                                .toLowerCase()
                                .includes(values.search.toLowerCase())
                          ) && (
                            <NotFoundItem message="Nenhuma campanha foi encontrada" />
                          )}
                        {loadingPage && <CustomSpinner />}
                        {errorOnLoadData && !loadingPage && (
                          <NetworkError
                            retry={getCampaigns}
                            back={() =>
                              router.push(MENU_ROUTES.CAMPAIGNS_MANAGEMENT)
                            }
                          />
                        )}
                        {hasError && (
                          <CustomInlineMessage
                            fontSize="12px"
                            marginTop="15px"
                            size="giga"
                            variant="danger"
                          >
                            Houve um problema ao salvar as Campanhas, tente
                            novamente.
                          </CustomInlineMessage>
                        )}

                        {!loadingPage && !errorOnLoadData && (
                          <StyledInlineButtons>
                            <CustomButton
                              id="campaigns-back-button"
                              type="button"
                              variant="secondary"
                              stretch
                              onClick={() =>
                                !equalsObject(campaigns, values)
                                  ? setOpenBackDialog(true)
                                  : handleCloseDialog()
                              }
                              style={{ width: 150 }}
                            >
                              Voltar
                            </CustomButton>
                            <CustomButton
                              id="campaigns-save-button"
                              type="submit"
                              onClick={() => handleSubmit(values)}
                              variant="primary"
                              stretch
                              isLoading={loading}
                              loadingLabel="Carregando..."
                              style={{ width: 150 }}
                            >
                              Salvar
                            </CustomButton>
                          </StyledInlineButtons>
                        )}
                      </>
                    );
                  }}
                />
              </Form>
            </>
          )}
        </Formik>
      </StyledBox>

      {openDialog && (
        <CustomDialog
          title="Cadastro realizado com sucesso"
          message="As alterações realizadas no cadastro de campanhas foram realizadas com sucesso."
          handleClose={() => handleCloseDialog()}
          primaryButton={{
            buttonTitle: 'Ok',
            onClick: () => handleCloseDialog()
          }}
        />
      )}

      {openBackDialog && (
        <CustomDialog
          title="Suas alterações não serão salvas"
          message="Ao clicar em Voltar, as alterações realizadas no cadastro de campanhas serão perdidas. Gostaria mesmo de voltar?"
          handleClose={() => setOpenBackDialog(false)}
          primaryButton={{
            buttonTitle: 'Continuar nesta página',
            onClick: () => setOpenBackDialog(false)
          }}
          secondaryButton={{
            buttonTitle: 'Voltar para página anterior',
            onClick: () => handleCloseDialog()
          }}
        />
      )}
    </>
  );
};

export default CampaignsPage;
