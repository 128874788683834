import { Container } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  InputField,
  SelectField,
  Spinner,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  Card,
  Toast
} from '../../components/_Molecules';
import { IToast } from '../../components/_Molecules/Toast';
import { NetworkError } from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import {
  ENUM_MACHINE,
  ENUM_TROCA_DEVOLUCAO,
  OPTIONS_EXCHANGE_AND_RETURN_MACHINE,
  OPTIONS_TYPE_MACHINE_ALLOCATE
} from '../../constants/machineAllocate.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';
import { IResquestInfoExchangeAndReturnDetails } from '../../interfaces/exchangeAndReturn';
import { IDataResponseModel } from '../../interfaces/requests.interfaces';
import { get, post } from '../../services/axios.service';
import { EspecialCharacters } from '../../utils/format.utils';
import { ReturnMachineForm } from '../../utils/yup-utils';
import { ContainerForm, TipoMaquina } from './style';

const ExchangeAndReturnFormEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [info, setInfo] = useState<IResquestInfoExchangeAndReturnDetails>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [options, setOptions] = useState<{ value: number; label: string }[]>(
    []
  );
  const { push } = useHistory();
  const [toast, setToastList] = useState<IToast[]>([]);

  const getDetails = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await get<
        IDataResponseModel<IResquestInfoExchangeAndReturnDetails>
      >(API.AQUISICAO_MAQUINA.DETALHES_TROCA_DEVOLUCAO_BY_ID(id));

      if (res.success) {
        setInfo(res.data);
        await getOptions(res.data.tipoSolicitacao);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const getOptions = async (type: number) => {
    const res = await get<
      IDataResponseModel<{ id: number; descricao: string }[]>
    >(
      type === ENUM_TROCA_DEVOLUCAO.Troca
        ? API.MOTIVOS.TROCA
        : API.MOTIVOS.DEVOLUCAO
    );
    if (res.success) {
      setOptions(
        res.data.map((item) => ({ value: item.id, label: item.descricao }))
      );
    }
  };

  const submitForm = async (values: any) => {
    setLoadingSubmit(true);

    try {
      const response = await post<IDataResponseModel<{ data: null }>>(
        API.AQUISICAO_MAQUINA.EDITAR_TROCA_DEVOLUCAO_BY_ID(id),
        {
          tipo: Number(values.tipoSolicitacao),
          merchantCode: values.merchantCode,
          motivoId: Number(values.motivoId),
          tipoMaquinaEntregue: Number(values.entregaTipoMaquina),
          tipoMaquinaRetirada: Number(values.retiradaTipoMaquina),
          numeroSerieMaquinaEntregue: values.entregaSerialNumber,
          numeroSeriePrinterEntregue: values.entregaSerialNumberPrinter,
          numeroSerieMaquinaRetirada: values.retiradaSerialNumber,
          numeroSeriePrinterRetirada: values.retiradaSerialNumberPrinter
        }
      );
      if (response.success) {
        setToastList([
          {
            id: 1,
            description: 'Solicitação editada com sucesso!',
            title: 'Sucesso!',
            type: 'sucess'
          }
        ]);

        setTimeout(() => {
          push(
            `${THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS}/${id}`
          );
        }, 2000);
      } else {
        setToastList([
          {
            id: 1,
            description: 'Ocorreu um erro ao editar a solicitação.',
            title: 'Erro',
            type: 'error'
          }
        ]);
      }
    } catch (error) {
      setLoadingSubmit(false);
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Container style={{ margin: 'auto', maxWidth: 1336 }}>
      <Breadcrumb
        currentRouteName="Editar informações"
        previousRoutes={[
          { routeName: 'Menu principal', routePath: ROUTES.MAIN_MENU },
          {
            routeName: 'Gestão de ativos',
            routePath: MENU_ROUTES.ASSET_MANAGEMENT
          },
          {
            routeName: 'Troca e Devolução',
            routePath: SUB_MENU_ROUTES.EXCHANGE_AND_RETURN
          },
          {
            routeName: 'Solicitações',
            routePath: THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS
          },
          {
            routeName: 'Detalhes solicitações Troca/Devolução',
            routePath: `${THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS}/${id}`
          }
        ]}
        style={{ maxWidth: 1046, marginBottom: 36 }}
        noMargin
      />

      <ContainerForm>
        <Title>
          Editar informações de{' '}
          {info?.tipoSolicitacao === 1 ? 'Troca' : 'Devolução'}
        </Title>

        {error && !loading && (
          <NetworkError
            image="pencil"
            retry={getDetails}
            subtitle="Aguarde alguns instantes e tente novamente."
          />
        )}

        {loading && (
          <div
            style={{ display: 'flex', justifyContent: 'center', height: 300 }}
          >
            <Spinner size="46px" color="#3388ff" width="5px" />
          </div>
        )}
        {info && options && !loading && !error && (
          <Formik
            initialValues={{
              tipoSolicitacao: info.tipoSolicitacao,
              merchantCode: info.merchantCode,
              motivoId: info.motivoId,
              retiradaTipoMaquina: info.tipoMaquinaRetirada,
              retiradaSerialNumber: info.numeroSerieMaquinaRetirada,
              retiradaTipoMaquinaPrinter: 1,
              retiradaSerialNumberPrinter: info.numeroSeriePrinterRetirada,
              entregaTipoMaquina: info.tipoMaquinaEntregue || '',
              entregaSerialNumber: info.numeroSerieMaquinaEntregue || '',
              entregaTipoMaquinaPrinter: 1,
              entregaSerialNumberPrinter: info.numeroSeriePrinterEntregue || ''
            }}
            validationSchema={ReturnMachineForm}
            onSubmit={(e) => console.log(e)}
            validateOnMount
            validateOnChange
            validateOnBlur
          >
            {({
              errors,
              handleBlur,
              handleChange,
              values,
              isValid,
              setFieldValue
            }) => (
              <Form>
                <Card.Root
                  style={{
                    border: '1px solid #D8DDE1',
                    maxWidth: 640,
                    margin: '24px auto'
                  }}
                >
                  <Card.Title
                    title={`Informações da ${
                      values.tipoSolicitacao === 1 ? 'Troca' : 'Devolução'
                    }`}
                  />
                  <InputField
                    style={{ width: '100%' }}
                    invalid={!!errors.merchantCode}
                    errorMessage={errors.merchantCode}
                    defaultValue={info.merchantCode}
                    name="merchantCode"
                    value={values.merchantCode}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      if (/^[a-zA-Z0-9-]*$/.test(e.target.value)) {
                        setFieldValue(
                          'merchantCode',
                          e.target.value.toLocaleUpperCase().trim()
                        );
                      }
                    }}
                    label="Merchant Code"
                    placeholder="Merchant Code"
                  />
                  <SelectField
                    style={{ width: '100%' }}
                    options={options}
                    defaultValue={info.motivoId}
                    value={values.motivoId}
                    placeholder="Selecione uma opção"
                    label="Motivo da Troca"
                    name="motivoId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Card.Root>

                <Card.Root
                  style={{
                    border: '1px solid #EA7A7A',
                    maxWidth: 640,
                    margin: '24px auto'
                  }}
                >
                  <Card.Title title="Informações máquina retirada do merchant" />
                  <Card.Row>
                    <TipoMaquina
                      options={OPTIONS_EXCHANGE_AND_RETURN_MACHINE}
                      defaultValue={info.tipoMaquinaRetirada}
                      name="retiradaTipoMaquina"
                      onBlur={handleBlur}
                      value={values.retiradaTipoMaquina}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('retiradaSerialNumberPrinter', '');
                        setFieldValue('retiradaSerialNumber', '');
                      }}
                      placeholder="Selecione um opção"
                      label="Tipo de máquina"
                      invalid={!!errors.retiradaTipoMaquina}
                      errorMessage={errors.retiradaTipoMaquina}
                    />
                    <InputField
                      defaultValue={info.numeroSerieMaquinaRetirada}
                      name="retiradaSerialNumber"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (!EspecialCharacters.test(e.target.value)) {
                          setFieldValue(
                            'retiradaSerialNumber',
                            e.target.value.toUpperCase().trim()
                          );
                        }
                      }}
                      value={values.retiradaSerialNumber}
                      label="Serial Number"
                      maxLength={
                        Number(values.retiradaTipoMaquina) ===
                        ENUM_MACHINE.Total
                          ? 8
                          : 17
                      }
                      invalid={!!errors.retiradaSerialNumber}
                      errorMessage={errors.retiradaSerialNumber}
                    />
                  </Card.Row>
                  {Number(values.retiradaTipoMaquina) === ENUM_MACHINE.Solo && (
                    <Card.Row>
                      <TipoMaquina
                        options={OPTIONS_TYPE_MACHINE_ALLOCATE}
                        placeholder="Selecione uma opção"
                        label="Tipo de máquina"
                        value={1}
                        disabled
                      />
                      <InputField
                        defaultValue={info.numeroSeriePrinterRetirada}
                        name="retiradaSerialNumberPrinter"
                        maxLength={17}
                        value={values.retiradaSerialNumberPrinter}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (!EspecialCharacters.test(e.target.value)) {
                            setFieldValue(
                              'retiradaSerialNumberPrinter',
                              e.target.value.toUpperCase().trim()
                            );
                          }
                        }}
                        invalid={!!errors.retiradaSerialNumberPrinter}
                        errorMessage={errors.retiradaSerialNumberPrinter}
                        label="Serial Number"
                      />
                    </Card.Row>
                  )}
                </Card.Root>

                {values.tipoSolicitacao === 1 && (
                  <Card.Root
                    style={{
                      border: '1px solid #8CC13F',
                      maxWidth: 640,
                      margin: '24px auto'
                    }}
                  >
                    <Card.Title title="Informações máquina entregue ao merchant" />
                    <Card.Row>
                      <TipoMaquina
                        options={OPTIONS_EXCHANGE_AND_RETURN_MACHINE}
                        placeholder="Selecione uma opção"
                        label="Tipo de máquina"
                        name="entregaTipoMaquina"
                        value={values.entregaTipoMaquina}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue('entregaSerialNumberPrinter', '');
                          setFieldValue('entregaSerialNumber', '');
                        }}
                        invalid={!!errors.entregaTipoMaquina}
                        errorMessage={errors.entregaTipoMaquina}
                        defaultValue={info.tipoMaquinaEntregue}
                      />
                      <InputField
                        maxLength={
                          Number(values.entregaTipoMaquina) ===
                          ENUM_MACHINE.Total
                            ? 8
                            : 17
                        }
                        defaultValue={info.numeroSerieMaquinaEntregue}
                        name="entregaSerialNumber"
                        value={values.entregaSerialNumber}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (!EspecialCharacters.test(e.target.value)) {
                            setFieldValue(
                              'entregaSerialNumber',
                              e.target.value.toUpperCase().trim()
                            );
                          }
                        }}
                        invalid={!!errors.entregaSerialNumber}
                        errorMessage={errors.entregaSerialNumber}
                        label="Serial Number"
                      />
                    </Card.Row>

                    {Number(values.entregaTipoMaquina) ===
                      ENUM_MACHINE.Solo && (
                      <Card.Row>
                        <TipoMaquina
                          options={OPTIONS_TYPE_MACHINE_ALLOCATE}
                          placeholder="Selecione um opção"
                          label="Tipo de máquina"
                          value={1}
                          disabled
                        />
                        <InputField
                          maxLength={17}
                          name="entregaSerialNumberPrinter"
                          onChange={(e) => {
                            if (!EspecialCharacters.test(e.target.value)) {
                              setFieldValue(
                                'entregaSerialNumberPrinter',
                                e.target.value.toUpperCase().trim()
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          label="Serial Number"
                          value={values.entregaSerialNumberPrinter}
                          defaultValue={info.numeroSeriePrinterEntregue}
                          invalid={!!errors.entregaSerialNumberPrinter}
                          errorMessage={errors.entregaSerialNumberPrinter}
                        />
                      </Card.Row>
                    )}
                  </Card.Root>
                )}

                <BottomFixedActions>
                  <Button
                    variant="secondary"
                    value="Voltar"
                    width="medium"
                    onClick={() =>
                      push(
                        `${THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS}/${id}`
                      )
                    }
                  />

                  <Button
                    disabled={!isValid}
                    onClick={() => submitForm(values)}
                    loading={loadingSubmit}
                    variant="primary"
                    width="medium"
                    value="Salvar"
                  />
                </BottomFixedActions>
              </Form>
            )}
          </Formik>
        )}

        <Toast toastlist={toast} setList={setToastList} position="top-right" />
      </ContainerForm>
    </Container>
  );
};

export default ExchangeAndReturnFormEdit;
