export const OPTIONS_MACHINE_ALLOCATE_STATUS = [
  {
    label: 'Todos',
    value: '0'
  },
  {
    label: 'Atribuidas',
    value: '1'
  },
  {
    label: 'Não atribuidas',
    value: '2'
  }
];

export const OPTIONS_TYPE_MACHINE_ALLOCATE = [
  {
    label: 'Printer',
    value: 1
  },
  {
    label: 'Total',
    value: 2
  },
  {
    label: 'Solo',
    value: 3
  }
];

export const OPTIONS_EXCHANGE_AND_RETURN_MACHINE = [
  {
    label: 'Total',
    value: 1
  },
  {
    label: 'Solo',
    value: 2
  }
];

export enum ENUM_MACHINE {
  Total = 1,
  Solo = 2,
  POS = 3
}

export enum ENUM_TROCA_DEVOLUCAO {
  Troca = 1,
  Devolução = 2
}
