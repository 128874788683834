/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Container } from '@material-ui/core';
import { Toggle } from '@sumup/circuit-ui';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CountItensPerPage,
  CustomPagination,
  EmptyValue,
  RedirectMessage,
  SimpleLink,
  Spinner,
  SubTitle,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  Modal,
  NoResultsState,
  RangeDateModal
} from '../../components/_Molecules';
import Toast, { IToast } from '../../components/_Molecules/Toast';
import { NetworkError } from '../../components/_Organisms';
import { CustomDate } from '../../components/_Organisms/Kanban-filters/selectDateRange/style';
import { API } from '../../constants/api.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES
} from '../../constants/routes.constants';
import {
  IResponseExtractReports,
  IResults
} from '../../interfaces/extracReports.interfaces';
import {
  IDataResponseModel,
  IRequestPaginatedQueryParams
} from '../../interfaces/requests.interfaces';
import { get } from '../../services/axios.service';
import { buildQueryParams } from '../../services/filters.service';
import {
  ContentModalImage,
  ExtractReportFiltersContainer,
  ExtractReportRowItem,
  ExtractReportTableHeader,
  PaginatorContainer,
  SpinnerContainer
} from './style';

const ExtractReports: React.FC = () => {
  const router = useHistory();
  const itemsPerPage = 6;

  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    allTime: true
  });
  const [dataTable, setDataTable] = useState<IResults[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingToResetPaginator, setLoadingToResetPaginator] = useState(false);
  const [error, setError] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [urlImage, setUrlImage] = useState('');
  const [loadingImage, setLoadingImage] = useState(true);
  const [showMessageToRedirect, setShowMessageToRedirect] = useState(false);
  const [toast, setToastList] = useState<IToast[]>([]);

  const handleQueryParams = (pageSize: number): string => {
    const params: IRequestPaginatedQueryParams = {
      PageSize: String(itemsPerPage),
      PageNumber: String(pageSize),
      ...(!filters.allTime &&
        (filters.startDate || filters.endDate) && {
          DataInicial: filters.startDate,
          DataFinal: filters.endDate
        })
    };

    return buildQueryParams(params);
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page + 1);
    getDataTable(page + 1);
  };

  const getDataTable = async (pag: number) => {
    setLoading(true);
    setError(false);
    try {
      const { data }: IDataResponseModel<IResponseExtractReports> = await get(
        `${API.COMODATO.GERAR_RELATORIO}${handleQueryParams(pag)}`
      );
      setDataTable(data.results);
      setTotalItems(data.totalItems);
      setCurrentPage(pag);

      if (data.error) {
        setError(true);
      }
    } catch {
      setDataTable([]);
      setError(true);
    }
    setLoading(false);
  };

  const getImage = async (id: string) => {
    setLoadingImage(true);
    setOpenModal(true);
    try {
      const response: { data: string } = await get(
        API.COMODATO.DOWNLOAD_IMAGEM(id)
      );

      setUrlImage(response.data);
    } catch (error) {
      setUrlImage('');
    }
    setLoadingImage(false);
  };

  useEffect(() => {
    const loadData = async () => {
      if (filters.allTime || filters.startDate || filters.endDate) {
        setLoadingToResetPaginator(true);
        await getDataTable(1);
        setLoadingToResetPaginator(false);
      }
    };

    loadData();
  }, [filters]);

  return (
    <Container style={{ paddingBottom: 150 }}>
      <Breadcrumb
        style={{ margin: 0, padding: 0 }}
        currentRouteName="Extração de relatório"
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          },
          {
            routeName: 'Gestão de propostas',
            routePath: MENU_ROUTES.PROPOSALS_MANAGEMENT
          }
        ]}
      />
      <Title style={{ margin: '32px 0px 8px 0px' }} fontSize="big">
        Extração de relatório
      </Title>
      <SubTitle>
        Selecione um usuário do sistema para consultar e visualizar todos os
        Serial Numbers de máquinas atribuídas disponíveis para venda.
      </SubTitle>

      <ExtractReportFiltersContainer>
        <CustomDate
          onChange={(e) =>
            setFilters({ ...filters, startDate: e.target.value })
          }
          disabled={filters.allTime}
          value={filters.startDate}
          label="Data inicial"
        />
        <CustomDate
          onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
          value={filters.endDate}
          disabled={filters.allTime}
          label="Data final"
        />

        <div style={{ marginTop: 24 }}>
          <Toggle
            label="Exibir todo o período"
            defaultChecked={false}
            checked={filters.allTime}
            onChange={() => {
              const SetValue = () => {
                let newFilter = filters;

                if (!filters.allTime) {
                  newFilter = { ...filters, startDate: '', endDate: '' };
                }

                setFilters({ ...newFilter, allTime: !filters.allTime });
              };

              SetValue();
            }}
            checkedLabel=" "
            uncheckedLabel=" "
          />
        </div>
      </ExtractReportFiltersContainer>

      {showMessageToRedirect && (
        <RedirectMessage
          style={{ marginBottom: 24 }}
          message="Arquivo enviado para o processamento, faça o download do arquivo na página de Histórico de arquivos."
          pageInfo="Ir para histórico de arquivos"
          route={SUB_MENU_ROUTES.HISTORY_FILES}
        />
      )}

      <ExtractReportTableHeader>
        <p>
          Data da <br /> proposta{' '}
        </p>
        <p>
          Merhant <br /> Code
        </p>
        <p>
          ID da imagem <br /> da assinatura
        </p>
        <p>
          ID da imagem <br /> do documento
        </p>
      </ExtractReportTableHeader>

      {!error &&
        (loading ? (
          <SpinnerContainer>
            <Spinner size="46px" color="#3388ff" width="5px" />
          </SpinnerContainer>
        ) : (
          dataTable &&
          dataTable &&
          dataTable.length > 0 &&
          dataTable.map((item) => (
            <ExtractReportRowItem>
              <p>{item.dataCriacao}</p>
              <p>{item.merchantCode}</p>
              {item.nomeArquivoAssinatura ? (
                <p onClick={() => getImage(item.nomeArquivoAssinatura)}>
                  <SimpleLink text={item.nomeArquivoAssinatura} />
                </p>
              ) : (
                <EmptyValue value="(Não encontrado)" />
              )}
              {item.nomeArquivoDocumento ? (
                <p onClick={() => getImage(item.nomeArquivoDocumento)}>
                  <SimpleLink text={item.nomeArquivoDocumento} />
                </p>
              ) : (
                <EmptyValue value="(Não encontrado)" />
              )}
            </ExtractReportRowItem>
          ))
        ))}

      {error && (
        <NetworkError
          title="Ops... Parece que algo deu errado"
          subtitle="Houve um erro no nosso sistema e não pudemos exibir as informações."
          secondSubtitle="Tente novamente mais tarde."
        />
      )}

      {!error && !loadingToResetPaginator && totalItems > 6 && (
        <PaginatorContainer>
          <CountItensPerPage
            currentPage={currentPage}
            itensPerPage={itemsPerPage}
            totalItens={totalItems}
          />
          <CustomPagination
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            totalItems={totalItems}
            initialPage={currentPage}
          />
        </PaginatorContainer>
      )}

      {!error && totalItems === 0 && !loading && (
        <NoResultsState
          subtitle="Não encontramos resultados com os parâmetros utilizados."
          subtitle2="Tente novamente utilizando outros parâmetros de busca"
        />
      )}

      <Modal
        showFooter={false}
        open={openModal}
        setOpen={setOpenModal}
        title="Visualizar imagem"
        widthAuto
      >
        <ContentModalImage>
          {!loadingImage ? (
            <img
              style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
              src={urlImage}
              alt=""
            />
          ) : (
            <Spinner size="46px" color="#3388ff" width="5px" />
          )}
        </ContentModalImage>
      </Modal>

      <BottomFixedActions style={{ zIndex: 1 }}>
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          value="Voltar"
          variant="secondary"
          onClick={() => router.push(MENU_ROUTES.PROPOSALS_MANAGEMENT)}
        />
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          disabled={
            (!filters.allTime && !filters.startDate && !filters.endDate) ||
            loading ||
            error ||
            !dataTable
          }
          value="Exportar"
          onClick={() => setOpenExportModal(true)}
        />
      </BottomFixedActions>

      <Toast position="top-right" toastlist={toast} setList={setToastList} />

      <RangeDateModal
        setToastList={setToastList}
        maxDays={7}
        open={openExportModal}
        urlToExport={API.ARQUIVO.DOWNLOAD_IMAGEM}
        setOpen={setOpenExportModal}
        title="Exportar relatório"
        subtitle="Selecione o período para exportação do relatório"
        action={setShowMessageToRedirect}
      />
    </Container>
  );
};

export default ExtractReports;
