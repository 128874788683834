import React from 'react';

import { Body, Checkbox, Headline, useModal } from '@sumup/circuit-ui';
import { CustomStatus, CustomTooltip, InputField } from '../../_Atoms';

import { getAssetsPropostaByEnum } from '../../../constants/alcada.constants';
import {
  TIPO_TAXA_BY_ENUM,
  TIPO_TRANSICAO_BY_ENUM
} from '../../../constants/plans.constants';
import { USER_TYPE_BY_ENUM } from '../../../constants/user.constants';

import {
  formatCpfCnpj,
  formatCreationDate,
  formatNumberToBrlCurrency,
  formatPhone
} from '../../../utils/format.utils';

import { OPCOES_TIPO_TRANSACAO } from '../../../constants/offers.constants';
import { IDetalhamentoProposta } from '../../../interfaces/requests.interfaces';
import ParcelasDetalhadasDialog from '../../ParcelasDetalhadasDialog';
import { StyledFixTitle, StyledSingleTitleBar } from '../AddPlansTable/styles';
import {
  NotFoundItem,
  StyledBox,
  StyledBoxTitleRow,
  StyledButton,
  StyledCommentBox,
  StyledRow,
  StyledRowItem,
  StyledScrollBar,
  StyledTitleItem
} from './styles';

interface IApproveAlcadaTable {
  proposta: IDetalhamentoProposta;
}
const ApproveAlcadaPropostaTable: React.FC<IApproveAlcadaTable> = ({
  proposta
}) => {
  const { setModal, removeModal } = useModal();
  const {
    cliente,
    dataCriacao,
    oferta,
    plano,
    promotor,
    comentarioSolicitacaoAprovacao,
    id,
    status,
    tipoTaxa
  } = proposta;

  const assetsPropostas = getAssetsPropostaByEnum[status];

  const openModal = (index: number): void => {
    setModal({
      children: (
        <ParcelasDetalhadasDialog
          removeModal={removeModal}
          plano={plano}
          index={index}
        />
      ),
      variant: 'immersive',
      preventClose: true
    });
  };

  const getCommentText = (): string => {
    return comentarioSolicitacaoAprovacao?.trim()
      ? comentarioSolicitacaoAprovacao
      : 'Essa proposta não possui comentários.';
  };

  return (
    <>
      <StyledBox>
        <Headline as="h2">Proposta</Headline>
        <StyledScrollBar>
          <StyledBoxTitleRow gridColumns="20% 60% 20%">
            <StyledTitleItem>Status</StyledTitleItem>
            <StyledTitleItem>Data da criação</StyledTitleItem>
            <StyledTitleItem>ID</StyledTitleItem>
          </StyledBoxTitleRow>
          <StyledRow gridColumns="5% 15% 60% 20%">
            <CustomStatus status={status} />
            <StyledRowItem textAlign="left">
              <Body
                noMargin
                as="span"
                style={{
                  color: assetsPropostas.color,
                  paddingLeft: '30px'
                }}
              >
                {assetsPropostas.name}
              </Body>
            </StyledRowItem>
            <StyledRowItem>
              {formatCreationDate(dataCriacao)} <br />
              <span>UTC - 03:00 (Horário de Brasília)</span>
            </StyledRowItem>
            <StyledRowItem>{id}</StyledRowItem>
          </StyledRow>
          <StyledCommentBox>
            <StyledBoxTitleRow gridColumns="100%">
              <StyledTitleItem>Comentários</StyledTitleItem>
            </StyledBoxTitleRow>
            <StyledRow gridColumns="100%">
              <StyledRowItem isDeactive={!comentarioSolicitacaoAprovacao}>
                {getCommentText()}
              </StyledRowItem>
            </StyledRow>
          </StyledCommentBox>
        </StyledScrollBar>
      </StyledBox>
      <StyledBox>
        <Headline as="h2">Promotor de Vendas</Headline>
        <StyledScrollBar>
          <StyledBoxTitleRow gridColumns="20% 20% 20% 20% 20%">
            <StyledTitleItem>Nome</StyledTitleItem>
            <StyledTitleItem>Empresa</StyledTitleItem>
            <StyledTitleItem>E-mail</StyledTitleItem>
            <StyledTitleItem>Tipo de usuário</StyledTitleItem>
            <StyledTitleItem>Subgrupo</StyledTitleItem>
          </StyledBoxTitleRow>
          <StyledRow gridColumns="20% 20% 20% 20% 20%">
            <CustomTooltip title={promotor.nome}>
              <StyledRowItem>{promotor.nome}</StyledRowItem>
            </CustomTooltip>

            <CustomTooltip title={promotor.empresa}>
              <StyledRowItem>{promotor.empresa}</StyledRowItem>
            </CustomTooltip>

            <CustomTooltip title={promotor.email}>
              <StyledRowItem>{promotor.email}</StyledRowItem>
            </CustomTooltip>

            <StyledRowItem>
              {USER_TYPE_BY_ENUM[promotor.tipoDeUsuario]}
            </StyledRowItem>

            <CustomTooltip title={promotor.subGrupo}>
              <StyledRowItem>{promotor.subGrupo}</StyledRowItem>
            </CustomTooltip>
          </StyledRow>
        </StyledScrollBar>
      </StyledBox>
      <StyledBox>
        <Headline as="h2">Cliente</Headline>
        <StyledScrollBar style={{ marginBottom: 64 }}>
          <StyledBoxTitleRow gridColumns="25% 25% 25% 25%">
            <StyledTitleItem>CPF / CNPJ</StyledTitleItem>
            <StyledTitleItem>
              Razão social ou <br /> nome completo
            </StyledTitleItem>
            <StyledTitleItem>Telefone</StyledTitleItem>
            <StyledTitleItem>E-mail</StyledTitleItem>
          </StyledBoxTitleRow>
          <StyledRow gridColumns="25% 25% 25% 25%">
            <StyledRowItem>{formatCpfCnpj(cliente.documento)}</StyledRowItem>

            <CustomTooltip title={cliente.nome}>
              <StyledRowItem>{cliente.nome}</StyledRowItem>
            </CustomTooltip>

            <StyledRowItem>{formatPhone(cliente.telefone)}</StyledRowItem>

            <StyledRowItem>
              {cliente.email != null ? (
                cliente.email
              ) : (
                <NotFoundItem>(Não encontrado)</NotFoundItem>
              )}
            </StyledRowItem>
          </StyledRow>
        </StyledScrollBar>
        <StyledScrollBar>
          <StyledBoxTitleRow gridColumns="25% 12.5% 25% 12.5% 25%">
            <StyledTitleItem>
              Faturamento <br /> mensal
            </StyledTitleItem>
            <StyledTitleItem />
            <StyledTitleItem>
              Ramo de atividade <br /> MCC
            </StyledTitleItem>
            <StyledTitleItem />
            <StyledTitleItem>
              Perfil mais comum <br /> de transação
            </StyledTitleItem>
          </StyledBoxTitleRow>
          <StyledRow gridColumns="25% 12.5% 25% 12.5% 25%">
            <StyledRowItem>
              {formatNumberToBrlCurrency(cliente.faturamentoMensal)}
            </StyledRowItem>
            <StyledTitleItem />

            <CustomTooltip title={cliente.mcc}>
              <StyledRowItem>{cliente.mcc}</StyledRowItem>
            </CustomTooltip>
            <StyledTitleItem />
            <StyledRowItem>
              {OPCOES_TIPO_TRANSACAO[cliente.perfilTransacao]}
            </StyledRowItem>
          </StyledRow>
        </StyledScrollBar>
      </StyledBox>
      <StyledBox>
        <Headline as="h2">Oferta</Headline>
        <StyledScrollBar>
          <StyledBoxTitleRow gridColumns="2% 15% 63% 20%">
            <StyledTitleItem />
            <StyledTitleItem>Nome</StyledTitleItem>
            <StyledTitleItem>Nível</StyledTitleItem>
            <StyledTitleItem>Alçada</StyledTitleItem>
          </StyledBoxTitleRow>

          <StyledRow gridColumns="2% 15% 63% 20%">
            <StyledRowItem />
            <StyledRowItem>{oferta.nome}</StyledRowItem>
            <StyledRowItem>{plano.nivel}</StyledRowItem>
            <StyledRowItem id="alcada-checkbox">
              <Checkbox defaultChecked={plano.alcada} readOnly disabled />
            </StyledRowItem>
          </StyledRow>
        </StyledScrollBar>
      </StyledBox>
      <StyledBox>
        <Headline noMargin as="h2">
          Plano de taxas
        </Headline>
        <Body as="p" style={{ marginBottom: 0 }}>
          Nome do plano: &nbsp;
          <Body as="b">{plano.nome}</Body>
        </Body>
        <Body as="p">
          Prazo de recebimento escolhido: &nbsp;
          <Body as="b">{TIPO_TAXA_BY_ENUM[tipoTaxa]}</Body>
        </Body>
        <StyledScrollBar>
          {!!plano.taxas.length &&
            plano.taxas.map((taxa, index) => (
              <React.Fragment key={index}>
                {index === 0 && (
                  <>
                    <StyledSingleTitleBar fontSize="14px">
                      {TIPO_TAXA_BY_ENUM[taxa.tipoTaxa]}
                    </StyledSingleTitleBar>
                    <StyledRow gridColumns="25% 50% 25%">
                      <StyledRowItem style={{ fontWeight: 'bold' }}>
                        Tipo de transação
                      </StyledRowItem>
                      <StyledRowItem style={{ fontWeight: 'bold' }}>
                        Visa/Master
                      </StyledRowItem>
                      <StyledRowItem style={{ fontWeight: 'bold' }}>
                        Outras bandeiras
                      </StyledRowItem>
                    </StyledRow>
                  </>
                )}
                {index === 3 && (
                  <StyledSingleTitleBar fontSize="14px">
                    {TIPO_TAXA_BY_ENUM[taxa.tipoTaxa]}
                  </StyledSingleTitleBar>
                )}
                <StyledRow gridColumns="25% 50% 25%">
                  <StyledRowItem>
                    <StyledFixTitle>
                      {TIPO_TRANSICAO_BY_ENUM[taxa.tipoTransacao]}
                    </StyledFixTitle>
                  </StyledRowItem>
                  <StyledRowItem>
                    <InputField
                      defaultValue={plano.taxas[index].taxaVisaMaster}
                      label="Porcentagem"
                      isTableComponent
                      readOnly
                      hideLabel
                      name={`taxas.${index}.taxaVisaMaster`}
                    />
                  </StyledRowItem>
                  <StyledRowItem>
                    <InputField
                      defaultValue={plano.taxas[index].taxaOutrasBandeiras}
                      label="Porcentagem"
                      isTableComponent
                      readOnly
                      hideLabel
                      name={`taxas.${index}.taxaOutrasBandeiras`}
                    />
                  </StyledRowItem>
                </StyledRow>
                {(index === 2 || index === 5) && plano.taxas[index].parcelas && (
                  <StyledRow gridColumns="100%" style={{ padding: '3em' }}>
                    <StyledRowItem style={{ marginTop: '-2em' }}>
                      <StyledButton
                        variant="secondary"
                        type="button"
                        size="giga"
                        stretch
                        onClick={() => openModal(index)}
                        style={{ width: '30%' }}
                      >
                        Detalhamento de parcelas
                      </StyledButton>
                    </StyledRowItem>
                  </StyledRow>
                )}
              </React.Fragment>
            ))}
        </StyledScrollBar>
        <StyledScrollBar>
          <StyledBoxTitleRow id="condicoes-taxas" gridColumns="25% 50% 25%">
            <StyledTitleItem>Valor da máquininha</StyledTitleItem>
            <StyledTitleItem>Remuneração do promotor</StyledTitleItem>
            <StyledTitleItem>Condições</StyledTitleItem>
          </StyledBoxTitleRow>
          <StyledRow gridColumns="25% 50% 25%">
            <StyledRowItem>{plano.valorMaquina}</StyledRowItem>
            <StyledRowItem>{plano.remuneracao}</StyledRowItem>
            <StyledRowItem>{plano.condicoes}</StyledRowItem>
          </StyledRow>
        </StyledScrollBar>
      </StyledBox>
    </>
  );
};

export default ApproveAlcadaPropostaTable;
