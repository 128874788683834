import { Checkbox, Spinner } from '@sumup/circuit-ui';
import { FC } from 'react';
import { IUser } from '../../../interfaces/user-management.interfaces';
import { NotFoundItem } from '../../_Molecules';
import { Items, StyleGrid } from '../styles';

interface IProps {
  users: IUser[];
  loadingGetUsers: boolean;
  error: boolean;
  usersChecked: IUser[];
  handleCheckbox: (user: IUser, checked: boolean) => void;
}

const tiposDePerfil: string[] = ['', 'Administrador', 'Promotor', 'Lider'];

const GridUsers: FC<IProps> = ({
  users,
  loadingGetUsers,
  error,
  usersChecked,
  handleCheckbox
}) => {
  return (
    <StyleGrid>
      {users.map((element) => (
        <Items>
          <div
            style={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Checkbox
              onChange={(el: { target: { checked: boolean } }) =>
                handleCheckbox(element, el.target.checked)
              }
              checked={usersChecked.some((u): boolean => u.id === element.id)}
              style={{
                verticalAlign: 'middle',
                marginTop: -15,
                padding: 0
              }}
            />
          </div>
          <p>{tiposDePerfil[element.tipoDeUsuario]}</p>
          <p>{element.nome}</p>
          <p>{element.empresa}</p>
          <p>{element.subGrupo ? element.subGrupo : '(Sem grupo)'}</p>
        </Items>
      ))}

      {users.length === 0 && !loadingGetUsers && (
        <NotFoundItem message="Nenhum resultado encontrado" />
      )}

      {(loadingGetUsers || error) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 24
          }}
        >
          {error ? (
            <p>
              A lista de destinatários não foi carregada. Por favor, tente
              novamente.
            </p>
          ) : (
            <Spinner size="giga" />
          )}
        </div>
      )}
    </StyleGrid>
  );
};

export default GridUsers;
