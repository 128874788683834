import styled from '@emotion/styled';
import { SelectField } from '../../components/_Atoms';

export const ContainerForm = styled.div`
  max-width: 620px;
  padding-bottom: 150px;
  margin: auto;
  input {
    height: 40px;
    width: 100%;
  }
  select {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const TipoMaquina = styled(SelectField)`
  width: 50%;
  margin-right: 20px;
  z-index: 1;
`;

export const ConfirmationModalForm = styled.div`
  padding: 16px 24px;
  font-size: 13px;
`;

export const ConfirmationModalMachineContainer = styled.div`
  display: grid;
  grid-template-columns: 128px 200px 128px 200px;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0;
  font-size: 13px;
`;

export const ConfirmationModalField = styled.div`
  display: grid;
  grid-template-columns: 128px auto;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0;
  font-size: 13px;
`;

export const ConfirmationLabel = styled.label`
  font-weight: bold;
`;
