import styled from '@emotion/styled';
import { Button } from '@sumup/circuit-ui';

export const StyledBackButton = styled(Button)`
  width: 231px;
`;

export const StyledBackgroundFooter = styled.footer`
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
`;
