import styled from '@emotion/styled';

export const StyledBoxFormTip = styled.div`
  width: 100%;
  background-color: #eaeffd;
  border-radius: 8px;
  padding-left: 41px;
  color: ${(props) => props.theme.colors.p500};
  display: flex;
  align-items: center;
  height: 43px;
  margin: 20px 0;
  p {
    font-size: 12px;
  }
  svg {
    margin-right: 14px;
  }
  b {
    font-size: 12px;
    font-weight: 700;
  }
`;
