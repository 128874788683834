import { SelectOption } from '@sumup/circuit-ui';
import { ENUM_TIPO_USUARIO } from '../enums/user.enums';

export const USER_TYPE_OPTIONS = [
  {
    label: 'Promotor de Vendas',
    value: ENUM_TIPO_USUARIO.REPRESENTANTE_VENDAS
  },
  {
    label: 'Administrador',
    value: ENUM_TIPO_USUARIO.ADMINISTRADOR
  },
  {
    label: 'Liderança de Vendas',
    value: ENUM_TIPO_USUARIO.LIDERANCA_VENDAS
  }
];

export const USER_TYPE_OPTIONS_LEADER = [
  {
    label: 'Promotor de Vendas',
    value: ENUM_TIPO_USUARIO.REPRESENTANTE_VENDAS
  },
  {
    label: 'Liderança de Vendas',
    value: ENUM_TIPO_USUARIO.LIDERANCA_VENDAS
  }
];

export const USER_TYPE_FILTER_OPTIONS = [
  {
    label: 'Todos os tipos',
    value: 'todos'
  },
  {
    label: 'Administrador',
    value: ENUM_TIPO_USUARIO.ADMINISTRADOR
  },
  {
    label: 'Liderança de Vendas',
    value: ENUM_TIPO_USUARIO.LIDERANCA_VENDAS
  },
  {
    label: 'Promotor de Vendas',
    value: ENUM_TIPO_USUARIO.REPRESENTANTE_VENDAS
  }
];

export const USER_TYPE_DEFAULT_VALUE = 2;

export const USER_TYPE_BY_ENUM = {
  '1': 'Administrador',
  '2': 'Promotor de Vendas',
  '3': 'Liderança de Vendas'
};

export const USER_STATUS_FILTER_OPTIONS: SelectOption[] = [
  { value: 'todos', label: 'Todos os usuários' },
  { value: 'ativos', label: 'Usuários ativos' },
  { value: 'inativos', label: 'Usuários inativados' }
];

export const USER_STATUS_FILTER_DEFAULT_VALUE = 'ativos';
