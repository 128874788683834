import { makeStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { FC } from 'react';

const useStyles = makeStyles(() => ({
  arrow: {
    color: '#323E49'
  },
  tooltip: {
    backgroundColor: '#323E49',
    fontSize: 13,
    fontFamily:
      'aktiv-grotesk,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  }
}));

const CustomTooltip: FC<TooltipProps> = (props: TooltipProps) => {
  const classes = useStyles();
  return <Tooltip arrow classes={classes} {...props} />;
};

export default CustomTooltip;
