import { ElementType, FC } from 'react';

interface IProps {
  icon: ElementType;
}

const CardIcon: FC<IProps> = ({ icon: Icon }) => {
  return <Icon />;
};

export default CardIcon;
