import styled from '@emotion/styled';
import { Checkbox, Spinner } from '@sumup/circuit-ui';
import { FC } from 'react';
import { IAddUser } from '../../../interfaces/addUser.interface';
import { NotFoundItem } from '../../_Molecules';
import { Items, StyleGrid } from '../styles';

interface IProps {
  users: IAddUser[];
  loadingGetUsers: boolean;
  error: boolean;
  usersChecked: IAddUser[];
  handleCheckbox: (user: IAddUser, checked: boolean) => void;
  preSelectbyIdLeader: number | undefined;
}

const CustomItems = styled(Items)<{ opacity: boolean }>`
  grid-template-columns: 10% repeat(5, 18%);
  text-align: center;
  opacity: ${({ opacity }) => (opacity ? '30%' : '100%')};
`;

const CustomStyledGrid = styled(StyleGrid)`
  max-height: 600px;
`;

const tiposDePerfil: string[] = ['', 'Administrador', 'Promotor', 'Lider'];

const GridAddUsers: FC<IProps> = ({
  users,
  loadingGetUsers,
  error,
  usersChecked,
  handleCheckbox,
  preSelectbyIdLeader
}) => {
  return (
    <CustomStyledGrid>
      {users.map((element) => (
        <CustomItems
          opacity={
            !!element.nomeLider && element.liderId !== preSelectbyIdLeader
          }
        >
          <div
            style={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {element.nomeLider && element.liderId !== preSelectbyIdLeader ? (
              <Checkbox
                style={{
                  verticalAlign: 'middle',
                  marginTop: -15,
                  padding: 0
                }}
                checked={false}
              />
            ) : (
              <Checkbox
                onChange={(el: { target: { checked: boolean } }) =>
                  handleCheckbox(element, el.target.checked)
                }
                checked={
                  !element.nomeLider ||
                  usersChecked.some((item) => item === element)
                    ? usersChecked.some((u): boolean => u.id === element.id)
                    : false
                }
                style={{
                  verticalAlign: 'middle',
                  marginTop: -15,
                  padding: 0
                }}
              />
            )}
          </div>
          <p>{tiposDePerfil[element.tipoDeUsuario]}</p>
          <p>{element.nome}</p>
          <p>{element.empresa}</p>
          <p>{element.subGrupo ? element.subGrupo : '(Sem grupo)'}</p>
          <p>{element.nomeLider ? element.nomeLider : '(Sem liderança)'}</p>
        </CustomItems>
      ))}

      {users.length === 0 && !loadingGetUsers && (
        <NotFoundItem message="Nenhum resultado encontrado" />
      )}

      {(loadingGetUsers || error) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 24
          }}
        >
          {error ? (
            <p>
              A lista de usuários não foi carregada. Por favor, tente novamente.
            </p>
          ) : (
            <Spinner size="giga" />
          )}
        </div>
      )}
    </CustomStyledGrid>
  );
};

export default GridAddUsers;
