import { FC } from 'react';

const SVGInvalida: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.85742 15C1.12017 15 0.795171 14.4531 1.13458 13.7849L7.38201 1.50118C7.72182 0.83294 8.27776 0.83294 8.61757 1.50118L14.865 13.7853C15.2052 14.4531 14.8798 15 14.1426 15H1.85742Z"
        fill="#F6CC1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5C7.44772 5 7 5.44772 7 6V9C7 9.55228 7.44772 10 8 10C8.55228 10 9 9.55228 9 9V6C9 5.44772 8.55228 5 8 5ZM8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
        fill="#212933"
      />
    </svg>
  );
};

export default SVGInvalida;
