import { AccordionSummary, Container } from '@material-ui/core';
import { CurrencyInput } from '@sumup/circuit-ui';
import { Delete } from '@sumup/icons';
import { FieldArray, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  InputLabel,
  SubTitle,
  TextIconPlus,
  Title,
  ValidationMessageError
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  CustomDialog
} from '../../components/_Molecules';
import { API } from '../../constants/api.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';
import {
  IMaquinas,
  IPlanoMaquinas,
  IRequestPlanoMaquinas,
  IResponsePost
} from '../../interfaces/addMachines.interfaces';
import { post, put } from '../../services/axios.service';
import { equalsObject, formatCurrencyToNumber } from '../../utils/format.utils';
import { addMachinePricesValidation } from '../../utils/yup-utils';
import {
  ContentAccordion,
  ContentCellCurrency,
  ContentCellProductName,
  CustomAccordion,
  MachineNameStyle,
  Row
} from './styles';

interface IProps {
  id?: string;
  initialValuesToEdit?: {
    nomeGrupoMaquina: string;
    maquinas: {
      id: number;
      nomeMaquina: string;
      valorOriginal: string;
      valorVenda: string;
    }[];
  };
}

const AddMachinePrice: FC<IProps> = ({ id, initialValuesToEdit }) => {
  const router = useHistory();
  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [errorNameDuplicated, setErrorNameDuplicated] = useState('');
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues: IPlanoMaquinas = {
    nomeGrupoMaquina: initialValuesToEdit?.nomeGrupoMaquina || '',
    maquinas: initialValuesToEdit?.maquinas || [
      {
        id: 0,
        nomeMaquina: '',
        valorOriginal: '',
        valorVenda: ''
      }
    ]
  };

  const handleSubmit = async (e: IPlanoMaquinas) => {
    setErrorNameDuplicated('');
    setLoading(true);

    const requestData: IRequestPlanoMaquinas = {
      nomeGrupoMaquina: e.nomeGrupoMaquina.trim(),
      maquinas: e.maquinas.map((item: IMaquinas) => ({
        id: item.id,
        nomeMaquina: item.nomeMaquina.trim(),
        valorOriginal: formatCurrencyToNumber(item.valorOriginal),
        valorVenda: formatCurrencyToNumber(item.valorVenda)
      }))
    };

    try {
      const res = !id
        ? await post<IResponsePost>(API.MAQUINAS.CRIAR, requestData)
        : await put<IResponsePost>(API.MAQUINAS.EDITAR(id), requestData);

      if (res.error === 'grupo_maquina_existente') {
        setErrorNameDuplicated('Nome de preço de máquina já existente.');
      }

      if (res.success) {
        setOpenSuccessDialog(true);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleCloseDialog = () => {
    router.push(
      id
        ? THIRD_LEVEL_MENU_ROUTES.EDIT_MACHINE_PRICES
        : SUB_MENU_ROUTES.MACHINES_PRICES
    );
  };

  return (
    <Container style={{ maxWidth: 1332, marginBottom: 160 }}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          setOpenConfirmDialog(true);
        }}
        validateOnChange
        validateOnBlur
        validateOnMount
        validationSchema={addMachinePricesValidation}
      >
        {({ values, errors, touched, setFieldTouched, isValid }) => (
          <>
            <Breadcrumb
              confirmationModal={!equalsObject(values, initialValues)}
              modalText={{
                title: 'Voltar para submenu',
                message:
                  'As informações preenchidas não foram salvas. Ao voltar, todas as informações serão descartadas. Deseja descartar e retornar ao submenu?'
              }}
              style={{ margin: 0, padding: 0 }}
              currentRouteName={id ? 'Editar preço' : 'Novo preço'}
              previousRoutes={[
                {
                  routeName: 'Menu principal',
                  routePath: ROUTES.MAIN_MENU
                },
                {
                  routeName: 'Gestão de campanhas',
                  routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
                },
                {
                  routeName: 'Preços de máquinas',
                  routePath: SUB_MENU_ROUTES.MACHINES_PRICES
                }
              ]}
            />
            <Title style={{ margin: '32px 0 8px 0' }} fontSize="big">
              {id ? 'Editar preço de máquina' : 'Novo preço de máquina'}
            </Title>
            <SubTitle>
              {id
                ? 'Gerencie, edite ou desative um preço de máquina cadastrado.'
                : 'Atribua um nome e configure o preço de uma máquina ou grupo de máquinas.'}
            </SubTitle>
            <Form>
              <FieldArray
                name="maquinas"
                render={({ form, push, remove }) => {
                  const { handleChange, handleBlur } = form;
                  const aux: any = errors;

                  return (
                    <>
                      <InputLabel
                        style={{ margin: '32px 0 -16px 0' }}
                        value={values.nomeGrupoMaquina}
                        setValue={handleChange}
                        handleChangeEvent={handleChange}
                        onChange={() => setErrorNameDuplicated('')}
                        onBlur={handleBlur}
                        name="nomeGrupoMaquina"
                        counter
                        errorMessage={
                          (touched.nomeGrupoMaquina && aux.nomeGrupoMaquina
                            ? aux.nomeGrupoMaquina
                            : '') || errorNameDuplicated
                        }
                        label="Nome do preço de máquina"
                        placeholder="Insira um nome"
                      />
                      {values.maquinas.map((item: IMaquinas, index: number) => (
                        <CustomAccordion
                          style={{ marginTop: 40 }}
                          expanded
                          defaultExpanded
                        >
                          <AccordionSummary>
                            <p>
                              Máquina {(index + 1).toString().padStart(2, '0')}
                            </p>
                            <Delete
                              onClick={() =>
                                values.maquinas.length > 1
                                  ? remove(index)
                                  : null
                              }
                              height="18"
                              style={{
                                color:
                                  values.maquinas.length > 1
                                    ? '#3063e9'
                                    : 'gray'
                              }}
                            />
                          </AccordionSummary>
                          <ContentAccordion>
                            <Row>
                              <ContentCellProductName>
                                <p>Produto</p>
                              </ContentCellProductName>
                              <ContentCellProductName>
                                <MachineNameStyle
                                  hideLabel
                                  autoComplete="off"
                                  label=" "
                                  defaultValue={item.nomeMaquina}
                                  value={item.nomeMaquina}
                                  onChange={(e) => {
                                    handleChange(e);

                                    setFieldTouched(
                                      `maquinas.${index}.nomeMaquina`,
                                      !!e.target.value
                                    );
                                  }}
                                  placeholder="Insira um nome"
                                  onBlur={handleBlur}
                                  name={`maquinas.${index}.nomeMaquina`}
                                  error={
                                    aux?.maquinas &&
                                    touched?.maquinas &&
                                    touched?.maquinas[index]?.nomeMaquina &&
                                    aux.maquinas[index]?.nomeMaquina
                                  }
                                />
                                {aux?.maquinas &&
                                  touched?.maquinas &&
                                  touched?.maquinas[index]?.nomeMaquina &&
                                  aux.maquinas[index]?.nomeMaquina && (
                                    <ValidationMessageError
                                      style={{
                                        position: 'absolute',
                                        left: '70%'
                                      }}
                                      message={aux.maquinas[index]?.nomeMaquina}
                                    />
                                  )}
                              </ContentCellProductName>
                            </Row>

                            <Row>
                              <ContentCellProductName>
                                <p>Valor original</p>
                              </ContentCellProductName>

                              <ContentCellCurrency
                                error={
                                  aux?.maquinas &&
                                  touched?.maquinas &&
                                  touched?.maquinas[index]?.valorOriginal &&
                                  aux.maquinas[index]?.valorOriginal
                                }
                              >
                                <CurrencyInput
                                  locale="pt-BR"
                                  currency="BRL"
                                  autoComplete="off"
                                  label=" "
                                  value={item.valorOriginal}
                                  placeholder="00,00"
                                  maxLength={15}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name={`maquinas.${index}.valorOriginal`}
                                />
                                {aux?.maquinas &&
                                  touched?.maquinas &&
                                  touched?.maquinas[index]?.valorOriginal &&
                                  aux.maquinas[index]?.valorOriginal && (
                                    <ValidationMessageError
                                      style={{
                                        position: 'absolute',
                                        left: '70%'
                                      }}
                                      message={
                                        aux.maquinas[index]?.valorOriginal
                                      }
                                    />
                                  )}
                              </ContentCellCurrency>
                            </Row>

                            <Row>
                              <ContentCellProductName>
                                <p>Valor de venda</p>
                              </ContentCellProductName>
                              <ContentCellCurrency
                                error={
                                  aux?.maquinas &&
                                  touched?.maquinas &&
                                  touched?.maquinas[index]?.valorVenda &&
                                  aux.maquinas[index]?.valorVenda
                                }
                              >
                                <CurrencyInput
                                  locale="pt-BR"
                                  currency="BRL"
                                  autoComplete="off"
                                  label=" "
                                  value={item.valorVenda}
                                  placeholder="00,00"
                                  onChange={handleChange}
                                  maxLength={15}
                                  onBlur={handleBlur}
                                  name={`maquinas.${index}.valorVenda`}
                                />
                                {aux?.maquinas &&
                                  touched?.maquinas &&
                                  touched?.maquinas[index]?.valorVenda &&
                                  aux.maquinas[index]?.valorVenda && (
                                    <ValidationMessageError
                                      style={{
                                        position: 'absolute',
                                        left: '70%'
                                      }}
                                      message={aux.maquinas[index]?.valorVenda}
                                    />
                                  )}
                              </ContentCellCurrency>
                            </Row>
                          </ContentAccordion>
                        </CustomAccordion>
                      ))}

                      <div style={{ width: 200 }}>
                        <TextIconPlus
                          onClick={() => {
                            push({
                              id: 0,
                              nomeMaquina: '',
                              valorOriginal: '',
                              valorVenda: ''
                            });
                          }}
                          align="start"
                          text="Adicionar máquina"
                        />
                      </div>
                    </>
                  );
                }}
              />

              <BottomFixedActions style={{ zIndex: 0 }}>
                <Button
                  style={{ margin: '0 6px' }}
                  width="medium"
                  value="Voltar"
                  variant="secondary"
                  onClick={() =>
                    !equalsObject(values, initialValues)
                      ? setOpenBackDialog(true)
                      : handleCloseDialog()
                  }
                />
                <Button
                  style={{ margin: '0 6px' }}
                  width="medium"
                  disabled={!isValid}
                  loading={loading}
                  value="Salvar"
                  type="submit"
                />
              </BottomFixedActions>

              {openBackDialog && (
                <CustomDialog
                  title="Voltar para submenu"
                  message="As informações preenchidas não foram salvas. Ao voltar, todas as informações serão descartadas. Deseja descartar e retornar ao submenu?"
                  handleClose={() => setOpenBackDialog(false)}
                  primaryButton={{
                    buttonTitle: 'Descartar modificações',
                    onClick: () => {
                      setOpenBackDialog(false);
                      handleCloseDialog();
                    }
                  }}
                  secondaryButton={{
                    buttonTitle: 'Cancelar',
                    onClick: () => setOpenBackDialog(false)
                  }}
                />
              )}
              {openSuccessDialog && (
                <CustomDialog
                  title="Alterações salvas"
                  message="As informações do novo preço de máquina foram salvas com sucesso."
                  handleClose={() => handleCloseDialog()}
                  primaryButton={{
                    buttonTitle: 'Ok',
                    onClick: () => handleCloseDialog()
                  }}
                />
              )}
              {openConfirmDialog && (
                <CustomDialog
                  title="Salvar novo preço de maquina"
                  message="Você tem certeza que deseja salvar esse preço de máquina? Essa ação não poderá ser desfeita."
                  handleClose={() => setOpenConfirmDialog(false)}
                  primaryButton={{
                    buttonTitle: 'Salvar',
                    onClick: () => {
                      handleSubmit(values);
                      setOpenConfirmDialog(false);
                    }
                  }}
                  secondaryButton={{
                    buttonTitle: 'Cancelar',
                    onClick: () => setOpenConfirmDialog(false)
                  }}
                />
              )}
            </Form>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default AddMachinePrice;
