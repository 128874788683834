import styled from '@emotion/styled';
import { Body, Button, CardFooter } from '@sumup/circuit-ui';

export const StyledBody = styled(Body)`
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 30px;
`;
export const StyledButton = styled(Button)`
  min-width: 220px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 16px;
`;
export const MainContainer = styled.div`
  max-width: 1156px;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 42px 38px 0 38px;

  @media (min-width: 1370px) {
    max-width: 1400px;
  }
`;
export const StyledCardFooter = styled(CardFooter)`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
`;
export const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 15px;
`;

export const StyleGrid = styled.div`
  display: grid;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;

  @media (max-width: 1370px) {
    max-height: 300px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3063e9;
    border-radius: 2px;
  }
`;
export const Items = styled.div`
  display: grid;
  height: 64px;
  font-size: 14px;
  align-items: center;
  grid-template-columns: 10% 15% 35% 20% 20%;
`;
export const Cabecalho = styled(Items)`
  background-color: #f9f9f9;
  margin-top: 16px;

  p {
    font-weight: bold;
    font-size: 16px;
  }
`;
export const Header = styled.header`
  padding: 0 48px;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 695px;
`;
export const SearchBar = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  label {
    width: 100%;
  }
`;

export const SearchIcon = styled.div<{ active: boolean }>`
  padding: 10px;
  height: 48px;
  width: 48px;
  background-color: ${({ active }) => (active ? '#3063e9' : '#9da7b1de')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 2px;
  margin-left: 30px;
  cursor: pointer;
`;
