import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ROUTES } from '../constants/routes.constants';
import { useAuth } from '../contexts/auth.context';

type PrivateRouteProps = RouteProps;

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { auth } = useAuth();

  if (!auth) return <Redirect to={ROUTES.LOGIN} />;
  return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
