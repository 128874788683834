import { FC, useState } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';

interface IProps {
  data: {
    name: string;
    value: number;
    color: string;
  }[];
}

interface ICoordinates {
  x: number;
  y: number;
}

const Chart: FC<IProps> = ({ data }) => {
  const [descriptionCoordinates] = useState<ICoordinates[]>(
    data.map(() => ({ x: -1, y: -1 }))
  );

  const ajustarCoordenadas = () => {
    const DISTANCIA_MINIMA = 50;

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < descriptionCoordinates.length; i++) {
      if (descriptionCoordinates[i - 1].y !== -1) {
        const distancia =
          descriptionCoordinates[i - 1].y - descriptionCoordinates[i].y;

        if (distancia < DISTANCIA_MINIMA) {
          // eslint-disable-next-line operator-assignment
          descriptionCoordinates[i].y =
            descriptionCoordinates[i - 1].y - DISTANCIA_MINIMA;
        }
      }
    }
  };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;

    descriptionCoordinates[data.indexOf(payload.payload)] = {
      x: cx + (outerRadius + 30) * cos,
      y: cy + (outerRadius + 30) * sin
    };

    if (cos >= 0 && endAngle < 90) {
      ajustarCoordenadas();
    }

    const ex =
      descriptionCoordinates[data.indexOf(payload.payload)].x +
      (cos >= 0 ? 1 : -1) * 60;
    const ey = descriptionCoordinates[data.indexOf(payload.payload)].y;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text
          fontSize="16"
          x={cx}
          y={cy}
          dy={-20}
          textAnchor="middle"
          fontWeight="normal"
          fill="black"
        >
          Totais de tarefas
        </text>
        <text
          fontSize="36"
          fontWeight="bold"
          x={cx}
          y={cy}
          dy={25}
          textAnchor="middle"
          fill="black"
        >
          {data.reduce((acumulador, objeto) => {
            return acumulador + Number(objeto.value || 0);
          }, 0)}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${
            descriptionCoordinates[data.indexOf(payload.payload)].x
          },${
            descriptionCoordinates[data.indexOf(payload.payload)].y
          }L${ex},${ey}`}
          stroke="gray"
          fill="none"
        />
        <circle cx={ex} cy={ey} r={4} fill="gray" stroke="none" />
        <text
          fontSize="18"
          fontWeight="bold"
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="black"
        >{`${value}`}</text>
        <text
          fontSize="14"
          fontWeight="bold"
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={16}
          textAnchor={textAnchor}
          fill="black"
        >
          {payload.name}
        </text>
      </g>
    );
  };

  return (
    <PieChart
      width={800}
      height={465}
      style={{
        boxShadow:
          '0px 2px 2px 0px rgba(12, 15, 20, 0.06), 0px 1px 1px 0px rgba(12, 15, 20, 0.06), 0px -1px 0px 0px rgba(12, 15, 20, 0.02)'
      }}
    >
      <Pie
        data={data.filter((item) => item.value !== 0)}
        activeIndex={data.map((_, index) => index)}
        activeShape={renderActiveShape}
        animationDuration={0}
        innerRadius={100}
        outerRadius={130}
        paddingAngle={2}
        dataKey="value"
      >
        {data
          .filter((item) => item.value !== 0)
          .map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
      </Pie>
    </PieChart>
  );
};

export default Chart;
