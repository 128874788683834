import styled from '@emotion/styled';
import { MultiSelect } from '../../_Atoms';

export const MultiSelectStyle = styled(MultiSelect)`
  font-size: 14px;
  margin-left: 15px;

  .p-multiselect {
    width: 302px;
  }
`;

export const StyledHeaderRow = styled.header`
  height: 72px;
  background-color: #f9f9f9;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1.5fr 1.5fr 0.5fr 0.5fr;
  top: 0;
  z-index: 23;
`;

export const HistoryAllocationTableLine = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1.5fr 1.5fr 0.5fr 0.5fr;
  border-bottom: 1px solid #eef0f2;
  text-align: center;
  height: 72px;

  p {
    font-size: 13px;
  }
`;

export const StyledHeaderCell = styled.div`
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  line-height: initial;
  :first-child {
    padding-left: 24px;
  }
  :last-child {
    padding-right: 24px;
  }
`;

export const SpinnerContainer = styled.div`
  min-height: 432px;
  display: flex;
  align-items: center;
`;

export const ConfirmationModalLine = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-bottom: 1px solid #eef0f2;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  text-align: center;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 12px;
  }
`;
export const ConfirmationModalLineHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-bottom: 1px solid #eef0f2;
  text-align: center;
  background-color: #f9f9f9;
  margin-top: 32px;
  font-weight: bold;
`;

export const StatusModal = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  border-left: 2px solid ${({ color }) => color};
  height: 100%;
  white-space: nowrap;
  padding-left: 12px;
  p {
    margin-left: 10px;
  }
`;

export const ContainerModalLines = styled.div`
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  margin-left: -4px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3063e9;
    border-radius: 2px;
  }
`;

export const ContainerModalConfirmation = styled.div`
  padding: 12px 40px 0px 40px;
  background-color: white;
`;
