import styled from '@emotion/styled';

export const DropDownContainer = styled.div`
  width: 10.5em;
  margin: 0 auto;
`;

export const DropDownHeader = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0.4em 2em 0.4em 2em;
  font-weight: 500;
  font-size: 1.3rem;
  cursor: pointer;
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 10.5em;
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 500;
  li:last-child {
    border-bottom: none;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  padding: 15px 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.n300};
  cursor: pointer;
`;
