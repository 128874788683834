import axios from 'axios';

const defaultHeaders = {
  'Content-Type': 'application/json'
};

const baseURL = process.env.REACT_APP_BASE_URL;

export async function get<T>(
  url: string,
  customHeaders: Record<string, unknown> = {}
): Promise<T> {
  const headers = { ...defaultHeaders, ...customHeaders };

  try {
    const response = await axios.get<T>(`${baseURL}${url}`, { headers });
    return response.data;
  } catch (error) {
    throw new Error('Houve um problema com a requisição.');
  }
}

export async function post<T>(
  url: string,
  bodyRequest: unknown,
  customHeaders: Record<string, unknown> = {}
): Promise<T> {
  const headers = { ...defaultHeaders, ...customHeaders };

  try {
    const response = await axios.post<T>(`${baseURL}${url}`, bodyRequest, {
      headers
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data);
    }
    throw new Error('Houve um problema com a requisição');
  }
}

export async function put<T>(
  url: string,
  bodyRequest: unknown,
  customHeaders: Record<string, unknown> = {}
): Promise<T> {
  const headers = { ...defaultHeaders, ...customHeaders };

  try {
    const response = await axios.put<T>(`${baseURL}${url}`, bodyRequest, {
      headers
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data);
    }
    throw new Error('Houve um problema com a requisição.');
  }
}

export async function patch<T>(
  url: string,
  bodyRequest: unknown,
  customHeaders: Record<string, unknown> = {}
): Promise<T> {
  const headers = { ...defaultHeaders, ...customHeaders };

  try {
    const response = await axios.patch<T>(`${baseURL}${url}`, bodyRequest, {
      headers
    });
    return response.data;
  } catch (error) {
    throw new Error('Houve um problema com a requisição.');
  }
}
