import { FC, FunctionComponent, SVGProps } from 'react';
import { Count } from '../../_Atoms';
import { Header, SubTitle, Title } from './styles';

interface IProps {
  title: string;
  subTitle: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  quantity: number;
  total: number;
  color: string;
}

const ColumnHeader: FC<IProps> = ({
  title,
  subTitle,
  icon: Icon,
  quantity,
  total,
  color = '#eef0f2'
}) => {
  return (
    <div>
      <Header>
        <Icon style={{ marginRight: 16 }} />
        <div>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </div>
      </Header>
      <div style={{ borderBottom: `2px solid ${color}` }}>
        <Count quantity={quantity} total={total} />
      </div>
    </div>
  );
};

export default ColumnHeader;
