import { FC } from 'react';
import { ENUM_STATUS_TAREFA } from '../../../enums/taskListStatus.enums';
import { formatFullDate } from '../../../utils/format.utils';
import { Modal } from '../../_Molecules';
import { TaskListStatus } from '../TaskListFollowUpTable/styles';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Done } from '../../../assets/icons/done.svg';
import { ReactComponent as Loading } from '../../../assets/icons/loading.svg';
import { ReactComponent as WarningDone } from '../../../assets/icons/warning_done.svg';
import { ITaskView } from '../../../interfaces/taskList.interfaces';
import { TableTitle, TableValues } from './styles';

interface IProps {
  selected: ITaskView;
  openModal: boolean;
  setOpenModal: (p: boolean) => void;
}

const TaskListFollowUpModal: FC<IProps> = ({
  selected,
  openModal,
  setOpenModal
}) => {
  return (
    <Modal
      widthAuto
      title="Detalhes da tarefa"
      open={openModal}
      setOpen={setOpenModal}
    >
      <div
        style={{
          width: '720px',
          padding: '0 0 16px 0',
          backgroundColor: 'white'
        }}
      >
        {selected && (
          <>
            <TableTitle>
              <p>Status da tarefa</p>
              <p>Prazo final para conclusão</p>
              <p>Data de conclusão da tarefa</p>
            </TableTitle>
            <TableValues>
              <TaskListStatus
                style={{ borderLeft: 'none' }}
                status={selected.status}
              >
                {selected.status === 4 && <WarningDone />}
                {selected.status === 3 && <Done />}
                {selected.status === 2 && <Loading />}
                {selected.status === 1 && <Close />}
                <span>{ENUM_STATUS_TAREFA[selected.status]}</span>
              </TaskListStatus>
              <div>
                <p>
                  {formatFullDate(new Date(selected.prazoFinal))} <br />
                  <span
                    style={{
                      color: '#9DA7B1',
                      textAlign: 'center',
                      fontSize: 13,
                      fontStyle: 'normal',
                      letterSpacing: '0.15px'
                    }}
                  >
                    UTC - 03:00 (Horário de Brasília)
                  </span>
                </p>
              </div>
              <p>
                {selected.dataConclusao
                  ? formatFullDate(new Date(selected.dataConclusao))
                  : '-'}
                {selected.dataConclusao && (
                  <>
                    <br />
                    <span
                      style={{
                        color: '#9DA7B1',
                        textAlign: 'center',
                        fontSize: 13,
                        fontStyle: 'normal',
                        letterSpacing: '0.15px'
                      }}
                    >
                      UTC - 03:00 (Horário de Brasília)
                    </span>
                  </>
                )}
              </p>
            </TableValues>
            <div
              style={{
                fontWeight: 'bold',
                padding: '46px 16px 16px 16px',
                fontSize: 16
              }}
            >
              <p>Merchant Code: {selected.merchantCode}</p>
              <p style={{ margin: '16px 0' }}>Detalhes</p>
              <p style={{ fontWeight: 'normal' }}>{selected.detalhes}</p>
            </div>

            <p style={{ padding: '0 16px', fontSize: 13 }}>Comentário</p>
            <p
              style={{
                padding: '16px 12px',
                border: '1px solid #eef0f2',
                margin: '0 16px',
                color: '#9DA7B1',
                borderRadius: 6,
                fontSize: 13,
                maxHeight: 150,
                overflowY: 'auto'
              }}
            >
              {selected.comentario || 'Sem comentário'}
            </p>
          </>
        )}
      </div>
    </Modal>
  );
};
export default TaskListFollowUpModal;
