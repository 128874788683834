import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { Headline } from '@sumup/circuit-ui';
import { CustomButton, SelectField } from '../../components/_Atoms';
import { Breadcrumb, CardContainer } from '../../components/_Molecules';
import { CustomEditListTable } from '../../components/_Organisms';
import {
  ENUM_ACTION_ICON_TYPE,
  ListItem
} from '../../components/_Organisms/CustomEditListTable';

import {
  DEFAULT_FILTER_OPTION_GROUPS,
  FILTER_OPTIONS,
  MCCS_STATUS_NAME
} from '../../constants/mcc-groups.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../../constants/routes.constants';

import { get } from '../../services/axios.service';

import { API } from '../../constants/api.constants';
import {
  IDataResponseModel,
  IResponseListToEdit
} from '../../interfaces/requests.interfaces';
import {
  StatusOptions,
  getFilterParamByStatus
} from '../../services/filters.service';

const StyledBoxPage = styled.div`
  max-width: 704px;
  margin: 0 auto;
  width: 100%;
`;
const StyledBoxButton = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
`;
const EditMccsGroupsPage: React.FC = () => {
  const [mccList, setMccList] = useState<ListItem[]>([]);
  const [filterOption, setFilterOption] = useState<StatusOptions>(
    DEFAULT_FILTER_OPTION_GROUPS
  );
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();
  const { colors } = useTheme();
  const { active, disabled } = MCCS_STATUS_NAME;
  const [networkError, setNetworkError] = useState(false);

  const getMccsGroups = async () => {
    setLoading(true);
    setNetworkError(false);
    const fetchUrl = `${API.GRUPOS_MCC.LISTAR}${getFilterParamByStatus(
      filterOption
    )}`;
    try {
      const { data } = await get<IDataResponseModel<IResponseListToEdit[]>>(
        fetchUrl
      );
      if (data) {
        const formatedMccList = data.map((item) => ({
          ...item,
          actions: [
            {
              url: THIRD_LEVEL_MENU_ROUTES.EDIT_MCC_GROUP,
              color: colors.p500,
              text: 'Editar',
              IconType: ENUM_ACTION_ICON_TYPE.EDIT
            }
          ]
        }));
        setMccList(formatedMccList);
      }
    } catch (error) {
      setNetworkError(true);
      setMccList([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMccsGroups();
  }, [filterOption]);

  return (
    <StyledBoxPage>
      <Breadcrumb
        noMargin
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          },
          {
            routeName: 'Gestão de campanhas',
            routePath: MENU_ROUTES.CAMPAIGNS_MANAGEMENT
          },
          {
            routeName: 'Grupos de MCCs',
            routePath: SUB_MENU_ROUTES.MCCS_GROUPS
          }
        ]}
        currentRouteName="Editar grupo MCC"
      />
      <Headline style={{ marginTop: 25 }} as="h2" size="four">
        Editar grupo MCC
      </Headline>
      <CardContainer
        title="Lista de grupos MCCs cadastrados"
        subtitle="Gerencie, edite ou remova um grupo."
      >
        <SelectField
          label="Status:"
          name="filters"
          options={FILTER_OPTIONS}
          value={filterOption}
          onChange={(e) => setFilterOption(e.target.value as StatusOptions)}
          disabled={networkError || mccList.length === 0}
        />
        <CustomEditListTable
          listData={mccList}
          mainColumnName="Nome do grupo"
          statusName={{ active, disabled }}
          resourceUrl={API.GRUPOS_MCC.CRIAR}
          loading={loading}
          networkError={networkError}
          retry={getMccsGroups}
          backRoute={() => push(SUB_MENU_ROUTES.MCCS_GROUPS)}
        />

        {!networkError && !loading && (
          <StyledBoxButton>
            <CustomButton
              onClick={() => push(SUB_MENU_ROUTES.MCCS_GROUPS)}
              variant="secondary"
              id="back-button"
            >
              Voltar
            </CustomButton>
          </StyledBoxButton>
        )}
      </CardContainer>
    </StyledBoxPage>
  );
};

export default EditMccsGroupsPage;
