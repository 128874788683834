import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from '@emotion/styled';
import { AlcadaTable, FilterAlcada } from '../../components/_Organisms';

import { ALCADA_FILTER_OPTIONS } from '../../constants/alcada.constants';
import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES
} from '../../constants/routes.constants';

import { post } from '../../services/axios.service';

import { usePagination } from '../../hooks/usePagination';

import {
  IDataResponseModel,
  IPagination,
  IResponseGetPropostas
} from '../../interfaces/requests.interfaces';

import {
  Button,
  CountItensPerPage,
  CustomPagination,
  CustomSpinner,
  RedirectMessage,
  Title
} from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  Toast
} from '../../components/_Molecules';
import { StyledBody } from '../../components/_Molecules/CardContainer/styles';
import { IToast } from '../../components/_Molecules/Toast';
import { IFilterAlcada } from '../../components/_Organisms/FilterAlcada';
import { API } from '../../constants/api.constants';
import { STORAGE } from '../../constants/storage.constants';
import { equalsObject } from '../../utils/format.utils';
import { rangeDateOptions } from '../../utils/kanban';

const StyledContentBox = styled.div`
  width: 1340px;
  margin: 25px auto;
`;
const StyledListBox = styled.div`
  max-width: 1340px;
  width: 100%;
  margin: 0 auto 20px auto;
`;
const StyledPaginationBox = styled.div`
  display: flex;
  justify-content: center;
`;

interface IPaginationAlcadaList extends IPagination<IResponseGetPropostas> {
  exportacaoSerialNumber: boolean;
}
const AlcadaListPage: React.FC = () => {
  const [proposals, setProposals] = useState<IResponseGetPropostas[]>([]);
  const [loadingProposals, setLoadingProposals] = useState(true);
  const [loadingExportFile, setLoadingExportFile] = useState({
    merchant: false,
    serialnumber: false
  });
  const [loadingToResetPaginator, setLoadingToResetPaginator] = useState(false);
  const { push } = useHistory();
  const { totalItems, setTotalItems } = usePagination();
  const itemsPerPage = 50;
  const [filters, setFilters] = useState<IFilterAlcada>((): IFilterAlcada => {
    const history = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_ALCADA) || '{}'
    );

    return {
      status:
        history.status ??
        ALCADA_FILTER_OPTIONS.map((x) => Number(x.value)).filter(
          (i) => i !== -1
        ),
      initialLabelDateIndex: history.initialLabelDateIndex ?? 11,
      startDate: history.startDate ?? rangeDateOptions('11').start,
      endDate: history.endDate ?? rangeDateOptions('11').end,
      subGroup: history.subGroup ?? [],
      notaFiscal: history.notaFiscal ?? [0, 1, 2],
      search: history.search ?? '',
      typeOffer: history.typeOffer ?? '0',
      typeProposal: history.typeProposal ?? '0'
    };
  });
  const [toastList, setToast] = useState<IToast[]>([]);
  const [currentPage, setPage] = useState(() => {
    const history = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_ALCADA) || '{}'
    );

    return history ? history.page : 1;
  });
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);
  const [serialExportValidation, setSerialExportValidation] = useState(false);
  const [lastExportFilters, setLastExportFilters] = useState({
    merchant: {},
    serial: {}
  });

  const handleExport = async (option: 'merchant' | 'serial') => {
    setShowRedirectMessage(false);
    setLoadingExportFile({
      ...loadingExportFile,
      [option === 'merchant' ? 'merchant' : 'serialnumber']: true
    });

    try {
      const response = await post<IDataResponseModel<null>>(
        API.ARQUIVO.SOLICITAR_PROPOSTA(option),
        {
          ...(filters.status.length > 0 && { statusProposta: filters.status }),
          ...(filters.startDate && { dataInicial: filters.startDate }),
          ...(filters.endDate && { dataFinal: filters.endDate }),
          ...(filters.search && { campoBusca: filters.search }),
          ...(filters.subGroup &&
            filters.subGroup.length > 0 && {
              subGrupos: filters.subGroup
            }),
          ...(filters.typeOffer !== '0' && {
            tipoOferta: Number(filters.typeOffer)
          }),
          ...(filters.typeProposal !== '0' && {
            tipoAquisicao: Number(filters.typeProposal)
          }),
          ...(filters.notaFiscal.length > 0 && {
            statusNotaFiscal: filters.notaFiscal
          })
        },
        { responseType: 'blob' }
      );

      if (response.success) {
        setToast([
          {
            id: 0,
            title: 'Arquivo em processamento!',
            description: 'Acompanhe pelo histórico de arquivos',
            type: 'sucess'
          }
        ]);
        setLastExportFilters({
          ...lastExportFilters,
          [option]: filters
        });
        setShowRedirectMessage(true);
      }
    } catch (error) {
      setToast([
        {
          id: 0,
          title: 'Não foi possível iniciar o processo!',
          description: 'Tente novamente em alguns minutos',
          type: 'error'
        }
      ]);
    }

    setLoadingExportFile({
      ...loadingExportFile,
      [option === 'merchant' ? 'merchant' : 'serialnumber']: false
    });
  };

  const getProposals = async (pageFromPagination?: number) => {
    setLoadingProposals(true);
    const getPage = pageFromPagination ?? 1;

    try {
      const { data } = await post<IDataResponseModel<IPaginationAlcadaList>>(
        API.PROPOSTAS.HISTORICO,
        {
          PageSize: itemsPerPage,
          PageNumber: getPage,
          ...(filters.status.length > 0 && { statusProposta: filters.status }),
          ...(filters.startDate && {
            dataInicial: new Date(filters.startDate).toISOString()
          }),
          ...(filters.endDate && {
            dataFinal: new Date(filters.endDate).toISOString()
          }),
          ...(filters.search && { CampoBusca: filters.search }),
          ...(filters.subGroup &&
            filters.subGroup.length > 0 && {
              subGrupos: filters.subGroup
            }),
          ...(filters.typeOffer !== '0' && {
            TipoOferta: Number(filters.typeOffer)
          }),
          ...(filters.typeProposal !== '0' && {
            TipoAquisicao: Number(filters.typeProposal)
          }),
          ...(filters.notaFiscal.length > 0 && {
            statusNotaFiscal: filters.notaFiscal
          })
        }
      );
      setProposals(data.results);
      setTotalItems(data.totalItems);
      setSerialExportValidation(data.exportacaoSerialNumber);
    } catch (error) {
      setProposals([]);
    } finally {
      setLoadingProposals(false);
    }
  };

  const handlePageChange = async (page: number) => {
    setPage(page + 1);
    getProposals(page + 1);
  };

  const getData = async () => {
    const history = JSON.parse(
      localStorage.getItem(STORAGE.HISTORY_PAGINATION_ALCADA) || '{}'
    );
    setPage(history?.page ?? 1);

    setLoadingToResetPaginator(true);
    await getProposals(history.page ?? 1);
    setLoadingToResetPaginator(false);
  };

  useEffect(() => {
    getData();
    localStorage.removeItem(STORAGE.HISTORY_PAGINATION_ALCADA);
  }, []);

  useEffect(() => {
    console.log(filters);
    console.log(lastExportFilters.serial);
  }, [filters]);

  return (
    <div style={{ padding: '0 25px 150px 25px' }}>
      <StyledContentBox>
        <Breadcrumb
          currentRouteName="Propostas"
          previousRoutes={[
            {
              routeName: 'Menu principal',
              routePath: ROUTES.MAIN_MENU
            },
            {
              routeName: 'Gestão de propostas',
              routePath: MENU_ROUTES.PROPOSALS_MANAGEMENT
            }
          ]}
          style={{ marginBottom: '36px' }}
          noMargin
        />
        <Title fontSize="big">Propostas</Title>
        <StyledBody as="p">
          Visualize, aprove ou reprove as propostas feitas pelos promotores da
          SumUp.
        </StyledBody>
      </StyledContentBox>

      <FilterAlcada
        getData={getData}
        filters={filters}
        setFilters={setFilters}
        serialButtomValidation={setSerialExportValidation}
      />

      <StyledListBox>
        {showRedirectMessage && (
          <RedirectMessage
            style={{ marginBottom: '20px' }}
            pageInfo="Ir para Histórico de arquivos"
            route={SUB_MENU_ROUTES.HISTORY_FILES}
            message="Arquivo enviado para o processamento, faça o download do arquivo na página de Histórico de arquivos."
          />
        )}
        {loadingProposals ? (
          <div
            style={{
              height: '100vh',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <CustomSpinner />
          </div>
        ) : (
          <AlcadaTable
            page={currentPage}
            filters={filters}
            proposals={proposals}
          />
        )}
        {!loadingToResetPaginator && totalItems > 50 && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CountItensPerPage
              currentPage={currentPage}
              itensPerPage={itemsPerPage}
              totalItens={totalItems}
            />
            <StyledPaginationBox>
              <CustomPagination
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                totalItems={totalItems}
                initialPage={currentPage}
              />
            </StyledPaginationBox>
          </div>
        )}
      </StyledListBox>

      <BottomFixedActions style={{ zIndex: 1 }}>
        <Button
          variant="secondary"
          type="button"
          value="Voltar"
          onClick={() => push(MENU_ROUTES.PROPOSALS_MANAGEMENT)}
          width="medium"
        />

        <Button
          variant="primary"
          type="button"
          value="Exportar por Merchant Code"
          onClick={() => handleExport('merchant')}
          width="large"
          disabled={
            proposals.length === 0 ||
            equalsObject(lastExportFilters.merchant, filters)
          }
          loading={loadingExportFile.merchant}
        />

        <Button
          variant="primary"
          type="button"
          value="Exportar por Serial Number"
          onClick={() => handleExport('serial')}
          width="large"
          disabled={
            proposals.length === 0 ||
            !serialExportValidation ||
            equalsObject(lastExportFilters.serial, filters)
          }
          loading={loadingExportFile.serialnumber}
        />
      </BottomFixedActions>
      <Toast toastlist={toastList} position="top-right" setList={setToast} />
    </div>
  );
};

export default AlcadaListPage;
