export enum ENUM_TIPO_TAXA {
  MULTIPLAS_TAXAS = 0,
  PADRAO = 1,
  PLANO_ANTECIPADO = 2,
  PLANO_ECONOMICO = 3,
  PLANO_INSTANTANEO = 4
}

export enum ENUM_TIPO_TRANSACAO {
  DEBITO = 1,
  CREDITO_A_VISTA = 2,
  CREDITO_PARCELADO = 3
}
