import styled from '@emotion/styled';

export const StyledSessionMessage = styled.div`
  width: 100%;
  padding-left: 56px;
  background-color: #eaeffd;
  color: ${(props) => props.theme.colors.p500};
  display: flex;
  align-items: center;
  height: 43px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 21;
  p {
    font-size: 12px;
  }
  svg {
    margin-right: 10px;
  }
  b {
    font-size: 12px;
    font-weight: 700;
  }
`;
