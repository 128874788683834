import { FC } from 'react';
import { Breadcrumb, MenuCardList } from '../../components/_Molecules';
import { IPreviousRoute } from '../../components/_Molecules/Breadcrumb';
import {
  LEADER_PROPOSAL_MENU_ITEMS,
  PROPOSAL_MENU_ITEMS
} from '../../constants/menu.constants';
import { ROUTES } from '../../constants/routes.constants';
import { useAuth } from '../../contexts/auth.context';

const ProposalsManagement: FC = () => {
  const { userType } = useAuth();

  const previousRouteList: IPreviousRoute[] = [
    {
      routeName: 'Menu principal',
      routePath: ROUTES.MAIN_MENU
    }
  ];

  return (
    <>
      <Breadcrumb
        previousRoutes={previousRouteList}
        currentRouteName="Gestão de propostas"
      />
      <MenuCardList
        columns={userType() === 'Lider' ? 'TWO' : 'THREE'}
        menuCardList={
          userType() === 'Lider'
            ? LEADER_PROPOSAL_MENU_ITEMS
            : PROPOSAL_MENU_ITEMS
        }
      />
    </>
  );
};

export default ProposalsManagement;
