export const statusPropostas = [
  {
    label: 'Aprovada',
    value: 3
  },
  {
    label: 'Decisor não encontrado',
    value: 7
  },
  {
    label: 'Em processamento',
    value: 2
  },
  {
    label: 'Negociação difícil',
    value: 6
  },
  {
    label: 'Nenhuma classificação',
    value: 9
  },
  {
    label: 'Outra classificação',
    value: 8
  },
  {
    label: 'Proposta inválida',
    value: 13
  },
  {
    label: 'Proposta não aceita',
    value: 12
  },
  {
    label: 'Quase fechando',
    value: 5
  },
  {
    label: 'Reprovada',
    value: 4
  },
  {
    label: 'Retorno agendado',
    value: 11
  },
  {
    label: 'Retorno não agendado',
    value: 10
  }
];

export enum ENUM_DATE_OPTIONS {
  'Essa semana' = 1,
  'Semana passada' = 2,
  'Últimos 30 dias' = 3,
  'Mês atual' = 4,
  'Mês anterior' = 5,
  'Últimos 3 meses' = 6,
  'Hoje' = 7,
  'Exibir todo o período' = 8,
  'Últimos 15 Dias' = 9,
  'Últimos 6 meses' = 10,
  'Ultimos 7 dias' = 11,
  'Próximos 7 dias' = 12,
  'Próximos 15 dias' = 13
}

export const DATE_OPTIONS = [
  {
    label: 'Essa semana',
    value: '1'
  },
  {
    label: 'Semana passada',
    value: '2'
  },
  {
    label: 'Últimos 30 dias',
    value: '3'
  },
  {
    label: 'Mês atual',
    value: '4'
  },
  {
    label: 'Mês anterior',
    value: '5'
  },
  {
    label: 'Últimos 3 meses',
    value: '6'
  },
  {
    label: 'Hoje',
    value: '7'
  },
  {
    label: 'Exibir todo o período',
    value: '8'
  },
  {
    label: 'Últimos 15 Dias',
    value: '9'
  },
  {
    label: 'Últimos 6 meses',
    value: '10'
  },
  {
    label: 'Últimos 7 dias',
    value: '11'
  },
  {
    label: 'Próximos 7 dias',
    value: '12'
  },
  {
    label: 'Próximos 15 dias',
    value: '13'
  },
  {
    label: 'Personalizado',
    value: '-1'
  }
];

export const DEFAULT_VALUE_STATUS = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
export const DEFAULT_RANGE_DATE = '1';
