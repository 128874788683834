import styled from '@emotion/styled';
import { Input } from '@sumup/circuit-ui';

export const Container = styled.div`
  display: flex;
  span {
    font-size: 13px;
  }
`;

export const InputStyle = styled(Input)<{ error?: boolean }>`
  height: 40px;
  font-size: 16px;
  color: ${({ error }) => (error ? '#db4d4d;' : '')};
  width: 776px;
  border: 1px solid ${({ error }) => (error ? '#db4d4d;' : '#d8dde1')};
  box-shadow: none;
  padding: 8px 12px;
  border-radius: 8px;

  ${({ error }) => (error ? '::placeholder {color: #d84d4d;}' : '')}

  &:focus {
    border: 1px solid ${({ error }) => (error ? '#db4d4d;' : '#3063e9')};
    box-shadow: none;
  }
`;

export const CounterCaracteres = styled.p`
  font-size: 13px;
  margin-top: -15px;
  text-align: right;
  color: #9da7b1;
`;
