export const API = {
  AQUISICAO_MAQUINA: {
    SUBGRUPO_PROMOTORES: '/maquina/lista-subgrupo-promotores-troca-devolucao',
    LISTA_EMAIL_SOLICITANTES: '/maquina/lista-email-solicitantes',
    GET_BY_ID: (id: string): string => `/maquina/${id}`,
    DETALHES_TROCA_DEVOLUCAO_BY_ID: (id: string): string =>
      `/maquina/detalhes-troca-devolucao/${id}`,
    EDITAR_TROCA_DEVOLUCAO_BY_ID: (id: string): string =>
      `/maquina/troca-devolucao/${id}/editar`,
    VALIDAR_MERCHANT_CODE: (merchant: string): string =>
      `/maquina/buscar-cliente/${merchant}?completo=false`,
    TROCA_DEVOLUCAO: '/maquina/troca-devolucao',
    LISTAR_TROCA_DEVOLUCAO: '/maquina/lista-troca-devolucao',
    EXPORTAR_TROCA_DEVOLUCAO: '/maquina/troca-devolucao/exportar'
  },
  ARQUIVO: {
    LISTAR: '/arquivo/listar',
    EMAIL_SOLICITANTES: '/arquivo/emailSolicitantes',
    SOLICITAR_PROPOSTA: (tipo: 'merchant' | 'serial'): string =>
      `/arquivo/solicitar/proposta/${tipo}`,
    DOWNLOAD_BY_ID: (id: number): string => `/arquivo/download/${id}`,
    DOWNLOAD_IMAGEM: '/arquivo/solicitar/comodato/imagens'
  },
  AUTH: {
    LOGIN: '/auth',
    REFRESH: '/auth/refresh-token'
  },
  CAMPANHAS: {
    POST: '/campanhas',
    GET: '/campanhas'
  },
  COMODATO: {
    DOWNLOAD_IMAGEM: (id: string, type?: number): string =>
      `/comodato/download-imagem?id=${id}${type ? `&tipoImagem=${type}` : ''}`,
    GERAR_RELATORIO: '/comodato/gerar-relatorio'
  },
  COMUNICADO: {
    ESTATISTICAS: (id: string, page: number, itemsPerPage: number): string =>
      `/comunicado/admin/${id}/estatisticas?PageNumber=${page}&PageSize=${itemsPerPage}`,
    USUARIOS: '/comunicado/usuarios',
    HISTORICO: (page: number, itemsPerPage: number): string =>
      `/comunicado/historico?PageNumber=${page}&PageSize=${itemsPerPage}`,
    COMUNICADO_BY_ID: (id: string): string => `/comunicado/admin/${id}`,
    ENVIAR: '/comunicado/enviar'
  },
  CONTROLE_ATIVOS: {
    SALVAR_ATRIBUICAO: '/ativos/atribuicao/salvar',
    HISTORICO_ATRIBUICAO: '/ativos/historico-atribuicao/listar',
    DOWNLOAD_ARQUIVO_BY_ID: (id: string): string =>
      `/ativos/download-arquivo/${id}`,
    HISTORICO_ATRIBUICAO_BY_ID: (id: string): string =>
      `/ativos/historico-atribuicao/detalhes/${id}`,
    LISTAR: '/ativos/listar',
    LISTAR_ATRIBUICAO: '/ativos/atribuicao/listar',
    BUSCAR_MAQUINAS: (partnerCode: string): string =>
      `/ativos/buscar-maquinas/${partnerCode}`
  },
  VISITAS: {
    EQUIPE: '/gestaovisitas/equipe',
    EXPORTAR: '/gestaovisitas/exportar',
    LISTAR: '/gestaovisitas/listas',
    VER_MAIS: '/gestaovisitas/ver-mais'
  },
  MAQUINAS: {
    DUPLICAR: '/grupo-maquina/duplicar',
    CRIAR: '/grupo-maquina',
    EDITAR: (id: string): string => `/grupo-maquina/${id}`,
    PAGINADO: '/grupo-maquina/paginado'
  },
  GRUPOS_MCC: {
    CRIAR: '/gruposmcc',
    EDITAR: (id: string): string => `/gruposmcc/${id}`,
    LISTAR: '/gruposmcc',
    GET_BY_ID: (id: string): string => `/gruposmcc/${id}`
  },
  MOTIVOS: {
    TROCA: '/motivos/troca',
    DEVOLUCAO: '/motivos/devolucao'
  },
  OFERTAS: {
    PAGINADO: '/ofertas/paginado',
    CRIAR: '/ofertas',
    GET: (id?: string): string => `/ofertas${id ? `/${id}` : ''}`,
    EDIT: (id?: string): string => `/ofertas${id ? `/${id}` : ''}`,
    DUPLICAR: '/ofertas/duplicar'
  },
  PLANOS: {
    CRIAR: '/planos',
    DUPLICAR: '/planos/duplicar',
    PAGINADO: '/planos/paginado',
    EDITAR: (id?: string): string => `/planos${id ? `/${id}` : ''}`,
    LISTAR: '/planos',
    GET_BY_ID: (id: string): string => `/planos/${id}`
  },
  PROPOSTAS: {
    GET_BY_ID: (id: string): string => `/propostas/admin/${id}`,
    EDITAR: (id?: string): string => `/propostas${id ? `/${id}` : ''}`,
    SUBGRUPOS: '/propostas/subgrupos',
    EXPORTAR: '/propostas/exportar',
    HISTORICO: '/propostas/admin/historico'
  },
  TAREFAS: {
    LISTAR_TIPO: '/tarefas/listar-tipo-tarefa',
    HISTORICO_CARGA: '/tarefas/historico-carga',
    REMETENTES: '/tarefas/remetentes-carga',
    REALIZAR_CARGA: '/tarefas/realizar-carga',
    LISTAR: '/tarefas/lista-tarefas'
  },
  USUARIOS: {
    SUBGRUPOS: '/usuarios/subgrupos',
    TODOS_SUBGRUPOS: '/usuarios/subgrupos/todos',
    EMPRESAS: '/usuarios/empresas',
    LISTAR_USUARIOS_ATRIBUICAO: '/usuarios/listar-usuarios-arquivo-atribuicao',
    LISTAR_PROMOTORES: '/usuarios/listar-membros-promotores',
    LISTAR_MEMBRO_TIME: '/usuarios/listar-membros-time',
    LISTAR_LIDERES: '/usuarios/listar-lideres',
    LIDERES_DROPDOWN: '/usuarios/listar-lideres-dropdown',
    EQUIPE_DROPDOWN: '/usuarios/listar-equipe-dropdown',
    ATIVAR: (id: number): string => `/usuarios/${id}/ativa`,
    DESATIVAR: (id: number): string => `/usuarios/${id}/desativa`,
    EDITAR: (id?: string): string => `/usuarios${id ? `/${id}` : ''}`,
    CRIAR: '/usuarios',
    TROCAR_SENHA: '/usuarios/trocar-senha',
    RECUPERAR_SENHA: '/usuarios/recuperar-senha',
    CARREGAR_USUARIOS: '/usuarios',
    GET_BY_ID: (id: string): string => `/usuarios/${id}`
  }
};
