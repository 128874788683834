import { FC } from 'react';
import { USER_STATUS_FILTER_OPTIONS } from '../../../../constants/user.constants';
import { FiltersEditUser } from '../../../../interfaces/addUser.interface';
import { CustomSelectFields } from '../styles';

const StatusFilter: FC<{
  filters: FiltersEditUser;
  setFilter: (p: FiltersEditUser) => void;
}> = ({ filters, setFilter }) => (
  <CustomSelectFields
    selected={!!filters.status}
    label="Status"
    options={USER_STATUS_FILTER_OPTIONS}
    onChange={(e) => setFilter({ ...filters, status: e.target.value })}
    value={filters.status}
    name="status"
    placeholder="Selecione uma opção"
  />
);

export default StatusFilter;
