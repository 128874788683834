import { FC, HTMLAttributes } from 'react';
import { Add } from '@sumup/icons';
import { Spinner } from '@sumup/circuit-ui';

interface IProps extends HTMLAttributes<HTMLParagraphElement> {
  text: string;
  loading?: boolean;
  align?: 'start' | 'center' | 'end';
}

const TextIconPlus: FC<IProps> = ({
  text,
  loading = false,
  align = 'center',
  ...rest
}) => {
  return (
    <p
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: align,
        color: 'blue',
        fontSize: 14,
        fontWeight: 'bold',
        padding: '16px 0 0 0',
        cursor: 'pointer'
      }}
    >
      {loading ? (
        <Spinner size="byte" />
      ) : (
        <>
          <Add style={{ transform: 'scale(0.4)' }} />
          {text}
        </>
      )}
    </p>
  );
};

export default TextIconPlus;
