import { FC, memo } from 'react';
import { STATUS_CARD, SUBSTATUS_CARD } from '../../../enums/kanban.enum';
import { StatusKanban } from '../../_Atoms';
import { CardContainer, CompanyName, TextInfo, UpdatedDate } from './styles';

interface IProps {
  updatedDate: string;
  company: string;
  promoter: string;
  returnDate: string | null;
  status: number | null;
  subStatus: number | null;
}

const Card: FC<IProps> = ({
  updatedDate,
  company,
  promoter,
  returnDate,
  status,
  subStatus
}) => {
  return (
    <CardContainer>
      <UpdatedDate>
        <b>Atualizado</b> {updatedDate}
      </UpdatedDate>
      <CompanyName>{company}</CompanyName>
      <TextInfo>{promoter}</TextInfo>
      {returnDate && (
        <TextInfo>
          <b>Retorno:</b> {returnDate}
        </TextInfo>
      )}
      {status && <StatusKanban status={STATUS_CARD[status]} />}
      {subStatus && <StatusKanban status={SUBSTATUS_CARD[subStatus]} />}
    </CardContainer>
  );
};

export default memo(Card);
