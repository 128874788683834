import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const ContainerStyle = styled.div`
  padding: 24px;
  border-radius: 4px;
`;

const CardRoot: FC<IProps> = ({ children, ...rest }) => {
  return (
    <ContainerStyle style={{ padding: 12 }} {...rest}>
      {children}
    </ContainerStyle>
  );
};

export default CardRoot;
