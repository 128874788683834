import { SelectOption } from '@sumup/circuit-ui';

export const FILTER_OPTIONS: SelectOption[] = [
  { value: 'ativos', label: 'Grupos ativos' },
  { value: 'inativos', label: 'Grupos inativados' },
  { value: 'todos', label: 'Todos os grupos' }
];

export const DEFAULT_FILTER_OPTION_GROUPS = 'ativos';

export const MCCS_STATUS_NAME = {
  active: 'Grupo ativo',
  disabled: 'Inativado'
};
