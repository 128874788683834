import styled from '@emotion/styled';

export const Container = styled.div`
  margin-top: 24px;
  padding-bottom: 160px;
`;

export const TableAssetManagementHeader = styled.div`
  display: grid;
  grid-template-columns: 56px repeat(6, 183px) 130px;
  height: 72px;
  justify-content: space-between;
  background-color: #fafbfc;

  p {
    font-size: 13px;
    padding: 24px;
    font-weight: bold;
  }

  @media screen and (max-width: 1366px) {
    grid-template-columns: 56px repeat(6, 130px) 110px;
    p {
      font-size: 11px;
      padding: 24px 0px;
    }
  }
`;

export const TableAssetManagementResults = styled.div<{ selected: boolean }>`
  display: grid;
  grid-template-columns: 56px repeat(6, 183px) 130px;
  height: 72px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eef0f2;
  border-top: none;
  cursor: pointer;

  ${(e) =>
    e.selected
      ? 'background-color: rgba(127, 181, 255, 0.06);border-radius: 4px;border: 2px solid #3063e9;'
      : ''}

  &:hover {
    border: 2px solid #3063e9;
    border-radius: 4px;
  }

  p {
    font-size: 13px;
    padding: 0 24px;
  }

  @media screen and (max-width: 1366px) {
    grid-template-columns: 56px repeat(6, 130px) 110px;
    height: 72px;
    grid-template-rows: auto;
    grid-gap: 8px;

    p {
      font-size: 11px;
      padding: 0;
    }
  }
`;

export const ResultValue = styled.div`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0 24px;
  font-size: 13px;

  @media screen and (max-width: 1366px) {
    font-size: 11px;
    padding: 0;
  }
`;

export const ResultOneLine = styled.div`
  overflow: hidden;
  padding: 0 24px;
  text-overflow: ellipsis;
  font-size: 13px;

  @media screen and (max-width: 1366px) {
    font-size: 11px;
    padding: 0;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  height: auto;
  margin-top: 24px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1366px) {
    flex-wrap: wrap;
  }
`;

export const SpinnerContainer = styled.div`
  min-height: 432px;
  display: flex;
  align-items: center;
`;
