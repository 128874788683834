import { useFormikContext } from 'formik';
import { FC } from 'react';
import {
  IFormAddRatePlans,
  ITaxasFlex
} from '../../../../interfaces/rate-plans.interfaces';
import { InputField } from '../../../_Atoms';
import { StyledFixTitle } from '../styles';
import { StyledCustomContent, StyledCustomFlexRowContent } from './styles';

interface IProps {
  taxas: 'taxaAntecipada' | 'taxaEconomica' | 'taxaInstantanea';
  parcela: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  getError: (error: string) => boolean;
  getTouch: (name: string) => boolean;
}

const FlexibleInstallment: FC<IProps> = ({
  taxas,
  parcela,
  handleChange,
  handleBlur,
  getError,
  getTouch
}) => {
  const { values } = useFormikContext<IFormAddRatePlans>();

  return (
    <StyledCustomFlexRowContent
      style={
        values[taxas][2].parceladoFlexivel
          ? { display: '' }
          : { display: 'none' }
      }
    >
      <StyledCustomContent>
        <StyledFixTitle style={{ marginTop: 10 }}>
          {parcela}ª parcela
        </StyledFixTitle>
      </StyledCustomContent>
      <StyledCustomContent>
        <InputField
          label="Porcentagem"
          isTableComponent
          onBlur={handleBlur}
          onChange={handleChange}
          value={
            values[taxas][2].detalhamentoParcelasVisaMaster?.[
              `taxa${parcela}x` as keyof ITaxasFlex
            ]
          }
          hideLabel
          invalid={
            getError(
              `${taxas}[2].detalhamentoParcelasVisaMaster.taxa${parcela}x`
            ) &&
            getTouch(
              `${taxas}[2].detalhamentoParcelasVisaMaster.taxa${parcela}x`
            )
          }
          errorMessage={
            getError(
              `${taxas}[2].detalhamentoParcelasVisaMaster.taxa${parcela}x`
            ) as unknown as string
          }
          placeholder="0.0%"
          name={`${taxas}[2].detalhamentoParcelasVisaMaster.taxa${parcela}x`}
        />
      </StyledCustomContent>
      <StyledCustomContent>
        <InputField
          label="Porcentagem"
          isTableComponent
          onBlur={handleBlur}
          onChange={handleChange}
          value={
            values[taxas][2].detalhamentoParcelasOutrasBandeiras?.[
              `taxa${parcela}x` as keyof ITaxasFlex
            ]
          }
          hideLabel
          invalid={
            getError(
              `${taxas}[2].detalhamentoParcelasOutrasBandeiras.taxa${parcela}x`
            ) &&
            getTouch(
              `${taxas}[2].detalhamentoParcelasOutrasBandeiras.taxa${parcela}x`
            )
          }
          errorMessage={
            getError(
              `${taxas}[2].detalhamentoParcelasOutrasBandeiras.taxa${parcela}x`
            ) as unknown as string
          }
          placeholder="0.0%"
          name={`${taxas}[2].detalhamentoParcelasOutrasBandeiras.taxa${parcela}x`}
        />
      </StyledCustomContent>
    </StyledCustomFlexRowContent>
  );
};

export default FlexibleInstallment;
