import { Container } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, SubTitle, Title } from '../../components/_Atoms';
import {
  BottomFixedActions,
  Breadcrumb,
  CustomDialog,
  Toast
} from '../../components/_Molecules';
import { IToast } from '../../components/_Molecules/Toast';
import {
  AllocationConfirmationList,
  AllocationFilters,
  AllocationList
} from '../../components/_Organisms';
import { API } from '../../constants/api.constants';
import { OPTIONS_TYPE_MACHINE_ALLOCATE } from '../../constants/machineAllocate.constants';
import { MENU_ROUTES, ROUTES } from '../../constants/routes.constants';
import {
  IFilters,
  IMachine,
  IMachineView
} from '../../interfaces/AllocateMachines.interfaces';
import { IDataResponseModel } from '../../interfaces/requests.interfaces';
import { get, post } from '../../services/axios.service';
import { decodeToken } from '../../services/jwt.service';

const MachineAllocation: FC = () => {
  const userInfo = decodeToken(
    JSON.parse(localStorage.getItem('user_data') || '{}').token
  );
  const router = useHistory();
  const [filters, setFilters] = useState<IFilters>({
    supervisor: '',
    tipoMaquina: OPTIONS_TYPE_MACHINE_ALLOCATE.map((i) => i.value),
    atribuidas: 0,
    equipe: userInfo?.role === 'Admin' ? [] : [Number(userInfo?.nameid)],
    serialNumber: ''
  });
  const [loading, setLoading] = useState<{
    data: boolean;
    teamOptions: boolean;
  }>({
    data: userInfo?.role !== 'Admin',
    teamOptions: false
  });
  const [machines, setMachines] = useState<IMachine[]>([]);
  const [selectedList, setSelectedList] = useState<IMachineView[]>([]);
  const [teamOptions, setTeamOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [openconfirmationModal, setOpenConfirmationModal] = useState(false);
  const [toastList, setToast] = useState<IToast[]>([]);
  const [viewAllSelected, setViewAllSelected] = useState(false);

  const getMachinesData = async () => {
    setLoading({
      ...loading,
      data: true
    });
    try {
      const data = await post<IDataResponseModel<IMachine[]>>(
        API.CONTROLE_ATIVOS.LISTAR_ATRIBUICAO,
        {
          ...(filters.tipoMaquina?.length < 3 && {
            tipoMaquinas: OPTIONS_TYPE_MACHINE_ALLOCATE.filter((item) =>
              filters.tipoMaquina.includes(item.value)
            ).map((item) => item.label)
          }),

          ...((filters.atribuidas === 1 || filters.atribuidas === 2) && {
            maquinasAtribuidas: filters.atribuidas === 1
          }),
          usuarioIds: filters.equipe,
          serialNumber: filters.serialNumber
        }
      );

      setMachines(data.data);
    } catch (error) {
      console.error(error);
    }

    setLoading({
      ...loading,
      data: false
    });
  };

  const getTeamOptions = async (id?: number) => {
    setLoading({
      ...loading,
      teamOptions: true
    });

    try {
      const teamRes = await get<
        IDataResponseModel<{ id: number; descricao: string }[]>
      >(
        `${API.USUARIOS.EQUIPE_DROPDOWN}${
          userInfo?.role === 'Admin' && id ? `?id=${id}` : ''
        }`
      );

      if (teamRes.success) {
        setTeamOptions(
          teamRes.data.map((item) => ({
            value: item.id,
            label: item.descricao
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }

    if (userInfo?.role !== 'Admin') {
      setFilters((prev) => ({
        ...prev,
        equipe: [Number(userInfo?.nameid)]
      }));
    }

    setLoading({
      ...loading,
      teamOptions: false
    });
  };

  useEffect(() => {
    getTeamOptions();
    if (userInfo?.role !== 'Admin') {
      getMachinesData();
    }
  }, []);

  return (
    <Container style={{ maxWidth: 1336 }}>
      <Breadcrumb
        style={{ margin: 0, padding: 0 }}
        currentRouteName="Atribuição de máquinas"
        confirmationModal
        modalText={{
          title: 'Atenção',
          message:
            'Ao sair desta tela, todas as novas informações não salvas serão perdidas.'
        }}
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          },
          {
            routeName: 'Gestão de ativos',
            routePath: MENU_ROUTES.ASSET_MANAGEMENT
          }
        ]}
      />
      <Title style={{ margin: '32px 0px 8px 0px' }} fontSize="big">
        Atribuição
      </Title>
      <SubTitle>Gerencie a atribuição de máquinas do seu estoque</SubTitle>

      <AllocationFilters
        filter={getMachinesData}
        filters={filters}
        setFilters={setFilters}
        teamOptions={teamOptions}
        getTeamOptions={getTeamOptions}
      />

      <AllocationList
        setSelectedList={setSelectedList}
        selectedList={selectedList}
        teamOptions={teamOptions}
        data={machines}
        loading={loading.data}
        viewAllSelected={viewAllSelected}
        setViewAllSelected={setViewAllSelected}
      />

      <AllocationConfirmationList
        open={openconfirmationModal}
        setOpen={setOpenConfirmationModal}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        toastList={toastList}
        setToast={setToast}
        setViewAllSelected={setViewAllSelected}
        getMachinesData={getMachinesData}
      />

      <BottomFixedActions style={{ zIndex: 1 }}>
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          value="Voltar"
          variant="secondary"
          onClick={() => setOpenBackModal(true)}
        />
        <Button
          style={{ margin: '0 6px' }}
          width="medium"
          disabled={selectedList.length === 0}
          value="Ver atribuições"
          onClick={() => setOpenConfirmationModal(true)}
        />
      </BottomFixedActions>

      {openBackModal && (
        <CustomDialog
          title="Atenção"
          message="Ao sair desta tela, todas as novas informações não salvas serão perdidas."
          handleClose={() => setOpenBackModal(false)}
          primaryButton={{
            buttonTitle: 'Sair desta tela',
            onClick: () => router.push(MENU_ROUTES.ASSET_MANAGEMENT)
          }}
          secondaryButton={{
            buttonTitle: 'Cancelar',
            onClick: () => setOpenBackModal(false)
          }}
        />
      )}

      <Toast toastlist={toastList} position="top-right" setList={setToast} />
    </Container>
  );
};

export default MachineAllocation;
