import styled from '@emotion/styled';
import { SelectField } from '../../components/_Atoms';

export const ContainerForm = styled.div`
  max-width: 620px;
  margin: auto;
  padding-bottom: 120px;
  input {
    height: 40px;
    width: 100%;
  }
  select {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const TipoMaquina = styled(SelectField)`
  width: 50%;
  margin-right: 20px;
`;
