export type StatusOptions = 'ativos' | 'inativos' | 'todos';
export const getFilterParamByStatus = (status: StatusOptions): string => {
  const param = {
    ativo: String(status === 'ativos')
  };

  return status === 'todos' ? '?' : buildQueryParams(param);
};

export const buildQueryParams = (params: Record<string, string>): string => {
  const searchParams = new URLSearchParams(params);
  return `?${searchParams.toString()}`;
};
