import styled from '@emotion/styled';

export const CountStyle = styled.div`
  text-align: center;
  font-size: 10px;
  padding: 8px;
  border-radius: 20px 20px 0 0;
  background-color: #eef0f2;
  max-width: 265px;
  b {
    font-weight: bold;
  }
`;
