import { FC, useEffect, useState } from 'react';
import { MENU_ROUTES, ROUTES } from '../../../constants/routes.constants';
import { SubTitle, Title } from '../../_Atoms';
import { Breadcrumb, SearchBarWithButton } from '../../_Molecules';

interface IProps {
  submitSearch: (p: string) => void;
  initialSearch: string;
}

const AssetManagementHeader: FC<IProps> = ({ submitSearch, initialSearch }) => {
  const [search, setSearch] = useState(initialSearch);

  const searchUser = () => {
    submitSearch(search);
  };

  useEffect(() => {
    setSearch(initialSearch);
  }, [initialSearch]);

  return (
    <div>
      <Breadcrumb
        style={{ margin: 0, padding: 0 }}
        currentRouteName="Controle de ativos"
        previousRoutes={[
          {
            routeName: 'Menu principal',
            routePath: ROUTES.MAIN_MENU
          },
          {
            routeName: 'Gestão de ativos',
            routePath: MENU_ROUTES.ASSET_MANAGEMENT
          }
        ]}
      />
      <Title style={{ margin: '32px 0px 8px 0px' }} fontSize="big">
        Controle de ativos
      </Title>
      <SubTitle>
        Selecione um usuário do sistema para consultar e visualizar todos os
        Serial Numbers de máquinas atribuídas disponíveis para venda.
      </SubTitle>

      <SearchBarWithButton
        search={search}
        placeholder="Pesquisar"
        button="Buscar"
        label="Buscar"
        setSearch={setSearch}
        submit={() => searchUser()}
      />
    </div>
  );
};

export default AssetManagementHeader;
