import React from 'react';
import { Checkbox, CheckboxProps } from '@sumup/circuit-ui';

type ICustomCheckbox = CheckboxProps;
const CustomCheckbox: React.FC<ICustomCheckbox> = ({
  value,
  name,
  children,
  ...props
}) => {
  return (
    <Checkbox
      onChange={props.onChange}
      checked={props.checked}
      value={value}
      name={name}
      noMargin
      {...props}
    >
      {children}
    </Checkbox>
  );
};

export default CustomCheckbox;
