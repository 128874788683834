import { SearchInput } from '@sumup/circuit-ui';
import { FC, useState } from 'react';
import {
  IFiltersTaskList,
  IRemetente
} from '../../../interfaces/taskList.interfaces';
import { rangeDateOptions } from '../../../utils/kanban';
import { Button, CustomDropdown } from '../../_Atoms';
import { SearchBar } from '../FilterUserEdit/SearchBar/styles';
import { ClearFilters } from '../FilterUserEdit/styles';
import SelectDateRange from '../Kanban-filters/selectDateRange';
import {
  FilterTaskListBottom,
  FilterTaskListContainer,
  FilterTaskListTop,
  MultiSelectStyle
} from './styles';

interface IProps {
  listaRemetentes: IRemetente[];
  getDataTable: () => void;
  filters: IFiltersTaskList;
  setFilters: (p: IFiltersTaskList) => void;
}

const CONST_STATUS = [
  { label: 'Todos', value: '' },
  { label: 'Completo', value: 'Completo' },
  { label: 'Reprovado', value: 'Reprovado' },
  { label: 'Parcial', value: 'Parcial' }
];

const FilterTaskList: FC<IProps> = ({
  filters,
  setFilters,
  listaRemetentes,
  getDataTable
}) => {
  const [initialLabelDate, setInitialLabelDate] = useState(8);

  return (
    <FilterTaskListContainer>
      <FilterTaskListTop>
        <div style={{ display: 'flex' }}>
          <CustomDropdown
            label="Status"
            placeholder="Selecione uma opção"
            options={CONST_STATUS}
            value={filters.status}
            onChangeValue={(e) => setFilters({ ...filters, status: e })}
            style={{ marginTop: 25, minWidth: 264, marginRight: 20 }}
          />
          <MultiSelectStyle
            options={listaRemetentes.map((item) => ({
              label: item.descricao,
              value: item.id
            }))}
            placeholder="Selecione uma opção"
            selected={filters.remetenteIdSelected}
            setSelected={(e) =>
              setFilters({ ...filters, remetenteIdSelected: e })
            }
            label="E-mail:"
            style={{ marginTop: 25 }}
          />
        </div>
        <ClearFilters
          active
          onClick={() => {
            setFilters({
              status: '',
              uploadDate: rangeDateOptions('8'),
              search: '',
              remetenteIdSelected: listaRemetentes.map((item) => item.id)
            });
            setInitialLabelDate(8);
          }}
          style={{ marginTop: 60, right: 10, position: 'relative' }}
        >
          Limpar filtros
        </ClearFilters>
      </FilterTaskListTop>

      <FilterTaskListBottom>
        <SelectDateRange
          date={filters.uploadDate}
          setValue={setInitialLabelDate}
          value={initialLabelDate}
          setDate={(e) => setFilters({ ...filters, uploadDate: e })}
        />

        <SearchBar style={{ maxWidth: 410, margin: -5 }}>
          <SearchInput
            label="Buscar por nome do arquivo"
            onClear={() => {
              setFilters({ ...filters, search: '' });
              getDataTable();
            }}
            clearLabel="clearable"
            value={filters.search}
            name="search"
            placeholder="Pesquisar"
            onChange={(e) => {
              setFilters({ ...filters, search: e.target.value.trimStart() });
              if (e.target.value === '') setFilters({ ...filters, search: '' });
            }}
            style={{ boxShadow: '0 0 0 1px #D8DDE1', borderRadius: 5 }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && filters.search !== '') {
                getDataTable();
              }
            }}
          />

          <Button
            style={{ marginTop: 5 }}
            value="Filtrar"
            onClick={() => {
              getDataTable();
            }}
          />
        </SearchBar>
      </FilterTaskListBottom>
    </FilterTaskListContainer>
  );
};

export default FilterTaskList;
