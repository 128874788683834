import styled from '@emotion/styled';
import { SelectOption } from '@sumup/circuit-ui';
import { Dropdown } from 'primereact/dropdown';
import { FC, HTMLAttributes } from 'react';
import './styles.css';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  options: SelectOption[];
  onChangeValue: (e: string) => void;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  filter?: boolean;
}

const DropDownStyle = styled.div`
  position: relative;
  min-width: 224px;

  @media (max-width: 1500px) {
    min-width: 210px;
    max-width: 210px;
  }

  div {
    &:active {
      outline: none;
      box-shadow: none;
    }
  }
`;

const DropDownLabel = styled.div`
  font-size: 14px;
`;

const CustomDropdown: FC<IProps> = ({
  label,
  value,
  onChangeValue,
  options,
  placeholder,
  filter = false,
  disabled = false,
  ...props
}) => {
  return (
    <DropDownStyle {...props}>
      <DropDownLabel>{label}</DropDownLabel>
      <Dropdown
        style={{ width: '100%' }}
        value={value}
        options={options}
        disabled={disabled}
        filter={filter}
        emptyMessage="Sem resultados"
        onChange={(e) => onChangeValue(e.value)}
        placeholder={placeholder}
      />
    </DropDownStyle>
  );
};

export default CustomDropdown;
