import { Close } from '@sumup/icons';
import { FC, useCallback, useEffect } from 'react';
import { StylesToast } from './styles';

interface IProps {
  toastlist: IToast[];
  position: string;
  setList: (item: IToast[]) => void;
  miliseconds?: number;
}

export interface IToast {
  id: number;
  title: string;
  description: string;
  type: 'error' | 'sucess';
}

const Toast: FC<IProps> = ({
  toastlist,
  position,
  setList,
  miliseconds = 5000
}) => {
  const deleteToast = useCallback(
    (id) => {
      const toastListItem = toastlist.filter((e: IToast) => e.id !== id);
      setList(toastListItem);
    },
    [toastlist, setList]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastlist.length) {
        deleteToast(toastlist[0].id);
      }
    }, miliseconds);

    return () => {
      clearInterval(interval);
    };
  }, [toastlist, deleteToast]);

  return (
    <StylesToast>
      <div className={`container ${position}`}>
        {toastlist.map((toast: IToast, i: number) => (
          <div
            key={i}
            className={`notification toast ${toast.type} ${position}`}
          >
            <button
              type="button"
              id="close-button"
              onClick={() => deleteToast(toast.id)}
            >
              <Close size="16" />
            </button>
            <div>
              <p className="title">{toast.title}</p>
              <p className="description">{toast.description}</p>
            </div>
          </div>
        ))}
      </div>
    </StylesToast>
  );
};

export default Toast;
