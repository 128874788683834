import { Dispatch, SetStateAction, useState } from 'react';

interface IPagination {
  readonly page: number;
  readonly setPage: Dispatch<SetStateAction<number>>;
  readonly totalItems: number;
  readonly setTotalItems: Dispatch<SetStateAction<number>>;
}
export const usePagination = (): IPagination => {
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  return {
    page,
    setPage,
    totalItems,
    setTotalItems
  } as const;
};
