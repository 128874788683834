import { FC } from 'react';

const SVGQuaseFechando: FC = () => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.48275 4.8975C2.40675 5.70816 2.35342 7.1428 2.83208 7.75346C2.83208 7.75346 2.60675 6.17748 4.62672 4.20018C5.44004 3.40419 5.62803 2.32154 5.34404 1.50956C5.18271 1.04956 4.88805 0.66957 4.63205 0.404241C4.48272 0.248243 4.59738 -0.00908618 4.81471 0.000247005C6.12936 0.0589128 8.25999 0.42424 9.16531 2.6962C9.56264 3.69352 9.59197 4.72417 9.40264 5.77216C9.28264 6.44148 8.85598 7.92946 9.8293 8.11212C10.524 8.24278 10.86 7.69079 11.0106 7.29347C11.0733 7.12814 11.2906 7.0868 11.4079 7.2188C12.5813 8.55345 12.6813 10.1254 12.4386 11.4787C11.9693 14.0947 9.31998 15.9987 6.68802 15.9987C3.40007 15.9987 0.782776 14.1174 0.10412 10.7121C-0.169209 9.33743 -0.0305438 6.61748 2.08942 4.69751C2.24675 4.55351 2.50408 4.68151 2.48275 4.8975Z"
        fill="url(#paint0_radial_4688_303)"
      />
      <path
        d="M7.89001 9.7894C6.67803 8.22942 7.22069 6.44945 7.51801 5.74013C7.55801 5.64679 7.45135 5.5588 7.36735 5.61613C6.84603 5.97079 5.77804 6.80544 5.28072 7.98009C4.60739 9.56807 4.65539 10.3454 5.05405 11.2947C5.29405 11.8667 5.01539 11.988 4.87539 12.0094C4.73939 12.0307 4.61406 11.94 4.51406 11.8454C4.2274 11.572 4.02074 11.216 3.92207 10.832C3.90074 10.7494 3.79274 10.7267 3.74341 10.7947C3.37008 11.3107 3.17675 12.1387 3.16742 12.724C3.13808 14.5333 4.63273 16 6.4407 16C8.71933 16 10.3793 13.48 9.06999 11.3734C8.69 10.76 8.33267 10.3587 7.89001 9.7894Z"
        fill="url(#paint1_radial_4688_303)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4688_303"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.03684 16.0401) rotate(-179.751) scale(9.41154 15.4425)"
        >
          <stop offset="0.3144" stopColor="#FF9800" />
          <stop offset="0.6616" stopColor="#FF6D00" />
          <stop offset="0.9715" stopColor="#F44336" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4688_303"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.56601 6.67463) rotate(90.5787) scale(9.84735 7.41087)"
        >
          <stop offset="0.2141" stopColor="#FFF176" />
          <stop offset="0.3275" stopColor="#FFF27D" />
          <stop offset="0.4868" stopColor="#FFF48F" />
          <stop offset="0.6722" stopColor="#FFF7AD" />
          <stop offset="0.7931" stopColor="#FFF9C4" />
          <stop offset="0.8221" stopColor="#FFF8BD" stopOpacity="0.804" />
          <stop offset="0.8627" stopColor="#FFF6AB" stopOpacity="0.529" />
          <stop offset="0.9101" stopColor="#FFF38D" stopOpacity="0.2088" />
          <stop offset="0.9409" stopColor="#FFF176" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default SVGQuaseFechando;
