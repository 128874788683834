import styled from '@emotion/styled';

export const ModalContainer = styled.div<{ open: boolean }>`
  display: ${(e) => (e.open ? 'flex' : 'none')};
  position: fixed;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px 24px 24px;

  p {
    font-size: 17px;
    color: #212933;
    font-weight: 700;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
`;

export const Children = styled.div`
  box-shadow: 0 -1px 0px rgba(12, 15, 20, 0.06),
    0 1px 1px rgba(12, 15, 20, 0.06);
  background-color: #fafbfc;
`;
