import { FC } from 'react';
import { ReactComponent as Pencil } from '../../../assets/pencil-broken.svg';
import { ReactComponent as SpilleCup } from '../../../assets/spilled-cup-error.svg';
import { Button, CustomButton } from '../../_Atoms';
import {
  ButtonsContainer,
  NetWorkErrorContainer,
  Subtitle,
  SubtitleContainer,
  Title
} from './styles';

interface IProps {
  retry?: () => void;
  back?: () => void;
  title?: string;
  subtitle?: string;
  secondSubtitle?: string;
  image?: 'spillecup' | 'pencil';
}

const NetworkError: FC<IProps> = ({
  retry,
  back,
  title,
  subtitle,
  secondSubtitle,
  image = 'spillecup'
}) => {
  return (
    <NetWorkErrorContainer>
      {image === 'pencil' ? <Pencil /> : <SpilleCup />}
      <Title>{title || 'Ops... Houve um erro ao carregar a lista'}</Title>
      <SubtitleContainer>
        <Subtitle>
          {subtitle || 'Aguarde uns instantes e tente novamente'}
        </Subtitle>
        {secondSubtitle && <Subtitle>{secondSubtitle}</Subtitle>}
      </SubtitleContainer>
      <ButtonsContainer>
        {!!back && (
          <CustomButton
            id="campaigns-back-button"
            type="button"
            variant="secondary"
            stretch
            onClick={back}
          >
            Voltar
          </CustomButton>
        )}
        {retry && (
          <Button
            value="Recarregar"
            id="campaigns-save-button"
            type="submit"
            variant="primary"
            onClick={retry}
          >
            Atualizar
          </Button>
        )}
      </ButtonsContainer>
    </NetWorkErrorContainer>
  );
};

export default NetworkError;
