import styled from '@emotion/styled';

export const StyledCustomTH = styled.th`
  background-color: #f9f9f9;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    position: sticky;
    left: 0;
    z-index: 15;
  }

  &:nth-child(2) {
    position: sticky;
    left: 256px;
    z-index: 15;
  }

  &:nth-child(3) {
    position: sticky;
    left: 511px;
    z-index: 15;
  }
`;

export const StyledCustomTD = styled.div`
  border-bottom: 1px solid #eef0f2;
  height: 72px;

  &:first-of-type {
    width: 260px;
    height: 72px;
    position: sticky;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 11;
    background-color: white;
    border-left: 1px solid #eef0f2;
    border-bottom: 1px solid #eef0f2;
  }

  &:nth-child(2) {
    position: sticky;
    left: 255px;
    z-index: 12;
    display: flex;
    align-items: center;
    align-content: center;
    height: 72px;
    padding: 0 12px;
    background-color: white;
    border-bottom: 1px solid #eef0f2;
  }
  &:nth-child(3) {
    position: sticky;
    left: 511px;
    z-index: 11;
    display: flex;
    align-items: center;
    align-content: center;
    height: 72px;
    padding: 0 12px;
    background-color: white;
    border-right: 1px solid #eef0f2;
    border-bottom: 1px solid #eef0f2;
  }

  &:last-child {
    border-right: 1px solid #eef0f2;
    height: 72px;
    position: sticky;
    right: 0;
    z-index: 2;
  }
`;
export const StyledCustomTRTitle = styled.div`
  height: 72px;
  background-color: #f9f9f9;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 16em) repeat(8, 192px) 5px;
  font-weight: 700;
  width: max-content;
  font-size: 16px;
  position: sticky;
  top: 0;
  z-index: 14;
`;
export const StyledCustomTRContent = styled.div<{ displayNone: boolean }>`
  display: ${({ displayNone }) => (!displayNone ? 'none' : 'grid')};
  width: max-content;
  grid-template-columns: repeat(3, 16em) repeat(8, 192px) 5px;
  align-items: center;
  height: 72px;
  text-align: center;
  z-index: -999;
`;

export const Box = styled.div`
  overflow: auto;
  height: auto;
  max-height: 509px;

  &::-webkit-scrollbar {
    padding: 0 30px; /* width of the entire scrollbar */
    border-left: 3px solid white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999999; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 5px solid white;
    margin-top: -200px;
  }
`;
