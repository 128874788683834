import { FC, HTMLAttributes } from 'react';
import {
  ActionContainer,
  BannerActionButton,
  BannerContainer,
  BannerTitle,
  Close
} from './style';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: string;
  action?: () => void;
  labelAction?: string;
  handleClose?: () => void;
}

const Banner: FC<IProps> = ({
  title,
  description,
  action,
  labelAction = 'Ok',
  handleClose,
  ...props
}) => {
  return (
    <BannerContainer {...props}>
      <BannerTitle>{title}</BannerTitle>
      <p>{description}</p>
      {action && (
        <ActionContainer>
          <BannerActionButton
            style={{ marginTop: 24 }}
            value={labelAction}
            width="auto"
            variant="secondary"
            onClick={action}
          />
        </ActionContainer>
      )}
      <Close onClick={handleClose} style={{ cursor: 'pointer' }} />
    </BannerContainer>
  );
};

export default Banner;
